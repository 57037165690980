import { applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import entitiesMiddleware from 'datasetManagementUI/reduxStuff/middleware/entities';

const middleware = [thunkMiddleware];

if (window.serverConfig?.environment === 'development') {
  middleware.push(
    createLogger({
      duration: true,
      timestamp: false,
      collapsed: true,
      logErrors: false
    })
  );

  middleware.push(entitiesMiddleware);

  console.log(
    'for convenience, try e.g. `console.table(ENTITIES.sources)` (only works when RAILS_ENV==development)'
  );
}

export default applyMiddleware(...middleware);
