
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import bytes from 'bytes';
import { Link } from 'react-router';
import I18n from 'common/i18n';

const scope = 'dataset_management_ui';

 // I18n.t('number_format.standard', { scope })

export class BlobFileInfo extends Component {
  render() {
    const { contentType, fileSize, sourcesLink } = this.props;

    return (
      <section className="blob-preview" id="blob-file-info">
        {/* blob-preview is a styleguide/common class */}
        <h2>{I18n.t('blob_preview.whats_this', { scope })}</h2>
        <div className="blob-file-info">
          {contentType && <span id="sourceFiletype">
            <b>{I18n.t('blob_preview.filetype', { scope })}:</b> {contentType}
          </span>}
          <br />
          <span id="sourceFilesize">
            <b>{I18n.t('blob_preview.filesize', { scope })}:</b> {bytes(fileSize)}
          </span>
        </div>
        {/* alert info is a styleguide/common class */}
        <div className="alert info">
          {I18n.t('blob_preview.non_parsable_format', { scope })}
          <br />
          <br />
          {I18n.t('blob_preview.not_expecting', { scope })}
          <br />
          <a href="https://support.socrata.com/hc/en-us/articles/202949918-Importing-Data-Types-and-You" target="_blank">
            {I18n.t('blob_preview.get_help', { scope })}
          </a>{' '}
          {I18n.t('blob_preview.or', { scope })}
          <Link to={sourcesLink}> {I18n.t('blob_preview.choose_new', { scope })}</Link>.
        </div>
      </section>
    );
  }
}

BlobFileInfo.propTypes = {
  contentType: PropTypes.string.isRequired,
  fileSize: PropTypes.number.isRequired,
  sourcesLink: PropTypes.string.isRequired
};

export default BlobFileInfo;
