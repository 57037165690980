import {
  OutputSchemaActivity,
  RevisionActivity,
  SourceActivity,
  TaskSetActivity,
  TaskSetFailedActivity,
  TaskSetFinishedActivity
} from 'datasetManagementUI/components/RecentActionItems/RecentActionItems';
import { DSMUIActivityType, ActivityItem } from 'datasetManagementUI/lib/recentActionsTypes';
import { Params } from 'datasetManagementUI/lib/types';
import React from 'react';

function RecentActions({ activities, params }: Props) {
  const items = activities.map((activity, idx) => {
    switch (activity.type) {
      case DSMUIActivityType.Revision:
        return <RevisionActivity key={idx} params={params} details={activity} />;
      case DSMUIActivityType.Source:
        return <SourceActivity key={idx} params={params} details={activity} />;
      case DSMUIActivityType.OutputSchema:
        return <OutputSchemaActivity key={idx} params={params} details={activity} />;
      case DSMUIActivityType.TaskSet:
        return <TaskSetActivity key={idx} params={params} details={activity} />;
      case DSMUIActivityType.TaskSetFailed:
        return <TaskSetFailedActivity key={idx} params={params} details={activity} />;
      case DSMUIActivityType.TaskSetFinished:
        return <TaskSetFinishedActivity key={idx} params={params} details={activity} />;
      default:
        return null;
    }
  });

  return <div>{items}</div>;
}

interface Props {
  activities: ActivityItem[];
  params: Params;
}

export default RecentActions;
