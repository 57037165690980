import _ from 'lodash';
import PropTypes from 'prop-types';
import { Params } from './types';
import { OutputSchemaId, TransformId, Transform } from 'common/types/dsmapiSchemas';
import {
  geocodeShortcut,
  geocodeShortcutErrors,
  showColumnErrors,
  showOutputSchema,
  showRowErrors
} from 'datasetManagementUI/links/links';

interface Normal {
  type: 'NORMAL';
  outputSchemaId: OutputSchemaId;
  pageNo: number;
}
interface RowErrors {
  type: 'ROW_ERRORS';
  outputSchemaId: OutputSchemaId;
  pageNo: number;
}
interface ColumnErrors {
  type: 'COLUMN_ERRORS';
  outputSchemaId: OutputSchemaId;
  pageNo: number;
  transformId: TransformId;
}


export type DisplayState = Normal | RowErrors | ColumnErrors;

export const NORMAL = 'NORMAL';
export const normal = (pageNo: number, outputSchemaId: OutputSchemaId): Normal => ({
  type: NORMAL,
  pageNo,
  outputSchemaId
});

export const COLUMN_ERRORS = 'COLUMN_ERRORS';
export const columnErrors = (transformId: TransformId, pageNo: number, outputSchemaId: OutputSchemaId): ColumnErrors => ({
  type: COLUMN_ERRORS,
  transformId,
  pageNo,
  outputSchemaId
});

export const ROW_ERRORS = 'ROW_ERRORS';
export const rowErrors = (pageNo: number, outputSchemaId: OutputSchemaId): RowErrors => ({
  type: ROW_ERRORS,
  pageNo,
  outputSchemaId
});

interface RouterState {
  params: Params;
  location: {
    pathname: string;
  };
}
export function fromUiUrl({ params, location }: RouterState) {
  const pageNo = _.toNumber(params.pageNo || '1');
  const outputSchemaId = _.toNumber(params.outputSchemaId);
  if (params.errorsTransformId) {
    return columnErrors(_.toNumber(params.errorsTransformId), pageNo, outputSchemaId);
  } else if (location.pathname.indexOf('row_errors') > 0) {
    return rowErrors(pageNo, outputSchemaId);
  } else {
    return normal(pageNo, outputSchemaId);
  }
}

export function toUiUrl(params: Params, displayState: DisplayState) {
  switch (displayState.type) {
    case NORMAL:
      if (params.geocodeShortcut) {
        return geocodeShortcut(params, displayState.pageNo);
      } else {
        return showOutputSchema(
          params,
          _.toNumber(params.sourceId),
          _.toNumber(params.inputSchemaId),
          _.toNumber(params.outputSchemaId),
          displayState.pageNo
        );
      }

    case ROW_ERRORS:
      return showRowErrors(
        params,
        _.toNumber(params.sourceId),
        _.toNumber(params.inputSchemaId),
        _.toNumber(params.outputSchemaId),
        displayState.pageNo
      );

    case COLUMN_ERRORS:
      if (params.geocodeShortcut) {
        return geocodeShortcutErrors(params, displayState.transformId, displayState.pageNo);
      } else {
        return showColumnErrors(
          params,
          _.toNumber(params.sourceId),
          _.toNumber(params.inputSchemaId),
          _.toNumber(params.outputSchemaId),
          displayState.transformId,
          displayState.pageNo
        );
      }

    default:
      console.error('unknown display state type', displayState);
  }
}

export function inErrorMode(displayState: DisplayState, transform: Transform) {
  return displayState.type === COLUMN_ERRORS && transform.id === displayState.transformId;
}

export const propType = PropTypes.shape({
  type: PropTypes.oneOf([NORMAL, COLUMN_ERRORS, ROW_ERRORS]).isRequired,
  pageNo: PropTypes.number.isRequired,
  outputSchemaId: PropTypes.number.isRequired,
  transformId: PropTypes.number
});
