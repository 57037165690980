import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import TextInput from 'datasetManagementUI/components/TextInput/TextInput';
import { getBasename, getExtension } from 'datasetManagementUI/lib/util';
import DSMUIIcon from '../DSMUIIcon';
import { DuplicateExtension, BadUrl, MissingValue } from 'datasetManagementUI/containers/HrefFormContainer';
import I18n from 'common/i18n';

const scope = 'dataset_management_ui.show_sources';

class URLField extends Component {
  render() {
    const { handleChangeUrl, handleXClick, value, errors, hrefId, uuid } = this.props;

    const urlErrors = _.chain(errors)
      .filter(err => err instanceof BadUrl)
      .flatMap(err => err.urls)
      .value();

    const dupeErrors = _.chain(errors)
      .filter(err => err instanceof DuplicateExtension)
      .filter(err => err.hrefId === hrefId)
      .flatMap(err => err.extensions)
      .value();

    const missingValueErrors = _.chain(errors)
      .filter(err => err instanceof MissingValue)
      .filter(err => err.hrefId === hrefId)
      .filter(err => err.urlId === uuid)
      .value();

    const urlInErrorState = urlErrors.length ? urlErrors.map(err => err.uuid).includes(uuid) : false;

    let filetypeInErrorState = false;
    let errorMessage = '';

    if (missingValueErrors.length) {
      filetypeInErrorState = true;
      errorMessage = I18n.t('error_no_empties', { scope });
    } else if (dupeErrors.length) {
      filetypeInErrorState = dupeErrors.includes(value.filetype);
      errorMessage = I18n.t('error_no_dupes', { scope });
    }

    return (
      <div id="url-field">
        <div className="urlFieldContainer">
          <div className="urlFieldArea">
            <label>{I18n.t('label_url', { scope })}</label>
            <TextInput
              field={{
                value: value.url,
                label: I18n.t('label_url', { scope }),
                name: 'url',
                isRequired: true
              }}
              inErrorState={urlInErrorState}
              handleChange={e =>
                handleChangeUrl({
                  url: e.target.value,
                  filetype: getExtension(getBasename(e.target.value))
                })
              } />
            {urlInErrorState && <div className="error">{I18n.t('error_url', { scope })}</div>}
          </div>
          <div className="filetypeFieldArea">
            <label>{I18n.t('label_file_type', { scope })}</label>
            <TextInput
              field={{
                name: 'filetype',
                value: value.filetype,
                label: I18n.t('label_file_type', { scope })
              }}
              inErrorState={filetypeInErrorState}
              handleChange={e =>
                handleChangeUrl({
                  ...value,
                  filetype: e.target.value
                })
              } />
            {filetypeInErrorState && <div className="error">{errorMessage}</div>}
          </div>
          <DSMUIIcon name="close-2" className="closeButton" onIconClick={handleXClick} />
        </div>
      </div>
    );
  }
}

URLField.propTypes = {
  value: PropTypes.shape({
    url: PropTypes.string,
    filetype: PropTypes.string
  }),
  uuid: PropTypes.string.isRequired,
  hrefId: PropTypes.number.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChangeUrl: PropTypes.func.isRequired,
  handleXClick: PropTypes.func.isRequired
};

export default URLField;
