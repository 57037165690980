import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import entities from 'datasetManagementUI/reduxStuff/reducers/entities';
import ui from 'datasetManagementUI/reduxStuff/reducers/ui';
import uploadFile from 'datasetManagementUI/reduxStuff/reducers/uploadFile';
import loadData from 'datasetManagementUI/reduxStuff/reducers/loadData';
import loadRevision from 'datasetManagementUI/reduxStuff/reducers/loadRevision';
import createSource from 'datasetManagementUI/reduxStuff/reducers/createSource';
import showOutputSchema from 'datasetManagementUI/reduxStuff/reducers/showOutputSchema';
import socket from 'datasetManagementUI/reduxStuff/reducers/socket';

const combined = combineReducers({
  entities,
  ui,
  socket
});

export default reduceReducers(
  combined,
  uploadFile,
  loadData,
  loadRevision,
  createSource,
  showOutputSchema
);
