import CheckBox from 'datasetManagementUI/components/CheckBox/CheckBox';
import React from 'react';
import { fetchTranslation } from 'common/locale';

const t = (k: string) => fetchTranslation(k, 'dataset_management_ui.parse_options');
type InputEvent = React.ChangeEvent<HTMLInputElement>;

interface Props {
  name: string;
  setOption: (v: boolean) => void;
  value: boolean;
}
export default function ParseFlag({ name, setOption, value}: Props) {
  return (
    <div>
      <label htmlFor={name}>
        {t(name)}
      </label>
      <CheckBox
        id={name}
        name={name}
        value={value}
        handleClick={(e: InputEvent) => setOption(e.target.checked)} />
    </div>
  );
}
