import { withRouter, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as Links from 'datasetManagementUI/links/links';
import { hideModal } from 'datasetManagementUI/reduxStuff/actions/modal';
import { currentRevision } from 'datasetManagementUI/selectors';
import ExtraColumnsWarning from 'datasetManagementUI/components/ExtraColumnsModal/ExtraColumnsModal';

const mapStateToProps = ({ entities }, { params, extraColumns }) => {
  const revision = currentRevision(entities, _.toNumber(params.revisionSeq));
  const canAddColumn = !(revision.action && revision.action.type === 'update');
  const goToAddColumn = () => browserHistory.push(Links.showAddCol(params));

  return {
    extraColumns,
    canAddColumn,
    goToAddColumn
  };
};

const mapDispatchToProps = dispatch => ({
  onDismiss: () => dispatch(hideModal())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExtraColumnsWarning));
