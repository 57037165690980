import { UPDATE_UNDO_REDO_HISTORY } from 'datasetManagementUI/reduxStuff/actions/vqeUndoRedoHistory';
import { UpdateUndoRedoHistory } from 'datasetManagementUI/reduxStuff/actions/vqeUndoRedoHistory';

const vqeUndoRedoHistory = (state = {}, action: UpdateUndoRedoHistory) => {
  switch (action.type) {
    case UPDATE_UNDO_REDO_HISTORY:
      return action.vqeUndoRedoHistory;
    default:
      return state;
  }
};

export default vqeUndoRedoHistory;
