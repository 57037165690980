import _ from 'lodash';
import { connect } from 'react-redux';
import UpdateReplaceChooser from 'datasetManagementUI/components/UpdateReplaceChooser/UpdateReplaceChooser';
import { updateRevision } from 'datasetManagementUI/reduxStuff/actions/revisions';
import { getPlan } from 'datasetManagementUI/reduxStuff/actions/applyRevision';
import * as Selectors from 'datasetManagementUI/selectors';
import { browserHistory } from 'react-router';
import * as Links from 'datasetManagementUI/links/links';

export function mapStateToProps({ entities }, { params, useInverseStyle, isDisabled }) {
  const revisionSeq = _.toNumber(params.revisionSeq);
  const revision = Selectors.currentRevision(entities, revisionSeq);

  return {
    revision,
    params,
    useInverseStyle,
    isDisabled
  };
}

function mapDispatchToProps(dispatch, { params }) {
  return {
    setDataAction: (updated) => dispatch(updateRevision(updated, params)),
    gotoSources: () => {
      browserHistory.push(Links.sources(params));
    },

    getPlan
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateReplaceChooser);
