const initialState = {
  mode: '',
  grant: ''
};

function modeGrant(state = initialState) {
  return state;
}

export default modeGrant;
