import { AutosaveAction } from '../types';

export enum AutosavingTypes {
  START_AUTOSAVE = 'START_AUTOSAVE',
  FINISH_AUTOSAVE = 'FINISH_AUTOSAVE'
}

export function startAutosave(): AutosaveAction {
  return { type: AutosavingTypes.START_AUTOSAVE };
}

export function finishAutosave() {
  return { type: AutosavingTypes.FINISH_AUTOSAVE };
}
