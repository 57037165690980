import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { CSSTransition } from 'react-transition-group';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import ProgressBar from 'datasetManagementUI/components/ProgressBar/ProgressBar';
import DSMUIIcon from '../DSMUIIcon';
import I18n from 'common/i18n';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.notifications' });

class Notification extends Component {
  constructor() {
    super();

    this.state = {
      detailsOpen: true
    };

    _.bindAll(this, ['toggleDetails']);
  }

  toggleDetails() {
    this.setState({
      detailsOpen: !this.state.detailsOpen
    });
  }

  render() {
    const {
      status,
      progressBar,
      percentCompleted,
      message,
      children,
      removeNotification,
      id,
      isInfinite
    } = this.props;
    const { detailsOpen } = this.state;
    let className = ['notification'];
    let statusIcon;
    let statusMessage;
    let progressBarType = status;

    switch (status) {
      case 'success':
        className = [...className, 'success'].join(' ');
        statusIcon = <DSMUIIcon name="check" className="success-icon" />;
        statusMessage = (
          <span className="success-message">
            {t('success')}
          </span>
        );
        break;
      case 'inProgress':
        className = [...className, 'in-progress'].join(' ');
        if (isInfinite) {
          statusIcon = <span className="progress-icon" />;
        } else {
          statusIcon = <span className="progress-icon">{`${Math.round(percentCompleted)}%`}</span>;
        }

        break;
      case 'error':
        className = [...className, 'error'].join(' ');
        statusIcon = <DSMUIIcon name="warning" className="error-icon icon" />;
        statusMessage = (
          <a href="#" className="details-toggle" onClick={this.toggleDetails}>
            {detailsOpen ? t('hide_details') : t('show_details')}
          </a>
        );
        break;
      case 'warning':
        className = [...className, 'warning'].join(' ');
        statusIcon = <DSMUIIcon name="check" className="warning-icon" />;
        statusMessage = (
          <span className="warning-message">
            {t('uploaded_with_errors')}
          </span>
        );
        break;
      case 'secure':
        className = [...className, 'secure'].join(' ');
        statusIcon = <SocrataIcon name={IconName.Security} />;
        statusMessage = (
          <span className="success-message">
            {t('success')}
          </span>
        );
        progressBarType = 'success';
        break;
      case 'insecure':
        className = [...className, 'insecure'].join(' ');
        statusIcon = <DSMUIIcon name="warning" className="error-icon icon" />;
        statusMessage = (
          <span className="warning-message">
            {t('file_issue')}
          </span>
        );
        progressBarType = 'error';
        break;
      default:
        className = className.join(' ');
    }

    return (
      <div className={className}>
        <div className="cf">
          <span className="message-area">
            {message}
          </span>
          <span className="status-area">
            {statusMessage}
            {statusIcon}
          </span>
        </div>
        {progressBar &&
          <div className="progress-bar-container">
            <ProgressBar percent={percentCompleted || 0} type={progressBarType} className="progress-bar" />
          </div>}
        {children &&
          <CSSTransition
            in={detailsOpen}
            mountOnEnter
            unmountOnExit
            classNames={{
              enter: 'enter',
              enterActive: 'enter-active',
              exit: 'exit',
              exitActive: 'exit-active'
            }}
            timeout={500}>
            {detailsOpen &&
              <div>
                {children}
                <div className="btn-container">
                  <button className="btn btn-primary btn-xs" onClick={() => removeNotification(id)}>
                    {t('dismiss')}
                  </button>
                  <a
                    target="_blank"
                    href="https://support.socrata.com"
                    className="contact-support">
                    {t('contact_support')}
                  </a>
                </div>
              </div>}
          </CSSTransition>}
      </div>
    );
  }
}

Notification.propTypes = {
  status: PropTypes.string.isRequired,
  progressBar: PropTypes.bool,
  percentCompleted: PropTypes.number,
  children: PropTypes.object,
  message: PropTypes.object.isRequired,
  id: PropTypes.any.isRequired,
  removeNotification: PropTypes.func.isRequired,
  isInfinite: PropTypes.bool
};

export default Notification;
