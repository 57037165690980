import I18n from 'common/i18n';
import { AssetType, View } from 'common/types/view';
import { isQueryJSONBased } from 'common/views/view_types';
import { canEditOrReviewSources } from 'datasetManagementUI/selectors';
import React from 'react';
import Card from '../DataActionsCard/DataActionsCard';

interface Props {
  view: View;
}
function DataActionsPanel({ view }: Props) {
  return (
    <section id="data-actions-panel">
      <h2>{I18n.t('common.data_actions.data_actions')}</h2>
      <div className="card-container">
        {canEditOrReviewSources(view) ? (
          <>
            <Card.AddDataCard />
            <Card.ReviewDataCard />
          </>
        ) : null}
        {/* isDerivedView is wrong, because it checks tabular-ness, which a draft is not? oof */}
        {(view.assetType === AssetType.Filter && !isQueryJSONBased(view)) && <Card.EditSoQLQueryCard />}
        <Card.EditMetadataCard />
        <Card.EditColumnMetadataCard />
      </div>
    </section>
  );
}

export default DataActionsPanel;
