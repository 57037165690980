export const SHOW_MODAL = 'SHOW_MODAL';
export const showModal = (contentComponentName, payload = null) => ({
  type: SHOW_MODAL,
  contentComponentName,
  payload
});

export const HIDE_MODAL = 'HIDE_MODAL';
export const hideModal = () => ({
  type: HIDE_MODAL
});

export const PUSH_MODAL = 'PUSH_MODAL';
export const pushModal = (contentComponentName, payload = null) => ({
  type: PUSH_MODAL,
  contentComponentName,
  payload
});

export const POP_MODAL = 'POP_MODAL';
export const popModal = () => ({
  type: POP_MODAL
});
