import PropTypes from 'prop-types';
import React from 'react';
import TransformColumn from 'datasetManagementUI/containers/TransformColumnContainer';

const TransformColumnPane = (props) => {
  return (
    <div className="content-wrap options-wrap">
      <TransformColumn params={props.params} location={props.location} redirectToOutputSchema={props.redirectToOutputSchema} />
    </div>
  );
};

TransformColumnPane.propTypes = {
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  redirectToOutputSchema: PropTypes.func.isRequired
};

export default TransformColumnPane;
