import { DsmapiResource, PhxChannel, socket } from 'common/types/dsmapi';
import {
  InputColumn,
  InputSchema,
  NestedOutputSchema,
  OutputColumn,
  OutputSchema,
  OutputSchemaId,
  Transform,
  WithTransform
} from 'common/types/dsmapiSchemas';
import ImportConfig from 'common/types/importConfig';
import { Scope } from 'common/types/soql';
import { Source, isViewSourceType, isUnloadedViewSource } from 'common/types/source';
import Modes from 'datasetManagementUI/lib/modes';
import { AppState, Dispatch, Params } from 'datasetManagementUI/lib/types';
import { transformColumn } from 'datasetManagementUI/links/links';
import { getImportConfig } from 'datasetManagementUI/reduxStuff/actions/configs';
import { DesiredColumns, newOutputSchema } from 'datasetManagementUI/reduxStuff/actions/showOutputSchema';
import { DsmuiCompiler, Preview } from 'datasetManagementUI/reduxStuff/reducers/compiler';
import _ from 'lodash';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import TransformColumn from '../components/TransformColumn/TransformColumn';
import { addCompiler, compileExpression, removeCompiler } from '../reduxStuff/actions/compiler';
import { columnsForInputSchema, columnsForOutputSchema } from '../selectors';

interface StateProps {
  uid: string;
  config: ImportConfig;
  source: Source;
  inputSchema: InputSchema;
  inputColumns: InputColumn[];
  outputSchema: OutputSchema;
  outputColumns: (OutputColumn & WithTransform)[];
  outputColumn: OutputColumn & WithTransform;
  transform: Transform;
  canChangeType: boolean;
  unloadedViewSource: boolean;
  snippetsChannel: () => PhxChannel;
  // this is because you can't pass params in at the router level, like you used to be able to
  // do in react-router
  params: Params;
  location: string;
  compiler: DsmuiCompiler | null;
  preview: Preview | null;
  scope: Scope;
  redirectToOutputSchema: (os: number) => void;
}
function mapStateToProps(
  { entities, ui }: AppState,
  { params, location, redirectToOutputSchema }: OwnProps
): StateProps {
  if (
    _.isUndefined(params.fourfour) ||
    _.isUndefined(params.sourceId) ||
    _.isUndefined(params.inputSchemaId) ||
    _.isUndefined(params.outputSchemaId) ||
    _.isUndefined(params.outputColumnId)
  ) {
    throw new Error('Undefined parameters while instantiating TransformColumn');
  }

  const snippetsChannel = () => socket().channel('transform_snippets');
  // If we're not editing and doing an update, we can change the resulting
  // type of the expression
  const canChangeType = !Modes.modes(entities, params).update;

  const source = entities.sources[params.sourceId];

  const isViewSource = isViewSourceType(source.source_type);
  const unloadedViewSource = isUnloadedViewSource(source.source_type);

  // we only supply the config in the case of a view source.
  const config = isViewSource ? entities.configs[params.fourfour] : null;

  const inputSchema = entities.input_schemas[params.inputSchemaId];
  const inputColumns = columnsForInputSchema(entities, inputSchema.id);

  const outputSchema = entities.output_schemas[params.outputSchemaId];
  const outputColumns = columnsForOutputSchema(entities, outputSchema.id);

  const transform = entities.transforms[entities.output_columns[params.outputColumnId].transform_id];
  const outputColumn: OutputColumn & WithTransform = {
    ...entities.output_columns[params.outputColumnId],
    transform
  };

  return {
    uid: params.fourfour,
    canChangeType,
    snippetsChannel,
    // this is because you can't pass params in at the router level, like you used to be able to
    // do in react-router
    params: { ...params, transformEditor: true },
    location,
    compiler: ui.compiler?.compiler || null,
    scope: ui.compiler?.scope || [],
    preview: ui.compiler.preview || null,
    redirectToOutputSchema,
    config,
    source,
    inputSchema,
    inputColumns,
    outputSchema,
    outputColumns,
    outputColumn,
    transform,
    unloadedViewSource,
  };
}

interface OwnProps {
  params: Params;
  location: string;
  redirectToOutputSchema: (os: OutputSchemaId) => void;
}

interface DispatchProps {
  dispatch: Dispatch;
  addCompiler: (is: InputSchema) => void;
  removeCompiler: () => void;
  compileExpression: (c: DsmuiCompiler, e: string, g: boolean) => void;
  newOutputSchema: (
    is: InputSchema,
    e: string,
    dc: DesiredColumns,
    os: Partial<OutputSchema>
  ) => Promise<DsmapiResource<NestedOutputSchema>>;
}

function mapDispatchToProps<T>(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    dispatch,
    addCompiler: (inputSchema: InputSchema) => dispatch(addCompiler(inputSchema)),
    removeCompiler: () => dispatch(removeCompiler()),
    compileExpression: (compiler: DsmuiCompiler, expression: string, generatePreview: boolean) =>
      dispatch(compileExpression(compiler, expression, generatePreview)),
    newOutputSchema: (inputSchema: InputSchema, expr: string, desiredColumns: DesiredColumns) =>
      dispatch(newOutputSchema(inputSchema.id, desiredColumns, true)).then(
        (resource: DsmapiResource<NestedOutputSchema>) => {
          const sourceId = inputSchema.source_id;
          const inputSchemaId = inputSchema.id;
          const outputSchema = resource.resource;
          if (outputSchema.output_columns) {
            const outputColumn = _.find(outputSchema.output_columns, (oc) => {
              return oc.transform ? oc.transform.transform_expr === expr : false;
            });

            if (!outputColumn) {
              throw new Error('Created successfully with the wrong expr?');
            }

            browserHistory.push(
              transformColumn(
                {
                  ...ownProps.params,
                  outputSchemaId: _.toString(outputSchema.id),
                  outputColumnId: _.toString(outputColumn.id)
                },
                sourceId,
                inputSchemaId,
                outputSchema.id,
                outputColumn.id
              )
            );
          }

          return resource;
        }
      )
  };
}

function mergeProps(
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): TransformColumnProps {
  const getConfig = async () => {
    if (stateProps.config) return stateProps.config;
    return dispatchProps.dispatch(getImportConfig(stateProps.params));
  };

  return {
    ...stateProps,
    ...ownProps,
    ...dispatchProps,
    getConfig
  };
}

export type TransformColumnProps = StateProps &
  DispatchProps & {
    getConfig: () => Promise<ImportConfig>;
  };

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TransformColumn);
