import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Fieldset from 'datasetManagementUI/components/Fieldset/Fieldset';
import TextInput from 'datasetManagementUI/components/TextInput/TextInput';
import TextArea from 'datasetManagementUI/components/TextArea/TextArea';
import URLField from 'datasetManagementUI/components/URLField/URLField';
import { BadDataDict } from 'datasetManagementUI/containers/HrefFormContainer';
import I18n from 'common/i18n';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.show_sources' });

function dataDictErrorsExist(errors = []) {
  return _.some(errors, err => err instanceof BadDataDict);
}

const DatasetFieldset = ({
  href,
  handleAddURL,
  handleChangeUrl,
  handleChangeHref,
  handleRemoveFirstURL,
  handleRemoveOtherURL,
  handleXClick,
  errors
}) => (
  <div id="dataset-fieldset">
    <Fieldset
      title={href.title}
      closable
      closeCallback={handleXClick}
      containerClass="dsmp-fieldset"
      legendClass="dsmp-legend">
      <div className="dsmp-field-wrapper" data-cheetah-hook="dataset-fieldset">
        <div>
          <label>{t('label_name')}</label>
          <TextInput
            field={{
              name: 'title',
              value: href.title,
              label: t('label_name')
            }}
            inErrorState={false}
            handleChange={e => handleChangeHref(href.id, 'title', e.target.value)} />
        </div>
        <div>
          <label>{t('label_description')}</label>
          <TextArea
            field={{
              name: 'description',
              value: href.description,
              label: t('label_description')
            }}
            inErrorState={false}
            handleChange={e => handleChangeHref(href.id, 'description', e.target.value)} />
        </div>
        <div>
          {Object.keys(href.urls).map((key, idx) => {
            return (
              <URLField
                key={key}
                uuid={key}
                hrefId={href.id}
                errors={errors}
                value={href.urls[key]}
                handleXClick={
                  idx === 0
                    ? () => handleRemoveFirstURL(href.id, key)
                    : () => handleRemoveOtherURL(href.id, key)
                }
                handleChangeUrl={handleChangeUrl(key)} />
            );
          })}
          <a className="dsmp-add-URL-btn" onClick={handleAddURL}>
            {t('add_url')}
          </a>
        </div>
        <div>
          <label>{t('label_data_dictionary')}</label>
          <TextInput
            field={{
              name: 'dictionary-url',
              value: href.data_dictionary,
              label: t('label_data_dictionary')
            }}
            inErrorState={dataDictErrorsExist(errors)}
            handleChange={e => handleChangeHref(href.id, 'data_dictionary', e.target.value)} />
            {dataDictErrorsExist(errors) && <div className="dsmp-fieldset-error">{t('error_url')}</div>}
        </div>
      </div>
    </Fieldset>
  </div>
);

DatasetFieldset.propTypes = {
  href: PropTypes.shape({
    id: PropTypes.number.isRequired,
    data_dictionary: PropTypes.string,
    data_dictionary_type: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    urls: PropTypes.object.isRequired
  }),
  handleAddURL: PropTypes.func.isRequired,
  handleChangeUrl: PropTypes.func.isRequired,
  handleChangeHref: PropTypes.func.isRequired,
  handleRemoveFirstURL: PropTypes.func.isRequired,
  handleRemoveOtherURL: PropTypes.func.isRequired,
  handleXClick: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DatasetFieldset;
