import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'datasetManagementUI/lib/types';
import I18n from 'common/i18n';
import FeatureFlags from 'common/feature_flags';

interface GeocodeButtonsOwnProps {
  oldOutputSchemaId: number;
  redirectToOutputSchema: (id: number) => void;
}

interface GeocodeButtonsStateProps {
  doneEnabled: boolean;
  newOutputSchemaId: number;
  oldOutputSchemaId?: number;
  saveEnabled: boolean;
}

type GeocodeButtonsProps = GeocodeButtonsOwnProps & GeocodeButtonsStateProps;

const SaveGeocodeShortcutButtons = ({
  doneEnabled,
  oldOutputSchemaId,
  newOutputSchemaId,
  redirectToOutputSchema,
  saveEnabled
}: GeocodeButtonsProps) => {

  const redirectToOldOutputSchema = () => redirectToOutputSchema(oldOutputSchemaId);
  const redirectToNewOutputSchema = () => redirectToOutputSchema(newOutputSchemaId);

  return (
    <div>
      <button className='btn btn-default' disabled={doneEnabled} onClick={redirectToOldOutputSchema}>
        {I18n.t('dataset_management_ui.common.cancel')}
      </button>
      <button className='btn btn-primary' disabled={!doneEnabled} onClick={redirectToNewOutputSchema}>
        {I18n.t('dataset_management_ui.common.done')}
      </button>
    </div>
  );
};

const mapStateToProps = (state: AppState, props: GeocodeButtonsOwnProps): GeocodeButtonsProps => {
  const form = state.ui.forms.geocodeShortcutForm;
  const desiredColumns = _.get(form, 'state.desiredColumns');
  const doneEnabled = _.get(form, 'submitted');
  const newOutputSchemaId = _.get(form, 'state.newOutputSchemaId');
  const oldOutputSchemaId = _.get(form, 'state.oldOutputSchemaId', props.oldOutputSchemaId);
  const saveEnabled = !!desiredColumns;

  return {
    ...props,
    doneEnabled,
    newOutputSchemaId,
    oldOutputSchemaId,
    saveEnabled
  };
};

export default connect(mapStateToProps)(SaveGeocodeShortcutButtons);
