import React, { Component, ReactElement } from 'react';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import SourceOption from './SourceOption';
import Pager from 'common/components/Pager';
import I18n from 'common/i18n';
import { fetchTranslation } from 'common/locale';
import { Option, none, some, Some } from 'ts-option';
import {
  AgentError,
  DataSourceParameterSpec,
  DataSourceParameters,
  CreateSource,
  StartSource
} from 'datasetManagementUI/lib/agentTypes';
const scope = 'dataset_management_ui.connection_agent';
const t = (k: string) => fetchTranslation(k, scope);

const PAGE_LIMIT = 15;


type HumanPath = string[];
type Path = string[];
interface Page {
  offset: number;
  limit: number;
}
interface SourceListResponse {
  sources: DataSource[];
  count: number;
}
interface SourceTreeProps {
  humanPath: HumanPath;
  path: Path;
  name: string;
  topLevel: boolean;
  listSources: (p: Path, ol: Page, f: string) => Promise<SourceListResponse>;
  startSource: StartSource;
  createSource: (p: Path, hp: HumanPath) => CreateSource;
}

interface DataSource {
  name: string;
  parameters: DataSourceParameterSpec;
  id: string;
  has_children: boolean;
}

interface SourceTreeState {
  children: Option<DataSource[]>;
  loading: boolean;
  collapsed: boolean;
  error: Option<string>;
  page: number;
  count: Option<number>;
  filter: string;
}

class SourceTree extends Component<SourceTreeProps, SourceTreeState> {
  constructor(props: SourceTreeProps) {
    super(props);
    this.state = {
      children: none,
      loading: false,
      collapsed: !props.topLevel,
      error: none,
      page: 1,
      count: none,
      filter: ''
    };
    this.toggle = this.toggle.bind(this);
    this.fetchListing = this.fetchListing.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);

  }

  handleFilterChange(event: React.FormEvent<HTMLInputElement>) {
    this.setState({ filter: event.currentTarget.value});
  }

  componentDidMount() {
    if (!this.state.collapsed && !this.state.children.isDefined) {
      this.fetchListing(this.state.page);
    }
  }

  componentDidUpdate(prevProps: SourceTreeProps, prevState: SourceTreeState) {
    if (prevState.filter !== this.state.filter) {
      this.fetchListing(1);
    }
  }

  fetchListing(page: number) {
    page = page || 1;
    // async/await is garbage
    this.setState({ ...this.state, page, loading: true, error: none });
    // page is 1 based, offset is 0 based. API speaks offsets, Pager component
    // speaks in pages
    const filter = this.state.filter;
    const offset = (page * PAGE_LIMIT) - PAGE_LIMIT;
    const offsetLimit = { offset, limit: PAGE_LIMIT };
    this.props.listSources(this.props.path, offsetLimit, filter).then(({ sources, count }) => {
      this.setState({ ...this.state, loading: false, children: some(sources), count: some(count) });
    }).catch((e) => {
      const message = (new AgentError(e)).get();
      this.setState({ ...this.state, loading: false, error: some(message) });
    });
  }

  toggle() {
    const collapsed = !this.state.collapsed;
    this.setState({ ...this.state, collapsed }, () => {
      if (!collapsed) this.fetchListing(this.state.page);
    });
  }

  renderChildren(): ReactElement | null {
    if (this.state.collapsed) return null;

    let contents: ReactElement | ReactElement[] | null = null;
    if (this.state.loading) {
      contents = (<span className="spinner-default" />);
    } else if (this.state.error.isDefined) {
      contents = (<span className="error-node">{this.state.error.get}</span>);
    } else if (this.state.children.isDefined && this.state.children.get.length === 0 && this.state.filter !== '') {
      const notice = I18n.t('empty_filter_results', {scope, filter: this.state.filter, name: this.props.name});
      contents = (<span className="empty-filter-results" dangerouslySetInnerHTML={{ __html: notice }} />);
    } else if (this.state.children.isDefined && this.state.children.get.length === 0) {
      contents = (<span className="empty-node">{t('empty_node')}</span>);
    } else if (this.state.children.isDefined && this.state.children.get.length) {

      contents = this.state.children.get.map(({ name, parameters, id, has_children: hasChildren }, i) => {
        const path = this.props.path.concat([id]);
        const humanPath = this.props.humanPath.concat([name]);
        // if the node has children, render a new source tree
        if (hasChildren) {
          return (
            <SourceTree
              key={i}
              name={name}
              path={path}
              humanPath={humanPath}
              topLevel={false}
              listSources={this.props.listSources}
              createSource={this.props.createSource}
              startSource={this.props.startSource} />
          );
        } else {
          return (
            <SourceOption
              key={i}
              name={name}
              parameters={parameters}
              createSource={this.props.createSource(path, humanPath)}
              startSource={this.props.startSource} />
          );
        }
      });
    }

    const filterPlaceholder = I18n.t('filter_placeholder', {scope, name: this.props.name});
    const resultsReminder = I18n.t('filter_results_reminder', {scope, name: this.props.name});
    return (
      <div className="source-node source-tree">
        <div>
          <input
            type="text"
            className="filter-box"
            placeholder={filterPlaceholder}
            value={this.state.filter}
            onChange={this.handleFilterChange}/>
          {this.state.filter !== '' && <span className="results-reminder">{resultsReminder}</span>}
        </div>
        {contents}
      </div>
    );
  }

  render() {
    const carat = (<SocrataIcon name={this.state.collapsed ? IconName.ArrowRight : IconName.ArrowDown} />);

    return (<div>
      {!this.props.topLevel && <a onClick={this.toggle}>{carat} {this.props.name}</a>}
      {this.renderChildren()}
      {!this.state.collapsed && <Pager
        resultsPerPage={PAGE_LIMIT}
        currentPage={this.state.page}
        resultCount={this.state.count.getOrElseValue(0)}
        changePage={this.fetchListing} />}
    </div>);
  }
}

export default SourceTree;
