import { AppState, IndexedById } from 'datasetManagementUI/lib/types';
import { mergeRecords } from 'datasetManagementUI/lib/util';
import { CELL_UPDATED, LoadDataEvent, LOAD_COLUMN_ERRORS_SUCCESS, LOAD_NORMAL_PREVIEW_SUCCESS, LOAD_ROW_ERRORS_SUCCESS } from 'datasetManagementUI/reduxStuff/actions/loadData';
import * as _ from 'lodash';

function mapEntities<T>(e: IndexedById<T>, fun: (item: T) => T): IndexedById<T> {
  return _.keyBy(_.map(e, (item) => fun(item)), 'id');
}

const loadData = (state: AppState, action: LoadDataEvent): AppState => {
  switch (action.type) {
    case LOAD_NORMAL_PREVIEW_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          col_data: mergeRecords(state.entities.col_data, action.colData),
          row_errors: mergeRecords(state.entities.row_errors, action.rowErrors)

        }
      };
    }

    case LOAD_COLUMN_ERRORS_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          col_data: mergeRecords(state.entities.col_data, action.colData),
          transforms: mapEntities(state.entities.transforms, (t) => {
            return {
              ...t,
              error_indices: {
                ...t.error_indices,
                ...action.transformErrorIndices[t.id]
              }
            };
          })
        }
      };
    }

    case LOAD_ROW_ERRORS_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          row_errors: {
            ...state.entities.row_errors,
            ...action.rowErrors
          }
        }
      };
    }

    case CELL_UPDATED: {
      return {
        ...state,
        entities: {
          ...state.entities,
          col_data: {
            ...state.entities.col_data,
            [action.transformId]: {
              ...state.entities.col_data[action.transformId],
              [action.offset]: action.value
            }
          }
        }
      };
    }

    default:
      return state;
  }
};

export default loadData;
