import { validateColumnDisplayName, validateColumnFieldName } from 'common/column/utils';
import I18n from 'common/i18n';
import AddColForm from 'datasetManagementUI/components/AddColForm/AddColForm';
import { conversionsByBackend } from 'datasetManagementUI/lib/soqlTypes';
import { AppState, Entities, Params } from 'datasetManagementUI/lib/types';
import { addCol } from 'datasetManagementUI/pages/ShowOutputSchema/ShowOutputSchema';
import * as FlashActions from 'datasetManagementUI/reduxStuff/actions/flashMessage';
import * as FormActions from 'datasetManagementUI/reduxStuff/actions/forms';
import { addColInitialErrorState } from 'datasetManagementUI/reduxStuff/reducers/forms';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

export type FormFieldName
  = 'displayName'
  | 'transform'
  | 'sourceColumnId'
  | 'fieldName'
  | 'description';

interface SelectOption {
  title: string;
  value: string;
}

export type Errors = {
  [K in FormFieldName]: string[];
};

export interface AddColFormState {
  displayName?: string;
  description?: string;
  transform?: string;
  fieldName?: string;
  position?: string;
  sourceColumnId?: string;
  transformExpr?: string;
}

interface StateProps {
  supportedConversions: (soqlType: string) => any;
  errors: Errors;
  inputColumns: any;
  clearInternalState?: boolean;
  selectOptions: SelectOption[];
  entities: Entities;
  params: Params;
  isDirty: boolean;
}

interface DispatchProps {
  syncToStore: (state: AddColFormState) => void;
  markFormDirty: () => void;
  resetFormErrors: () => void;
  toggleClearInternalState: () => void;
  hideAllFlash: () => void;
  addCol: (columnFormState: any) => void;
  resetFormStatus: () => void;
}

export type CombinedProps = StateProps & DispatchProps;

const scope = 'dataset_management_ui';

export function validateValue(value: number): string[] {
  const errors = [];
  // don't check for falsiness only in case id is 0
  if (value !== 0 && !value) {
    errors.push(I18n.t('add_col.error_no_value', { scope }));
  }
  return errors;
}
export function validateFieldName(val: string, existingFieldNames: string[]): string[] {
  return validateColumnFieldName(val, existingFieldNames);
}

export function validateDisplayName(val: string, existingDisplayNames: string[]): string[] {
  return validateColumnDisplayName(val, existingDisplayNames);
}

export const mapStateToProps = ({ ui, entities }: AppState, { params }: { params: Params }): StateProps => {
  const isid = _.toNumber(params.inputSchemaId);
  const selectOptions = [
    { title: `-- ${I18n.t('add_col.make_selection', { scope })} --`, value: '' },
    { title: I18n.t('add_col.no_source_col', { scope }), value: 'no_source_column' },
    ..._.values(entities.input_columns)
      .filter(ic => ic.input_schema_id === isid)
      .sort((j, k) => j.position > k.position ? 1 : 0)
      .map(col => ({
        title: col.field_name,
        value: _.toString(col.id)
      }))
  ];

  return {
    supportedConversions: conversionsByBackend(entities.views[params.fourfour]),
    errors: ui.forms.addColForm.errors as any as Errors, // oof, not worth it right now to restructure this
    inputColumns: entities.input_columns,
    clearInternalState: ui.forms.addColForm.clearInternalState,
    selectOptions,
    entities,
    params,
    isDirty: ui.forms.addColForm.isDirty
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: any): DispatchProps => ({
  syncToStore: (state: any) => dispatch(FormActions.setFormState('addColForm', state)),
  markFormDirty: () => dispatch(FormActions.markFormDirty('addColForm')),
  resetFormErrors: () => dispatch(FormActions.setFormErrors('addColForm', addColInitialErrorState as Errors)),
  toggleClearInternalState: () => dispatch(FormActions.clearInternalState('addColForm', false)),
  hideAllFlash: () => dispatch(FlashActions.hideAllFlashMessages()),
  addCol: (columnFormState: any) => addCol(columnFormState, dispatch, ownProps),
  resetFormStatus: () => {
    dispatch(FormActions.markFormClean('addColForm'));
    dispatch(FormActions.markFormUnsubmitted('addColForm'));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddColForm));
