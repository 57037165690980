import { Agent, Plugin } from 'common/types/gateway';
import AgentList from 'datasetManagementUI/components/AgentList/AgentList';
import * as ApiCalls from 'datasetManagementUI/lib/apiCallStatus';
import { AppState, Params } from 'datasetManagementUI/lib/types';
import { hideModal, showModal } from 'datasetManagementUI/reduxStuff/actions/modal';
import * as PluginActions from 'datasetManagementUI/reduxStuff/actions/plugins';
import * as Selectors from 'datasetManagementUI/selectors';
import _ from 'lodash';
import { connect } from 'react-redux';

interface OwnProps {
  params: Params;
}

interface StateProps {
  hrefExists: boolean;
  agents: Agent[];
  plugins: Plugin[];
  isLoading: boolean;
  pluginError: boolean;
  params: Params;
}

interface DispatchProps {
  getPlugins: () => void;
  showPluginHelp: (plugin: Plugin) => void;
  /* eslint @typescript-eslint/ban-types: "warn" */
  showAvailablePlugins: (showPluginHelp: Function, plugins: Plugin[], canSetupPlugins: boolean) => void;
  /* eslint @typescript-eslint/ban-types: "warn" */
  showProvisionAgent: (showAvailablePlugins: Function) => void;
}

export type CombinedProps = StateProps & DispatchProps;

const mapStateToProps = ({ entities, ui }: AppState, { params }: OwnProps): StateProps => {
  const rev = Selectors.currentRevision(entities, _.toNumber(params.revisionSeq));
  const call = _.find(ui.apiCalls, c => c.operation === PluginActions.PLUGINS_INDEXED);
  return {
    hrefExists: !!rev?.href?.length,
    agents: entities.agents,
    plugins: Object.values(entities.plugins),
    isLoading: !call || ApiCalls.isInProgress(call),
    pluginError: call && ApiCalls.isFailed(call) || false,
    params
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  getPlugins: () => dispatch(PluginActions.getPlugins()),
  showPluginHelp: (plugin: Plugin) => dispatch(showModal('ProvisionPluginModal', {
    plugin,
    hideModal: () => dispatch(hideModal())
  })),
  /* eslint @typescript-eslint/ban-types: "warn" */
  showAvailablePlugins: (showPluginHelp: Function, plugins: Plugin[], canSetupPlugins: boolean) => dispatch(showModal('AvailablePlugins', {
    showPluginHelp,
    plugins,
    canSetupPlugins,
    hideModal: () => dispatch(hideModal())
  })),
  /* eslint @typescript-eslint/ban-types: "warn" */
  showProvisionAgent: (showAvailablePlugins: Function) => dispatch(showModal('ProvisionAgentModal', {
    showAvailablePlugins,
    hideModal: () => dispatch(hideModal())
  }))
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentList);
