import I18n from 'common/i18n';
import { OutputColumn } from 'common/types/dsmapiSchemas';
import { UnAnalyzedSelectedExpression, isColumnRef } from 'common/types/soql';
import { ColumnLike } from './columnLike';


const t = (k: string, scope = 'dataset_management_ui.metadata_manage.dataset_tab') => I18n.t(k, { scope });

// isUnique :: Array a -> a -> Validation
// checks that 'a' does not appear more than once in 'as'
export function isUnique(as: any[] = []) {
  return (a: any) => {
    if (!a) {
      return;
    } else if (as.filter((item) => item === a).length > 1) {
      return t('validation_error_dupe', 'screens.edit_metadata');
    } else {
      return;
    }
  };
}

// isProperFieldName :: Boolean -> String -> Validation
function isProperFieldName(isComputedColumn = false) {
  const fieldNameRegex = isComputedColumn ? /^(:@)?[a-z_][a-z0-9_]*$/ : /^[a-z_][a-z0-9_]*$/;
  return (value: string) => {
    if (!value) {
      return;
    } else if (fieldNameRegex.test(value)) {
      return;
    } else {
      return t('validation_error_fieldname', 'screens.edit_metadata');
    }
  };
}

// hasValue :: a -> Validation
export function hasValue(v: any) {
  if (v) {
    return;
  } else {
    return t('validation_error_required', 'screens.edit_metadata');
  }
}

export function doesNotExceedMaxLength(value: string | null) {
  // Spread operator gives us Unicode code points, so that we match
  // how postgres determines string length
  if (value !== null && [...value].length <= 255) {
    return;
  } else {
    return t('validation_error_length', 'screens.edit_metadata');
  }
}


// runs on form change, giving initial feedback before the server-side validation on submit
// validateColumns :: { [String] : OutputColumn } -> { [ String ] : ColumnError }
export function validateColumns(columns: (ColumnLike<unknown> | OutputColumn)[] = []) {
  const fieldNames = columns.map((col) => col.field_name);
  const displayNames = columns.map((col) => col.display_name);

  return columns.reduce((acc, column) => {
    const { field_name: fieldName, display_name: displayName } = column;

    const haphazardlyTypedColumn = column as any as ColumnLike<UnAnalyzedSelectedExpression>;
    const isComputed =
      (haphazardlyTypedColumn.key?.expr &&
        isColumnRef(haphazardlyTypedColumn.key.expr) &&
        haphazardlyTypedColumn.key.expr.value.startsWith(':@')
        ) || false;
    const fieldNameValidations = [
      hasValue,
      isUnique(fieldNames),
      isProperFieldName(isComputed),
      doesNotExceedMaxLength
    ];
    const displayNameValidations = [hasValue, isUnique(displayNames), doesNotExceedMaxLength];
    const fieldNameErrors: (string | undefined)[] = [];
    const displayNameErrors: (string | undefined)[] = [];

    fieldNameValidations.forEach((validation) => {
      const errorMessage = validation(fieldName);
      if (errorMessage) {
        fieldNameErrors.push(errorMessage);
      }
    });

    displayNameValidations.forEach((validation) => {
      const errorMessage = validation(displayName);
      if (errorMessage) {
        displayNameErrors.push(errorMessage);
      }
    });

    return {
      ...acc,
      [column.field_name]: {
        field_name: fieldNameErrors,
        display_name: displayNameErrors
      }
    };
  }, {});
}
