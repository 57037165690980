import React, { FunctionComponent } from 'react';

import I18n from 'common/i18n';
import { View } from 'common/types/view';
import { launchDeleteAssetModal } from 'common/components/AssetActionBar/components/delete_asset_modal';
interface StaleRevisionFlashMessageProps {
  revision: {
    fourfour: string;
    created_at: {
      toLocaleDateString: () => string;
    };
    revision_seq: number;
  };
  view: View;
}

const StaleRevisionFlashMessage: FunctionComponent<StaleRevisionFlashMessageProps> = ({ revision, view }) => (
  <span style={{ textAlign: 'left' }}>
    <h2>
      {I18n.t('header', {
        date: revision.created_at.toLocaleDateString(),
        scope: 'shared.revision.stale_revision'
      })}
    </h2>
    <p>{I18n.t('shared.revision.stale_revision.instruction')}</p>
    <a
      href="#"
      onClick={(evt) => {
        evt.preventDefault();
        launchDeleteAssetModal(view, () => `/d/${revision.fourfour}`, revision.revision_seq);
      }}
    >
      {I18n.t('shared.components.asset_action_bar.publication_action.discard_draft')}
    </a>
  </span>
);

export default StaleRevisionFlashMessage;
