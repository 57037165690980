import { combineReducers } from 'redux';
import notifications from 'datasetManagementUI/reduxStuff/reducers/notifications';
import flashMessage from 'datasetManagementUI/reduxStuff/reducers/flashMessage';
import modal from 'datasetManagementUI/reduxStuff/reducers/modal';
import apiCalls from 'datasetManagementUI/reduxStuff/reducers/apiCalls';
import history from 'datasetManagementUI/reduxStuff/reducers/history';
import forms from 'datasetManagementUI/reduxStuff/reducers/forms';
import compiler from 'datasetManagementUI/reduxStuff/reducers/compiler';
import agents from 'datasetManagementUI/reduxStuff/reducers/agents';
import metadataTemplates from 'datasetManagementUI/reduxStuff/reducers/metadataTemplates';
import schemaMismatch from 'datasetManagementUI/reduxStuff/reducers/schemaMismatch';
import modeGrant from 'datasetManagementUI/reduxStuff/reducers/modeGrant';
import autosaving from 'datasetManagementUI/reduxStuff/reducers/autosaving';
import vqeUndoRedoHistory from 'datasetManagementUI/reduxStuff/reducers/vqeUndoRedoHistory';

export default combineReducers({
  flashMessage,
  notifications,
  modal,
  apiCalls,
  history,
  forms,
  compiler,
  agents,
  metadataTemplates,
  schemaMismatch,
  modeGrant,
  autosaving,
  vqeUndoRedoHistory
});
