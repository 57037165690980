/* eslint no-confusing-arrow: 0 */
import { InputSchema, OutputSchema } from 'common/types/dsmapiSchemas';
import { Revision } from 'common/types/revision';
import { Source } from 'common/types/source';
import { TaskSet, TaskSetStatus } from 'common/types/taskSet';
import RecentActions from 'datasetManagementUI/components/RecentActions/RecentActions';
import { DSMUIActivityType, ActivityItem } from 'datasetManagementUI/lib/recentActionsTypes';
import { AppState, Entities, Params } from 'datasetManagementUI/lib/types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';




const CREATED_AT_FALLBACK = new Date(Date.now());
const CREATED_BY_FALLBACK = 'Unknown';

export const shapeRevisions = (revisions: Revision[], revisionSeq: number): ActivityItem[] => {
  const currentRevision = revisions.find((revision) => revision.revision_seq === revisionSeq);

  if (!currentRevision) {
    return [];
  }
  const createdAt = currentRevision.created_at || CREATED_AT_FALLBACK;
  const createdBy = currentRevision.created_by.display_name || CREATED_BY_FALLBACK;
  const createdById = currentRevision.created_by.user_id || null;

  return [
    {
      type: DSMUIActivityType.Revision,
      createdAt,
      createdBy,
      createdById
    }
  ];
};

export const shapeSources = (sources: Omit<Source, 'schemas'>[]) =>
  sources
    .filter((s) => s.source_type.type !== 'view')
    .map((source) => {
      return {
        type: DSMUIActivityType.Source,
        createdAt: source.created_at || CREATED_AT_FALLBACK,
        createdBy: source.created_by.display_name || CREATED_BY_FALLBACK,
        createdById: source.created_by.user_id || null,
        source,
        // this was broken a long time ago.
        previousSource: null
      };
    });

export const shapeOutputSchemas = (oss: OutputSchema[], iss: InputSchema[], ss: Omit<Source, 'schemas'>[]) =>
  oss.map((os) => {
    const inputSchema = iss.find((is) => is.id === os.input_schema_id);
    const source = ss.find((s) => s.id === inputSchema?.source_id);

    return {
      type: DSMUIActivityType.OutputSchema,
      createdAt: os.created_at || CREATED_AT_FALLBACK,
      createdBy: os.created_by.display_name || CREATED_BY_FALLBACK,
      createdById: os.created_by.user_id || null,
      sourceId: source?.id,
      isid: inputSchema?.id,
      osid: os.id
    };
  });

export const shapeTaskSets = (tss: TaskSet[]) =>
  tss.map((ts) => ({
    type: DSMUIActivityType.TaskSet,
    createdAt: ts.created_at || CREATED_AT_FALLBACK,
    createdBy: ts.created_by.display_name || CREATED_BY_FALLBACK,
    createdById: ts.created_by.user_id || null
  }));

export const shapeFailedTaskSets = (tss: TaskSet[]) =>
  tss
    .filter((ts) => !!ts.finished_at)
    .filter((ts) => ts.status === TaskSetStatus.Failure)
    .map((ts) => ({
      type: DSMUIActivityType.TaskSetFailed,
      createdAt: ts.finished_at || CREATED_AT_FALLBACK,
      createdBy: ts.created_by.display_name || CREATED_BY_FALLBACK,
      createdById: ts.created_by.user_id || null
    }));

export const shapeFinishedTaskSets = (tss: TaskSet[]) =>
  tss
    .filter((ts) => !!ts.finished_at)
    .filter((ts) => ts.status === TaskSetStatus.Successful)
    .map((ts) => ({
      type: DSMUIActivityType.TaskSetFinished,
      createdAt: ts.finished_at || CREATED_AT_FALLBACK,
      createdBy: ts.created_by.display_name || CREATED_BY_FALLBACK,
      createdById: ts.created_by.user_id || null
    }));

export const createActivities = (entities: Entities, params: Params) => {
  const activities = [
    ...shapeRevisions(_.values(entities.revisions), _.toNumber(params.revisionSeq)),
    ...shapeSources(_.values(entities.sources)),
    ...shapeOutputSchemas(
      _.values(entities.output_schemas),
      _.values(entities.input_schemas),
      _.values(entities.sources)
    ),
    ...shapeTaskSets(_.values(entities.task_sets)),
    ...shapeFailedTaskSets(_.values(entities.task_sets)),
    ...shapeFinishedTaskSets(_.values(entities.task_sets))
  ] as ActivityItem[];

  return _.chain(activities)
    .sortBy((activity) => activity.createdAt)
    .reverse()
    .value();
};

const mapStateToProps = ({ entities }: AppState, { params }: { params: Params }) => ({
  activities: createActivities(entities, params),
  params
});

export default withRouter(connect(mapStateToProps)(RecentActions));
