/* eslint react/jsx-indent: 0 */
import PropTypes from 'prop-types';
import _ from 'lodash';
import React, { Component } from 'react';
import classNames from 'classnames';
import FlashMessage from 'datasetManagementUI/containers/FlashMessageContainer';
import ColumnPreview from './ColumnPreview';
import ErrorHandling from './ErrorHandling';
import formatString from 'common/js_utils/formatString';
import I18n from 'common/i18n';
import withTooltip from 'common/components/hocs/withTooltip';
import SocrataIcon from 'common/components/SocrataIcon';

import {
  LatLngFields
} from './LatLngFields';

import {
  CombinedFields
} from './CombinedFields';

import {
  ComponentFields
} from './ComponentFields';

import ColumnNameInputs from './ColumnNameInputs';
import { validateFieldName, validateDisplayName } from 'datasetManagementUI/containers/AddColFormContainer';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.show_output_schema.geocode_shortcut' });


// Different types of composition strategies
const COMPONENTS = 'COMPONENTS';
const COMBINED = 'COMBINED';
const LATLNG = 'LATLNG';

class GeocodeShortcut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialOutputSchemaId: props.revision.output_schema_id,
      isEdit: !!props.outputColumn
    };
    props.setOldOutputSchemaId(props.revision.output_schema_id);
    this.handleSave = this.handleSave.bind(this);
  }

  componentWillUnmount() {
    const { revision, revertRevisionOutputSchema } = this.props;
    const currentOutputSchemaId = revision.output_schema_id;
    const initialOutputSchemaId = this.state.initialOutputSchemaId;

    this.props.markFormUnsubmitted();
    this.props.clearForm();
    this.props.clearOutputSchemaId();

    if (initialOutputSchemaId !== currentOutputSchemaId) {
      // revert the revision output schema to what it was before
      revertRevisionOutputSchema(initialOutputSchemaId, currentOutputSchemaId);
    }
  }

  createNewOutputSchema(desiredColumns) {
    return this.props
      .newOutputSchema(desiredColumns, {}, true, this.props.outputSchema)
      .catch(resp => {
        const { body } = resp;
        if (resp.message) {
          this.props.showError(resp.message);
        } else if (body && body.params) {
          const message = _.flatMap(_.values(body.params), errors => errors);
          this.props.showError(message);
        } else {
          console.error(resp);
        }
        return resp;
      });
  }

  columnNameValidationErrors() {
    const { outputColumns, outputColumn } = this.props;
    const { columnNames } = this.props.formState;
    const fieldNameChanged = _.get(outputColumn, 'field_name') !== columnNames.fieldName;
    const displayNameChanged = _.get(outputColumn, 'display_name') !== columnNames.displayName;
    const fieldNameErrors = fieldNameChanged ?
      validateFieldName(columnNames.fieldName, outputColumns.map(c => c.field_name))
      : [];
    const displayNameErrors = displayNameChanged ?
      validateDisplayName(columnNames.displayName, outputColumns.map(c => c.display_name))
      : [];
    return fieldNameErrors.length || displayNameErrors.length ? {
      fieldNameErrors,
      displayNameErrors
    } : undefined;
  }

  handleSave(evt) {
    evt.preventDefault();
    this.props.setErrors({ fieldNameErrors: [], displayNameErrors: [] });
    const { isPreviewable } = this.props;
    const { desiredColumns, columnNames } = this.props.formState;
    if (!this.props.anySelected) return;
    const outputColumn = this.props.outputColumn;
    const saveRequired = !outputColumn
      || outputColumn.display_name !== columnNames.displayName
      || outputColumn.field_name !== columnNames.fieldName
      || !_.find(desiredColumns, (oc) => {
        return oc.transform.transform_expr === outputColumn.transform.transform_expr;
      })
      || !isPreviewable;

    if (saveRequired) {
      const columnErrors = this.columnNameValidationErrors();
      if (columnErrors) {
        this.props.setErrors(columnErrors);
        return;
      }

      this.createNewOutputSchema(desiredColumns).then(resp => {
        // reset the initialOutputSchemaId so the state isn't reverted in componentWillUnmount
        this.setState({ initialOutputSchemaId: resp.resource.id });
        this.props.showSuccess(t(this.state.isEdit ? 'column_update_success' : 'column_create_success'));
        this.props.markFormSubmitted();
        this.props.setNewOutputSchemaId(resp.resource.id);
        this.props.setOldOutputSchemaId(resp.resource.id);
        if (!this.state.isEdit) {
          // Doesn't make sense to clear the form when we're making an edit
          this.props.clearForm();
        }
        // If it's an edit, we redirect to the updated column edit form, otherwise
        // we redirect to the base form but with the new output schema id.
        this.props.redirectGeocodePane(resp.resource, this.state.isEdit);
      });
    } else {
      this.setState({ initialOutputSchemaId: this.props.outputSchema.id }, () => {
        this.props.showSuccess(t(this.state.isEdit ? 'column_update_success' : 'column_create_success'));
        this.props.markFormSubmitted();
        if (!this.state.isEdit) {
          // Doesn't make sense to clear the form when we're making an edit.
          // Since we want to stay on the filled out form if user is updating, and previewing
          // has already redirected them, we don't need to redirect here if editing).
          this.props.clearForm();
          this.props.redirectGeocodePane(this.props.outputSchema, false);
        }
      });
    }
  }

  render() {
    const {
      params,
      entities,
      redirectGeocodePane,
      displayState,
      inputSchema,
      inputColumns,
      outputSchema,
      outputColumn,
      anySelected,
      isPreviewable,
      enableAddColumn,
      changeColumnNames,
      formErrors,
      setErrors
    } = this.props;
    const {
      mappings,
      shouldConvertToNull,
      composedFrom,
      desiredColumns,
      configurationError,
      columnNames
    } = this.props.formState;

    const onPreview = () => {
      setErrors({ fieldNameErrors: [], displayNameErrors: [] });
      const columnErrors = this.columnNameValidationErrors();
      if (columnErrors) {
        setErrors(columnErrors);
        return;
      }

      this.createNewOutputSchema(desiredColumns).then(resp => {
        this.props.setNewOutputSchemaId(resp.resource.id);
        redirectGeocodePane(resp.resource);
      });
    };

    const isLatLng = composedFrom === LATLNG;
    const isCombined = composedFrom === COMBINED;
    const isComponents = composedFrom === COMPONENTS;

    const composeLatlng = () => this.props.setComposedFrom(LATLNG);
    const latlngClassname = classNames({
      'btn btn-primary': isLatLng,
      'btn btn-default': !isLatLng
    });
    const composeComponents = () => this.props.setComposedFrom(COMPONENTS);
    const componentsClassname = classNames({
      'btn btn-primary': isComponents,
      'btn btn-default': !isComponents
    });
    const composeCombined = () => this.props.setComposedFrom(COMBINED);
    const combinedClassname = classNames({
      'btn btn-primary': isCombined,
      'btn btn-default': !isCombined
    });

    let fieldSet;
    switch (this.props.formState.composedFrom) {
      case LATLNG:
        fieldSet = (<LatLngFields
          inputColumns={inputColumns}
          setMapping={this.props.setMapping}
          mappings={mappings} />);
        break;
      case COMPONENTS:
        fieldSet = (<ComponentFields
          inputColumns={inputColumns}
          setMapping={this.props.setMapping}
          mappings={mappings} />);
        break;
      case COMBINED:
        fieldSet = (<CombinedFields
          inputColumns={inputColumns}
          setMapping={this.props.setMapping}
          mappings={mappings} />);
        break;
      default:
        fieldSet = null;
    }

    const GeocodeTooltip = withTooltip(
      <SocrataIcon name="info" className="geocode-tooltip-icon" />,
      <span className="geocode-tooltip-text">
        <p>{t('tooltip_text')}</p>
      </span>
    );

    const geocodeForm = (
      <div className="form-wrap">
        <form>
          <button
            id="save-geocode-form"
            onClick={this.handleSave}
            style={{ display: 'none' }}>&nbsp;
            aria-labelledby="Save geocode form"</button>

          {fieldSet}

          <ErrorHandling
            shouldConvertToNull={shouldConvertToNull}
            toggleConvertToNull={this.props.toggleConvertToNull} />

          <ColumnNameInputs
            {...columnNames}
            changeColumnNames={changeColumnNames}
            errors={formErrors}
          />
        </form>
      </div>
    );

    const columnPreview = (
      <ColumnPreview
        entities={entities}
        anySelected={anySelected}
        outputColumn={outputColumn}
        inputSchema={inputSchema}
        outputSchema={outputSchema}
        displayState={displayState}
        isPreviewable={isPreviewable}
        onPreview={onPreview}
        params={params}
        enableAddColumn={enableAddColumn}
        onAddColumn={this.handleSave}
        isEdit={this.state.isEdit} />
    );

    const configurationErrorContent = (
      <div className={classNames('configuration-error-alt', 'configuration-error')}>
        {configurationError}
      </div>
    );

    const title = (<h2>
      {
      outputColumn ?
        formatString(t('edit_title'), { column_name: outputColumn.display_name })
        : t('add_title')
      }
    </h2>);

    const geocodeOptions = (
      <div className="geocode-options">
        <div className="geocode-subtitle">
          <h6>{t('source_column_type')}</h6>
          <GeocodeTooltip/>
        </div>
        <div className="composition-selector">
          <button onClick={composeLatlng} className={latlngClassname}>
            {t('lat_long')}
          </button>
          <button onClick={composeComponents} className={componentsClassname}>
            {t('addr_separate')}
          </button>
          <button onClick={composeCombined} className={combinedClassname}>
            {t('addr_combined')}
          </button>
        </div>
      </div>
    );

    return (
      <div className="geocode-wrapper ">
        <FlashMessage />
        {title}
        <p className="geocode-explanation">{t('what_is_geocoding')}</p>
        <div className="content column-container">
          <div className="left-column">
            {geocodeOptions}
            {configurationError ? configurationErrorContent : geocodeForm}
          </div>
          <div class="right-column">
            {!configurationError && columnPreview}
          </div>
        </div>
      </div>
    );
  }
}

GeocodeShortcut.propTypes = {
  view: PropTypes.object.isRequired,
  onDismiss: PropTypes.func,
  entities: PropTypes.object.isRequired,
  displayState: PropTypes.object.isRequired,
  newOutputSchema: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  redirectGeocodePane: PropTypes.func.isRequired,
  redirectToOutputSchema: PropTypes.func.isRequired,
  revertRevisionOutputSchema: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,

  setMapping: PropTypes.func.isRequired,
  toggleConvertToNull: PropTypes.func.isRequired,
  setComposedFrom: PropTypes.func.isRequired,
  changeColumnNames: PropTypes.func.isRequired,

  inputSchema: PropTypes.object.isRequired,
  inputColumns: PropTypes.array.isRequired,
  outputSchema: PropTypes.object.isRequired,
  outputColumns: PropTypes.array.isRequired,
  outputColumn: PropTypes.object,
  revision: PropTypes.object.isRequired,

  anySelected: PropTypes.bool.isRequired,
  isPreviewable: PropTypes.bool.isRequired,
  enableAddColumn: PropTypes.bool.isRequired,
  showSuccess: PropTypes.func.isRequired,
  markFormSubmitted: PropTypes.func.isRequired,
  markFormUnsubmitted: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  setNewOutputSchemaId: PropTypes.func.isRequired,
  clearOutputSchemaId: PropTypes.func.isRequired
};

export { GeocodeShortcut, COMBINED, COMPONENTS, LATLNG };
