import {
  SOCKET_CONNECTED,
  SOCKET_ERROR,
  SOCKET_CREATED
} from '../../reduxStuff/actions/socket';

const initialState = {
  connectionEstablishedOnce: false,
  connectionErrorCount: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SOCKET_CREATED: {
      return { ...state };
    }
    case SOCKET_CONNECTED: {
      return { ...state, connectionEstablishedOnce: true, connectionErrorCount: 0 };
    }
    case SOCKET_ERROR: {
      return { ...state, connectionErrorCount: state.connectionErrorCount + 1 };
    }
    default:
      return state;
  }
};
