import PropTypes from 'prop-types';
import React from 'react';
import { commaify } from 'common/formatNumber';

function ErrorPill({ number }) {
  return (
    <span className='dsmp-error-pill'>
      {commaify(number)}
    </span>
  );
}

ErrorPill.propTypes = {
  number: PropTypes.number.isRequired
};

export default ErrorPill;

