import {
  InputSchema,
  InputSchemaId,
  OutputColumn,
  OutputSchema,
  OutputSchemaId,
  SourceId,
  WithTransform
} from 'common/types/dsmapiSchemas';
import { Revision } from 'common/types/revision';
import { Expr, isFunCall } from 'common/types/soql';
import { ArchivalSource, isArchivalSourceType, Source } from 'common/types/source';
import { View, ViewFlag } from 'common/types/view';
import { LogEntry, RowsUpsertedResult } from 'common/types/taskSet';
import { STATUS_CALL_IN_PROGRESS } from 'datasetManagementUI/lib/apiCallStatus';
import * as DisplayState from 'datasetManagementUI/lib/displayState';
import { LOAD_ROWS } from 'datasetManagementUI/reduxStuff/actions/apiCalls';
import { CREATE_SOURCE } from 'datasetManagementUI/reduxStuff/actions/createSource';
import { PAGE_SIZE } from 'datasetManagementUI/reduxStuff/actions/loadData';
import hasFlag from 'common/views/has_flag';
import _ from 'lodash';
import { none, option, Option, some } from 'ts-option';
import { traverse } from './lib/ast';
import { ApiCall, Entities, RevisionSeq, TaskSetId, AppState } from './lib/types';

export function currentView(entities: Entities): View {
  return Object.values(entities.views)[0]!;
}

export const hasDatasetErrors = (ui: AppState['ui']) => {
  return ui.forms.hasMetadataErrors;
};

export function rowsToBeImported(entities: Entities, outputSchemaId: OutputSchemaId) {
  const outputSchema = entities.output_schemas[outputSchemaId];
  const inputSchema = entities.input_schemas[outputSchema.input_schema_id];
  const rowCount = inputSchema.total_rows || 0;
  const errorRows = outputSchema.error_count || 0;
  return Math.max(0, rowCount - errorRows);
}

export const logEntryIsRowsUpsertedResult = (logItem: LogEntry): logItem is RowsUpsertedResult =>
  logItem && logItem.stage === 'rows_upserted';

export function rowsUpserted(entities: Entities, taskSetId: TaskSetId) {
  const taskSet = entities.task_sets[taskSetId];
  if (!taskSet || !taskSet.log) {
    return 0;
  }
  // TODO: update status here to reflect new DSMAPI changes
  const rowItems = taskSet.log
    .filter(logEntryIsRowsUpsertedResult)
    .map((logItem: RowsUpsertedResult) => logItem.details.count);
  return _.max(rowItems) || 0;
}

export function currentRevision(entities: Entities, revisionSeq: RevisionSeq | string): Revision | undefined {
  const revSeq = _.toNumber(revisionSeq);
  return _.values(entities.revisions).find((rev) => rev.revision_seq === revSeq);
}

export function currentOutputSchema(entities: Entities, revisionSeq: RevisionSeq) {
  const revision = currentRevision(entities, revisionSeq);
  return revision && revision.output_schema_id !== null
    ? entities.output_schemas[revision.output_schema_id]
    : null;
}

export function currentBlobSource(
  entities: Entities,
  revisionSeq: RevisionSeq
): Omit<Source, 'schemas'> | null {
  const revision = currentRevision(entities, revisionSeq);
  return revision && revision.blob_id !== null ? entities.sources[revision.blob_id] : null;
}

export const canEditOrReviewSources = (view: View) => hasFlag(view, ViewFlag.Default);

export const getArchivalSourceType = (sources: Omit<Source, 'schemas'>[]): Option<ArchivalSource> => {
  const source = sources.find((s) => s.source_type.type === 'archival');
  if (source && isArchivalSourceType(source.source_type)) {
    return some(source.source_type);
  }
  return none;
};

// TODO: prob do some backend work to put sourceId on the revision
// need to discuss when we link the two (e.g. on upload vs on save)
export function currentSource(entities: Entities, revisionSeq: RevisionSeq): Omit<Source, 'schemas'> | null {
  const blobSource = currentBlobSource(entities, revisionSeq);
  if (blobSource) {
    return blobSource;
  } else {
    const os = currentOutputSchema(entities, revisionSeq);
    const is = os ? entities.input_schemas[os.input_schema_id] : null;
    return is ? entities.sources[is.source_id] : null;
  }
}

export function columnsForInputSchema(entities: Entities, inputSchemaId: InputSchemaId) {
  const unsortedColumns = _.filter(entities.input_columns, (ic) => ic.input_schema_id === inputSchemaId);
  return _.sortBy(unsortedColumns, 'position');
}

export function columnsForOutputSchema(entities: Entities, outputSchemaId: OutputSchemaId) {
  return _.chain(entities.output_schema_columns)
    .filter({ output_schema_id: outputSchemaId })
    .map((outputSchemaColumn) => {
      const outputColumn = entities.output_columns[outputSchemaColumn.output_column_id];
      return {
        ...outputColumn,
        transform: entities.transforms[outputColumn.transform_id],
        is_primary_key: outputSchemaColumn.is_primary_key || false
      };
    })
    .sortBy('position')
    .value();
}

export function outputColumnsForInputSchemaUniqByTransform(entities: Entities, inputSchemaId: InputSchemaId) {
  const outputSchemaIds = _.filter(entities.output_schemas, {
    input_schema_id: inputSchemaId
  }).map((os) => os.id);

  return _.chain(entities.output_schema_columns)
    .filter((osc) => _.includes(outputSchemaIds, osc.output_schema_id))
    .map((outputSchemaColumn) => {
      const outputColumn = entities.output_columns[outputSchemaColumn.output_column_id];
      return {
        ...outputColumn,
        transform: entities.transforms[outputColumn.transform_id],
        is_primary_key: outputSchemaColumn.is_primary_key || false
      };
    })
    .uniqBy((oc) => oc.transform.id)
    .sortBy('position')
    .value();
}

function getRowOffsets(
  entities: Entities,
  inputSchemaId: InputSchemaId,
  displayState: DisplayState.DisplayState
): number[] {
  const startRow = (displayState.pageNo - 1) * PAGE_SIZE;
  const endRow = startRow + PAGE_SIZE;

  if (displayState.type === DisplayState.NORMAL) {
    return _.range(startRow, endRow); // show all the rows, we're not filtering
  } else if (displayState.type === DisplayState.COLUMN_ERRORS) {
    return option(entities.transforms[displayState.transformId])
      .map((t) => {
        return (t.error_indices || [])[displayState.pageNo] || [];
      })
      .getOrElseValue([]);
  } else if (displayState.type === DisplayState.ROW_ERRORS) {
    return _.filter(entities.row_errors, (re) => re.input_schema_id === inputSchemaId)
      .map((rowError) => rowError.offset)
      .slice(startRow, endRow);
  }
  return [];
}

export function getRowData(
  entities: Entities,
  inputSchemaId: InputSchemaId,
  displayState: DisplayState.DisplayState,
  outputColumns: (OutputColumn & WithTransform)[]
) {
  // In the past, we had a flat list of error indexes. As far as I can tell, it never worked properly?

  // We can't have a flat list of error indexes, because we don't
  // know which page corresponds to which, if we enter the UI in
  // the middle of the dataset while filtering by errors.

  // Consider the following scenario:
  // The user loads the app on page2 while filtering by errors.
  // Then they page around to page1, page2, page3, page2, as represented
  // in the following table

  // error_count 6 | page_size 2
  // page  idxs                 startRow  endRow        what we need to call in order to return the correct offsets to render
  // 2     [6, 8]               2         4             slice(0, 2)
  // 1     [2, 4, 6, 8]         0         2             slice(0, 2)
  // 2     [2, 4, 6, 8]         2         4             slice(2, 4)
  // 3     [2, 4, 6, 8, 10, 12] 4         6             slice(4, 6)
  // 2     [2, 4, 6, 8, 10, 12] 2         4             slice(2, 4)

  // maybe there is a way to make the correct numbers get passed to the slice
  // call, but i'm not smart enough to figure it out. it's much simpler at this point
  // to just make DSMAPI be the arbiter of what-error-lands-on-which-page, and store
  // that in our state.

  // SOLUTION:
  // make error_indices: {
  //   2: [6, 8],
  //   1: [2, 4],
  //   3: [10, 12]
  // }
  // and index into it here by pagenum. Then getRowOffsets can just return what DSMAPI told
  // it, rather than doing anything difficult.
  //
  return getRowOffsets(entities, inputSchemaId, displayState).map((rowIdx) => ({
    rowIdx,
    columns: outputColumns.map((column) => {
      const transform = column.transform;
      const cell = entities.col_data[transform.id] ? entities.col_data[transform.id][rowIdx] : null;
      return {
        tid: transform.id,
        id: column.id,
        format: column.format,
        flags: column.flags,
        cell
      };
    }),
    rowError: entities.row_errors[`${inputSchemaId}-${rowIdx}`]
  }));
}

export function allColumnsWithOSID(entities: Entities) {
  return _.chain(entities.output_schema_columns)
    .map((oc) => ({
      ...entities.output_columns[oc.output_column_id],
      outputSchemaId: oc.output_schema_id
    }))
    .map((oc) => ({
      ...oc,
      transform: entities.transforms[oc.transform_id],
      is_primary_key: oc.is_primary_key || false
    }))
    .map((oc) => _.omit(oc, 'transform_id'))
    .orderBy('outputSchemaId', 'desc')
    .value();
}

interface TheTrinity {
  source: Omit<Source, 'schemas'> | null;
  inputSchema: InputSchema | null;
  outputSchema: OutputSchema | null;
}
export function treeForOutputSchema(entities: Entities, outputSchemaId: OutputSchemaId): TheTrinity {
  const maybeOs = option(entities.output_schemas[outputSchemaId]);
  const maybeIs = maybeOs.map((os) => entities.input_schemas[os.input_schema_id]);
  const maybeSrc = maybeIs.map((is) => entities.sources[is.source_id]);

  return {
    outputSchema: maybeOs.orNull,
    inputSchema: maybeIs.orNull,
    source: maybeSrc.orNull
  };
}

export function allTransformsDone(columnsWithTransforms: (OutputColumn & WithTransform)[] = []) {
  return columnsWithTransforms
    .map((col) => !!col.transform.finished_at)
    .reduce((acc, bool) => acc && bool, true);
}

export function sourcesInProgress(apiCalls: ApiCall[]) {
  return _.filter(
    apiCalls,
    (apiCall) => apiCall.status === STATUS_CALL_IN_PROGRESS && apiCall.operation === CREATE_SOURCE
  );
}

export function totalRows(entities: Entities, revisionSequence: RevisionSeq) {
  if (!entities || revisionSequence == null) {
    return 0;
  }

  const revSeq = _.toNumber(revisionSequence);

  const rev = _.find(entities.revisions, (r) => r.revision_seq === revSeq);

  const os = rev && rev.output_schema_id ? entities.output_schemas[rev.output_schema_id] : null;

  const is = os && os.input_schema_id ? entities.input_schemas[os.input_schema_id] : null;

  const rows = is ? is.total_rows : null;

  return rows || 0;
}

export function rowsTransformed(outputColumns: (OutputColumn & WithTransform)[]) {
  return _.min(outputColumns.map((col) => col.transform.contiguous_rows_processed || 0)) || 0;
}

export function pathForOutputSchema(entities: Entities, outputSchemaId: OutputSchemaId) {
  const outputSchema = entities.output_schemas[outputSchemaId];
  const inputSchema = entities.input_schemas[outputSchema.input_schema_id];
  const source = entities.sources[inputSchema.source_id];
  return {
    outputSchema,
    inputSchema,
    source
  };
}

// lol this is garbage ᕕ( ᐛ )ᕗ
export function getGeocodedOrLocationOutputColumn(outputColumns: (OutputColumn & WithTransform)[]) {
  const outputColumn = _.find(outputColumns, (oc) =>
    traverse(oc.transform.parsed_expr, false, (node: Expr, acc: boolean) => {
      if (
        node &&
        isFunCall(node) &&
        (node.function_name === 'geocode' ||
          node.function_name === 'geocode_arcgis' ||
          node.function_name === 'make_point')
      ) {
        return !!node;
      }
      return acc;
    })
  );
  return outputColumn;
}

export function sourceFromInputSchema(entities: Entities, inputSchemaId: InputSchemaId) {
  const inputSchema = entities.input_schemas[inputSchemaId];
  return entities.sources[inputSchema.source_id];
}

export function inputSchemaFromOutputSchema(entities = {}, outputSchemaId: OutputSchemaId) {
  const inputSchemaId = _.get(entities, `output_schemas.${outputSchemaId}.input_schema_id`);
  return _.get(entities, `input_schemas.${inputSchemaId}`);
}

export function rowLoadOperationsInProgress(apiCalls: ApiCall[]) {
  return _.filter(apiCalls, (call) => call.operation === LOAD_ROWS && call.status === STATUS_CALL_IN_PROGRESS)
    .length;
}

export const inputSchemas = (entities: Entities, sourceId: SourceId) => {
  return _.filter(entities.input_schemas, { source_id: sourceId });
};

export const latestOutputSchemaForSource = (entities: Entities, sourceId: SourceId) => {
  const inputSchema = _.filter(entities.input_schemas, {
    source_id: sourceId
  })[0];

  if (!inputSchema) {
    return null; // an input schema has not yet been parsed out of this upload
  }

  const outputSchemas = _.filter(entities.output_schemas, {
    input_schema_id: inputSchema.id
  });

  return _.maxBy(_.values(outputSchemas), 'id');
};

export const latestOutputSchemaForInputSchema = (entities: Entities, inputSchemaId: InputSchemaId) => {
  const inputSchema = entities.input_schemas[inputSchemaId];

  if (!inputSchema) {
    return null; // an input schema has not yet been parsed out of this upload
  }

  const outputSchemas = _.filter(entities.output_schemas, {
    input_schema_id: inputSchema.id
  });

  return _.maxBy(_.values(outputSchemas), 'id');
};

// DATASET METADATA
// The purpose of these selectors is to reshape the revision.metadata structure
// that exists in the store into the shape expected by core. Used in the
// saveDatasetMetadata thunk in manageMetadata.js
const filterUndefineds = (val: any) => val === undefined;
const convertToNull = (val: string) => (val === '' ? null : val);

const regularPublic = (metadata: any) =>
  _.chain(metadata)
    .pick([
      'id',
      'name',
      'description',
      'category',
      'licenseId',
      'license',
      'attribution',
      'attributionLink',
      'tags',
      'attachments'
    ])
    .omitBy(filterUndefineds)
    .mapValues(convertToNull)
    .value();

const regularPrivate = (metadata: any) =>
  _.chain(metadata.privateMetadata || {})
    .omit('custom_fields')
    .omitBy(filterUndefineds)
    .mapValues(convertToNull)
    .value();

const customPublic = (metadata: any) =>
  _.chain(metadata ? metadata.custom_fields : {})
    .omitBy(filterUndefineds)
    .mapValues(convertToNull)
    .value();

const customPrivate = (metadata: any) =>
  _.chain(metadata ? metadata.privateMetadata.custom_fields : {})
    .omitBy(filterUndefineds)
    .mapValues(convertToNull)
    .value();

export const datasetMetadata = (metadata: any) => {
  const publicMetadata = regularPublic(metadata);
  const privateMetadata = regularPrivate(metadata);
  const customMetadata = customPublic(metadata);
  const privateCustomMetadata = customPrivate(metadata);

  return {
    ...publicMetadata,
    privateMetadata: {
      ...privateMetadata,
      custom_fields: privateCustomMetadata
    },
    metadata: {
      ...metadata.metadata,
      custom_fields: customMetadata
    }
  };
};

const parseRevisionForViewType = (revision: Revision) => {
  switch (true) {
    case (_.get(revision, 'href') || '').length > 0:
      return 'href';
    case _.isNumber(revision.blob_id):
      return 'blobby';
    default:
      return null;
  }
};

// shapeRevisionForProps :: Revision, View -> ViewlikeObj
export const shapeRevisionIntoView = (revision: Revision, view: View) => ({
  ...view,
  ...{
    name: revision.metadata.name,
    description: revision.metadata.description,
    category: revision.metadata.category,
    tags: revision.metadata.tags,
    attribution: revision.metadata.attribution,
    attributionLink: revision.metadata.attributionLink || '',
    license: revision.metadata.license,
    createdAt: Math.floor(revision.created_at.getTime() / 1000),
    metadata: {
      ...revision.metadata.metadata,
      attachments: revision.attachments.map((a) => ({ ...a, assetId: a.asset_id }))
    },
    privateMetadata: {
      ...revision.metadata.privateMetadata
    },
    viewType: parseRevisionForViewType(revision) || view.viewType
  }
});

export const getShouldParseFile = (entities: Entities) => {
  const view = currentView(entities);
  return view.displayType === 'draft' ? null : view.viewType === 'tabular';
};
