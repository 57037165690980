import I18n from 'common/i18n';

export const getEnabledFileExtensions = () => [
  '.csv',
  '.tsv',
  '.xls',
  '.xlsx',
  '.zip',
  '.json',
  '.geojson',
  '.kml',
  '.kmz'
];

export function formatDescription(format) {
  if (format === '.zip') {
    return '.zip (shapefile)';
  }
  if (format === '.json') {
    return '.json (GeoJSON)';
  }
  return format;
}

export const acceptedFileTypes = [
  {
    extension: 'csv',
    mimeType: 'text/csv',
    description: I18n.t('dataset_management_ui.show_uploads.file_types.csv_description')
  },
  {
    extension: 'tsv',
    mimeType: 'text/tsv',
    description: I18n.t('dataset_management_ui.show_uploads.file_types.tsv_description')
  },
  {
    extension: 'xls',
    mimeType: 'application/vnd.ms-excel',
    description: I18n.t('dataset_management_ui.show_uploads.file_types.xls_description')
  },
  {
    extension: 'xlsx',
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    description: I18n.t('dataset_management_ui.show_uploads.file_types.xlsx_description')
  },
  {
    extension: 'zip',
    mimeType: 'application/zip',
    description: I18n.t('dataset_management_ui.show_uploads.file_types.zip_description')
  },
  {
    extension: 'json',
    mimeType: 'application/json',
    description: I18n.t('dataset_management_ui.show_uploads.file_types.json_description')
  },
  {
    extension: 'geojson',
    mimeType: 'application/json',
    description: I18n.t('dataset_management_ui.show_uploads.file_types.geojson_description')
  },
  {
    extension: 'kml',
    mimeType: 'application/vnd.google-earth.kml+xml',
    description: I18n.t('dataset_management_ui.show_uploads.file_types.kml_description')
  },
  {
    extension: 'kmz',
    mimeType: 'application/vnd.google-earth.kmz',
    description: I18n.t('dataset_management_ui.show_uploads.file_types.kmz_description')
  }
];
