import { COL_FORM_NAME } from 'datasetManagementUI/containers/ManageColumnMetadataContainer';
import * as Links from 'datasetManagementUI/links/links';
import {
  markFormClean,
  markFormUnsubmitted,
  setFormState,
  setFormErrors
} from 'datasetManagementUI/reduxStuff/actions/forms';
import * as ModalActions from 'datasetManagementUI/reduxStuff/actions/modal';
import _ from 'lodash';
import { browserHistory } from 'react-router';

export const dismissMetadataPane =
  (currentOutputSchemaPath, params, form, closeUnsaved = false) =>
  (dispatch, getState) => {
    if (form?.isDirty) {
      if (!closeUnsaved) {
        dispatch(ModalActions.pushModal('ConfirmCloseMetadata'));
        return;
      }
    }

    dispatch(ModalActions.popModal());

    // Ensure form is marked clean so that if user reopens pane they don't see the
    // save button enabled before they make any changes.
    dispatch(markFormClean(COL_FORM_NAME));
    // Alright, this is funky and a relic from the past.
    // way back in the day, this whole "ManageMetadata" thing was implemented as one component
    // which had state in two places in redux, and two places local to the component. It put things
    // in redux to share form state with other components (like the publish button, and metadata sidebar)
    // and had some local state which consisted of the form data itself, mostly.
    // In refactoring it to all just be in redux, things became *much* simpler, but the old behavior of
    // you being shown a modal of "You have unsaved changes, do you want to abandon them?"
    // and clicking yes actually abandoning them was kind of broken, since if you navigated back to the
    // form, the state was still there in redux, so you'd be shown the errors and the old state.
    // Arguably, this is a *better* user experience, but it complicates things as you navigate around the app.
    // What happens if you change a column name, don't hit save, and then navigate to the table page? Should
    // the table have your updated column name? It's in the form state, but not really part of the rest
    // of the state yet, as you you haven't committed to the change. What happens if you enter an invalid
    // column name, navigate away and say "yes I want to abandon my changes" and then you see a
    // disabled publish button because there is lurking invalid data...that's weird!
    //
    // So, let's use the redux state, since it makes things *way* simpler, and we only have state in one place,
    // rather than 4, but we'll maintain the old behavior of blowing the state away when the user dismisses
    // the modal, even though we *could* keep it.
    dispatch(markFormUnsubmitted(COL_FORM_NAME));
    dispatch(setFormState(COL_FORM_NAME, undefined));
    dispatch(setFormErrors(COL_FORM_NAME, {}));

    const { history } = getState().ui;
    const isMetadataModalPath = /^\/[\w-]+\/.+\/\w{4}-\w{4}\/revisions\/\d+\/metadata.*/; // eslint-disable-line
    const isShowOutputSchemaPath =
      /^\/[\w-]+\/.+\/\w{4}-\w{4}\/revisions\/\d+\/sources\/\d+\/schemas\/\d+\/output\/\d+/; // eslint-disable-line

    // helper determines where to route users once they close the
    // modal that holds the column and dataset metedata forms
    const helper = (hist) => {
      const previousLocation = hist[hist.length - 1];

      // is this the first page you have visited? If so, just route to home page
      if (hist.length === 0) {
        browserHistory.push(Links.revisionBase(params));

        // did you come from ShowOutputSchema page? If so, route to that same page, but
        // show the new output schema (since update column metadata creates an new schema).
        // Note that we assume currentOutputSchemaPath will be falsey if on the dataset
        // metadata form--ie we rely on the programmer to not pass it in if on the dataset
        // tab.
      } else if (currentOutputSchemaPath && isShowOutputSchemaPath.test(previousLocation.pathname)) {
        browserHistory.push(currentOutputSchemaPath);

        // did you come from one of the tabs in the metadata modal? If so, forget that
        // location and try again
      } else if (isMetadataModalPath.test(previousLocation.pathname)) {
        helper(hist.slice(0, -1));
      } else {
        browserHistory.push(previousLocation.pathname);
      }
    };

    helper(history);
  };

export function focusColumnEditor(routerState) {
  return () => {
    const hash = routerState.location.hash;
    if (hash.length > 1) {
      // react router doesn't seem to provide a hook for after the component
      // has rendered, so we have to do this
      setTimeout(() => {
        const columnId = _.toNumber(hash.slice(1));
        const element = document.getElementById(`display-name-${columnId}`);
        if (element) {
          element.focus();
        }
      }, 0);
    }
  };
}
