import _ from 'lodash';
import { traverse, isColumnRef, map } from 'datasetManagementUI/lib/ast';
import {
  REMOVED_REFERENCE,
  EMPTY_REFERENCE
} from 'datasetManagementUI/components/SchemaMismatch/SchemaMismatch';
import { cloneOutputColumn } from 'datasetManagementUI/reduxStuff/actions/showOutputSchema';

export function getInputColumnReferences(inputColumns, outputColumns) {
  return _.uniqBy(_.flatMap(outputColumns, (oc) => {
    const transform = oc.transform;

    return traverse(transform.parsed_expr, [], (node, acc) => {
      if (isColumnRef(node)) {
        const fieldName = node.value;
        const existing = _.find(inputColumns, { field_name: fieldName });
        if (!existing) {
          return acc.concat([{
            fieldName,
            displayName: fieldName,
            refersTo: null
          }]);
        } else {
          return acc.concat([{
            fieldName,
            displayName: existing.display_name,
            refersTo: existing
          }]);
        }
      }
      return acc;
    });
  }), (ref) => ref.fieldName);
}

export function hasUndefinedReferences(references, mappings) {
  // if any reference refers to nothing, and it is not being
  // re-mapped to something that does exist, then it will not compile
  return _.some(references, r => !r.refersTo && !mappings[r.fieldName]);
}

export function remapColumnAsts(outputColumns, mappings) {
  const remapAst = (ast, newFieldName, oldFieldName) => map(_.cloneDeep(ast), (node) => {
    if (isColumnRef(node) && node.value === oldFieldName) {
      if (newFieldName === EMPTY_REFERENCE) {
        return { type: 'null_literal', value: null };
      }
      return { ...node, value: newFieldName };
    }
    return node;
  });

  const isRemoved = (oc) => {
    return traverse(oc.transform.parsed_expr, false, (node, acc) => {
      if (
        isColumnRef(node) &&
        mappings[node.value] === REMOVED_REFERENCE
      ) {
        return true;
      }
      return acc;
    });
  };

  return outputColumns
    .filter(oc => !isRemoved(oc))
    .map(oc => {
      const newAst = _.reduce(mappings, remapAst, oc.transform.parsed_expr);

      const cloned = cloneOutputColumn(oc);

      let newTransform = {
        ...cloned.transform,
        output_soql_type: oc.transform.output_soql_type
      };

      if (!_.isEqual(newAst, oc.transform.parsed_expr)) {
        newTransform = {
          ..._.omit(newTransform, ['transform_expr']),
          parsed_expr: newAst
        };
      }

      return {
        ...cloned,
        transform: newTransform
      };
    });
}
