import { socrataFetch, getJson } from 'datasetManagementUI/lib/http';
import { sessionStatus } from 'datasetManagementUI/links/links';

// TODO: move these constants to some other file
// these actions should be reusable with another set of operations
export const LOAD_ROWS = 'LOAD_ROWS';
export const LOAD_DATA = 'LOAD_DATA';
export const VALIDATE_ROW_IDENTIFIER = 'VALIDATE_ROW_IDENTIFIER';
export const SET_ROW_IDENTIFIER = 'SET_ROW_IDENTIFIER';
export const NEW_OUTPUT_SCHEMA = 'NEW_OUTPUT_SCHEMA';
export const DROP_COLUMN = 'DROP_COLUMN';
export const ADD_COLUMN = 'ADD_COLUMN';
export const HIDE_COLUMN = 'HIDE_COLUMN';
export const UNHIDE_COLUMN = 'UNHIDE_COLUMN';
export const UPDATE_COLUMN_TYPE = 'UPDATE_COLUMN_TYPE';
export const SAVE_DATASET_METADATA = 'SAVE_DATASET_METADATA';
export const SAVE_COLUMN_METADATA = 'SAVE_COLUMN_METADATA';
export const UPDATE_REVISION = 'UPDATE_REVISION';
export const APPLY_REVISION = 'APPLY_REVISION';
export const ADD_EMAIL_INTEREST = 'ADD_EMAIL_INTEREST';
export const SAVE_CURRENT_BLOB = 'SAVE_CURRENT_BLOB';
export const CREATE_IMPORT_CONFIG = 'CREATE_IMPORT_CONFIG';
export const DELETE_REVISION = 'DELETE_REVISION';
export const DELETE_VIEW = 'DELETE_VIEW';

export const COLUMN_OPERATIONS = [
  SET_ROW_IDENTIFIER,
  VALIDATE_ROW_IDENTIFIER,
  DROP_COLUMN,
  ADD_COLUMN,
  HIDE_COLUMN,
  UNHIDE_COLUMN,
  UPDATE_COLUMN_TYPE
];

// TODO: factor these to just be params, not an object
export const API_CALL_STARTED = 'API_CALL_STARTED';
export const apiCallStarted = (id, { operation, callParams }) => ({
  type: API_CALL_STARTED,
  id,
  operation,
  callParams
});

export const API_CALL_SUCCEEDED = 'API_CALL_SUCCEEDED';
export const apiCallSucceeded = id => ({
  type: API_CALL_SUCCEEDED,
  id
});

export const API_CALL_FAILED = 'API_CALL_FAILED';
export const apiCallFailed = (id, error) => dispatch => {
  if (typeof id !== 'undefined' && id !== null) {
    dispatch({
      type: API_CALL_FAILED,
      id,
      error
    });
  }

  // if user session is expired, redirect to login page
  return socrataFetch(sessionStatus)
    .then(getJson)
    .then(resp => {
      if (resp.expired === 'expired') {
        window.location.reload();
      }
    })
    .catch(requestErr => {
      console.error('session status error:', requestErr);
    });
};
