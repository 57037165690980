import { Source } from 'common/types/source';

export interface DSMUIActivity {
  createdAt: string | Date;
  createdBy: string;
  createdById: string | null;
}
export type DSMUIRevisionActivity = DSMUIActivity & {
  type: DSMUIActivityType.Revision;
};
export type DSMUISourceActivity = DSMUIActivity & {
  type: DSMUIActivityType.Source;
  source: Omit<Source, 'schemas'>;
  previousSource: Omit<Source, 'schemas'> | null;
};
export type DSMUIOutputSchemaActivity = DSMUIActivity & {
  type: DSMUIActivityType.OutputSchema;
  sourceId: number | undefined;
  isid: number | undefined;
  osid: number | undefined;
};
export type DSMUITaskSetActivity = DSMUIActivity & {
  type: DSMUIActivityType.TaskSet;
};
export type DSMUITaskSetSucceededActivity = DSMUIActivity & {
  type: DSMUIActivityType.TaskSetFinished;
};
export type DSMUITaskSetFailedActivity = DSMUIActivity & {
  type: DSMUIActivityType.TaskSetFailed;
};

export type ActivityItem =
  | DSMUIRevisionActivity
  | DSMUISourceActivity
  | DSMUIOutputSchemaActivity
  | DSMUITaskSetActivity
  | DSMUITaskSetFailedActivity
  | DSMUITaskSetSucceededActivity;

export enum DSMUIActivityType {
  Revision,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Source,
  OutputSchema,
  TaskSet,
  TaskSetFailed,
  TaskSetFinished
}
