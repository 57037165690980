import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import SourceBreadcrumbs from 'datasetManagementUI/components/SourceBreadcrumbs/SourceBreadcrumbs';
import * as Selectors from 'datasetManagementUI/selectors';

export const mapStateToProps = ({ entities }, { atShowSource, params }) => {

  let inputSchema;
  let inputSchemaId;
  let sourceId;

  const revision = Selectors.currentRevision(entities, _.toNumber(params.revisionSeq));
  const { output_schema_id: outputSchemaId, blob_id: blobId } = revision;
  const outputSchema = entities.output_schemas[outputSchemaId];
  const view = entities.views[params.fourfour];
  const blobPreviewAvailable = blobId !== null || view.viewType === 'blobby';

  // can't just do !!outputSchemaId because it could be 0 possibly?
  if (outputSchema && outputSchema.input_schema_id != null) {
    inputSchemaId = outputSchema.input_schema_id;
    inputSchema = entities.input_schemas[inputSchemaId];
  }

  if (inputSchema && inputSchema.source_id != null) {
    sourceId = inputSchema.source_id;
  }

  return {
    atShowSource,
    sourceId,
    blobId,
    outputSchemaId,
    inputSchemaId,
    blobPreviewAvailable
  };
};

export default withRouter(connect(mapStateToProps)(SourceBreadcrumbs));
