import PropTypes from 'prop-types';
import React from 'react';
import DSMUIIcon from '../DSMUIIcon';

const FlashMessage = ({
  kind, message, helpMessage, helpUrl, visible, onCloseClick, alignLeft
}) => {
  let className;
  let iconName;
  const iconClass = ['flash-message-icon'];

  switch (kind) {
    case 'success':
      className = 'flash-message-success';
      iconName = 'checkmark-alt';
      iconClass.push('flash-message-icon-success');
      break;
    case 'error':
      className = 'flash-message-error';
      iconName = 'warning';
      iconClass.push('flash-message-icon-error');
      break;
    case 'warning':
      className = 'flash-message-warning';
      iconName = 'warning';
      iconClass.push('flash-message-icon-warning');
      break;
    case 'href':
      className = 'flash-message-href';
      break;
    default:
      iconName = 'info';
      break;
  }

  if (visible) {
    return (
      <div id="flash-message-base" className={className}>
        <div className={alignLeft ? 'left-align' : ''}>
          <DSMUIIcon name={iconName} className={iconClass.join(' ')} />
          <DSMUIIcon name="close-2" className="flash-message-close-button" onIconClick={onCloseClick} />
          {
            (helpMessage && helpUrl) ?
            (<span className="message">
              {message} <a href={helpUrl} target="_blank">{helpMessage}</a>
            </span>) :
              <span className="message">{message}</span>
          }
        </div>
      </div>
    );
  } else {
    return null;
  }
};

FlashMessage.propTypes = {
  alignLeft: PropTypes.bool,
  kind: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  helpMessage: PropTypes.string,
  helpUrl: PropTypes.string,
  message: PropTypes.string.isRequired
};

export default FlashMessage;
