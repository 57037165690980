import PropTypes from 'prop-types';
import React from 'react';
import { ModalContent, ModalFooter } from 'common/components/Modal';
import formatString from 'common/js_utils/formatString';
import I18n from 'common/i18n';

const t = (k, scope = 'dataset_management_ui.show_output_schema.row_identifier_error') => I18n.t(k, { scope });

const REASON_DUPLICATE = 'duplicate';
const REASON_NULL = 'null';
const REASON_BAD_TYPE = 'bad type';

function errorMessage(result) {
  switch (result.reason) {
    case REASON_DUPLICATE:
      return formatString(t('reason_duplicate'), { value: result.what, first_index: result.where });

    case REASON_NULL:
      return formatString(t('reason_null'), { row_index: result.where });

    case REASON_BAD_TYPE:
      return t('reason_bad_type');

    default:
      return null;
  }
}

function RowIdentifierError({ result, doCancel }) {
  return (
    <div>
      <h2>Error setting row identifier</h2>
      <ModalContent>
        <p>
          {errorMessage(result)}
        </p>
        <p>
          {t('what_to_do')}
        </p>
      </ModalContent>
      <ModalFooter>
        <button onClick={doCancel} className="btn btn-default">
          {t('ok', 'dataset_management_ui.common')}
        </button>
      </ModalFooter>
    </div>
  );
}

RowIdentifierError.propTypes = {
  result: PropTypes.shape({
    what: PropTypes.string.isRequired,
    where: PropTypes.number.isRequired,
    valid: PropTypes.bool.isRequired,
    reason: PropTypes.oneOf([REASON_DUPLICATE, REASON_NULL, REASON_BAD_TYPE]).isRequired
  }).isRequired,
  doCancel: PropTypes.func.isRequired
};

export default RowIdentifierError;
