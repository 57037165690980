import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import DSMUIIcon from '../DSMUIIcon';
import formatString from 'common/js_utils/formatString';
import I18n from 'common/i18n';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.show_output_schema.fatal_error' });

const FatalError = props => {
  const { source, outputSchema, outputColumns } = props;

  if (!outputSchema) {
    return null;
  }

  let message = t('unknown_error');
  let details = '';
  let requestId = '';
  let style;
  let icon;

  const failedColumns = _.filter(outputColumns, 'transform.failed_at');

  const compilationFailures = failedColumns.filter(fc => (
    fc.transform.failure_details &&
    fc.transform.failure_details.type === 'transform_compilation_failure'
  ));

  if (compilationFailures.length) {
    const failures = compilationFailures.map(fc => fc.display_name).join(', ');
    message = t('schema_mismatch');
    details = formatString(t('schema_mismatch_details'), { failures });
    style = 'warning';
    icon = <DSMUIIcon name="warning" />;

  } else {
    style = 'fatal-error';
    icon = <DSMUIIcon name="failed" />;

    if (source.failed_at) {
      message = t('source_error');
      const failure = source.failure_details;

      if (failure) {
        details = failure.message;
        requestId = failure.request_id;
      }
    } else if (failedColumns.length) {
      const failedColumn = failedColumns[0];
      message = t('transform_error');
      details = failedColumn.transform.transform_expr;
    }
  }


  return (
    <div id="fatal-error-component">
      <div className={style}>
        {icon}
        <span className="error-message">{message}</span>
        <span className="error-details" title={requestId}>{details}</span>
      </div>
    </div>
  );
};

FatalError.propTypes = {
  source: PropTypes.object.isRequired,
  outputSchema: PropTypes.object,
  outputColumns: PropTypes.array.isRequired
};

export default FatalError;
