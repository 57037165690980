import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  UPDATE_NOTIFICATION
} from 'datasetManagementUI/reduxStuff/actions/notifications';

const LOCAL_STORAGE_KEY = 'dsmp-notifications';

const getLocalState = () => {
  try {
    return JSON.parse(localStorage[LOCAL_STORAGE_KEY] || '{}');
  } catch (e) {
    return {};
  }
};

const writeLocalStorage = (key, val) => {
  localStorage[LOCAL_STORAGE_KEY] = JSON.stringify({
    ...getLocalState(),
    [key]: val
  });
};

export default function notificationReducer(state = [], action) {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      // don't add it if we've previously seen it and it has been dismissed
      const previouslySeen = getLocalState()[action.notification.subject];
      if (previouslySeen && !previouslySeen.showing) {
        return state;
      }

      // if we haven't previously seen it, or it wasn't dismissed, add it
      return [...state, action.notification];
    }
    case UPDATE_NOTIFICATION:
      return state.map(notification => {
        if (notification.subject === action.notification.subject) {
          return { ...notification, ...action.notification.change };
        }
        return notification;
      });

    case REMOVE_NOTIFICATION: {
      writeLocalStorage(action.subject, { showing: false });
      return state.filter(notification => (
        notification.subject !== action.subject && notification.id !== action.subject
      ));
    }
    default:
      return state;
  }
}
