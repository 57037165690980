import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { getEnabledFileExtensions, formatDescription } from 'datasetManagementUI/lib/fileExtensions';
import TextInput from 'datasetManagementUI/components/TextInput/TextInput';
import SourceMessage from 'datasetManagementUI/components/SourceMessage/SourceMessage';
import ApiCallButton from 'datasetManagementUI/containers/ApiCallButtonContainer';
import { CREATE_SOURCE } from 'datasetManagementUI/reduxStuff/actions/createSource';
import I18n from 'common/i18n';

const scope = 'dataset_management_ui.import_from_url';

class URLSource extends Component {
  constructor() {
    super();

    this.state = {
      url: '',
      error: ''
    };

    _.bindAll(this, ['onURLChange', 'onError', 'onStartImport']);
  }

  onURLChange(e) {
    this.setState({
      url: e.target.value
    });
  }

  onError(err) {
    console.error(err);
    const errorKey = _.get(err, 'body.key');
    const knownErrors = ['unparsable_file', 'invalid_extension', 'validation_failed'];

    const errMessage = () => {
      if (knownErrors.includes(errorKey)) {
        return err.message || I18n.t(errorKey, { scope });
      } else if (_.get(err, 'body.message') === 'Unknown URL fetch error') {
        return I18n.t('unable_to_fetch', { scope });
      } else {
        return I18n.t('unknown_error', { scope });
      }
    };

    const showSupportedFilesLink = (['unparsable_file', 'invalid_extension'].includes(errorKey));

    this.setState({
      error: errMessage(),
      showSupportedFilesLink
    });
  }

  async onStartImport(e) {
    e.preventDefault();

    this.setState({
      error: ''
    });

    await this.props.createURLSource(this.state.url).catch(this.onError);
  }

  render() {
    const { hrefExists, sourceInProgress } = this.props;

    if (hrefExists) {
      return <SourceMessage hrefExists={hrefExists} />;
    }

    const sourceType = {
      type: 'url',
      url: this.state.url
    };

    const callParams = {
      source_type: sourceType
    };

    const fileTypesString =
      `${I18n.t('dataset_management_ui.show_uploads.filetypes')}
      ${getEnabledFileExtensions().map(formatDescription).join(', ')}`;

    const helpUrl = 'https://support.socrata.com/hc/en-us/articles/202949918';
    const helpMessage = I18n.t('help_message', { scope });

    const inProgressSourceWarning = sourceInProgress ?
      (
      <div className="cancel-source-warning">
        {I18n.t('cancel_url_warning', { scope })}
      </div>
      ) : null;

    return (
      <section className="import-url-section-container">
        <div className="import-url-image-container">
          <img
            alt="external source"
            className="image-container-image"
            src="/images/datasetManagementUI/external-square.svg" />
        </div>
        <div className="import-url-text-container">
          <div className="text-container-content">
            <h2>{I18n.t('title', { scope })}</h2>
            <div className="browse-msg">{I18n.t('url_prompt', { scope })}</div>
            <form>
              <label
                htmlFor="import-url"
                className="accessibility-label">{I18n.t('dataset_management_ui.format_column.url')}
              </label>
              <TextInput
                field={{
                  name: 'import-url',
                  value: this.state.url
                }}
                handleChange={this.onURLChange}
                inErrorState={!!this.state.error} />
              {this.state.error && <div className="error-message">
                {this.state.error} {this.state.showSupportedFilesLink &&
                  <a href={helpUrl} target="_blank">{helpMessage}</a>
                }
              </div>}
              <ApiCallButton
                onClick={this.onStartImport}
                operation={CREATE_SOURCE}
                callParams={callParams}
                className="btn btn-primary">
                {this.state.error ? I18n.t('retry', { scope }) : I18n.t('start_import', { scope })}
              </ApiCallButton>
              {inProgressSourceWarning}
              <div className="file-types">
                {fileTypesString}
              </div>
              <div className="file-types">
                {I18n.t('dataset_management_ui.show_uploads.non_parsable_accepted_legacy')}
              </div>

            </form>
          </div>
        </div>
      </section>
    );
  }
}

URLSource.propTypes = {
  createURLSource: PropTypes.func.isRequired,
  hrefExists: PropTypes.bool.isRequired,
  sourceInProgress: PropTypes.object
};

export default URLSource;
