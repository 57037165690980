import { IconName } from 'common/components/SocrataIcon';
import TimelineItem, { TimelineUser } from 'common/components/AssetTimeline/TimelineItem';
import I18n from 'common/i18n';
import { Source } from 'common/types/source';
import {
  DSMUIActivity,
  DSMUIOutputSchemaActivity,
  DSMUIRevisionActivity,
  DSMUISourceActivity,
  DSMUITaskSetActivity,
  DSMUITaskSetFailedActivity,
  DSMUITaskSetSucceededActivity
} from 'datasetManagementUI/lib/recentActionsTypes';
import { Params } from 'datasetManagementUI/lib/types';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { showOutputSchema } from 'datasetManagementUI/links/links';

const scope = 'dataset_management_ui.home_pane.home_pane_sidebar';

interface Props<T extends DSMUIActivity> {
  details: T;
  params: Params;
}

export const RevisionActivity = ({ details }: Props<DSMUIRevisionActivity>) => (
  <TimelineItem icon={IconName.Plus2}>
    <p className="activity-details">
      <TimelineUser createdBy={details.createdBy} createdById={details.createdById} />{' '}
      {I18n.t('opened_revision', { scope })}
    </p>
  </TimelineItem>
);

RevisionActivity.propTypes = {
  details: PropTypes.shape({
    createdAt: PropTypes.object,
    createdBy: PropTypes.string
  }).isRequired
};

const sourceActionLabel = (
  source: Omit<Source, 'schemas'>,
  previousSource: Omit<Source, 'schemas'> | null
) => {
  if (previousSource) return I18n.t('changed_parse_options', { scope });
  if (source.source_type && source.source_type.type === 'url') return I18n.t('added_a_url_source', { scope });
  if (source.source_type && source.source_type.type === 'upload') return I18n.t('added_an_upload', { scope });
};

const sourceActionDetails = (source: Omit<Source, 'schemas'>) => {
  if (source.source_type && source.source_type.type === 'url') return source.source_type.url;
  if (source.source_type && source.source_type.type === 'upload') return source.source_type.filename;
};

export const SourceActivity = ({ details }: Props<DSMUISourceActivity>) => {
  const { source, previousSource } = details;
  if (source.source_type.type === 'view') return null;
  return (
    <TimelineItem icon={IconName.Data}>
      <p className="activity-details">
        <TimelineUser createdBy={details.createdBy} createdById={details.createdById} />
        {sourceActionLabel(source, previousSource)}
      </p>
      <p className="small muted">{sourceActionDetails(source)}</p>
    </TimelineItem>
  );
};

// TODO: translation for this
export const OutputSchemaActivity = ({ details, params }: Props<DSMUIOutputSchemaActivity>) => (
  <TimelineItem icon={IconName.Edit}>
    <p className="activity-details">
      <TimelineUser createdBy={details.createdBy} createdById={details.createdById} /> changed the&nbsp;
      <Link to={showOutputSchema(params, details.sourceId!, details.isid!, details.osid!)}>schema</Link>
    </p>
  </TimelineItem>
);

export const TaskSetActivity = ({ details }: Props<DSMUITaskSetActivity>) => (
  <TimelineItem icon={IconName.Dataset}>
    <p className="activity-details">
      <TimelineUser createdBy={details.createdBy} createdById={details.createdById} />{' '}
      {I18n.t('started_processing', { scope })}
    </p>
  </TimelineItem>
);

export const TaskSetFinishedActivity = ({ details }: Props<DSMUITaskSetSucceededActivity>) => (
  <TimelineItem icon={IconName.Checkmark3}>
    <p className="activity-details">{I18n.t('processing_succeeded', { scope })}</p>
  </TimelineItem>
);

export const TaskSetFailedActivity = ({ details }: Props<DSMUITaskSetFailedActivity>) => (
  <TimelineItem icon={IconName.Failed}>
    <p className="activity-details">{I18n.t('processing_failed', { scope })}</p>
  </TimelineItem>
);
