import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import UploadNotification from 'datasetManagementUI/containers/UploadNotificationContainer';
import AttachmentNotification from
  'datasetManagementUI/components/AttachmentNotification/AttachmentNotification';
import Notification from 'datasetManagementUI/containers/NotificationContainer';
import I18n from 'common/i18n';

// This component is responsible for choosing the kind of notification to display
// (e.g. source, socket error, etc.) and sliding all the notifications into view.
// Displaying status (success, error, inProgress) and all the logic needed to figure
// that status out are handled farther down the component tree.
const NotificationList = ({ notifications, revision }) => {
  const items = notifications.map((notification, i) => {
    switch (notification.kind) {
      case 'source':
        return (<UploadNotification
          notification={notification}
          revision={revision}
          key={i} />);
      case 'attachment':
        return (<AttachmentNotification
          filename={notification.subject}
          percent={notification.percent}
          status={notification.status}
          error={notification.error}
          key={i} />);
      case 'error':
        return (<Notification
          key={i}
          status="error"
          id={notification.id}
          message={I18n.t('dataset_management_ui.notifications.error')}>
          {notification.subject}
        </Notification>);
      default:
        return null;
    }
  });

  const itemTransitions = items.map((item, i) => (<CSSTransition
    in={true}
    key={i}
    classNames={{
      enter: 'dsmui-notification-list-enter',
      enterActive: 'dsmui-notification-list-enter-active',
      exit: 'dsmui-notification-list-exit',
      exitActive: 'dsmui-notification-list-exit-active'
    }}
    timeout={500}>
    {item}
  </CSSTransition>));

  return (
    <div className="dsmui-notification-list-list">
      <TransitionGroup>
        {itemTransitions}
      </TransitionGroup>
    </div>
  );
};

NotificationList.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object),
  revision: PropTypes.object.isRequired
};

export default NotificationList;
