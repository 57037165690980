import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'datasetManagementUI/lib/types';
import { Transform } from 'common/types/dsmapiSchemas';
import I18n from 'common/i18n';
import Button, { VARIANTS } from 'common/components/Button';

const t = (k: string) => I18n.t(k, { scope: 'dataset_management_ui.show_output_schema' });

interface OwnProps {
  redirectToOutputSchema: () => void;
  outputColumnId?: number;
}

interface StateProps extends OwnProps {
  transform?: Transform;
  expression?: string;
}

type TransformEditorButtonProps = StateProps & OwnProps;

export const TransformEditorButton = ({ redirectToOutputSchema, transform, expression }: TransformEditorButtonProps) => {
  const transformDone = _.get(transform, 'finished_at') || _.get(transform, 'completed_at' || _.get(transform, 'failed_at'));
  const showDone = transformDone && expression === _.get(transform, 'transform_expr');

  return (<Button
    variant={showDone ? VARIANTS.PRIMARY : VARIANTS.DEFAULT}
    onClick={redirectToOutputSchema}>
    {t(showDone ? 'done' : 'back_to_data_preview')}
  </Button>);
};

const mapStateToProps = ({ entities, ui }: AppState, ownProps: OwnProps): StateProps => {
  const outputColumn = ownProps.outputColumnId ? _.get(entities, `output_columns.${ownProps.outputColumnId}`) : undefined;
  const transform = outputColumn ? _.get(entities, `transforms.${outputColumn.transform_id}`) : undefined;
  const expression = _.get(ui, 'compiler.compiler.expression');

  return {
    ...ownProps,
    transform,
    expression
  };
};

export default connect(mapStateToProps)(TransformEditorButton);
