import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { PAGE_SIZE } from 'datasetManagementUI/reduxStuff/actions/loadData';
import { commaify } from 'common/formatNumber';
import Pager from 'common/components/Pager';
import I18n from 'common/i18n';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.home_pane' });

function PagerBar({ currentPage, resultCount, urlForPage, changePage, isLoading }) {
  if (resultCount) {
    const firstPageRow = commaify((currentPage - 1) * PAGE_SIZE + 1);
    const lastPageRow = commaify(Math.min(currentPage * PAGE_SIZE, resultCount));
    const lastPage = urlForPage(Math.ceil(resultCount / PAGE_SIZE));

    let resultCountElem;
    if (resultCount > PAGE_SIZE && !isLoading) {
      resultCountElem = (
        <Link to={lastPage}>
          {commaify(resultCount)}
        </Link>
      );
    } else {
      resultCountElem = commaify(resultCount);
    }

    let pager = null;
    if (isLoading) {
      pager = (
        <span className="spinner-default page-loading"></span>
      );
    } else {
      pager = (
        <Pager
          resultsPerPage={PAGE_SIZE}
          currentPage={currentPage}
          resultCount={resultCount}
          changePage={changePage} />
      );
    }

    return (
      <div id="dsmui-pager-bar">
        {t('showing')} {firstPageRow}–{lastPageRow} {t('of')} {resultCountElem}
        {pager}
      </div>
    );
  } else {
    return <div id="dsmui-pager-bar" />;
  }
}

PagerBar.propTypes = {
  currentPage: PropTypes.number.isRequired,
  resultCount: PropTypes.number,
  urlForPage: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default PagerBar;
