/* eslint react/jsx-indent: 0 */
import classNames from 'classnames';
import { attachTo } from 'common/components/legacy';
import I18n from 'common/i18n';
import formatString from 'common/js_utils/formatString';
import { OutputColumn, OutputSchema, Transform } from 'common/types/dsmapiSchemas';
import { getErrorFlyoutId } from 'datasetManagementUI/components/ErrorFlyout/ErrorFlyout';
import ErrorPill from 'datasetManagementUI/components/ErrorPill/ErrorPill';
import GeoFlyout, { getGeoFlyoutId } from 'datasetManagementUI/components/GeoFlyout/GeoFlyout';
import ProgressBar from 'datasetManagementUI/components/ProgressBar/ProgressBar';
import StatusText from 'datasetManagementUI/components/StatusText/StatusText';
import ErrorFlyout from 'datasetManagementUI/containers/ErrorFlyoutContainer';
import MapFlyout from 'datasetManagementUI/containers/MapFlyoutContainer';
import * as DisplayState from 'datasetManagementUI/lib/displayState';
import { Params } from 'datasetManagementUI/lib/types';
import { singularOrPlural } from 'datasetManagementUI/lib/util';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router';
import DSMUIIcon from '../DSMUIIcon';

const t = (k: string) => I18n.t(k, { scope: 'dataset_management_ui.show_output_schema.column_header' });

const GEOSPATIAL_TYPES = ['location', 'multiline', 'multipoint', 'multipolygon', 'point', 'line', 'polygon'];

export enum COL_STATUS {
  UNLOADED = 'unloaded',
  ERROR = 'error',
  DONE = 'done',
  IN_PROGRESS = 'inProgress',
  COMPILATION_FAILED = 'compilationFailed',
  WARNING = 'warning',
  VALIDATING_ROW_ID = 'validatingRowId',
  PREVIEW = 'preview'
}
const GeospatialShortcut = ({ flyoutId }: { flyoutId: string }) => (
  <Link
    className="geo-badge status-item"
    onClick={() => console.warn('not implemented!')}
    data-flyout={flyoutId}
  >
    <DSMUIIcon name="geo" />
  </Link>
);
GeospatialShortcut.propTypes = {
  flyoutId: PropTypes.string.isRequired
};

const MapFlyoutShortcut = ({ toggleMap }: { toggleMap: (e: React.MouseEvent<HTMLDivElement>) => void }) => (
  <Link className="map-badge status-item" onClick={toggleMap}>
    <DSMUIIcon name="region" />
  </Link>
);
MapFlyoutShortcut.propTypes = {
  toggleMap: PropTypes.func.isRequired
};

interface Props {
  outputSchema: OutputSchema;
  outputColumn: OutputColumn;
  transform: Transform;
  params: Params;
  isDropping: boolean;
  displayState: DisplayState.DisplayState;
  totalRows: number;
  shortcuts: string[];
  flyouts: boolean;
  columnErrorLinkPath?: string;
  unloadedViewSource: boolean;
  editMode: boolean;
  width: number;
  isValidatingRowId: boolean;
  preview: () => void;
}

interface State {
  isMapShowing: boolean;
  mapPosition: number | null;
}
export class TransformStatus extends Component<Props, State> {
  flyoutParentEl: HTMLTableHeaderCellElement | null;
  constructor(props: Props) {
    super(props);
    this.state = {
      isMapShowing: false,
      mapPosition: null
    };

    this.toggleMap = this.toggleMap.bind(this);
  }
  componentDidMount() {
    this.attachFlyouts();
  }

  componentDidUpdate() {
    this.attachFlyouts();
  }

  hasTransformErrors() {
    const count = this.props.transform.error_count;
    if (_.isNumber(count)) {
      return count > 0;
    }
    return false;
  }

  showGeocodeShortcut() {
    return _.includes(this.props.shortcuts, 'geocode');
  }

  showMapFlyout() {
    return _.includes(GEOSPATIAL_TYPES, this.props.transform.output_soql_type);
  }

  toggleMap(e: React.MouseEvent<HTMLDivElement>) {
    if (this.state.isMapShowing) {
      this.setState({
        isMapShowing: !this.state.isMapShowing,
        mapPosition: 0
      });
    } else {
      const sidebarWidth = 258;
      const mapWidth = 500;
      const left = e.currentTarget.getBoundingClientRect().left;

      let posn = -250;
      posn = Math.max(posn, sidebarWidth - left);
      posn = Math.min(posn, window.innerWidth - mapWidth);

      this.setState({
        isMapShowing: !this.state.isMapShowing,
        mapPosition: posn
      });
    }
  }

  attachFlyouts() {
    // We only want to attach if there are flyouts on the page, otherwise we get a
    // ton of warnings about how flyouts weren't found - so we need to limit
    // the attach code to run only when we might be showing an element
    // that shows a flyout; which at the time of writing this comment
    // are the transform error and geocode shortcut icons
    const hasFlyouts = this.hasTransformErrors() || this.showGeocodeShortcut();

    if (this.props.flyouts && this.flyoutParentEl && hasFlyouts) {
      attachTo(this.flyoutParentEl);
    }
  }

  determineColStatus() {
    const { transform, unloadedViewSource, isValidatingRowId } = this.props;
    if (transform.failed_at) {
      return COL_STATUS.ERROR;
    } else if (isValidatingRowId) {
      return COL_STATUS.IN_PROGRESS;
    } else if (transform.finished_at) {
      return COL_STATUS.DONE;
    } else if (unloadedViewSource) {
      return COL_STATUS.UNLOADED;
    } else {
      return COL_STATUS.IN_PROGRESS;
    }
  }

  render() {
    const {
      outputSchema,
      outputColumn,
      transform,
      params,
      totalRows,
      displayState,
      columnErrorLinkPath,
      isDropping,
      width
    } = this.props;

    let inErrorMode;
    let rowsProcessed;
    let percentage;
    let hasErrors;
    let errorStatusMessage;
    let thClasses;
    let progressbarPercent;
    let statusTextMessage;
    let statusText = null;

    const colStatus = this.determineColStatus();
    const progressbarType = colStatus;

    if (colStatus !== COL_STATUS.UNLOADED) {
      inErrorMode = DisplayState.inErrorMode(displayState, transform);

      rowsProcessed = transform.contiguous_rows_processed || 0;

      percentage = Math.round((rowsProcessed / totalRows) * 100);

      hasErrors = this.hasTransformErrors();

      errorStatusMessage =
        colStatus === COL_STATUS.DONE
          ? singularOrPlural(transform.error_count!, t('error_exists'), t('errors_exist'))
          : singularOrPlural(transform.error_count!, t('error_exists_scanning'), t('errors_exist_scanning'));
    }
    if (this.props.preview) {
      thClasses = 'col-preview';
      progressbarPercent = 100;
      statusText = (
        <a className="status-item" onClick={this.props.preview}>
          {t('just_a_preview')}
        </a>
      );
    } else {
      switch (colStatus) {
        case COL_STATUS.ERROR:
          thClasses = 'failed-column col-failed';
          progressbarPercent = 0;
          statusTextMessage = t('transform_failed');
          break;
        case COL_STATUS.DONE:
          thClasses = 'col-errors';
          progressbarPercent = percentage;
          statusTextMessage = t('no_errors_exist');
          break;
        case COL_STATUS.UNLOADED:
          thClasses = 'col-errors';
          progressbarPercent = 100;
          statusTextMessage = t('not_loaded');
          break;
        default:
          thClasses = 'col-errors';
          progressbarPercent = percentage;
          if (transform.attempts > 0) {
            statusTextMessage = formatString(t('struggling'), {
              attempt: transform.attempts,
              max_attempts: 3
            });
          } else {
            statusTextMessage = this.props.isValidatingRowId ? t('validating_row_id') : t('scanning');
          }
      }

      statusText = <StatusText message={statusTextMessage} status={colStatus} />;

      if (hasErrors) {
        thClasses = classNames('col-errors', { ['col-errors-selected']: inErrorMode }); // eslint-disable-line no-useless-computed-key

        const statusClasses = classNames('status-item', { ['transform-status-selected']: inErrorMode }); // eslint-disable-line no-useless-computed-key

        statusText = (
          <Link
            className={statusClasses}
            to={columnErrorLinkPath}
            data-flyout-close-button="true"
            data-flyout={getErrorFlyoutId(transform)}
          >
            <ErrorPill number={transform.error_count} />
            {errorStatusMessage}
          </Link>
        );
      }
    }

    if (isDropping) {
      thClasses = [thClasses, 'dropping'].join(' ');
    }

    return (
      <th
        style={{ width }}
        className={thClasses}
        key={transform.id}
        data-cheetah-hook="col-errors"
        ref={(flyoutParentEl: HTMLTableHeaderCellElement) => (this.flyoutParentEl = flyoutParentEl)}
      >
        <div className="column-progress-bar">
          <ProgressBar
            type={progressbarType}
            percent={progressbarPercent}
            ariaLabeledBy={`column-display-name-${outputColumn.id}`}
          />
        </div>
        <div className="col-attributes">
          {this.showGeocodeShortcut() && <GeospatialShortcut flyoutId={getGeoFlyoutId(transform)} />}
          {this.showMapFlyout() && !this.props.preview && <MapFlyoutShortcut toggleMap={this.toggleMap} />}
          <GeoFlyout transform={transform} />

          {this.state.isMapShowing && (
            <MapFlyout
              outputSchema={outputSchema}
              transform={transform}
              params={params}
              displayState={this.props.displayState}
              left={this.state.mapPosition}
              onClose={this.toggleMap}
            />
          )}

          {statusText}
          {hasErrors && (
            <ErrorFlyout
              params={params}
              errorPath={columnErrorLinkPath}
              outputSchema={outputSchema}
              outputColumn={outputColumn}
              transform={transform}
            />
          )}
        </div>
      </th>
    );
  }
}

export default TransformStatus;
