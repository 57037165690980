import _ from 'lodash';
import * as dsmapiLinks from 'datasetManagementUI/links/dsmapiLinks';
import { editRevision } from 'datasetManagementUI/reduxStuff/actions/revisions';
import { showModal } from 'datasetManagementUI/reduxStuff/actions/modal';
import * as ApplyRevision from 'datasetManagementUI/reduxStuff/actions/applyRevision';
import { addNotification } from 'datasetManagementUI/reduxStuff/actions/notifications';
import { createSourceSuccess } from 'datasetManagementUI/reduxStuff/actions/createSource';
import {
  subscribeToOutputSchemaThings,
  subscribeToRevision,
  joinMetadataInterpreter,
  subscribeToSource,
  subscribeToTaskSet,
  subscribeToAgents
} from 'datasetManagementUI/reduxStuff/actions/subscriptions';
import { normalizeCreateSourceResponse } from 'datasetManagementUI/lib/jsonDecoders';
import { socrataFetch, checkStatus, getJson } from 'datasetManagementUI/lib/http';
import { parseDate } from 'datasetManagementUI/lib/parseDate';
import { apiCallFailed } from 'datasetManagementUI/reduxStuff/actions/apiCalls';
import { showFlashMessage } from 'datasetManagementUI/reduxStuff/actions/flashMessage';
import React from 'react';
import StaleRevisionFlashMessage from 'datasetManagementUI/components/StaleRevisionFlashMessage/index';
import { modes } from 'datasetManagementUI/lib/modes';
import * as ModeGrantActions from 'datasetManagementUI/reduxStuff/actions/modeGrant';
import { TaskSetStatus } from 'common/types/taskSet';

export const LOAD_REVISION_SUCCESS = 'LOAD_REVISION_SUCCESS';

function showOutdatedRevisionMessage(dispatch, revision, view) {
  dispatch(showFlashMessage({
    kind: 'warning',
    id: 'outdated_revision_warning',
    alignLeft: true,
    message: <StaleRevisionFlashMessage revision={revision} view={view} />
  }));
}

// loadRevision is called every time the app root (ie the Home component) mounts.
// It controls the modal of absolution
export function loadRevision(params) {
  return (dispatch, getState) => {
    const { entities, ui } = getState();
    const views = entities.views;
    const isViewer = ModeGrantActions.isViewer(_.get(ui, 'modeGrant.grant', ''));
    return Promise.all([dispatch(getCurrentRevision(params)), dispatch(getSources(params))])
      .then(([revision, srcs]) => {
        const view = views[revision.fourfour];
        const viewLastModified = view.viewLastModified && new Date(view.viewLastModified * 1000);
        const { edit: editMode } = modes(entities, params);
        if (
          viewLastModified &&
          revision.created_at &&
          !revision.closed_at &&
          editMode &&
          viewLastModified > revision.created_at &&
          !isViewer &&
          !revision.deleted &&
          (!revision.task_sets.length || !revision.task_sets.some((ts) => ts.status != TaskSetStatus.Failure))
        ) {
          showOutdatedRevisionMessage(dispatch, revision, view);
        }

        // joining the metadata template channel also prompts an evaluation of metadata
        // which, if there are any metadata errors, will set them in state
        dispatch(joinMetadataInterpreter(revision));

        // make taskSets to insert into store
        const taskSets = makeTaskSets(revision);

        // show toast for any failed notifications
        // const [failed, succeeded] = _.partition(srcs, source => source.failed_at);
        // failed.forEach(source => dispatch(addNotification('source', null, source.id)));
        srcs
          .filter(source =>
            !!source.failed_at &&
            (_.get(source, 'failure_details.key') !== 'invalid_extension'))
          .forEach(source => dispatch(addNotification('source', source.id)));

        // subscribe to sockets for input / output schemas for sources
        // that haven't failed or finished
        _.flatMap(srcs, source => {
          if (source.failed_at) {
            return [];
          }
          return source.schemas.map(schema => ({
            ...schema,
            source_id: source.id
          }));

        }).forEach(is => dispatch(subscribeToOutputSchemaThings(is)));

        // insert new transforms, input schemas, etc into store; we parse this
        // stuff using the same code that we use when we create a new source
        srcs.forEach(src => {
          const payload = normalizeCreateSourceResponse(src);
          dispatch(createSourceSuccess(payload));
          dispatch(subscribeToSource(src.id, params));
        });

        // insert other stuff into store
        dispatch(loadRevisionSuccess(revision, taskSets));

        // subscribe to unfinished tasks
        revision.task_sets.forEach(taskSet => {
          if (ApplyRevision.taskSetInProgress(taskSet)) {
            dispatch(subscribeToTaskSet(taskSet.id));
          }
        });

        const isInProgressOrSuccessful = _.some(
          revision.task_sets,
          ts => ts.status !== ApplyRevision.TASK_SET_FAILURE
        );
        // if the revision is closed because it has been published or there is a job in progress,
        // show the modal of absolution that blocks further action
        if ((revision.closed_at && !revision.deleted) || isInProgressOrSuccessful) {
          dispatch(showModal('Publishing'));
        }
        if (revision.closed_at && revision.deleted) {
          dispatch(showModal('ErrorModal'));
        }

        // subscribe to the revision channel, mostly to catch output schema id updates
        dispatch(subscribeToRevision(revision.id));
        dispatch(subscribeToAgents(revision));
      });
  };
}

function loadRevisionSuccess(revision, taskSets) {
  return {
    type: LOAD_REVISION_SUCCESS,
    revision,
    taskSets
  };
}

function makeTaskSets(revision) {
  return revision.task_sets.reduce(
    (acc, taskSet) => ({
      ...acc,
      [taskSet.id]: {
        ...taskSet,
        created_at: parseDate(taskSet.created_at),
        finished_at: taskSet.finished_at ? parseDate(taskSet.finished_at) : null,
        created_by: taskSet.created_by
      }
    }),
    {}
  );
}

export function getCurrentRevision(params) {
  return dispatch =>
    socrataFetch(dsmapiLinks.revisionBase(params))
      .then(checkStatus)
      .then(getJson)
      .then(({ resource }) => {
        // y tho?
        const taskSets = resource.task_sets;
        // check to see if the resource has been deleted by checking
        // if it has a closed_at attribute and if it has a task_set
        // that has a status of successful
        return {
          deleted: resource.closed_at &&
                   (!taskSets[0] || (taskSets[taskSets.length - 1].status !== 'successful')),
          id: resource.id,
          domain_id: resource.domain_id,
          action: resource.action,
          fourfour: resource.fourfour,
          metadata: resource.metadata,
          href: resource.href,
          output_schema_id: resource.output_schema_id,
          permission: _.get(resource, 'action.permission', 'public'),
          task_sets: resource.task_sets,
          revision_seq: _.toNumber(resource.revision_seq),
          created_at: parseDate(resource.created_at),
          created_by: resource.created_by,
          closed_at: resource.closed_at ? parseDate(resource.closed_at) : null,
          attachments: resource.attachments,
          is_parent: resource.is_parent
        };
      })
      .catch(error => {
        dispatch(apiCallFailed(null, error));
        throw error;
      });
}

export function getRevision(params) {
  return dispatch =>
    getCurrentRevision(params, dispatch)
      .then(rev => dispatch(editRevision(rev.id, rev)))
      .catch(() => console.warn('Revision fetch failed'));
}

function getSources(params) {
  return dispatch =>
    socrataFetch(dsmapiLinks.sourceIndex(params))
      .then(checkStatus)
      .then(getJson)
      .then(sources => {
        return sources.map(source => source.resource);
      })
      .catch(error => {
        dispatch(apiCallFailed(null, error));
        throw error;
      });
}
