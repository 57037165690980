import _ from 'lodash';

export const HIDE_FLASH_MESSAGE = 'HIDE_FLASH_MESSAGE';
export const hideFlashMessage = (id) => ({
  type: HIDE_FLASH_MESSAGE,
  id
});

export const SHOW_FLASH_MESSAGE = 'SHOW_FLASH_MESSAGE';
export const showFlashMessage = ({ kind, id, message, hideAfterMS, helpMessage, helpUrl, alignLeft }) => dispatch => {
  dispatch({
    type: SHOW_FLASH_MESSAGE,
    id,
    kind,
    message,
    helpMessage,
    helpUrl,
    alignLeft
  });
  if (hideAfterMS && _.isNumber(hideAfterMS)) {
    setTimeout(() => {
      dispatch(hideFlashMessage(id));
    }, hideAfterMS);
  }
};

export const HIDE_ALL_FLASH_MESSAGES = 'HIDE_ALL_FLASH_MESSAGES';
export const hideAllFlashMessages = () => ({
  type: HIDE_ALL_FLASH_MESSAGES
});
