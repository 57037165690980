import uuid from 'uuid';
import * as dsmapiLinks from 'datasetManagementUI/links/dsmapiLinks';
import {
  addNotification,
  removeNotificationAfterTimeout,
  updateNotification
} from 'datasetManagementUI/reduxStuff/actions/notifications';
import {
  apiCallStarted,
  apiCallSucceeded,
  apiCallFailed
} from 'datasetManagementUI/reduxStuff/actions/apiCalls';

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const CHUNK_COMPLETED = 'CHUNK_COMPLETED';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';

export function getContentType(fileType) {
  // Substitute .json for .geojson content type
  // because that's the only json variant we support -
  // if the user has a file `foo.json`, we'll translate
  // the content type to geojson for them
  if (fileType.indexOf('application/json') > -1) {
    // TODO: stop doing this for json files when EN-21134 is fixed
    return 'application/vnd.geo+json';
  }
  return fileType;
}

export function xhrPromise(method, url, file, onProgress) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open(method, url);

    if (xhr.upload) {
      xhr.upload.onprogress = evt => {
        onProgress(evt);
      };
    }

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr);
      } else {
        const error = new Error(xhr.statusText);
        error.response = xhr;
        try {
          error.body = JSON.parse(xhr.response);
        } catch (_err) {
          // this is probably wrong
          error.body = { message: xhr.response };
        }
        reject(error);
      }
    };

    xhr.onerror = error => {
      error.response = xhr;
      reject(error);
    };

    xhr.setRequestHeader('Content-type', getContentType(file.type));
    xhr.setRequestHeader('X-File-Name', encodeURIComponent(file.name));

    xhr.send(file);
  });
}

export function updateProgress(sourceId, percentCompleted) {
  return {
    type: UPDATE_PROGRESS,
    sourceId,
    percentCompleted
  };
}

export function uploadAttachment(revision, file) {
  return dispatch => {
    const callParams = {
      fourfour: revision.fourfour,
      revision_seq: revision.revision_seq
    };

    const callId = uuid();
    // The version included in the subject is a randomized string.
    // This is so that any uploads of the same file name are tracked separately
    // by the browser.
    const subject = `${file.name} (version ${Math.random().toString(36).substring(2, 15)})`;

    const call = {
      operation: UPLOAD_FILE,
      callParams
    };

    dispatch(apiCallStarted(callId, call));
    dispatch(addNotification('attachment', subject, { percent: 0, status: 'inProgress' }));

    const onProgress = (evt) => {
      dispatch(updateNotification(subject, {
        percent: evt.loaded / evt.total * 100,
        status: 'inProgress'
      }));
    };

    return xhrPromise('POST', dsmapiLinks.addAttachment(revision), file, onProgress)
      .then(resp => JSON.parse(resp.responseText))
      .then(resp => {
        dispatch(apiCallSucceeded(callId));
        dispatch(updateNotification(subject, { status: 'success' }));
        dispatch(removeNotificationAfterTimeout(subject));
        return resp;
      })
      .catch(error => {
        dispatch(updateNotification(subject, { status: 'error', error: error.body }));
        dispatch(removeNotificationAfterTimeout(subject));
        dispatch(apiCallFailed(callId, error));
        throw error;
      });
  };
}
