import React from 'react';
import {
  toTextExpr,
  inputColumnSelection,
  getInputColumnFromMapping,
  columnMatchingAst,
  fieldPropTypes,
  stringLiteral
} from './Fields';
import { stripToTextAst, stripToNumberAst } from 'datasetManagementUI/lib/ast';


const ComponentFields = ({ inputColumns, setMapping, mappings }) => (
  <div>
    {inputColumnSelection('address', inputColumns, setMapping, mappings, true)}
    {inputColumnSelection('city', inputColumns, setMapping, mappings, true)}
    {inputColumnSelection('state', inputColumns, setMapping, mappings, true)}
    {inputColumnSelection('zip', inputColumns, setMapping, mappings, true)}
  </div>
);

ComponentFields.propTypes = fieldPropTypes;

const composeFromComponents = (mappings, { useLocation, provider, defaultCountry }) => {
  const geocodeFunc = provider === 'esri' ? 'geocode_arcgis' : 'geocode';

  const address = toTextExpr(getInputColumnFromMapping(mappings, 'address'));
  const city = toTextExpr(getInputColumnFromMapping(mappings, 'city'));
  const state = toTextExpr(getInputColumnFromMapping(mappings, 'state'));
  const zip = toTextExpr(getInputColumnFromMapping(mappings, 'zip'));


  let point = `${geocodeFunc}(${address}, ${city}, ${state}, ${zip})`;
  if (defaultCountry) {
    point = `${geocodeFunc}(${address}, ${city}, ${state}, ${zip}, "${defaultCountry}")`;
  }

  if (useLocation) {
    return `make_location(
      ${address},
      ${city},
      ${state},
      ${zip},
      ${point}
    )`;
  }
  return point;
};

const decomposeFromComponents = (geocodeFunc, inputColumns) => {
  // Remember, any one of these could be null, because we could be decomposing
  // a funcall like: geocode(`address`, null, null, `zip`)
  const [address, city, state, zip] = geocodeFunc.args;

  return [
    // The ORs here are because we might have wrapped the AST in a to_text call depending on the output column type
    ['address', columnMatchingAst(inputColumns, address)
      || columnMatchingAst(inputColumns, stripToTextAst(address))
      || stringLiteral(address)],
    ['city', columnMatchingAst(inputColumns, city)
      || columnMatchingAst(inputColumns, stripToTextAst(city))
      || stringLiteral(city)],
    ['state', columnMatchingAst(inputColumns, state)
      || columnMatchingAst(inputColumns, stripToTextAst(state))
      || stringLiteral(state)],
    ['zip', columnMatchingAst(inputColumns, zip)
      || columnMatchingAst(inputColumns, stripToTextAst(zip))
      || columnMatchingAst(inputColumns, stripToNumberAst(zip))
      || stringLiteral(zip)]
  ];
};

const relevantMappingsForComponents = () => ['address', 'city', 'state', 'zip'];


export {
  ComponentFields,
  composeFromComponents,
  decomposeFromComponents,
  relevantMappingsForComponents
};
