import Button, { VARIANTS } from 'common/components/Button';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';
import React, { Component } from 'react';

const t = (k: string) => I18n.t(k, { scope: 'dataset_management_ui.metadata_manage.column_tab' });

interface Props {
  name: string;
  isHidden: boolean;
  setHidden: (hidden: boolean) => void;
}

class VisibilityToggle extends Component<Props> {
  render() {
    const { name, isHidden, setHidden } = this.props;

    return (
      <div
        className="visibility-toggle">
        <Button
          id={name}
          aria-label={t('hide_column')}
          aria-pressed={isHidden}
          variant={VARIANTS.TRANSPARENT}
          onClick={e => setHidden(!isHidden)}>
        {isHidden ?
          <span>
            <SocrataIcon
              name={IconName.EyeBlocked}
              className="col-form-toggle" />
            <span className="visibility-toggle-text">{t('hidden')}</span>
          </span>
        :
          <SocrataIcon
            name={IconName.Eye}
            className="col-form-toggle" />
        }
        </Button>
      </div>
    );
  }
}

export default VisibilityToggle;
