import { SoQLCellError, SoQLCellOk } from 'common/components/DatasetTable/cell/TableCell';
import { CompilationStatus, ExpressionCompilationResult } from 'common/types/compiler';
import { PhxChannel } from 'common/types/dsmapi';
import { InputSchema } from 'common/types/dsmapiSchemas';
import { Scope, SoQLType } from 'common/types/soql';
import { CompilerAction, CompilerActionType } from '../../reduxStuff/actions/compiler';

export interface DsmuiCompiler {
  inputSchema: InputSchema;
  channel: PhxChannel;
  result: ExpressionCompilationResult | null;
  expression: string | null;
}

interface PreviewLoading {
  type: 'preview_loading';
}
export type PreviewResults = (SoQLCellOk | SoQLCellError)[];
interface PreviewAvailable {
  type: 'preview_available';
  results: PreviewResults;
  soqlType: SoQLType;
}

export type Preview = PreviewLoading | PreviewAvailable;

export interface CompilerState {
  scope: Scope;
  compiler: DsmuiCompiler | null;
  preview: Preview | null;
}

const initialState: CompilerState = {
  compiler: null,
  preview: null,
  scope: []
};

const compiler = (state: CompilerState = initialState, action: CompilerAction): CompilerState => {
  switch (action.type) {
    case CompilerActionType.COMPILER_ADD_SUCCESS: {
      const theCompiler = {
        inputSchema: action.inputSchema,
        channel: action.channel,
        result: null,
        expression: null
      };
      return { ...state, compiler: theCompiler };
    }
    case CompilerActionType.COMPILER_SCOPE: {
      return { ...state, scope: action.scope };
    }
    case CompilerActionType.COMPILATION_STARTED:
      if (state.compiler) {
        const result: ExpressionCompilationResult = {
          type: CompilationStatus.Started,
          expr: action.expression
        };
        return {
          ...state,
          compiler: {
            ...state.compiler,
            expression: action.expression,
            result
          }
        };
      }
      return state;
    case CompilerActionType.COMPILATION_SUCCESS:
      if (state.compiler) {
        const result: ExpressionCompilationResult = {
          type: CompilationStatus.Succeeded,
          expr: action.expression,
          parsed: action.parsed
        };
        return {
          ...state,
          compiler: {
            ...state.compiler,
            result
          }
        };
      }
      return state;
    case CompilerActionType.COMPILATION_FAILURE:
      if (state.compiler) {
        const result: ExpressionCompilationResult = {
          type: CompilationStatus.Failed,
          soql_exception: action.soqlException
        };
        return {
          ...state,
          compiler: {
            ...state.compiler,
            result
          }
        };
      }
      return state;
    case CompilerActionType.COMPILER_REMOVE:
      if (state.compiler) {
        return {
          ...state,
          preview: null,
          compiler: null
        };
      }
      return state;
    case CompilerActionType.PREVIEW_EMPTY: {
      if (state.compiler) {
        return {
          ...state,
          preview: null
        };
      }
      return state;
    }
    case CompilerActionType.PREVIEW_STARTED: {
      return state;
    }
    case CompilerActionType.PREVIEW_AVAILABLE: {
      if (state.compiler) {
        return {
          ...state,
          preview: {
            type: 'preview_available',
            results: action.results,
            soqlType: action.soqlType
          }
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export default compiler;
