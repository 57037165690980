export const SCHEMA_MISMATCH_UPDATE_MAPPING = 'SCHEMA_MISMATCH_UPDATE_MAPPING';
export const SCHEMA_MISMATCH_CLEAR_MAPPING = 'SCHEMA_MISMATCH_CLEAR_MAPPING';
export const updateMapping = (fromColumnFieldName, toColumnFieldName) => ({
  type: SCHEMA_MISMATCH_UPDATE_MAPPING,
  fromColumnFieldName,
  toColumnFieldName
});

export const clearMappings = () => ({
  type: SCHEMA_MISMATCH_CLEAR_MAPPING
});
