import React, { Component } from 'react';
import SocrataIcon from 'common/components/SocrataIcon';
import Picklist from 'common/components/Picklist';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { fetchTranslation } from 'common/locale';
import { ForgeIcon, ForgeIconButton, ForgeTextField } from '@tylertech/forge-react';
const t = (k) => fetchTranslation(k, 'dataset_management_ui.transform_column');


export default class TransformSelect extends Component {
  constructor(props) {
    super(props);
    this.state = { isPicklistVisible: false };
    this.toggleSnippetsDropdown = this.toggleSnippetsDropdown.bind(this);
    this.handleOptionDelete = this.handleOptionDelete.bind(this);
  }

  toggleSnippetsDropdown() {
    const isPicklistVisible = this.state.isPicklistVisible;
    if (!isPicklistVisible && this.props.snippets) {
      return this.props.onCloseDropdown();
    }

    this.setState({ isPicklistVisible: !isPicklistVisible });

    if (isPicklistVisible && this.props.onCloseDropdown) {
      this.props.onCloseDropdown();
    } else if (!isPicklistVisible && this.props.onOpenDropdown) {
      this.props.onOpenDropdown();
    }
  }

  handleOptionDelete(snippet, event) {
    if (this.props.onDelete) {
      event.stopPropagation();
      this.props.onDelete(snippet);
    }
  }

  genPicklistOptions() {
    if (this.props.snippets) {
      return !_.isEmpty(this.props.snippets) ?
        _.map(this.props.snippets, snippet => ({
          value: snippet.id.toString(),
          group: this.props.dropdownHeader || t('recent_snippets'),
          render: () => (
            <div>
              <span className="snip">{snippet.name}</span>
              <span className="picklist-item-delete" onClick={evt => this.handleOptionDelete(snippet, evt)}>
                <SocrataIcon className="picklist-item-delete-icon" name="close-2" />
              </span>
            </div>
          )
        })) :
        [{
          value: 'no-results',
          disabled: true,
          group: this.props.dropdownHeader || t('recent_snippets'),
          render: () => <span className="snip">No Results</span>
        }];
    } else {
      return [{
        disabled: true,
        group: this.props.dropdownHeader || t('recent_snippets'),
        render: () => (
          <div>
            <span className="spinner spinner-default" />
          </div>
        )
      }];
    }
  }

  render() {
    return (
      <div className="transform-name-container">
        <div className="transform-name-field-container">
          <div className="transform-name-input-container">
            <ForgeTextField floatLabelType="always">
              <input
                type="text"
                id="search-transforms"
                value={this.props.snippetName || ''}
                aria-label={t('search_snippets')}
                onChange={this.props.onSnippetNameChange}
              />
              <label htmlFor="search-transforms" slot="label">{t('search_snippets')}</label>
              <ForgeIconButton slot="addon-end">
                <button type="button" onClick={this.toggleSnippetsDropdown} aria-label="Snippet dropdown" >
                  <ForgeIcon name="arrow_drop_down" />
                </button>
              </ForgeIconButton>
            </ForgeTextField>
            {(this.state.isPicklistVisible || this.props.snippets) &&
              <Picklist
                stopPropagation={false}
                onSelection={(...args) => {
                  this.toggleSnippetsDropdown();
                  this.props.onSelect(...args);
                }}
                options={this.genPicklistOptions()} />
            }
          </div>
        </div>
      </div>
    );
  }
}

TransformSelect.propTypes = {
  snippetName: PropTypes.string,
  dropdownHeader: PropTypes.string,
  snippets: PropTypes.array,
  onSelect: PropTypes.func,
  onSnippetNameChange: PropTypes.func,
  onCloseDropdown: PropTypes.func,
  onOpenDropdown: PropTypes.func,
  onDelete: PropTypes.func
};
