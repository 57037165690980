import PropTypes from 'prop-types';
import React, { Component } from 'react';
import mtz from 'moment-timezone';
import { every, map, get, uniq } from 'lodash';
import getLocale from 'common/js_utils/getLocale';
import Button from 'common/components/Button';
import Modal, { ModalContent, ModalFooter, ModalHeader } from 'common/components/Modal';
import DatePicker from 'common/components/DatePicker';
import Select from 'common/components/Select';
import Checkbox from 'common/components/Forms/Checkbox';
import { fetchTranslation } from 'common/locale';

const t = (k) => fetchTranslation(k, 'dataset_management_ui.connection_agent.source_parameters');

class SetSourceParameters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sourceParams: {},
      paramValidity: {},
      canSubmit: true,
      canCancel: true,
      loading: false,
      error: false
    };
    this.submitInProgress = this.submitInProgress.bind(this);
    this.updateFormInputValidity = this.updateFormInputValidity.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.checkInputValidity = this.checkInputValidity.bind(this);
  }

  submitInProgress(submitting) {
    this.setState({
      ...this.state,
      canSubmit: !submitting,
      canCancel: !submitting
    });
  }

  updateFormInputValidity(paramName, validity) {
    const paramValidity = { ...this.state.paramValidity, [paramName]: validity };
    const formValidity = every(Object.values(paramValidity));

    this.setState({
      ...this.state,
      paramValidity: paramValidity,
      canSubmit: formValidity
    });
  }

  checkInputValidity(paramName) {
    return get(this.state, ['paramValidity', paramName], true);
  }

  onSubmit() {
    const { createSource, startSource, hideModal } = this.props;
    this.setState({ ...this.state, loading: true, error: false, canSubmit: false, canCancel: false });

    createSource(this.state.sourceParams).then((source) => {
      startSource(source).catch((error) => {
        this.setState({ loading: false, error: true, canSubmit: true, canCancel: true });
        throw error;
      });
      hideModal();
    }).catch((error) => {
      this.setState({ loading: false, error: true, canSubmit: true, canCancel: true });
      throw error;
    });
  }

  render() {
    const { parameters, hideModal } = this.props;

    const setParam = (paramId) => (value) => {
      this.setState({ sourceParams: { ...this.state.sourceParams, [paramId]: value } });
    };

    const getParam = (paramId) => {
      return this.state.sourceParams[paramId];
    };

    return (
      <div className="dsmp-modal set-source-parameters">
        <Modal onDismiss={hideModal}>
          <ModalHeader title={t('title')} onDismiss={hideModal} />
          <ModalContent>
            <div>
              {t('info_text')}
            </div>
            <SourceParameterInputs setParam={setParam} parameters={parameters} getCurrentVal={getParam} updateFormInputValidity={this.updateFormInputValidity} checkInputValidity={this.checkInputValidity}/>
          </ModalContent>
          <ModalFooter>
            <Button
              disabled={!this.state.canCancel}
              size={'xs'}
              onClick={hideModal} >
              {t('cancel')}
            </Button>
            <Button
              className='parameter-submit'
              disabled={!this.state.canSubmit}
              busy={this.state.loading}
              variant={this.state.error ? 'error' : 'primary'}
              size='xs'
              onClick={this.onSubmit} >
              {this.state.error ? t('try_again') : t('start_import')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const SourceParameterInputs = ({ parameters, setParam, getCurrentVal, updateFormInputValidity, checkInputValidity}) => {
  return (<div className="parameter-inputs">
    { map(parameters,
      param =>
        <SourceParameter
          key={param.id}
          parameter={param}
          currentVal={getCurrentVal(param.id)}
          setValue={setParam(param.id)}
          updateFormInputValidity={updateFormInputValidity}
          checkInputValidity={checkInputValidity}
        />
    )}
  </div>);
};

const SourceParameter = ({parameter, currentVal, setValue, updateFormInputValidity, checkInputValidity}) => {
  const paramName = parameter.label.translations[getLocale()] || parameter.label.fallback;
  const errorMsg = parameter.type === 'NUMBER' ? t('invalid_number_input') : t('default_invalid_input');

  const parameterInput = () => {
    switch (parameter.type) {
      case 'FLOATING_TIMESTAMP':
      case 'FIXED_TIMESTAMP':
        return <DateTimeParameter
          paramName={paramName}
          setValue={setValue}
          isValid={checkInputValidity(paramName)}
          useTimezone={parameter.type === 'FIXED_TIMESTAMP'}
          updateFormInputValidity={updateFormInputValidity} />;
      case 'BOOLEAN':
        return <BooleanParameter
          paramName={paramName}
          setValue={setValue}
          currentVal={currentVal} />;
      default:
        return <GenericParameter
          paramName={paramName}
          paramType={parameter.type}
          currentVal={currentVal}
          isValid={checkInputValidity(paramName)}
          errorMsg={errorMsg}
          setValue={setValue}
          updateFormInputValidity={updateFormInputValidity} />;
    }
  };

  return (
    <div className="source-parameters-field">
      <label id={`${paramName}-label`} htmlFor={paramName}>
        {paramName}
      </label>
      <br/>
      {parameterInput()}
    </div>
  );
};

const GenericParameter = ({ paramType, paramName, currentVal, errorMsg, isValid, setValue, updateFormInputValidity }) => {
  const classNames = ['parameter-input'];
  if (!isValid) {
    classNames.push('field-error');
  }

  return (
    <div className={classNames.join(' ')}>
      { isValid || (
        <span className='error-details '>{errorMsg}</span>
      )}
      <div className='parameter-selection'>
        <input
          type={paramType}
          id={paramName}
          name={paramName}
          value={currentVal ||  ''}
          aria-label={paramName}
          onInput={(e) => {
            const valid = get(e, ['target', 'validity', 'valid'], true);
            updateFormInputValidity(paramName, valid);
            setValue(e.target.value);
          }} />
      </div>
    </div>
  );
};

const BooleanParameter = ({ paramName, currentVal, setValue }) => {
  const classNames = ['parameter-input'];
  const onChange = () => {
    setValue(!currentVal);
  };

  return (
    <div className={classNames.join(' ')}>
      <div className='parameter-selection'>
        <Checkbox
          id={paramName}
          name={paramName}
          aria-label={paramName}
          checked={currentVal ||  false}
          onChange={onChange}
          ></Checkbox>
      </div>
    </div>
  );
};

class DateTimeParameter extends Component {
  constructor(props) {
    super(props);
    // track whether or not the user has touched the timezone value
    this.state = { date: '', time: '', timezone:  mtz().format('Z'), timezoneChanged: false};
  }

  getDate({date, time, timezone}) {
    // ISO8601 plz
    let dateTimeString = `${date}T${time}:00.000`;
    if (this.props.useTimezone) {
      dateTimeString = dateTimeString + `${timezone}`;
    }
    return dateTimeString;
  }

  checkValidity({date, time, timezone}, timezoneJustChanged) {
    const timezoneEverChanged = timezoneJustChanged || this.state.timezoneChanged;
    const allFilled = !!date && !!time && (!!timezone || !this.props.useTimezone);
    // don't count the timezone as filled if it's just been left as default
    const noneFilled = !date && !time && (!timezone || !timezoneEverChanged);
    return allFilled || noneFilled;
  }

  handleDateTimeChange = (dateTimeInfo, timezoneJustChanged) => {
    const isValid = this.checkValidity(dateTimeInfo, timezoneJustChanged);
    this.setState({
      ...dateTimeInfo,
      timezoneChanged: timezoneJustChanged || this.state.timezoneChanged
    });
    this.props.updateFormInputValidity(this.props.paramName, isValid);
    if (isValid) {
      this.props.setValue(this.getDate(dateTimeInfo));
    }
  };

  handleTimeChange = (event) => {
    const dateTimeInfo = {
      ...this.state,
      time: event.target.value
    };
    this.handleDateTimeChange(dateTimeInfo);
  };

  handleTimezoneChange = (event) => {
    const dateTimeInfo = {
      ...this.state,
      timezone: event.target.value,
    };
    this.handleDateTimeChange(dateTimeInfo, true);
  };

  handleDateChange = (date) => {
    const dateTimeInfo = {
      ...this.state,
      date: date
    };
    this.handleDateTimeChange(dateTimeInfo);
  };

  render() {
    const { useTimezone, isValid } = this.props;

    const timezoneNoSelection = {
      title: `-- ${t('no_selection')} --`,
      value: ''
    };

    const timezoneOffsets = uniq(map(mtz.tz.names(), (name) => {
      return mtz().tz(name).format('Z');
    })).sort((a, b) => {
      return parseInt(a.replace(':', ''), 10) - parseInt(b.replace(':', ''), 10);
    });

    const timezoneField = {
      name: 'timezone',
      label: 'timezone',
      isRequired: false,
      value: this.state.timezone,
      options: [
        timezoneNoSelection,
        ...map(timezoneOffsets, (offset) => {
          return {
            value: offset,
            title: `GMT ${offset}`,
            selected: this.state.timezone === offset
          };
        })
      ]
    };

    const classNames = ['datetime-picker', 'parameter-input'];
    if (!isValid) {
      classNames.push('field-error');
    }

    return (
      <div className={classNames.join(' ')}>
        { isValid || (
          <span className='error-details'>{t('datetime_incomplete')}</span>
        )}
        <DatePicker date={this.state.date || undefined} onChangeDate={this.handleDateChange} allowDateClearing={true} />
        <input type="time" onChange={this.handleTimeChange} />
        { useTimezone &&
          (<span>
            <Select field={timezoneField} handleChange={this.handleTimezoneChange} />
          </span>)
        }
      </div>
    );
  }
}

SetSourceParameters.propTypes = {
  parameters: PropTypes.array.isRequired,
  createSource: PropTypes.func.isRequired,
  startSource: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default SetSourceParameters;
