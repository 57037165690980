import _ from 'lodash';
import { connect } from 'react-redux';
import * as Selectors from 'datasetManagementUI/selectors';
import RowDetails from 'datasetManagementUI/components/RowDetails/RowDetails';
import { isEditMode } from 'datasetManagementUI/lib/modes';

export function mapStateToProps({ entities }, { fourfour, revisionSeq }) {
  const view = entities.views[fourfour];
  const numericRevisionSeq = _.toNumber(revisionSeq);
  const revision = Selectors.currentRevision(entities, numericRevisionSeq);
  const source = Selectors.currentSource(entities, numericRevisionSeq);

  const currentSchema = Selectors.currentOutputSchema(entities, numericRevisionSeq);

  const sourceType = _.get(source, 'source_type.type');

  let changedSchema;
  if (currentSchema) {
    const outputSchemas = Object.keys(entities.output_schemas);
    changedSchema = sourceType === 'view' && outputSchemas.length > 1;
  }

  return {
    editMode: isEditMode(view),
    changedData: currentSchema && (sourceType !== 'view' || changedSchema),
    draftRowLabel: _.get(revision, 'metadata.metadata.rowLabel', ''),
    fourfour,
    revisionSeq
  };
}

export default connect(mapStateToProps)(RowDetails);
