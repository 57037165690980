import PropTypes from 'prop-types';
import React from 'react';
import { ModalContent, ModalFooter } from 'common/components/Modal';
import { fetchTranslation } from 'common/locale';
const common = (k) => {
  return fetchTranslation(k, 'dataset_management_ui.common');
};

const ConfirmationModal = ({
  onDismiss,
  onConfirm,
  confirmText,
  title,
  body,
  classes = [],
  confirmBtnClasses = ['btn-primary']
}) => (
  <div className={`dsmp-confirmation-modal ${classes.join(' ')}`}>
    <h2>{title}</h2>
    <ModalContent>
      {body}
    </ModalContent>
    <ModalFooter>
      <button
        className="btn btn-default"
        onClick={onDismiss}>
        {common('cancel')}
      </button>
      <button
        className={`btn ${confirmBtnClasses.join(' ')}`}
        onClick={onConfirm}>
        {confirmText}
      </button>
    </ModalFooter>
  </div>
);

ConfirmationModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  confirmText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.any.isRequired,
  classes: PropTypes.array,
  confirmBtnClasses: PropTypes.array
};

export default ConfirmationModal;
