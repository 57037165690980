import _ from 'lodash';
import { connect } from 'react-redux';
import { isUnloadedViewSource } from 'common/types/source';
import SoQLResults from '../components/TransformColumn/SoQLResults';
import * as DisplayState from '../lib/displayState';
import * as Links from '../links/links';

function mapStateToProps(state, props) {
  const params = props.params;

  const source = state.entities.sources[_.toNumber(params.sourceId)];
  const unloadedViewSource = isUnloadedViewSource(source.source_type);

  const outputColumn = state.entities.output_columns[props.outputColumn.id];
  const transform = state.entities.transforms[outputColumn.transform_id];
  outputColumn.transform = transform;

  const displayState = DisplayState.fromUiUrl(_.pick(props, ['params', 'location']));

  const columnErrorLinkPath = DisplayState.inErrorMode(displayState, transform)
    ? Links.transformColumn(
      params,
      params.sourceId,
      params.inputSchemaId,
      params.outputSchemaId,
      outputColumn.id
    )
    : Links.transformColumnErrors(
      params,
      params.sourceId,
      params.inputSchemaId,
      params.outputSchemaId,
      outputColumn.id,
      transform.id
    );

  return {
    params: props.params,
    entities: state.entities,
    inputSchema: props.inputSchema,
    displayState,
    outputColumn,
    transform,
    columnErrorLinkPath,
    unloadedViewSource
  };
}

export default connect(mapStateToProps)(SoQLResults);
