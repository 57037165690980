import _ from 'lodash';
import { createStore, compose } from 'redux';
import rootReducer from 'datasetManagementUI/reduxStuff/reducers/rootReducer';
import middleware from 'datasetManagementUI/reduxStuff/middleware';
import { setModeGrant } from 'datasetManagementUI/reduxStuff/actions/modeGrant';
import { none } from 'ts-option';

export function getInitialState(view, customMetadataFieldsets) {
  const [ computedColumns, userColumns ] = _.partition(view.columns, col =>
    col.fieldName.startsWith(':@'));

  const initialView = {
    ...view,
    id: view.id,
    name: view.name,
    columns: userColumns,
    computedColumns: computedColumns || [],
    displayType: view.displayType,
    viewType: view.viewType,
    blobMimeType: view.blobMimeType,
    blobFileSize: view.blobFileSize,
    blobFilename: view.blobFilename,
    blobId: view.blobId,
    rowCount: 0, // just initailizing here, will update later with soda api call
    description: view.description,
    category: view.category,
    owner: view.owner,
    viewLastModified: view.viewLastModified,
    rowsUpdatedAt: view.rowsUpdatedAt,
    createdAt: view.createdAt,
    viewCount: view.viewCount,
    downloadCount: view.downloadCount,
    license: view.license || {},
    licenseId: view.licenseId,
    provenance: view.provenance,
    attribution: view.attribution,
    attributionLink: view.attributionLink,
    tags: view.tags || [],
    privateMetadata: view.privateMetadata || {},
    attachments: _.get(view, 'metadata.attachments', []),
    metadata: view.metadata || {},
    approvals: view.approvals || [],
    rights: view.rights || [],
    customMetadataFieldsets
  };

  return {
    entities: {
      views: {
        [view.id]: initialView
      }
    },
    ui: {
      modeGrant: setModeGrant(initialView),
      autosaving: { inProgress: false },
      vqeUndoRedoHistory: { undo: [], redo: [], justApplied: none },
    }
  };
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function () {
  const initialState = getInitialState(window.initialState.view, window.initialState.customMetadataFieldsets);

  return createStore(rootReducer, initialState, composeEnhancers(middleware));
}
