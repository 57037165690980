import Dropdown from 'common/components/Dropdown';
import { DropdownOption } from 'common/components/Forms/Dropdown';
import I18n from 'common/i18n';
import { ColumnFormat } from 'common/types/viewColumn';
import React from 'react';
import DropdownWithIcon from '../DropdownWithIcon/DropdownWithIcon';
import Alignment from 'common/FormatColumn/Alignment';

const t = (k: string) => I18n.t(k, { scope: 'dataset_management_ui.format_column' });

interface Props {
  onUpdateFormat: (f: ColumnFormat) => void;
  format: ColumnFormat;
}
interface locationProps {
  onUpdateFormat: (f: ColumnFormat) => void;
  format: ColumnFormat;
  defaultAlignment: string;
}

function ViewTypeChooser({ onUpdateFormat, format }: Props) {
  const dropdownProps = {
    onSelection: (e: DropdownOption<string>) => onUpdateFormat({ view: e.value }),
    value: format.view || 'address_coords',
    placeholder: t('render_type'),
    options: [
      {
        title: t('address_coords'),
        value: 'address_coords',
        icon: 'socrata-icon-map',
        render: DropdownWithIcon
      },
      {
        title: t('address'),
        value: 'address',
        icon: 'socrata-icon-text',
        render: DropdownWithIcon
      },
      {
        title: t('coords'),
        value: 'coords',
        icon: 'socrata-icon-geo',
        render: DropdownWithIcon
      }
    ]
  };
  return (
    <div>
      <label>{t('render_type')}</label>
      <Dropdown {...dropdownProps} />
    </div>
  );
}

function LocationColumnFormat({ onUpdateFormat, format, defaultAlignment }: locationProps) {
  return (
    <div id="format-column">
      <form>
        <Alignment onChange={onUpdateFormat} format={format} defaultAlignment={defaultAlignment}/>
        <ViewTypeChooser onUpdateFormat={onUpdateFormat} format={format} />
      </form>
    </div>
  );
}

export default LocationColumnFormat;
