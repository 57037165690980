import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { COL_STATUS } from 'datasetManagementUI/components/TransformStatus/TransformStatus';

export default function ProgressBar({ percent, ariaLabel, ariaLabeledBy, type, className }) {
  const ariaLabelProp = {};

  if (ariaLabel) {
    ariaLabelProp['aria-label'] = ariaLabel;
  }

  if (ariaLabeledBy) {
    ariaLabelProp['aria-labelledby'] = ariaLabeledBy;
  }

  let classNames = ['progress-bar-container']; // eslint-disable-line

  className && classNames.push(className); // eslint-disable-line

  let progressBarClass;

  switch (type) {
    case 'success':
      progressBarClass = 'progress-bar-success';
      break;
    case COL_STATUS.ERROR:
      progressBarClass = 'progress-bar-error';
      break;
    case COL_STATUS.IN_PROGRESS:
      progressBarClass = 'progress-bar-in-progress';
      break;
    case COL_STATUS.DONE:
      progressBarClass = 'progress-bar-done';
      break;
    case COL_STATUS.UNLOADED:
      progressBarClass = 'progress-bar-done';
      break;
    case COL_STATUS.WARNING:
      progressBarClass = 'progress-bar-warning';
      break;
    default:
      progressBarClass = 'progress-bar-default';
  }

  return (
    <div
      className={classNames.join(' ')}
      role="progressbar"
      aria-valuenow={Math.round(percent)}
      aria-valuemin="0"
      aria-valuemax="100">
      <div className={`progress-bar ${progressBarClass}`} style={{ width: `${percent}%` }} />
    </div>
  );
}

ProgressBar.propTypes = {
  percent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ariaLabel: PropTypes.string,
  ariaLabeledBy: PropTypes.string,
  type: PropTypes.oneOf(['success', 'warning', ..._.values(COL_STATUS)]),
  className: PropTypes.string
};
