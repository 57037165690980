import uuid from 'uuid';
import { parseDate } from 'datasetManagementUI/lib/parseDate';
import {
  apiCallStarted,
  apiCallSucceeded,
  apiCallFailed,
  UPDATE_REVISION,
  DELETE_REVISION
} from 'datasetManagementUI/reduxStuff/actions/apiCalls';
import * as dsmapiLinks from 'datasetManagementUI/links/dsmapiLinks';
import { socrataFetch, checkStatus, getJson } from 'datasetManagementUI/lib/http';

export const EDIT_REVISION = 'EDIT_REVISION';
export const SET_REVISION_VALUE = 'SET_REVISION_VALUE';
export const editRevision = (id, payload) => ({
  type: EDIT_REVISION,
  id,
  payload
});

export const setRevisionValue = (path, value) => ({
  type: SET_REVISION_VALUE,
  path,
  value
});

export function shapeRevision(revision) {
  return {
    ...revision,
    created_at: parseDate(revision.created_at)
  };
}

export function updateRevision(update, params, callId = uuid()) {
  return dispatch => {
    dispatch(
      apiCallStarted(callId, {
        operation: UPDATE_REVISION,
        callParams: {
          ...update
        }
      })
    );

    return socrataFetch(dsmapiLinks.revisionBase(params), {
      method: 'PUT',
      body: JSON.stringify(update)
    })
      .then(checkStatus)
      .then(getJson)
      .then(resp => {
        const rev = resp.resource;
        dispatch(apiCallSucceeded(callId));
        dispatch(editRevision(rev.id, shapeRevision(rev)));
        return resp;
      })
      .catch(err => {
        dispatch(apiCallFailed(callId, err));
        throw err;
      });
  };
}

export function deleteRevision(params, callId = uuid()) {
  return dispatch => {
    dispatch(
      apiCallStarted(callId, {
        operation: DELETE_REVISION,
        callParams: {}
      })
    );
    return socrataFetch(dsmapiLinks.revisionBase(params), { method: 'DELETE' })
      .then(checkStatus)
      .then(() => dispatch(apiCallSucceeded(callId)))
      .catch(err => {
        dispatch(apiCallFailed(callId, err));
        throw err;
      });
  };
}

/**
 * Get revision guidance for the given asset.
 * NOTE - This is a different "guidance" than the approvals guidance used elsewhere.
 * @param {string} fourfour The 4x4 of the asset
 * @returns Revision guidance
 */
export function guidance(fourfour) {
  return socrataFetch(`/api/publishing/v1/revision/${fourfour}/guidance`, { method: 'GET' })
    .then(checkStatus)
    .then(getJson);
}
