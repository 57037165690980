import { METADATA_CHANNEL_JOINED } from '../actions/metadataTemplate';

const initialState = {
  channel: null,
  templates: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case METADATA_CHANNEL_JOINED: {
      return { ...state, channel: action.channel, templates: action.templates };
    }
    default:
      return state;
  }
};
