import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import * as ModeGrantActions from 'datasetManagementUI/reduxStuff/actions/modeGrant';
import MetadataTable from 'datasetManagementUI/containers/MetadataTableContainer';
import SchemaPreview from 'datasetManagementUI/containers/SchemaPreviewContainer';
import HomePaneSidebar from 'datasetManagementUI/components/HomePaneSidebar/HomePaneSidebar';
import RowDetails from 'datasetManagementUI/containers/RowDetailsContainer';
import InfoPane from 'datasetManagementUI/containers/InfoPaneContainer';
import DataActionsPanel from 'datasetManagementUI/components/DataActionsPanel/DataActionsPanel';
import I18n from 'common/i18n';
import { AppState, Params } from 'datasetManagementUI/lib/types';
import { View } from 'common/types/view';
import { canEditOrReviewSources } from 'datasetManagementUI/selectors';

interface Props {
  params: Params;
  isParentRevision: boolean;
  hasOutputSchema: boolean;
  isViewer: boolean;
  view: View;
}
interface State {
  recentActionsOpened: boolean;
}
export class ShowRevision extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { recentActionsOpened: false };
    this.toggleRecentActions = this.toggleRecentActions.bind(this);
  }
  toggleRecentActions() {
    this.setState({ recentActionsOpened: !this.state.recentActionsOpened });
  }
  render() {
    const { params, isParentRevision, hasOutputSchema, isViewer, view } = this.props;
    return (
      <div className="show-revision-container">
        <div className="show-revision-content">
          <InfoPane params={params} />
          {!isViewer && <DataActionsPanel view={view} />}
          {this.state.recentActionsOpened ? (
            <HomePaneSidebar close={() => this.setState({ recentActionsOpened: false })} />
          ) : (
            <button
              className="recent-actions-btn btn btn-alternate-2 btn-inverse"
              onClick={this.toggleRecentActions}
            >
              {I18n.t('dataset_management_ui.home_pane.home_pane_sidebar.recent_actions')}
            </button>
          )}
          <MetadataTable />
          {
            // even non-data views have an unloaded output schema for column metadata, but we
            // don't want to show data-like components for them
          }
          {hasOutputSchema && canEditOrReviewSources(view) && (
            <div className="container schema-preview-container">
              <SchemaPreview />
              {isParentRevision || (
                <RowDetails fourfour={params.fourfour} revisionSeq={_.toNumber(params.revisionSeq)} />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

interface OwnProps {
  params: Params;
}
const mapStateToProps = ({ entities, ui }: AppState, { params }: OwnProps): Props => {
  const revision = _.values(entities.revisions).find(
    (rev) => rev.revision_seq === _.toNumber(params.revisionSeq)
  )!;
  const view = entities.views[params.fourfour]!;

  const isViewer = ModeGrantActions.isViewer(_.get(ui, 'modeGrant.grant', ''));

  const isParentRevision = revision.is_parent;

  return {
    params,
    isParentRevision,
    isViewer,
    hasOutputSchema: !_.isNil(revision.output_schema_id),
    view
  };
};

export default connect(mapStateToProps)(ShowRevision);
