// TODO: remove this since the aab has it's own modal? need to confirm that people won't apply revisions
// outside of aab

import { ModalContent, ModalFooter } from 'common/components/Modal';
import { FeatureFlags } from 'common/feature_flags';
import { commaify } from 'common/formatNumber';
import I18n from 'common/i18n';
import { Revision } from 'common/types/revision';
import { TaskSet } from 'common/types/taskSet';
import ProgressBar from 'datasetManagementUI/components/ProgressBar/ProgressBar';
import { PERMISSIONS } from 'datasetManagementUI/components/PublishConfirmation/PublishConfirmation';
import ApiCallButton from 'datasetManagementUI/containers/ApiCallButtonContainer';
import NotifyButton from 'datasetManagementUI/containers/NotifyButtonContainer';
import { Params } from 'datasetManagementUI/lib/types';
import { APPLY_REVISION } from 'datasetManagementUI/reduxStuff/actions/apiCalls';
import * as ApplyRevision from 'datasetManagementUI/reduxStuff/actions/applyRevision';
import { logEntryIsRowsUpsertedResult } from 'datasetManagementUI/selectors';
import _ from 'lodash';
import React from 'react';
import DSMUIIcon from '../DSMUIIcon';

const t = (k: string, scope = 'dataset_management_ui.home_pane.publish_modal') => I18n.t(k, { scope });

// creating columns takes up 10% of the progress bar
const CREATING_COLUMNS_STAGE_FRACTION = 0.1;
const CREATING_COLUMNS_STAGE_TIME_GUESS_MS = 7000;
// hold at this until column creation succeeds
const CREATING_COLUMNS_FRACTION_WHEN_OVER = 0.9;
// actually upserting the data takes 80% of the progress bar
const UPSERTING_STAGE_FRACTION = 0.8;
// everything after reaching 100% data upserted takes up 10% of the progress bar
const FINISHING_STAGE_FRACTION = 0.1;

export function computeProgress(rowsToBeUpserted: number, taskSet: TaskSet) {
  const now = new Date();
  switch (taskSet.status) {
    case ApplyRevision.TASK_SET_INITIALIZING:
    case ApplyRevision.TASK_SET_CREATING_COLUMNS: {
      const fractionOfTimeEstimate = (now.getTime() -  (new Date(taskSet.created_at).getTime())) / CREATING_COLUMNS_STAGE_TIME_GUESS_MS;
      return (
        Math.min(fractionOfTimeEstimate, CREATING_COLUMNS_FRACTION_WHEN_OVER) *
        CREATING_COLUMNS_STAGE_FRACTION
      );
    }
    case ApplyRevision.TASK_SET_UPSERTING: {
      if (rowsToBeUpserted === null) {
        return CREATING_COLUMNS_STAGE_FRACTION + UPSERTING_STAGE_FRACTION;
      }
      const mostRecentLogItem = taskSet.log[0];
      if (logEntryIsRowsUpsertedResult(mostRecentLogItem)) {
        const rowsUpserted = mostRecentLogItem.details.count || 0;
        const fractionUpserted = rowsUpserted / rowsToBeUpserted;
        return CREATING_COLUMNS_STAGE_FRACTION + fractionUpserted * UPSERTING_STAGE_FRACTION;
      } else {
        return 0;
      }
    }
    case ApplyRevision.TASK_SET_UNRETRYABLE_FAILURE:
    case ApplyRevision.TASK_SET_SUCCESSFUL:
    case ApplyRevision.TASK_SET_FAILURE:
      return 1;
    default:
      return CREATING_COLUMNS_STAGE_FRACTION + UPSERTING_STAGE_FRACTION + FINISHING_STAGE_FRACTION / 2;
  }
}

function inProgressMessage(rowsToBeUpserted: number, taskSet: TaskSet) {
  if (taskSet.status === ApplyRevision.TASK_SET_UPSERTING && rowsToBeUpserted !== null) {
    const upserting = t('progress_messages.upserting');
    const mostRecentLogItem = taskSet.log[0];

    if (logEntryIsRowsUpsertedResult(mostRecentLogItem)) {
      const upserted = commaify(mostRecentLogItem.details?.count || 0);
      const total = commaify(rowsToBeUpserted);
      const rows = t('rows', 'dataset_management_ui.notifications');
      return `${upserting} (${upserted} / ${total} ${rows})`;
    } else {
      return upserting;
    }
  } else {
    const statuses = [
      'initializing',
      'creating_columns',
      'upserting',
      'awaiting_upsert_response',
      'creating_indexes',
      'finishing',
      'successful',
      'failure',
      'invalid',
      'unretryable_failure'
    ];
    const translationKey = statuses.includes(taskSet.status) ? taskSet.status : 'default';
    return t(`progress_messages.${translationKey}`);
  }
}

function getProcessingTitle(revision: Revision) {
  if (FeatureFlags.value('usaid_features_enabled')) {
    return revision.is_parent
      ? t('publishing.data_asset_processing')
      : t('publishing.dataset_processing');
  } else {
    // revision.actions.permission is deprecated
    // revision.permissions.scope is only used on the first revision for a new asset
    // window.initialState.view.permissions.scope is the new shiny
    const permission = _.get(revision, 'permissions.scope')
      || _.get(revision, 'action.permission')
      || _.get(window, 'initialState.view.permissions.scope');
    switch (permission) {
      case PERMISSIONS.PUBLIC:
        return t('publishing.title_public');
      case PERMISSIONS.INTERNAL:
        return t('publishing.title_internal');
      default: // PERMISSIONS.PRIVATE
        return t('publishing.title_private');
    }
  }
}

function getSuccessfulMessage(revision: Revision) {
  if (FeatureFlags.value('usaid_features_enabled')) {
    return revision.is_parent
      ? t('successful.data_asset_submitted_title')
      : t('successful.dataset_submitted_title');
  } else {
    return t('successful.title');
  }
}

const TICK_MS = 500;

interface Props {
  revision: Revision;
  taskSet: TaskSet;
  rowsToBeUpserted: number;
  fourfour: string;
  applyRevision: (p: Params) => void;
  onCancelClick: () => void;
  params: Params;
}

interface State {
  ticks: number;
  tickerIntervalID: NodeJS.Timeout;
}

class Publishing extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ticks: 0, // thing we change so it'll rerender
      tickerIntervalID: setInterval(() => {
        this.setState({
          ticks: this.state.ticks + 1
        });
      }, TICK_MS)
    };
  }

  componentWillUnmount() {
    clearInterval(this.state.tickerIntervalID);
  }

  render() {
    const {
      revision,
      taskSet,
      fourfour,
      rowsToBeUpserted,
      applyRevision,
      onCancelClick,
      params
    } = this.props;

    let title;
    let body;
    let icon;
    let button;
    let message;
    let progressBarType;
    let usaidButton;
    const showUsaidButton = FeatureFlags.value('usaid_features_enabled') && revision.is_parent;

    switch (taskSet.status) {
      case ApplyRevision.TASK_SET_SUCCESSFUL:
        title = getSuccessfulMessage(revision);
        body = t('successful.body');
        progressBarType = 'success';
        message = t('progress_messages.done');
        icon = <DSMUIIcon className="status-icon success" name="checkmark-alt" />;
        button = (
          <a href={`/d/${fourfour}`} className="btn btn-primary button">
            {t('to_primer')}
          </a>
        );
        usaidButton = (showUsaidButton &&
          <div>
            <br />
            <a href={'/datasets/new?beta=true&data_asset=false'} className="btn btn-primary button">
              {t('create_a_dataset')}
            </a>
          </div>
        );
        break;
      case ApplyRevision.TASK_SET_UNRETRYABLE_FAILURE:
        title = t('unretryable_failure.title');
        body = t('unretryable_failure.body');
        progressBarType = 'error';
        message = (<div>
          <span>{t('failure.include_request_id')}</span>
          <pre>
            request_id = {taskSet.request_id}
          </pre>
        </div>);
        icon = <DSMUIIcon className="status-icon failure" name="close-circle" />;
        button = (
          <div>
            <button key="cancel" onClick={onCancelClick} className="btn btn-default cancel-button">
              {t('cancel', 'dataset_management_ui.common')}
            </button>
            <a href={`/d/${fourfour}`} className="btn btn-primary cancel-button button">
              {t('unretryable_failure.review_data')}
            </a>
          </div>
        );
        break;
      case ApplyRevision.TASK_SET_FAILURE:
        title = t('failure.title');
        body = t('failure.body');
        progressBarType = 'error';
        message = (<div>
          <span>{t('failure.include_request_id')}</span>
          <pre>
            request_id = {taskSet.request_id}
          </pre>
        </div>);
        icon = <DSMUIIcon className="status-icon failure" name="close-circle" />;
        button = (
          <div>
            <button key="cancel" onClick={onCancelClick} className="btn btn-default cancel-button">
              {t('cancel', 'dataset_management_ui.common')}
            </button>
            <ApiCallButton
              additionalClassName="cancel-button btn-primary"
              callParams={null}
              onClick={() => {
                applyRevision(params);
              }}
              operation={APPLY_REVISION}>
              {t('try_again', 'dataset_management_ui.common')}
            </ApiCallButton>
          </div>
        );
        break;
      case ApplyRevision.TASK_SET_UNPAUSING:
      case ApplyRevision.TASK_SET_PAUSED:
        title = t('paused.title');
        body = t('paused.body');
        icon = <DSMUIIcon className="status-icon in-progress" name="pause" />;
        button = <NotifyButton className="btn button" />;
        break;
      default:
        // in progress
        title = getProcessingTitle(revision);
        body = t('publishing.body');
        progressBarType = 'inProgress';
        message = inProgressMessage(rowsToBeUpserted, taskSet);
        icon = <DSMUIIcon className="status-icon in-progress" name="public-open" />;
        button = <NotifyButton className="btn button" />;
        break;
    }

    return (
      <div id="publishing">
        <h2>
          {title}
        </h2>
        <ModalContent>
          <p>
            {body}
          </p>
          {icon}
          {progressBarType && <div className="progress-bar-containter">
            <ProgressBar
              percent={computeProgress(rowsToBeUpserted, taskSet) * 100}
              type={progressBarType}
              ariaLabel="progress publishing" />
          </div>}
          <div className="status-message">
            {message}
          </div>
        </ModalContent>
        <ModalFooter className="publishing-modal-footer">
          {button}
          {usaidButton}
        </ModalFooter>
      </div>
    );
  }
}


export default Publishing;
