import * as React from 'react';
import * as _ from 'lodash';
import Fieldset from 'datasetManagementUI/components/Fieldset/Fieldset';
import TextInput from 'datasetManagementUI/components/TextInput/TextInput';
import TextArea from 'datasetManagementUI/components/TextArea/TextArea';
import Select from 'common/components/Select';
import { Entities } from 'datasetManagementUI/lib/types';
import { CombinedProps as AddColFormProps,
  Errors,
  FormFieldName,
  AddColFormState
} from 'datasetManagementUI/containers/AddColFormContainer';
import ApiCallButton from 'datasetManagementUI/containers/ApiCallButtonContainer';
import I18n from 'common/i18n';
import { ADD_COLUMN } from 'datasetManagementUI/reduxStuff/actions/apiCalls';

const scope = 'dataset_management_ui';

export function makeFieldName(displayName = ''): string {
  // First 'replace' swaps all whitespace for '_'
  // Second 'replace' swaps all non-alphanumerics/non-underscores for '_'
  let inProgressName = displayName
    .replace(/\s/g, '_')
    .replace(/\W/g, '_');

  const startsWithNumber = new RegExp(/^\d/);
  if (startsWithNumber.test(inProgressName)) {
    inProgressName = '_' + inProgressName;
  }
  // This could result in several consecutive underscores: e.g.
  // 'this is @ field name' -> 'this_is_@_field_name' -> 'this_is__field_name'.
  // So we run the final 'replace' to find consecutive underscores and replace them
  // with a single underscore.
  return inProgressName.replace(/__+/g, '_').toLowerCase();
}

interface TransformFn {
  (x: { field_name: string } | null, y: Entities): string;
}

export function makeTransformExpr(fieldName: string, transform: TransformFn, entities: Entities) {
  if (fieldName === 'null') {
    return transform(null, entities);
  } else {
    return transform({ field_name: fieldName }, entities);
  }
}

const ErrorList = ({ errors }: { errors: string[] }) => {
  return (
    <ul className="dsmp-error-list">
      {errors.map(error => (
        <li key={btoa(error)} className="dsmp-error-message">
          {error}
        </li>
      ))}
    </ul>
  );
};

export const initialState = {
  displayName: '',
  description: '',
  transform: '',
  fieldName: '',
  position: '',
  sourceColumnId: '',
  transformExpr: ''
};

class AddColForm extends React.Component<AddColFormProps, AddColFormState> {
  constructor(props: AddColFormProps) {
    super(props);
    this.state = initialState;
  }

  UNSAFE_componentWillReceiveProps(newProps: AddColFormProps) {
    // Kind of a hack for the submit button not being a child of the component
    // (which we could avoid if we moved it out of the modal footer >:{). The
    // button toggles a flag in the reduxstate, which resets the internal
    // state of this component
    if (newProps.clearInternalState) {
      this.setState(initialState);

      this.props.toggleClearInternalState();
    }
  }

  // This method is called on prop/state change. React has already diffed the
  // old props/state against the new and decided that they were different so
  // it should update the ui. Since we know at this point that a significant
  // change has occured, we check if that change occured to the state and if
  // so push it to the redux store. This is necessary because the submit button
  // is outside this component and known nothing about the internal component
  // state. One could argue that the component should have no internal state
  // and that it should all be in the redux store to begin with, but the
  // boilerplate code, complexity, and inefficiency of doing that makes it
  // a less viable option than just syncing it over...for now at least.
  UNSAFE_componentWillUpdate(nextProps: AddColFormProps, nextState: AddColFormState) {
    if (!_.isEqual(nextState, this.state)) {
      this.props.syncToStore(nextState);
    }
  }

  componentWillUnmount() {
    this.props.hideAllFlash();
    this.props.resetFormErrors();
    this.props.syncToStore(initialState);
    this.props.resetFormStatus();
  }

  handleChange = (name: FormFieldName) => {
    let ic: any;
    let fieldName;
    let transforms;

    // it would be nice to have a generic change-handler that did not know
    // about the particular form-field change that invoked it, but we want
    // to modify some parts of the state when other parts change, and react
    // doesn't offer a nice way to do that if the change-logic is non-trivial
    return (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      this.props.markFormDirty();

      const { target } = event;
      let val;
      if (target instanceof HTMLSpanElement) {
        val = _.get(target, 'dataset.name', '');
      } else {
        val = _.get(target, 'value', '');
      }

      switch (name) {
        case 'displayName':
          this.setState({
            displayName: val,
            fieldName: makeFieldName(val)
          });
          break;
        case 'transform': {
          if (this.state.sourceColumnId) {
            ic = this.props.inputColumns[this.state.sourceColumnId];
          }
          transforms = ic ? this.props.supportedConversions(ic.soql_type) : this.props.supportedConversions('text');
          fieldName = ic ? ic.field_name : 'null';

          let transformExpr = '';
          if (val) {
            transformExpr = makeTransformExpr(fieldName, transforms[val], this.props.entities);
          }

          this.setState({
            transform: val,
            transformExpr
          });
          break;
        }
        case 'sourceColumnId': {
          type transformFunc = (a: string, b?: string) => string | undefined;
          let transformFunc;
          const newState: {transform: string; transformExpr: string; sourceColumnId: string } = {
            transform: '',
            transformExpr: '',
            sourceColumnId: val
          };

          if (val) {
            ic = this.props.inputColumns[val];
            transforms = ic ? this.props.supportedConversions(ic.soql_type) : this.props.supportedConversions('text');
            fieldName = ic ? ic.field_name : 'null';

            if (this.state.transform) {
              transformFunc = transforms[this.state.transform];
            }

            if (transformFunc && this.state.transform) {
              newState.transformExpr =
                makeTransformExpr(fieldName, transforms[this.state.transform], this.props.entities);
            } else {
              newState.transform = '';
              newState.transformExpr = '';
            }
          }
          this.setState(newState);
          break;
        }
        default:
          this.setState({
            [name]: val
          });
      }

    };
  };

  checkErrorState(errors: Errors, fieldname: FormFieldName) {
    return !!errors[fieldname].length;
  }

  renderColumnTypeSelector() {
    let transforms;
    let ic;
    if (this.state.sourceColumnId) {
      ic = this.props.inputColumns[this.state.sourceColumnId];
    }
    if (ic && ic.soql_type) {
      transforms = this.props.supportedConversions(ic.soql_type);
    } else {
      transforms = this.props.supportedConversions('text');
    }

    const transformOptions = Object.keys(transforms).sort().map((transform: string) => {
      return {
        title: I18n.t(`show_output_schema.column_header.type_display_names.${transform}`, { scope }),
        value: transform
      };
    });
    const options = [{ title: `-- ${I18n.t('add_col.make_selection', { scope })} --`, value: '' }, ...transformOptions];

    return (
      <Select
        field={{
          name: 'transform',
          value: this.state.transform,
          options,
          isRequired: true
        }}
        inErrorState={this.checkErrorState(this.props.errors, 'transform')}
        handleChange={this.handleChange('transform')} />
    );
  }

  render() {
    const { params, addCol, isDirty } = this.props;

    return (
      <div id="add-col-form" className="save-and-exit">
        <form className="dsmp-form">
          <Fieldset title={I18n.t('add_col.fieldset_title', { scope })} subtitle={I18n.t('add_col.fieldset_subtitle', { scope })}>
            <label htmlFor="displayName">{I18n.t('add_col.display_name', { scope })}</label>
            <TextInput
              field={{
                name: 'displayName',
                value: this.state.displayName || ''
              }}
              inErrorState={this.checkErrorState(this.props.errors, 'displayName')}
              handleChange={this.handleChange('displayName')} />
            <ErrorList errors={this.props.errors.displayName} />
            <label htmlFor="fieldName">{I18n.t('add_col.field_name', { scope })}</label>
            <TextInput
              field={{
                name: 'fieldName',
                value: this.state.fieldName || ''
              }}
              inErrorState={this.checkErrorState(this.props.errors, 'fieldName')}
              handleChange={this.handleChange('fieldName')} />
            <ErrorList errors={this.props.errors.fieldName} />
            <label htmlFor="description">{I18n.t('add_col.description', { scope })}</label>
            <TextArea
              field={{
                name: 'description',
                value: this.state.description
              }}
              inErrorState={false}
              handleChange={this.handleChange('description')} />
            <label htmlFor="sourceColumnId">{I18n.t('add_col.source_column', { scope })}</label>
            <Select
              field={{
                name: 'sourceColumnId',
                value: this.state.sourceColumnId,
                options: this.props.selectOptions,
                isRequired: true
              }}
              inErrorState={this.checkErrorState(this.props.errors, 'sourceColumnId')}
              handleChange={this.handleChange('sourceColumnId')} />
            <ErrorList errors={this.props.errors.sourceColumnId} />
            <label htmlFor="transform">
              {I18n.t('show_output_schema.column_header.soql_pill_type_label', { scope })}
            </label>
            {this.renderColumnTypeSelector()}
            <ErrorList errors={this.props.errors.transform} />
          </Fieldset>
        </form>
        <div className="add-col-button-container">
          <ApiCallButton
            onClick={() => addCol(this.state)}
            callParams={params}
            operation={ADD_COLUMN}
            forceDisable={!isDirty}
          >
            {I18n.t('add_col.add_column', { scope })}
          </ApiCallButton>
        </div>
      </div>
    );
  }
}

export default AddColForm;
