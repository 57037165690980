import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import TypeIcon from 'common/components/SoQLTypeIcon';
import { singularOrPlural } from 'datasetManagementUI/lib/util';
import { commaify } from 'common/formatNumber';
import SocrataIcon from 'common/components/SocrataIcon';
import formatString from 'common/js_utils/formatString';
import I18n from 'common/i18n';

const t = (k, scope = 'dataset_management_ui.show_output_schema.column_header') => I18n.t(k, { scope });

export function getErrorFlyoutId(transform) {
  return `transform-status-flyout-${transform.id}`;
}

const ErrorFlyout = ({ transform, errorPath, forgiveErrors }) => {
  const flyoutId = getErrorFlyoutId(transform);

  const msgTemplate = singularOrPlural(
    transform.error_count,
    t('column_status_flyout.error_msg_singular'),
    t('column_status_flyout.error_msg_plural')
  );

  // forgiveness is only possible if you're not manually editing stuff
  let forgive = null;

  function keydownHandler(e) {
    if (!e) {
      return;
    }
    if (e.keyCode === 13 || e.key === 'Enter') {
      e.preventDefault();
      forgiveErrors();
    }
  }

  if (!transform.has_cell_edits) {
    forgive = (
      <p className="or-forgive">
        {t('or_you_can')}
        {' '}
        <a onClick={forgiveErrors} onKeyDown={keydownHandler} className="ignore-all" tabIndex="0">
          {t('ignore_errors')}
        </a>
        {' '}
        {t('convert_to_null')}
      </p>
    );
  }

  const canonicalTypeName = transform.output_soql_type;

  return (
    <div id={flyoutId} className="transform-status-flyout flyout flyout-hidden">
      <section className="flyout-content">
        <a className="flyout-close" tabIndex="0">
          <SocrataIcon name="close-2" />
        </a>
        {formatString(msgTemplate, {
          num_errors: commaify(transform.error_count),
          type: t(`type_display_names.${canonicalTypeName}`)
        })}
        <TypeIcon type={canonicalTypeName} />
        <br />
        <Link to={errorPath} className="click-to-view">
          {t('click_to_view', 'dataset_management_ui.show_output_schema')}
        </Link>
        {forgive}
      </section>
    </div>
  );
};

ErrorFlyout.propTypes = {
  errorPath: PropTypes.string,
  forgiveErrors: PropTypes.func.isRequired,
  outputSchema: PropTypes.object.isRequired,
  outputColumn: PropTypes.object.isRequired,
  transform: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired
};

export default ErrorFlyout;
