/* eslint react/jsx-indent: 0 */
import PropTypes from 'prop-types';

import React from 'react';
import { Link } from 'react-router';
import * as Links from 'datasetManagementUI/links/links';
import SocrataIcon from 'common/components/SocrataIcon';
import I18n from 'common/i18n';

const scope = 'dataset_management_ui.home_pane';

function getPreviewLink(params, blobPreviewAvailable, blobId, sourceId, inputSchemaId, outputSchemaId) {
  if (blobPreviewAvailable) {
    return Links.showBlobPreview(params, blobId);
  } else if (sourceId && inputSchemaId && outputSchemaId) {
    return Links.showOutputSchema(params, sourceId, inputSchemaId, outputSchemaId);
  } else {
    return null;
  }
}

const SourceBreadcrumbs = ({
  atShowSource,
  blobId,
  sourceId,
  outputSchemaId,
  inputSchemaId,
  blobPreviewAvailable,
  params
}) => {
  const canShowSchemaPreviewPage = sourceId != null && outputSchemaId != null && inputSchemaId != null;
  const canShowBlobPreviewPage = blobPreviewAvailable;
  const canPreview = canShowSchemaPreviewPage || canShowBlobPreviewPage;

  return (
    <ol id="source-breadcrumbs" className="list">
      <li className={atShowSource ? 'active' : null}>
        {atShowSource
          ? I18n.t('data', { scope })
          : <Link to={Links.sources(params)}>{I18n.t('data', { scope })}</Link>
        }
        {canPreview && <SocrataIcon name="arrow-right" className="icon" />}
      </li>
      {canPreview && (
        <li className={!atShowSource ? 'active' : null}>
          {!atShowSource ? (
            I18n.t('preview', { scope })
          ) : (
            <Link
              to={getPreviewLink(
                params,
                blobPreviewAvailable,
                blobId,
                sourceId,
                inputSchemaId,
                outputSchemaId)}>
              {I18n.t('preview', { scope })}
            </Link>
          )}
        </li>
      )}
    </ol>
  );
};

SourceBreadcrumbs.propTypes = {
  blobPreviewAvailable: PropTypes.bool,
  atShowSource: PropTypes.bool,
  blobId: PropTypes.number,
  sourceId: PropTypes.number,
  outputSchemaId: PropTypes.number,
  inputSchemaId: PropTypes.number,
  params: PropTypes.object.isRequired
};

export default SourceBreadcrumbs;
