import _ from 'lodash';
import React, { Component } from 'react';
import url from 'url';
import {
  ForgeDialog,
  ForgeCircularProgress,
  ForgeToolbar,
  ForgeIconButton,
  ForgeIcon,
  ForgeButton
} from '@tylertech/forge-react';

import { assign as windowLocationAssign } from 'common/window_location';
import I18n from 'common/i18n';
import { defaultHeaders } from 'common/http';
import { View } from 'common/types/view';

import airbrake from 'common/airbrake';
import { showToastNow, ToastType } from 'common/components/ToastNotification/Toastmaster';

const scope = 'shared.components.asset_action_bar.publication_action';
const t = (key: string, options = {}) =>
  I18n.t(key, { scope: 'dataset_management_ui.edit_query.unsaved_dialog', ...options });

interface Props {
  currentView: View;
  discardRevision: () => Promise<void>;
  revisionHasChanges: () => boolean;
}

interface State {
  unsavedChangesDialogOpen: boolean;
  optOutInProgress: boolean;
}

class OptOutButton extends Component<Props, State> {
  state = {
    unsavedChangesDialogOpen: false,
    optOutInProgress: false
  };

  deleteDraft() {
    this.props.discardRevision();
  }

  toast(toastType: ToastType, content: string) {
    showToastNow({
      type: toastType,
      content: content
    });
  }

  // Because this code is always polling against working-copy creation of a derived view,
  // it should theoretically never get triggered because it's a fast operation.
  // But I'm leaving it in because my reviewer said I should.
  pollForCopyCompletion(): Promise<View> {
    const TIMEOUTCONSTANT = window.COPY_POLLING_INTERVAL || 15000;

    return new Promise((resolve, reject) => {
      const workingCopyIsCopied = async () => {
        const response = await this.createWorkingCopy();
        if (response.status === 200) {
          resolve(await response.json());
          return true;
        } else if (response.status === 202) {
          return false;
        } else {
          reject(response);
        }
      };

      const waitAround = () => {
        if (!workingCopyIsCopied()) {
          setTimeout(waitAround, TIMEOUTCONSTANT);
        }
      };

      waitAround();
    });
  }

  urlForWorkingCopy(workingCopy: View) {
    let base = '';
    let fullUrl = '';

    // component is probably unnecessary since it's not likely we'll cross into federation
    // territory. But then again, maybe we will.
    // TODO: do we need to do something special for internal to public federation?
    // TODO EN-39798: should this be sourceDomainCName?
    if (!_.isEmpty(workingCopy.domainCName)) {
      const loc = document.location;
      const domain = loc.hostname;

      base = `${loc.protocol}//${domain}`;
    }

    const shouldPrefixLocale = new RegExp(`^\/(${I18n.locale})`).test(
      url.parse(window.location.href, true).pathname!
    );
    const rootPath = shouldPrefixLocale ? `/${I18n.locale}` : '';

    fullUrl = `${base}${rootPath}/d/${workingCopy.id}/data`;

    return fullUrl;
  }

  prepareDraftForImport() {
    const {
      currentView: { id: viewId }
    } = this.props;

    return fetch(`/api/views/${viewId}.json?method=prepareDraftForImport`, {
      credentials: 'same-origin',
      headers: defaultHeaders,
      method: 'PATCH',
      body: '{}'
    });
  }

  setDisplayType() {
    const {
      currentView: { id: viewId }
    } = this.props;

    const payload = { displayType: 'table' };

    return fetch(`/api/views/${viewId}.json`, {
      credentials: 'same-origin',
      headers: defaultHeaders,
      method: 'PUT',
      body: JSON.stringify(payload)
    });
  }

  createWorkingCopy() {
    const {
      currentView: { id: viewId }
    } = this.props;

    return fetch(`/api/views/${viewId}/publication.json?method=copy`, {
      credentials: 'same-origin',
      headers: defaultHeaders,
      method: 'POST'
    });
  }

  unsavedChangesDialog() {
    const onDismiss = () => this.setState({ unsavedChangesDialogOpen: false });
    const onCommit = () => {
      this.setState({ unsavedChangesDialogOpen: false });
      this.executeOptOut();
    };
    return (
      <ForgeDialog open={this.state.unsavedChangesDialogOpen} onDismiss={_.noop}>
        <div id="opt-out-with-unsaved-changes-dialog">
          <ForgeToolbar className="dialog-header forge-typography">
            <h1 slot="start" className="forge-typography--title">
              {t('header')}
            </h1>
            <ForgeIconButton slot="end">
              <button className="tyler-icons" onClick={onDismiss}>
                <ForgeIcon name="close" />
              </button>
            </ForgeIconButton>
          </ForgeToolbar>
          <div className="forge-dialog-body__padding">
            <div>
              <p className="forge-typography--body1 forge-dialog-body-text__margin">{t('body')}</p>
            </div>
          </div>
          <div className="footer">
            <ForgeToolbar inverted={true} className="footer-toolbar">
              <div slot="end">
                <ForgeButton type="outlined">
                  <button id="cancel-button" onClick={onDismiss}>
                    {t('cancel')}
                  </button>
                </ForgeButton>
                <ForgeButton type="raised">
                  <button id="accept-button" onClick={onCommit}>
                    {t('commit')}
                  </button>
                </ForgeButton>
              </div>
            </ForgeToolbar>
          </div>
        </div>
      </ForgeDialog>
    );
  }

  async executeOptOut() {
    this.setState({ optOutInProgress: true });
    const { publicationStage } = this.props.currentView;

    try {
      if (publicationStage === 'published') {
        const workingCopy = await this.pollForCopyCompletion();
        await this.deleteDraft();
        windowLocationAssign(this.urlForWorkingCopy(workingCopy));
      } else {
        await this.prepareDraftForImport();
        await this.setDisplayType();
        await this.deleteDraft();
        windowLocationAssign(this.urlForWorkingCopy(this.props.currentView));
      }
    } catch (response) {
      const json = await response.json();
      this.toast(ToastType.ERROR, I18n.t('dataset_management_ui.alert_working_copy.broken'));
      airbrake.notify({ error: `OptOutButton failure: ${response.statusText}: ${json.message}` });
      this.setState({ optOutInProgress: false });
    }
  }

  onClick() {
    const revisionHasChanges = this.props.revisionHasChanges();
    this.setState({ unsavedChangesDialogOpen: revisionHasChanges });
    if (!revisionHasChanges) {
      this.executeOptOut();
    }
  }

  render() {
    const contents = this.state.optOutInProgress ? (
      <ForgeCircularProgress className="custom-circular-progress"></ForgeCircularProgress>
    ) : (
      <button onClick={() => this.onClick()}>
        {I18n.t('dataset_management_ui.edit_query.return_to_grid_view')}
      </button>
    );
    return (
      <React.Fragment>
        {this.unsavedChangesDialog()}
        <ForgeButton type="outlined" slot="button">
          {contents}
        </ForgeButton>
      </React.Fragment>
    );
  }
}

export default OptOutButton;
