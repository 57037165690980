import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'common/components/Dropdown';
import DSMUIIcon from '../DSMUIIcon';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import { fetchTranslation } from 'common/locale';

const t = (k) => fetchTranslation(k, 'dataset_management_ui.layers');

const LayerChooser = ({ currentInputSchema, inputSchemasAndPaths }) => {
  if (inputSchemasAndPaths.length === 1) return null;

  const dropdownOptions = inputSchemasAndPaths.map(({ inputSchema }) => {
    return {
      title: (
        <div>
          <DSMUIIcon className="socrata-icon-layers" name="socrata-icon-layers" />
          {inputSchema.name}
        </div>
      ),
      value: inputSchema.id,
      group: t('title')
    };
  });

  const gotoSchema = ({ value: inputSchemaId }) => { // eslint-disable-line
    const { link } = _.find(
      inputSchemasAndPaths,
      ({ inputSchema }) => inputSchema.id === inputSchemaId
    );
    browserHistory.push(link);
  };


  return (
    <div className="dsmui-layer-chooser">
      <Dropdown
        value={currentInputSchema.id}
        options={dropdownOptions}
        onSelection={gotoSchema} />
    </div>
  );
};

LayerChooser.propTypes = {
  inputSchemasAndPaths: PropTypes.array.isRequired,
  currentInputSchema: PropTypes.object.isRequired
};

export default LayerChooser;
