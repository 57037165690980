import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import DSMUIIcon from '../DSMUIIcon';
import LayerChooser from 'datasetManagementUI/containers/LayerChooserContainer';
import Modes from 'datasetManagementUI/lib/modes';
import SchemaActions from 'datasetManagementUI/containers/SchemaActionsContainer';
import { IndexLink, Link } from 'react-router';
import * as Links from 'datasetManagementUI/links/links';
import StatusIndicator, { ERRORED } from 'datasetManagementUI/components/StatusIndicator/StatusIndicator';
import * as ModeGrantActions from 'datasetManagementUI/reduxStuff/actions/modeGrant';
import { isViewSource } from 'datasetManagementUI/lib/sources';
import I18n from 'common/i18n';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.show_output_schema' });

function makeTab(tab, hasCompilationFailures) {
  const classNames = 'dsmp-tab sidebar-tab dsmui-sidebar-tab';
  const disabledClassNames = `${classNames} disabled`;
  const activeClassNames = 'dsmp-tab sidebar-tab selected';

  if (tab.isDisabled) {
    return (
      <span key={tab.name} className={disabledClassNames}>
        <DSMUIIcon name={tab.iconName} className="disabled" />
        {tab.text}
      </span>
    );
  }

  if (tab.indexLink) {
    return (
      <IndexLink
        to={tab.linkTo}
        key={tab.name}
        className={classNames}
        activeClassName={activeClassNames}>
        <DSMUIIcon name={tab.iconName} />
        {tab.text}
      </IndexLink>
    );
  } else {
    return (
      <Link
        to={tab.linkTo}
        key={tab.name}
        className={classNames}
        activeClassName={activeClassNames}>
        <DSMUIIcon name={tab.iconName} />
        {tab.text}
        {(hasCompilationFailures && tab.name === 'colMap') && <StatusIndicator formStatus={ERRORED} />}
      </Link>
    );
  }
}

function OutputSchemaSidebar(props) {
  const {
    params,
    modes,
    hasCompilationFailures,
    isViewer,
    source
  } = props;

  const tabs = [
    {
      indexLink: true,
      linkTo: Links.showOutputSchema(params, params.sourceId, params.inputSchemaId, params.outputSchemaId),
      iconName: 'table',
      text: t('preview_table'),
      name: 'tablePrev'
    },
    {
      indexLink: false,
      linkTo: Links.showParseOptions(params),
      iconName: 'settings',
      text: t('specify_headers'),
      name: 'fileConfig',
      isDisabled: isViewer || (source && isViewSource(source))
    },
    {
      indexLink: false,
      linkTo: Links.geocodeShortcut({ ...params, outputColumnId: undefined }),
      iconName: 'geo',
      text: t('geocode'),
      name: 'geoRef',
      isDisabled: modes.update || hasCompilationFailures || isViewer
    },
    {
      indexLink: false,
      linkTo: Links.showAddCol(params),
      iconName: 'add',
      text: t('add_col'),
      name: 'addCol',
      isDisabled: modes.update || hasCompilationFailures || isViewer
    },
    {
      indexLink: false,
      linkTo: Links.showSchemaMismatch(params),
      iconName: 'column-mapping',
      text: t('column_mapping'),
      name: 'colMap',
      isDisabled: (!modes.update && !modes.edit) || isViewer || (source && isViewSource(source))
    }
  ];

  return (
    <div className="dsmp-sidebar" id="output-schema-sidebar">
      {tabs.map(tab => makeTab(tab, hasCompilationFailures))}
      <LayerChooser />
      <SchemaActions />
    </div>
  );
}

OutputSchemaSidebar.propTypes = {
  params: PropTypes.object.isRequired,
  modes: Modes.shape,
  hasCompilationFailures: PropTypes.bool.isRequired,
  isViewer: PropTypes.bool,
  source: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    isViewer: ModeGrantActions.isViewer(_.get(state.ui, 'modeGrant.grant', ''))
  };
}

export default connect(mapStateToProps)(OutputSchemaSidebar);
