import I18n from 'common/i18n';
import { View } from 'common/types/view';

const t = (k: string, scope = 'dataset_management_ui') => I18n.t(k, { scope });

export function showFederatedHrefMessage(view: View, showFlash: (...unsure: any) => void) {
  const viewIsFederatedHref =
    view.displayType === 'federated' && view.assetType === 'federated_href' && view.viewType === 'href';

  if (viewIsFederatedHref) {
    showFlash(
      'href',
      'href_info',
      t('metadata_manage.dataset_tab.href_message'),
      undefined,
      t('metadata_manage.dataset_tab.here'),
      'https://support.socrata.com/hc/en-us/articles/1500005478122'
    );
  }
}
