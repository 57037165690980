import React from 'react';

/*
 * DEPRECATED: Use common/components/SocrataIcon instead of this.
 *
 * React component for socrata icons. Used like so:
 * <DSMUIIcon name='arrow-right' className='custom-className' size='lg' />
 */

interface Props {
  name: string;
  className?: string;
  size?: 'lg' | '2x' | '3x' | '4x' | '5x';
  onIconClick?: () => void;
  isButtonIcon?: boolean;
}

export const DSMUIIcon = ({ name, className, size, onIconClick, isButtonIcon }: Props) => {
  const classNames = [];

  classNames.push(`socrata-icon-${name}`);
  className && classNames.push(className); // eslint-disable-line no-unused-expressions
  size && classNames.push(`socrata-icon-${size}`); // eslint-disable-line no-unused-expressions

  if (isButtonIcon) {
    classNames.push('btn-icon');
  }

  return (
    <span onClick={onIconClick} className={classNames.join(' ')}></span>
  );
};

export default DSMUIIcon;
