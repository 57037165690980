import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import GeocodeShortcut from 'datasetManagementUI/containers/GeocodeShortcutContainer';
import map from 'lodash/map';
import * as FormActions from 'datasetManagementUI/reduxStuff/actions/forms';
import { getConfigurations } from 'datasetManagementUI/lib/util';

class GeocodeShortcutPane extends Component {
  UNSAFE_componentWillMount() {
    const { columns, geocodeShortcutForm, dispatch } = this.props;
    const initialOutputColumns = map(columns, col => col.field_name);
    const newFormState = { ...geocodeShortcutForm.state, initialOutputColumns };
    dispatch(FormActions.setFormState('geocodeShortcutForm', newFormState));

    // this has the behavior of using the domain geocoding provider as the one
    // true provider. this behavior isn't necessarily wrong...but, if someone
    // sets up a geocode_arcgis(...) transform in the editor, and then edits
    // it through the UI, and their provider on the domain is the default, the
    // expression will be convered to regular geocode(...) with the edited values.
    // that may or may not be what we want, but either way could confused people.
    // but if we decide we want the other way, one option would be to pluck the
    // geocode func name out of the outputColumn here, and if it's present,
    // use that rather than the domain config.
    getConfigurations('geocoding').then((geocoding) => {
      if (geocoding) {
        const properties = geocoding.properties || [];
        const provider = (
          (_.find(properties, p => p.name === 'provider') || {}).value
          || 'mapquest'
        ).toLowerCase();

        const {
          country
        } = (_.find(properties, p => p.name === 'defaults') || {}).value || {};

        dispatch(FormActions.setFormState('geocodeShortcutForm', {
          ...newFormState,
          provider,
          defaultCountry: country
        }));
      }
    });
  }
  render() {
    const {
      params, location, newOutputSchema, revision, redirectToOutputSchema, revertRevisionOutputSchema
    } = this.props;
    return (
      <div className="content-wrap options-wrap">
        <GeocodeShortcut
          params={params}
          revision={revision}
          location={location}
          newOutputSchema={newOutputSchema}
          redirectToOutputSchema={redirectToOutputSchema}
          revertRevisionOutputSchema={revertRevisionOutputSchema} />
      </div>
    );
  }
}

GeocodeShortcutPane.propTypes = {
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  geocodeShortcutForm: PropTypes.object,
  redirectToOutputSchema: PropTypes.func,
  revertRevisionOutputSchema: PropTypes.func,
  dispatch: PropTypes.func,
  newOutputSchema: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.object),
  revision: PropTypes.object
};

export default GeocodeShortcutPane;
