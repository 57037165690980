import { socrataFetch, checkStatus, getJson, getError } from 'datasetManagementUI/lib/http';
import {
  apiCallStarted,
  apiCallSucceeded,
  apiCallFailed
} from 'datasetManagementUI/reduxStuff/actions/apiCalls';
import uuid from 'uuid';

export const PLUGINS_INDEXED = 'PLUGINS_INDEXED';

export const onPluginsIndexed = (plugins) => ({
  type: PLUGINS_INDEXED,
  plugins
});

export const getPlugins = () => (dispatch) => {
  const callId = uuid();
  const call = { operation: PLUGINS_INDEXED, callParams: {} };
  dispatch(apiCallStarted(callId, call));

  return socrataFetch('/api/publishing/v1/connection_agent/plugin')
    .then(checkStatus)
    .then(getJson)
    .then(plugins => {
      dispatch(onPluginsIndexed(plugins));
      dispatch(apiCallSucceeded(callId));
    })
    .catch(getError)
    .catch((e) => {
      dispatch(apiCallFailed(callId, e));
    });
};
