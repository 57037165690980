import React from 'react'; // eslint-disable-line no-unused-vars
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as Selectors from 'datasetManagementUI/selectors';
import CommonSchemaPreview from 'common/components/SchemaColumnPreview';

const calcColumns = (entities, os) => {
  if (os) {
    return Selectors.columnsForOutputSchema(entities, os.id).map((column, index) => {
      const transform = entities.transforms[column.transform_id];
      return {
        id: index,
        position: index,
        dataTypeName: transform && transform.output_soql_type,
        description: column.description,
        fieldName: column.field_name,
        name: column.display_name
      };
    });
  } else {
    return [];
  }
};

const mapStateToProps = ({ entities }, { params }) => {
  const revisionSeq = _.toNumber(params.revisionSeq);
  const currentOutputSchema = Selectors.currentOutputSchema(entities, revisionSeq);

  return {
    columns: calcColumns(entities, currentOutputSchema)
  };
};

export default withRouter(connect(mapStateToProps)(CommonSchemaPreview));
