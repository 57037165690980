import {
  AGENTS_CHANNEL_JOINED
} from '../../reduxStuff/actions/agents';

const initialState = {
  channel: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AGENTS_CHANNEL_JOINED: {
      return { ...state, channel: action.channel };
    }
    default:
      return state;
  }
};
