import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import Modal, { ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import { connect } from 'react-redux';
import * as Links from 'datasetManagementUI/links/links';
import * as Selectors from 'datasetManagementUI/selectors';
import * as Actions from 'datasetManagementUI/reduxStuff/actions/showOutputSchema';
import { updateSourceParseOptions } from 'datasetManagementUI/reduxStuff/actions/createSource';
import * as DisplayState from 'datasetManagementUI/lib/displayState';
import Modes from 'datasetManagementUI/lib/modes';
import SourceBreadcrumbs from 'datasetManagementUI/containers/SourceBreadcrumbsContainer';
import ReadyToImport from 'datasetManagementUI/containers/ReadyToImportContainer';
import * as FormActions from 'datasetManagementUI/reduxStuff/actions/forms';
import * as FlashActions from 'datasetManagementUI/reduxStuff/actions/flashMessage';
import FatalError from 'datasetManagementUI/containers/FatalErrorContainer';
import OutputSchemaSidebar from 'datasetManagementUI/components/OutputSchemaSidebar/OutputSchemaSidebar';
import SaveParseOptionsButtons from './SaveParseOptionsButtons';
import SaveBlobOrSchemaButton from './SaveBlobOrSchemaButton';
import SaveGeocodeShortcutButtons from './SaveGeocodeShortcutButtons';
import SaveSchemaMismatchButton from './SaveSchemaMismatchButton';
import SaveColButton from './SaveColButton';
import TransformEditorButton from './TransformEditorButton';
import * as ModeGrantActions from 'datasetManagementUI/reduxStuff/actions/modeGrant';
import { updateRevision } from 'datasetManagementUI/reduxStuff/actions/revisions';
import { showSuccessToastNow } from 'common/components/ToastNotification/Toastmaster';
import I18n from 'common/i18n';

const t = (k, scope = 'dataset_management_ui.show_output_schema') => I18n.t(k, { scope });

function getCurrentPane(location) {
  const pathComponents = location.pathname.split('/');
  const end = _.last(pathComponents);
  const secondToLast = pathComponents[pathComponents.length - 2];
  if (end === 'georeference' || secondToLast === 'edit_georeference') {
    return 'geocodeShortcut';
  }
  if (end === 'parse_options') {
    return 'parseOptions';
  }
  if (end === 'add_col') {
    return 'addColumn';
  }
  if (pathComponents.includes('editor')) {
    return 'editor';
  }
  if (end === 'mismatch') {
    return 'mismatch';
  }

  return 'tablePreview';
}

export class ShowOutputSchema extends Component {

  constructor() {
    super();
    this.state = { hasShownMismatchPane: false };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.hasCompilationFailures && !this.state.hasShownMismatchPane) {
      nextProps.showSchemaMismatchPane();
      // we only want to redirect panes once - once we have shown the pane, the user
      // is free to flip back and forth between them.
      this.setState({ hasShownMismatchPane: true });
    }
  }

  saveButtonForOption() {
    const currentPane = getCurrentPane(this.props.location);
    switch (currentPane) {
      case 'parseOptions':
        return <SaveParseOptionsButtons {...this.props} />;
      case 'geocodeShortcut':
        return <SaveGeocodeShortcutButtons
          oldOutputSchemaId={this.props.outputSchema.id}
          redirectToOutputSchema={this.props.redirectToOutputSchema} />;
      case 'addColumn':
        return (
          <SaveColButton
            handleClick={this.props.addCol}
            isDirty={this.props.addColForm.isDirty}
            isSubmitted={this.props.addColForm.submitted}
            callParams={this.props.params} />
        );
      case 'editor':
        return (<TransformEditorButton
          redirectToOutputSchema={() => this.props.redirectToOutputSchema(this.props.outputSchema.id)}
          outputColumnId={this.props.params.outputColumnId} />);
      case 'mismatch':
        return <SaveSchemaMismatchButton {...this.props} />;
      default:
        return <SaveBlobOrSchemaButton
            autosaved={this.props.revision.output_schema_id === this.props.outputSchema.id}
            params={this.props.params}
            outputSchemaId={this.props.outputSchema.id}
            goToRevisionBase={this.props.goToRevisionBase} />;
    }
  }

  modalFooterButton() {
    const { isViewer, goToRevisionBase } = this.props;
    if (isViewer) {
      return (<button className="btn btn-primary" onClick={() => goToRevisionBase()}>
        {t('done')}
      </button>);
    } else {
      return this.saveButtonForOption();
    }
  }

  render() {
    const {
      canApplyRevision,
      fatalError,
      goToRevisionBase,
      modes,
      params,
      hasCompilationFailures,
      source
    } = this.props;

    const currentPane = getCurrentPane(this.props.location);
    const onTablePreview = currentPane === 'tablePreview';
    let footerInfo;

    if (!fatalError && !hasCompilationFailures && canApplyRevision && onTablePreview) {
      footerInfo = <ReadyToImport />;
    } else if (fatalError && onTablePreview) {
      footerInfo = <FatalError />;
    } else {
      footerInfo = <div className="placeholder" />;
    }

    return (
      <div className="output-schema-container">
        <Modal fullScreen onDismiss={goToRevisionBase}>
          <ModalHeader onDismiss={goToRevisionBase}>
            <SourceBreadcrumbs />
          </ModalHeader>

          <ModalContent className={onTablePreview ? 'modal-content' : 'modal-content modal-content-alt'}>
            <OutputSchemaSidebar
              modes={modes}
              source={source}
              hasCompilationFailures={hasCompilationFailures}
              params={params} />
            {this.props.children &&
              React.cloneElement(this.props.children, {
                ...this.props
              })}
          </ModalContent>

          <ModalFooter className={onTablePreview ? 'modal-footer' : 'modal-footer-alt'}>
            {footerInfo}
            {this.modalFooterButton()}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

ShowOutputSchema.propTypes = {
  children: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequrired
  }),
  revision: PropTypes.object.isRequired,
  source: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  inputSchema: PropTypes.object.isRequired,
  outputSchema: PropTypes.object.isRequired,
  modes: Modes.shape,
  displayState: DisplayState.propType.isRequired,
  canApplyRevision: PropTypes.bool.isRequired,
  fatalError: PropTypes.bool.isRequired,
  hasCompilationFailures: PropTypes.bool.isRequired,
  parseOptionsForm: PropTypes.object.isRequired,
  addColForm: PropTypes.object.isRequired,
  numLoadsInProgress: PropTypes.number.isRequired,
  goToRevisionBase: PropTypes.func.isRequired,
  redirectToOutputSchema: PropTypes.func.isRequired,
  addCol: PropTypes.func.isRequired,
  showSchemaMismatchPane: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  isViewer: PropTypes.bool,
  params: PropTypes.shape({
    revisionSeq: PropTypes.string.isRequired,
    outputSchemaId: PropTypes.string.isRequired,
    option: PropTypes.string
  }).isRequired
};

export function mapStateToProps(state, ownProps) {
  const params = ownProps.params;
  const entities = state.entities;
  const view = entities.views[params.fourfour];
  const revisionSeq = _.toNumber(params.revisionSeq);
  const revision = _.find(entities.revisions, { fourfour: params.fourfour, revision_seq: revisionSeq });
  const outputSchemaId = _.toNumber(params.outputSchemaId);
  const source = entities.sources[_.toNumber(params.sourceId)];
  const inputSchema = entities.input_schemas[_.toNumber(params.inputSchemaId)];
  const outputSchema = entities.output_schemas[outputSchemaId];

  const latestOutputSchema = _.max(_.filter(
    entities.output_schemas, { input_schema_id: inputSchema.id }
  ).map(oc => oc.id));

  const columns = Selectors.columnsForOutputSchema(entities, outputSchemaId);
  const canApplyRevision = !!outputSchema.finished_at;
  const fatalError = !!source.failed_at;

  const hasCompilationFailures = _.some(columns.map(oc =>
    oc.transform &&
    oc.transform.failed_at &&
    oc.transform.failure_details &&
    oc.transform.failure_details.type === 'transform_compilation_failure'
  ));

  const isViewer = ModeGrantActions.isViewer(_.get(state, 'ui.modeGrant.grant', ''));

  const parseOptionsForm = state.ui.forms.parseOptionsForm;

  const { addColForm, geocodeShortcutForm } = state.ui.forms;

  return {
    view,
    revision,
    source,
    modes: Modes.modes(entities, params),
    inputSchema,
    outputSchema,
    latestOutputSchema,
    flashVisible: state.ui.flashMessage.visible,
    parseOptionsForm,
    columns,
    canApplyRevision,
    fatalError,
    addColForm,
    geocodeShortcutForm,
    numLoadsInProgress: Selectors.rowLoadOperationsInProgress(state.ui.apiCalls),
    displayState: DisplayState.fromUiUrl(_.pick(ownProps, ['params', 'location'])),
    hasCompilationFailures,
    isViewer,
    params
  };
}

export function addCol(colState, dispatch, ownProps) {
  const hideAddColFlash = () => {
    dispatch(FlashActions.hideFlashMessage('add_col_success'));
    dispatch(FlashActions.hideFlashMessage('add_col_error'));
  };
  dispatch(Actions.addCol(colState, ownProps.params))
    .then(resp => {
      const { resource: os } = resp;

      const newParams = {
        ...ownProps.params,
        outputSchemaId: os.id
      };

      hideAddColFlash();
      dispatch(FormActions.setFormState('addColForm', {}));
      dispatch(FormActions.markFormClean('addColForm'));
      dispatch(FormActions.markFormSubmitted('addColForm'));
      dispatch(FormActions.clearInternalState('addColForm', true));
      dispatch(FlashActions.showFlashMessage({
        kind: 'success', id: 'add_col_success', message: t('success_flash_message', 'dataset_management_ui.add_col')
      }));

      browserHistory.push(Links.showAddCol(newParams));
    })
    .catch((err) => {
      hideAddColFlash();
      const message = err.body && err.body.key === 'validation_failed' ? err.message : t('error_flash_message', 'dataset_management_ui.add_col');
      dispatch(FlashActions.showFlashMessage({ kind: 'error', id: 'add_col_error', message}));
  });
}

function mergeProps(stateProps, { dispatch }, ownProps) {
  return {
    ...stateProps,
    ...ownProps,
    goToRevisionBase: () => {
      browserHistory.push(Links.revisionBase(ownProps.params));
    },
    revertRevisionOutputSchema: (outputSchemaId, currentOutputSchemaId = null) => {
      const updateObj = { blob_id: null, output_schema_id: outputSchemaId };
      // update the revision on the server
      return dispatch(updateRevision(updateObj, ownProps.params))
        .then(resp => {
          // update the url browser history to reflect the reverted schema ID
          const newPathname = Links.changeOutputSchema(currentOutputSchemaId, outputSchemaId);
          if (newPathname) {
            browserHistory.push(newPathname);
          }
          return resp;
        });
    },
    redirectToOutputSchema: (outputSchemaId) => {
      dispatch(Actions.redirectToOutputSchema(stateProps.params, outputSchemaId));
    },
    saveCurrentParseOptions: (params, source, parseOptions) => {
      dispatch(updateSourceParseOptions(params, source, parseOptions)).then(() => {
        showSuccessToastNow(t('configuration_applied'));
      });
    },
    newOutputSchema: (desiredColumns, call, force) =>
      dispatch(Actions.newOutputSchema(
        stateProps.inputSchema.id,
        desiredColumns,
        call,
        force,
        stateProps.outputSchema
      )),
    showSchemaMismatchPane: () => {
      browserHistory.push(Links.showSchemaMismatch(stateProps.params));
    },
    addCol: () => addCol(stateProps.addColForm.state, dispatch, ownProps),
    dispatch
  };
}

export default connect(mapStateToProps, null, mergeProps)(ShowOutputSchema);
