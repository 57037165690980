import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { showModal } from 'datasetManagementUI/reduxStuff/actions/modal';
import ReadyToImport from 'datasetManagementUI/components/ReadyToImport/ReadyToImport';
import * as Selectors from 'datasetManagementUI/selectors';
import _ from 'lodash';


const mapStateToProps = ({ entities }, { params }) => {
  const { outputSchemaId } = params;
  if (!outputSchemaId) return {};

  const outputSchema = entities.output_schemas[outputSchemaId];
  const inputSchema = entities.input_schemas[outputSchema.input_schema_id];
  const source = entities.sources[inputSchema.source_id];
  const errorRows = outputSchema.error_count || 0;
  const importableRows = Math.max(0, inputSchema.total_rows - errorRows);

  const outputColumns = Selectors.columnsForOutputSchema(entities, _.toNumber(outputSchemaId));
  const inputColumns = Selectors.columnsForInputSchema(entities, _.toNumber(outputSchema.input_schema_id));
  const usedInputColumns = _.flatMap(outputColumns, oc => {
    return _.map(oc.transform.transform_input_columns, c => c.input_column_id);
  });

  const extraColumns = _.filter(inputColumns, ic => !usedInputColumns.includes(ic.id));

  return {
    source,
    inputSchema,
    importableRows,
    errorRows,
    outputSchema,
    extraColumns,
    params
  };
};

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
  ...ownProps,
  ...stateProps,
  openModal: componentName => dispatch(showModal(componentName)),
  openExtraColumnsModal: () => {
    dispatch(showModal('ExtraColumnsModal', {
      extraColumns: stateProps.extraColumns
    }));
  },
  openAutomationModal: () => {
    dispatch(showModal('SetupAutomation', {
      outputSchemaId: stateProps.outputSchema.id,
      fourfour: ownProps.params.fourfour,
      source: stateProps.source
    }));
  }
});

export default withRouter(connect(mapStateToProps, null, mergeProps)(ReadyToImport));
