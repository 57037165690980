/* eslint react/jsx-boolean-value: 0 */
import CompilerResult from 'common/components/CompilerResult';
import 'common/components/CompilerResult/compiler-result.scss';
import SoQLDocs, { Match, Matcher } from 'common/components/SoQLDocs';
import SoQLEditor from 'common/components/SoQLEditor';
import { fetchTranslation } from 'common/locale';
import { SimpleCompilationResult } from 'common/types/compiler';
import { Scope } from 'common/types/soql';
import * as React from 'react';
import { none, option, Option } from 'ts-option';

export interface Props {
  scope: Scope;
  compiler: { result: SimpleCompilationResult | null };
  expression: string;
  completeExpression: Matcher;
  onChange: (s: string) => void;
  hideCompilationResult: boolean;
}
interface State {
  selectedFunction: Option<Match>;
}

export class SoQLEditorWrapper extends React.Component<Props, State> {
  state: State = {
    selectedFunction: none
  };

  onChangeSelectedFunction = (match: Option<Match>) => {
    this.setState({ selectedFunction: match });
  };

  render() {
    const { compiler, hideCompilationResult } = this.props;

    return (
      <div className="soql-editor">
        {!hideCompilationResult && <CompilerResult<SimpleCompilationResult> result={option(compiler.result)} />}

        <SoQLEditor
          scope={this.props.scope}
          soql={this.props.expression}
          compilationResult={option(compiler.result)}
          matcher={this.props.completeExpression}
          onChange={this.props.onChange}
          onChangeSelectedFunction={this.onChangeSelectedFunction}
          selectedFunction={this.state.selectedFunction}
          height="420px"
          soqlMode={true}
        />

        <SoQLDocs
          completer={this.props.completeExpression}
          selection={this.state.selectedFunction.getOrElseValue(null as unknown as Match)}
        />
      </div>
    );
  }
}
