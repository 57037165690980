import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import Button, { VARIANTS } from 'common/components/Button';
import * as Links from 'datasetManagementUI/links/links';
import I18n from 'common/i18n';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.home_pane' });

const SaveColButton = ({ isDirty, callParams, isSubmitted }) => {
  const dataPreviewLink = Links.showOutputSchema(callParams, callParams.sourceId, callParams.inputSchemaId, callParams.outputSchemaId);

  return <div>
    <Button
      variant={VARIANTS.DEFAULT}
      disabled={isSubmitted && !isDirty}
      onClick={() => browserHistory.push(dataPreviewLink)}>
      {t('cancel')}
    </Button>
    <Button
      variant={VARIANTS.PRIMARY}
      disabled={isDirty || !isSubmitted}
      onClick={() => browserHistory.push(dataPreviewLink)}>
      {t('done')}
    </Button>
  </div>;
};

SaveColButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  callParams: PropTypes.object.isRequired
};

export default SaveColButton;
