/* eslint react/sort-comp: 0 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TableCell from 'common/components/DatasetTable/cell/TableCell';
import { DEFAULT_WIDTH } from 'datasetManagementUI/components/Table/Table';
import { LeftSpacer, RightSpacer } from 'common/components/DatasetTable/cell/Spacer';
import RowError from 'datasetManagementUI/components/RowError/RowError';
import * as Selectors from 'datasetManagementUI/selectors';

class TableBody extends Component {
  constructor() {
    super();
    this.state = {
      isEditing: false,
      editPosition: {}
    };
    this.editCell = this.editCell.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
  }

  componentDidMount() {
    const { loadVisibleData } = this.props;
    loadVisibleData();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { loadVisibleData } = nextProps;

    // This lifecycle method gets called when props update.
    // Due to some (perhaps) poor decisions, the props of this
    // component are basically the entire state of dsmui, so
    // it gets called A LOT. loadVisibleData makes a guarded
    // http call, but if the guard fails, there is a danger that
    // we start making a ton of http calls. So the intent of
    // async/await here is to mitigate this by blocking
    await loadVisibleData();
  }

  getData() {
    return Selectors.getRowData(
      this.props.entities,
      this.props.inputSchemaId,
      this.props.displayState,
      this.props.columns
    );
  }

  editCell(rowIdx, columnId) {
    // cell editing is disabled in some contexts - like the transform editor
    if (!this.props.enableCellEditing) return;

    this.setState({
      editPosition: { rowIdx, columnId },
      isEditing: true
    });
  }

  cancelEdit() {
    this.setState({
      isEditing: false,
      editPosition: {}
    });
  }

  getColumnsInView() {
    if (this.props.columnsInView) {
      return this.props.columnsInView;
    }
    // columnsInView is an optional prop - if it's not provided then we
    // will always render all the columns
    return { minColIdx: 0, maxColIdx: this.props.columns.length };
  }

  renderNormalRow(row) {
    const {
      dropping,
      isViewer,
      columns,
      uid
    } = this.props;


    const getWidth = this.props.getWidth || (() => DEFAULT_WIDTH);

    const columnsInView = this.getColumnsInView();

    return (
      <tr key={row.rowIdx}>
        <LeftSpacer
          columns={columns}
          columnsInView={columnsInView}
          getWidth={getWidth} />

        {row.columns.slice(columnsInView.minColIdx, columnsInView.maxColIdx).map((column, offset) => {
          const t = this.props.entities.transforms[column.tid];
          const type = t ? t.output_soql_type : null;
          const hasFailed = t ? !!t.failed_at : false;

          const editPosition = this.state.editPosition;
          const isEditingThisCell = this.state.isEditing ?
            (editPosition.rowIdx === row.rowIdx && editPosition.columnId === column.id) : false;
          // This ternary is just so we don't make a billion closures
          // in the non edit case
          const updateCell = isEditingThisCell ?
            (newValue) => {
              // passing undefined means we're cancelling the edit
              if (_.isUndefined(newValue)) {
                return this.cancelEdit();
              }
              return this.props.updateCell(t.id, row.rowIdx, newValue).then(this.cancelEdit);
            } : null;

          return (
            <TableCell
              uid={uid}
              offset={row.rowIdx}
              width={getWidth(column.id)}
              isDropping={dropping === column.id}
              isHidden={_.includes(column.flags, 'hidden')}
              key={`${column.tid}-${row.rowIdx}-${offset}`}
              cell={column.cell}
              format={column.format}
              failed={hasFailed}
              updateCell={updateCell}
              isEditing={isEditingThisCell}
              canEdit={!isViewer}
              editCell={() => this.editCell(row.rowIdx, column.id)}
              type={type} />
          );
        })}

        <RightSpacer
          columns={columns}
          columnsInView={columnsInView}
          getWidth={getWidth} />
      </tr>
    );
  }

  render() {
    const data = this.getData();
    const rows = data.map(row => {
      return row.rowError ? (
        <RowError key={row.rowIdx} row={row} />
      ) : (
        this.renderNormalRow(row)
      );
    });

    return (
      <tbody className="tableBody" tabIndex="0">
        {rows}
      </tbody>
    );
  }
}

TableBody.propTypes = {
  uid: PropTypes.string.isRequired,
  dropping: PropTypes.number,
  entities: PropTypes.object.isRequired,
  apiCalls: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnsInView: PropTypes.object,
  displayState: PropTypes.object.isRequired,
  inputSchemaId: PropTypes.number.isRequired,
  loadVisibleData: PropTypes.func.isRequired,
  updateCell: PropTypes.func.isRequired,
  enableCellEditing: PropTypes.bool,
  getWidth: PropTypes.func,
  isViewer: PropTypes.bool
};

export default TableBody;
