import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DSMUIIcon from '../DSMUIIcon';
import { attachTo } from 'common/components/legacy';
import I18n from 'common/i18n';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.show_output_schema.column_header.sort_column_flyout' });

function flytoutId(columnId) {
  return `sort-col-flyout-${columnId}`;
}

function sortColumnFlyout(columnId, ascending, name) {
  return (
    <div id={flytoutId(columnId)} className="col-attribute-flyout flyout flyout-hidden">
      <section className="flyout-content">
        <DSMUIIcon name={name} className="col-icon" />
        {t('title')}
        <br />
        <span className="flyout-body">
          {t(ascending ? 'body_asc' : 'body_desc')}
        </span>
      </section>
    </div>
  );
}

class SortColumnIcon extends Component {

  componentDidMount() {
    this.attachFlyouts();
  }

  componentDidUpdate() {
    this.attachFlyouts();
  }

  attachFlyouts() {
    attachTo(this.flyoutParentEl);
  }

  render() {
    const { columnId, ascending } = this.props;
    const name = ascending ? 'sort-asc' : 'sort-desc';

    return (
      <div className="header-icon" ref={flyoutParentEl => { this.flyoutParentEl = flyoutParentEl; }}>
        <div data-flyout={flytoutId(columnId)}>
          <DSMUIIcon name={name} />
        </div>
        {sortColumnFlyout(columnId, ascending, name)}
      </div>
    );
  }
}

SortColumnIcon.propTypes = {
  columnId: PropTypes.number.isRequired,
  ascending: PropTypes.bool.isRequired
};


export default SortColumnIcon;
