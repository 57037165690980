import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import SourceSidebar from 'datasetManagementUI/components/SourceSidebar/SourceSidebar';
import { Entities, Params } from 'datasetManagementUI/lib/types';
import * as Selectors from 'datasetManagementUI/selectors';

export const mapStateToProps = ({ entities }: { entities: Entities }, { params }: { params: Params }) => {
  const fourfour = params.fourfour;
  const pendingOrSuccessfulSources = _.chain(entities.sources)
    .values()
    .filter(source => !source.failed_at)
    .value();


  const revision = Selectors.currentRevision(entities, params.revisionSeq)!;
  const displayType = _.toLower(entities.views[fourfour].displayType);
  const viewType = _.toLower(entities.views[fourfour].viewType);
  const hrefAllowedDisplayType = displayType === 'draft' || viewType === 'href';

  return {
    revision,
    sources: _.orderBy(pendingOrSuccessfulSources, ['finished_at'], ['desc']),
    entities,
    hideHrefLink: !hrefAllowedDisplayType,
    hideImportLinks: viewType === 'href'
  };
};

export default withRouter(connect(mapStateToProps)(SourceSidebar));
