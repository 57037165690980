import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import Button, { SIZES, VARIANTS } from 'common/components/Button';
import { hideModal, showModal } from 'datasetManagementUI/reduxStuff/actions/modal';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { fetchTranslation } from 'common/locale';
import { Option, none, some } from 'ts-option';
import {
  AgentError,
  DataSourceParameterSpec,
  DataSourceParameters,
  CreateSource,
  StartSource
} from 'datasetManagementUI/lib/agentTypes';
import { Source } from 'common/types/source';
const t = (k: string) => fetchTranslation(k, 'dataset_management_ui.connection_agent');

interface StateProps {
  showParametersModal: (ps: DataSourceParameterSpec, cs: CreateSource, ss: StartSource) => void;
}

interface OwnProps {
  name: string;
  parameters: DataSourceParameterSpec;
  createSource: CreateSource;
  startSource: StartSource;
}

export type SourceOptionProps = StateProps & OwnProps;

interface SourceOptionState {
  loading: boolean;
  error: Option<string>;
}

export class SourceOption extends Component<SourceOptionProps, SourceOptionState> {
  constructor(props: SourceOptionProps) {
    super(props);
    this.state = {
      loading: false,
      error: none
    };
    this.buttonAction = this.buttonAction.bind(this);
  }

  buttonAction() {
    const { parameters, createSource, startSource, showParametersModal } = this.props;

    const wrappedStartSource = (source: Source): Promise<void> =>
      startSource(source).catch((error) => {
        const message = new AgentError(error).get();
        this.setState({ loading: false, error: some(message) });
      });

    const wrappedCreateSource = (params: DataSourceParameters): Promise<Source> => {
      this.setState({ ...this.state, loading: true, error: none });
      return createSource(params)
        .catch((error) => {
          const message = new AgentError(error).get();
          this.setState({ loading: false, error: some(message) });
          throw error;
        });
    };

    if (isEmpty(parameters)) {
      return () => {
        wrappedCreateSource({}).then(wrappedStartSource);
      };
    } else {
      return () => showParametersModal(parameters, wrappedCreateSource, wrappedStartSource);
    }
  }

  render() {
    const { name } = this.props;
    return (
      <React.Fragment>
        <div className="source-node source-option">
          <div className="source-node-attrs">
            <SocrataIcon name={IconName.Data} />
            <span className="source-name">{name}</span>
          </div>
          <Button
            busy={this.state.loading}
            variant={this.state.error.isDefined ? VARIANTS.ERROR : VARIANTS.PRIMARY}
            size={SIZES.X_SMALL}
            onClick={this.buttonAction()}
          >
            {this.state.error.isDefined ? t('try_again') : t('use_datasource')}
          </Button>
        </div>
        {this.state.error.map((msg) => <div className="error-node">{msg}</div>).getOrElseValue(<div></div>)}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: any): StateProps => {
  return {
    showParametersModal: (
      parameters: DataSourceParameters,
      createSource: CreateSource,
      startSource: StartSource
    ) => {
      dispatch(
        showModal('SetSourceParameters', {
          parameters: parameters,
          createSource: createSource,
          startSource: startSource,
          hideModal: () => {
            dispatch(hideModal());
          }
        })
      );
    }
  };
};

export default connect<null, StateProps>(null, mapDispatchToProps)(SourceOption);
