import classNames from 'classnames';
import { ParseOptions } from 'common/types/source';
import _ from 'lodash';
import React from 'react';
import { fetchTranslation } from 'common/locale';
const t = (k: string) => fetchTranslation(k, 'dataset_management_ui.parse_options');

const GRID_SIZE = { columns: 6, rows: 12 };


interface Props {
  parseOptions: ParseOptions;
}

function GridPreview({ parseOptions }: Props) {
  const {
    column_header: columnHeader,
    header_count: headerCount
  } = parseOptions;


  const rowClass = (rowIndex: number) => (
    classNames('grid-row', {
      ['the-header-row']: rowIndex === columnHeader,
      ['in-header-range']: rowIndex <= headerCount &&
        rowIndex !== columnHeader
    })
  );
  const annotationClass = (rowIndex: number) => (
    classNames('annotation-cell', {
      ['header-row-annotation']: rowIndex === columnHeader,
      ['header-range-annotation']: rowIndex <= headerCount &&
        rowIndex !== columnHeader
    })
  );

  const getAnnotationFor = (rowIndex: number) => {
    if (rowIndex === columnHeader) {
      return t('this_is_your_header');
    }
    if (rowIndex <= headerCount) {
      return t('this_row_is_ignored');
    }
  };

  const start = (Math.floor(headerCount / GRID_SIZE.rows) * GRID_SIZE.rows) + 1;
  const end = start + GRID_SIZE.rows;

  return (
    <table className="grid-preview">
      <tbody>
        {_.range(start, end).map(row => (
          <tr key={row} className={rowClass(row)}>
            <td className={annotationClass(row)}>
              {getAnnotationFor(row)}
            </td>
            <td className="index-cell">
              {row}
            </td>
            {_.range(0, GRID_SIZE.columns).map(column => (
              <td key={`${row}-${column}`} className="preview-cell"></td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}


export default GridPreview;
