import Button, { VARIANTS } from 'common/components/Button';
import I18n from 'common/i18n';
import ApiCallButton from 'datasetManagementUI/containers/ApiCallButtonContainer';
import { AppState, Params } from 'datasetManagementUI/lib/types';
import { UPDATE_REVISION } from 'datasetManagementUI/reduxStuff/actions/apiCalls';
import { updateRevision } from 'datasetManagementUI/reduxStuff/actions/revisions';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

const t = (k: string) => I18n.t(k, { scope: 'dataset_management_ui.home_pane' });

interface OwnProps {
  params: Params;
  autosaved: boolean;
  outputSchemaId?: number | null;
  blobId?: number | null;
  goToRevisionBase: () => void;
}

interface StateProps extends OwnProps {
  saving: boolean;
}

interface DispatchProps {
  saveBlobOrSchema: () => void;
}

type SaveBlobOrSchemaButtonProps = StateProps & DispatchProps;

export const SaveBlobOrSchemaButton = ({
  params,
  autosaved,
  saving,
  outputSchemaId,
  blobId,
  goToRevisionBase,
  saveBlobOrSchema
}: SaveBlobOrSchemaButtonProps) => {

  if (autosaved) {
    return (
      <div>
        <span className='autosaved footer-alert footer-alert-success'>
          {saving ? t('saving') : t('autosaved')}
        </span>
        <Button
          variant={VARIANTS.PRIMARY}
          onClick={goToRevisionBase}
          busy={saving}
          disabled={saving}>
          {t('done')}
        </Button>
      </div>
    );
  } else {
    return (
      <div>
        <ApiCallButton
          onClick={saveBlobOrSchema}
          operation={UPDATE_REVISION}
          callParams={{ output_schema_id: outputSchemaId, blob_id: blobId }} >
          {t('save_draft')}
        </ApiCallButton>
      </div>
    );
  }
};

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
  const {autosaved, goToRevisionBase, params} = ownProps;
  return {
    outputSchemaId: ownProps.outputSchemaId || null,
    blobId: ownProps.blobId || null,
    autosaved,
    goToRevisionBase,
    saving: _.get(state, 'ui.autosaving.inProgress'),
    params
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps): DispatchProps => ({
  saveBlobOrSchema: () => {
    const {outputSchemaId, blobId, goToRevisionBase, params} = ownProps;
    const update = {output_schema_id: outputSchemaId || null, blob_id: blobId || null};
    dispatch(updateRevision(update, params))
      .then(() => {
        goToRevisionBase();
      });
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(SaveBlobOrSchemaButton);
