import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Fieldset from 'datasetManagementUI/components/Fieldset/Fieldset';
import GridPreview from './GridPreview';
import ParseOption from './ParseOption';
import ParseFlag from './ParseFlag';
import DelimiterChooser from './DelimiterChooser';
import _ from 'lodash';
import { fetchTranslation } from 'common/locale';
import { Source, ParseOptions } from 'common/types/source';

const t = (k: string) => fetchTranslation(k, 'dataset_management_ui.parse_options');

interface ErrorPayload<T> {
  message: string;
  value: T;
}
interface Error<T> {
  error: ErrorPayload<T>;
}
interface Ok<T> {
  ok: T;
}

function parseOptionAsIntOrEmptyString(value: string): Ok<'' | number> | Error<string> {
  if (value === '') return { ok: '' };

  const integer = parseInt(value, 10);
  if (_.isNaN(integer)) {
    return { error: { message: t('must_be_an_int'), value } };
  }
  return { ok: integer };
}

type ParseOptionName =
  | 'column_separator'
  | 'column_header'
  | 'header_count'
  | 'encoding'
  | 'quote_char'
  | 'trim_whitespace'
  | 'remove_empty_rows';

interface FormState {
  parseOptions: ParseOptions;
  errors: {
    column_separator?: ErrorPayload<string>;
    column_header?: ErrorPayload<number>;
    header_count?: ErrorPayload<number>;
    encoding?: ErrorPayload<string>;
    quote_char?: ErrorPayload<string>;
    trim_whitespace?: ErrorPayload<boolean>;
    remove_empty_rows?: ErrorPayload<boolean>;
  };
}
interface Props {
  source: Source;
  form: FormState;
  setFormState: (fs: FormState) => void;
}
interface State {}

export default class ParseOptionsView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.setOption = this.setOption.bind(this);
    this.setFlag = this.setFlag.bind(this);
    this.getOption = this.getOption.bind(this);
    this.getFlag = this.getFlag.bind(this);
    this.setHeaderCount = this.setHeaderCount.bind(this);
    this.setColumnHeader = this.setColumnHeader.bind(this);
  }

  componentDidMount() {
    // Make sure when the component shows, we're using the parse_options
    // from the source, and not some old form state that may be hanging around
    this._updateParseOptions(this.props.source.parse_options);
  }

  getOption(option: ParseOptionName) {
    return this.props.form.parseOptions[option];
  }

  getFlag(option: ParseOptionName) {
    return !!this.props.form.parseOptions[option];
  }

  setError(name: ParseOptionName, reason: ErrorPayload<string | number>) {
    this.props.setFormState({
      ...this.props.form,
      errors: {
        ...this.props.form.errors,
        [name]: reason
      }
    });
  }

  setOption<T>(option: ParseOptionName): (value: T) => void {
    return (value: T) => {
      this._updateParseOptions({ [option]: value });
    };
  }

  setHeaderCount(value: string) {
    const headerCount = parseOptionAsIntOrEmptyString(value);
    if ('ok' in headerCount) {
      this._ensureColumnHeaderGteHeaderCount(
        (headerCount as Ok<number>).ok,
        this.props.form.parseOptions.column_header
      );
    } else {
      this.setError('header_count', headerCount.error);
    }
  }

  setColumnHeader(value: string) {
    const columnHeader = parseOptionAsIntOrEmptyString(value);
    if ('ok' in columnHeader) {
      this._ensureColumnHeaderGteHeaderCount(
        this.props.form.parseOptions.header_count,
        (columnHeader as Ok<number>).ok
      );
    } else {
      this.setError('column_header', columnHeader.error);
    }
  }

  setFlag(option: ParseOptionName) {
    return (value: boolean) => {
      this._updateParseOptions({ [option]: value });
    };
  }

  _updateParseOptions(values: any) {
    this.props.setFormState({
      ...this.props.form,
      errors: {
        ..._.omit(this.props.form.errors, _.keys(values)) // clear the errors for the keys we're updating
      },
      parseOptions: {
        ...this.props.form.parseOptions,
        ...values // do the update
      }
    });
  }

  _ensureColumnHeaderGteHeaderCount(headerCount: number, columnHeader: number) {
    if (_.isNumber(headerCount) && _.isNumber(columnHeader) && headerCount < columnHeader) {
      return this.setError('header_count', {
        message: t('header_count_gt_error'),
        value: headerCount
      });
    }
    this._updateParseOptions({
      column_header: columnHeader,
      header_count: headerCount
    });
  }

  clearError(name: ParseOptionName) {
    this.props.setFormState({
      ...this.props.form,
      errors: {
        ...this.props.form.errors,
        [name]: false
      }
    });
  }

  render() {
    const {
      form: { errors }
    } = this.props;

    return (
      <div className="parse-options">
        <div className="options-panes">
          <div className="options-form">
            <form>
              <Fieldset title={t('title')} subtitle={t('subtitle')}>
                <div className="grouping">
                  <ParseOption<number>
                    name={'header_count'}
                    error={errors.header_count}
                    placeholder={t('header_count_must_be_gt_column_header')}
                    value={this.getOption('header_count')}
                    setOption={this.setHeaderCount}
                  />
                  <ParseOption<number>
                    name={'column_header'}
                    error={errors.column_header}
                    value={this.getOption('column_header')}
                    setOption={this.setColumnHeader}
                  />
                </div>
                <div className="grouping">
                  <DelimiterChooser
                    value={this.getOption('column_separator')}
                    setOption={this.setOption('column_separator')}
                  />
                </div>
                <div className="grouping">
                  <ParseOption<string>
                    name={'encoding'}
                    error={errors.encoding}
                    placeholder={t('automatic')}
                    value={this.getOption('encoding')}
                    setOption={this.setOption('encoding')}
                  />
                </div>
                <div className="grouping">
                  <ParseOption
                    name={'quote_char'}
                    error={errors.quote_char}
                    value={this.getOption('quote_char')}
                    setOption={this.setOption('quote_char')}
                  />
                </div>
                <div className="grouping">
                  {_.has(this.props.form.parseOptions, 'trim_whitespace') && (
                    <ParseFlag
                      name={'trim_whitespace'}
                      value={this.getFlag('trim_whitespace')}
                      setOption={this.setFlag('trim_whitespace')}
                    />
                  )}
                  {_.has(this.props.form.parseOptions, 'remove_empty_rows') && (
                    <ParseFlag
                      name={'remove_empty_rows'}
                      value={this.getFlag('remove_empty_rows')}
                      setOption={this.setFlag('remove_empty_rows')}
                    />
                  )}
                </div>
              </Fieldset>
            </form>
          </div>
          <div className="options-preview">
            <GridPreview parseOptions={this.props.form.parseOptions} />
          </div>
        </div>
      </div>
    );
  }
}
