/* eslint react/jsx-indent: 0 */
/* eslint react/sort-comp: 0 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import TypeIcon from 'common/components/SoQLTypeIcon';
import { soqlTypesForBackend, soqlProperties, isOrderable } from 'datasetManagementUI/lib/soqlTypes';
import { traverse } from 'datasetManagementUI/lib/ast';
import Modes from 'datasetManagementUI/lib/modes';
import * as Links from 'datasetManagementUI/links/links';
import FirefoxDragHack from 'common/explore_grid/lib/firefoxDragHack';
import * as Selectors from 'datasetManagementUI/selectors';
import DSMUIIcon from '../DSMUIIcon';
import * as ModalActions from 'datasetManagementUI/reduxStuff/actions/modal';
import * as FlashActions from 'datasetManagementUI/reduxStuff/actions/flashMessage';
import * as ShowActions from 'datasetManagementUI/reduxStuff/actions/showOutputSchema';
import Dropdown from 'common/components/Dropdown';
import { picklistSizingStrategy } from 'common/components/Dropdown/picklistSizingStrategy';
import HiddenColumnIcon from './HiddenColumnIcon'; // absolute imports suddenly stopped working. they worked literally a second ago.
import SortColumnIcon from './SortColumnIcon';
import { isEternalObe } from 'datasetManagementUI/lib/util';
import I18n from 'common/i18n';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.show_output_schema' });

function DropdownWithIcon(dropdownProps) {
  const { icon, title, disabled, additionalClasses } = dropdownProps;
  const classNames = ['col-dropdown-item'];

  if (disabled) {
    classNames.push('col-dropdown-item-disabled');
  }

  _.forEach(additionalClasses, additionalClass => {
    classNames.push(additionalClass);
  });

  return (
    <div className={classNames.join(' ')}>
      <DSMUIIcon className={icon} name={title} />
      {t(`column_header.${title}`)}
    </div>
  );
}

DropdownWithIcon.proptypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

function RedirectError() {
  this.name = 'RedirectError';
  this.message = t('redirect_error');
}

RedirectError.prototype = new Error();

const redirectToNewOutputschema = (dispatch, params) => resp => {
  if (resp && resp.resource) {
    dispatch(ShowActions.redirectToOutputSchema(params, resp.resource.id));
  } else {
    throw new RedirectError();
  }
};

const isTypeSupportedInBackend = (view) => (type) => {
  return _.includes(soqlTypesForBackend(view), type);
};

const isRowIdSupportedForType = (view, type) => {
  const nbeRowIds = ['calendar_date', 'text', 'number', 'date', 'checkbox'];
  const obeRowIds = ['text', 'number'];
  if (isEternalObe(view)) {
    return _.includes(obeRowIds, type);
  } else {
    return _.includes(nbeRowIds, type);
  }
};

export class ColumnHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // this function is being passed as a callback to the onChange of the selector
    this.updateColumnType = this.updateColumnType.bind(this);
    this.onDragWidthHandle = _.throttle(this.onDragWidthHandle, 50).bind(this);
    this.onDragWidthHandleStart = this.onDragWidthHandleStart.bind(this);
    this.onDragWidthHandleEnd = this.onDragWidthHandleEnd.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.dragManager = new FirefoxDragHack();
  }

  componentWillUnmount() {
    this.dragManager.cleanup();
  }

  onDropColumn() {
    // button is disabled but click handler still fires
    // so we need to guard against this stuff twice
    if (this.isDropColumnDisabled()) {
      return;
    }

    this.dropColumn();
  }

  onRowId() {
    // guard against disabled but not actually click handler
    if (this.isRowIdDisabled()) {
      return;
    }

    this.validateThenSetRowIdentifier();
  }

  onUnsetRowId() {
    // guard against disabled but not actually click handler
    if (this.isUnsetRowIdDisabled()) return;
    this.unSetRowIdentifier();
  }

  onMoveLeft() {
    if (this.isMoveLeftDisabled()) return;
    this.moveLeft();
  }

  onMoveRight() {
    if (this.isMoveRightDisabled()) {
      return;
    }
    this.moveRight();
  }

  onFormatColumn() {
    if (this.isFormatDisabled()) {
      return;
    }
    this.formatColumn();
  }

  onTransform() {
    if (this.isTransformDisabled()) return;
    browserHistory.push(Links.transformColumn(
      this.props.params,
      this.props.params.sourceId,
      this.props.params.inputSchemaId,
      this.props.params.outputSchemaId,
      this.props.outputColumn.id
    ));
  }

  onEditGeocode() {
    if (this.isEditGeocodeDisabled()) return;
    browserHistory.push(Links.geocodeShortcut(
      { ...this.props.params, outputColumnId: this.props.outputColumn.id }
    ));
  }

  onHideColumn() {
    if (this.isHideColumnDisabled()) return;
    this.hideColumn();
  }

  onShowColumn() {
    if (this.isShowColumnDisabled()) return;
    this.showColumn();
  }

  onSortAscending() {
    if (this.isSortingDisabled()) return;
    this.sortAscending();
  }

  onSortDescending() {
    if (this.isSortingDisabled()) return;
    this.sortDescending();
  }

  onClearSort() {
    if (this.isSortingAscending() || this.isSortingDescending()) {
      this.clearSort();
    }
  }

  isDropColumnDisabled() {
    return this.props.modes.update || this.props.outputColumn.is_primary_key;
  }

  isRowIdDisabled() {
    const type = this.props.outputColumn.transform.output_soql_type;
    return this.props.modes.update ||
      this.isInProgress() ||
      this.hasFailed() ||
      !isRowIdSupportedForType(this.props.view, type) ||
      this.props.outputColumn.is_primary_key;
  }

  isUnsetRowIdDisabled() {
    return this.isInProgress() ||
      this.hasFailed() ||
      !this.props.outputColumn.is_primary_key ||
      this.props.modes.update;
  }

  isHideColumnDisabled() {
    return this.isHidden();
  }

  isShowColumnDisabled() {
    return !this.isHidden();
  }

  isMoveLeftDisabled() {
    return this.props.outputColumn.position <= 1;
  }

  isMoveRightDisabled() {
    return this.props.outputColumn.position >= this.props.columnCount;
  }

  isSortingDisabled() {
    const canonicalTypeName = this.props.outputColumn.transform.output_soql_type;
    return !isOrderable(canonicalTypeName);
  }

  hasFailed() {
    return !!(this.props.outputColumn && this.props.outputColumn.transform.failed_at);
  }

  isFormatDisabled() {
    return this.hasFailed();
  }

  isEditGeocodeDisabled() {
    // if there is no geocoded column, it is disabled
    return !Selectors.getGeocodedOrLocationOutputColumn([this.props.outputColumn]);
  }

  isSortingAscending() {
    const sort = this.getSort();
    return sort && sort.ascending;
  }

  isSortingDescending() {
    const sort = this.getSort();
    return sort && !sort.ascending;
  }

  isHidden() {
    return _.includes(this.props.outputColumn.flags, 'hidden');
  }

  getSort() {
    const { outputSchema, outputColumn } = this.props;
    return _.find(outputSchema.sort_bys || [], (sb) => sb.field_name === outputColumn.field_name);
  }

  dropColumn() {
    const { dispatch, params, outputSchema, outputColumn } = this.props;

    this.props.setDropping();
    return dispatch(ShowActions.dropColumn(outputSchema, outputColumn))
      .then(redirectToNewOutputschema(dispatch, params))
      .then(this.props.resetDropping)
      .catch(e => {
        if (e.name === 'RedirectError') {
          dispatch(FlashActions.showFlashMessage({
            kind: 'error',
            id: 'drop_col_error',
            message: e.message }));
        } else {
          dispatch(
            FlashActions.showFlashMessage({
              kind: 'error',
              id: 'drop_col_unknown_error',
              message: t('fatal_error.unknown_error')
            })
          );
        }
      });
  }

  newSchema(action) {
    const { dispatch, params } = this.props;
    return dispatch(action).then(
      redirectToNewOutputschema(dispatch, params)
    );
  }

  updateColumnTransform(newExpr) {
    const { outputSchema, outputColumn } = this.props;
    this.newSchema(ShowActions.updateColumnTransform(outputSchema, outputColumn, newExpr));
  }

  updateColumnType(newType) {
    const { outputSchema, outputColumn } = this.props;
    this.newSchema(
      ShowActions.updateColumnType(outputSchema, outputColumn, newType)
    );
  }

  validateThenSetRowIdentifier() {
    const { outputSchema, outputColumn } = this.props;
    this.newSchema(ShowActions.validateThenSetRowIdentifier(outputSchema, outputColumn));
  }

  unSetRowIdentifier() {
    const { outputSchema } = this.props;
    this.newSchema(ShowActions.unsetRowIdentifier(outputSchema));
  }

  move(delta) {
    const { outputSchema, outputColumn } = this.props;
    this.newSchema(
      ShowActions.moveColumnToPosition(outputSchema, outputColumn, outputColumn.position + delta)
    );
  }

  moveLeft() {
    this.move(-1);
  }

  moveRight() {
    this.move(1);
  }

  sort(ascending) {
    const { outputSchema, outputColumn } = this.props;
    this.newSchema(ShowActions.sortColumn(outputSchema, outputColumn.field_name, ascending));
  }

  sortAscending() {
    this.sort(true);
  }

  sortDescending() {
    this.sort(false);
  }

  clearSort() {
    const { outputSchema, outputColumn } = this.props;
    this.newSchema(ShowActions.unSortColumn(outputSchema, outputColumn.field_name));
  }

  formatColumn() {
    const { dispatch, params, outputSchema, outputColumn } = this.props;

    return dispatch(
      ModalActions.showModal('FormatColumn', {
        outputSchema,
        outputColumn,
        params
      })
    );
  }

  hideColumn() {
    const { outputSchema, outputColumn } = this.props;
    this.newSchema(ShowActions.hideColumn(outputSchema, outputColumn));
  }

  showColumn() {
    const { outputSchema, outputColumn } = this.props;
    this.newSchema(ShowActions.showColumn(outputSchema, outputColumn));
  }

  isInProgress() {
    const transform = this.props.outputColumn.transform;
    if (transform) {
      return !transform.finished_at;
    }
    return false;
  }

  isTransformDisabled() {
    return false;
  }

  onDragWidthHandleStart(event) {
    // this is to make the default drag and drop ghost element
    // not show up. there's no way to do it in CSS (without also
    // disabling the drag and drop behavior entirely) so this is
    // the only way to achieve what we want.
    const empty = document.createElement('canvas');
    empty.width = 0;
    empty.height = 0;
    if (event.dataTransfer.setDragImage) {
      event.dataTransfer.setDragImage(empty, 0, 0);
    }

    this.dragManager.onStart(event);
    const pageX = this.dragManager.getX(event.pageX);
    if (!_.isNumber(pageX)) return;
    this.lastDrag = pageX;
  }

  onDragWidthHandle(maybePageX) {
    const pageX = this.dragManager.getX(maybePageX);
    if (!_.isNumber(pageX)) return;
    // it's not actually 0, the browser is lying to us
    if (pageX === 0) return;
    if (_.isNumber(this.lastDrag) && pageX !== 0) {
      const dw = pageX - this.lastDrag;
      this.lastDrag = pageX;
      this.props.setWidth(Math.max(80, this.props.width + dw));
    } else {
      this.lastDrag = pageX;
    }
  }

  onDragWidthHandleEnd() {
    this.lastDrag = null;
    this.props.updateColumnWidth();
    this.dragManager.onEnd();
  }

  optionsFor() {
    const column = this.props.outputColumn;
    const isHidden = this.isHidden();

    return [
      {
        title: 'formatting',
        value: 'onFormatColumn',
        icon: 'socrata-icon-paragraph-left',
        disabled: this.isFormatDisabled(),
        render: DropdownWithIcon
      },
      {
        title: 'data_transforms',
        value: 'onTransform',
        icon: 'socrata-icon-embed',
        disabled: this.isTransformDisabled(),
        render: DropdownWithIcon
      },
      {
        title: 'edit_geocode',
        value: 'onEditGeocode',
        icon: 'socrata-icon-geo',
        disabled: this.isEditGeocodeDisabled(),
        render: DropdownWithIcon
      },
      {
        title: isHidden ? 'unhide_column' : 'hide_column',
        value: isHidden ? 'onShowColumn' : 'onHideColumn',
        icon: isHidden ? 'socrata-icon-eye' : 'socrata-icon-eye-blocked',
        disabled: false,
        render: DropdownWithIcon
      },
      {
        title: column.is_primary_key ? 'unset_row_id' : 'set_row_id',
        value: column.is_primary_key ? 'onUnsetRowId' : 'onRowId',
        icon: 'socrata-icon-id',
        disabled: column.is_primary_key
          ? this.isInProgress() || this.isUnsetRowIdDisabled()
          : this.isRowIdDisabled(),
        render: DropdownWithIcon
      },
      {
        title: 'move_left',
        value: 'onMoveLeft',
        disabled: this.isMoveLeftDisabled(),
        icon: 'socrata-icon-arrow-prev',
        render: DropdownWithIcon
      },
      {
        title: 'move_right',
        value: 'onMoveRight',
        disabled: this.isMoveRightDisabled(),
        icon: 'socrata-icon-arrow-next',
        render: DropdownWithIcon
      },
      {
        title: this.isSortingAscending() ? 'unsort_ascending' : 'sort_ascending',
        value: this.isSortingAscending() ? 'onClearSort' : 'onSortAscending',
        disabled: this.isSortingDisabled(),
        icon: 'socrata-icon-sort-asc',
        render: DropdownWithIcon
      },
      {
        title: this.isSortingDescending() ? 'unsort_descending' : 'sort_descending',
        value: this.isSortingDescending() ? 'onClearSort' : 'onSortDescending',
        disabled: this.isSortingDisabled(),
        icon: 'socrata-icon-sort-desc',
        render: DropdownWithIcon
      },
      {
        title: 'drop_column',
        value: 'onDropColumn',
        icon: 'socrata-icon-close-circle',
        disabled: this.isDropColumnDisabled(),
        render: DropdownWithIcon,
        additionalClasses: ['danger']
      }
    ];
  }

  columnType() {
    return this.props.outputColumn.transform.output_soql_type;
  }

  icon(isDisabled) {
    if (this.props.activeApiCallInvolvingThis) {
      return <span className="progress-spinner spinner-default" />;
    } else if (this.props.outputColumn.is_primary_key) {
      return <span className="row-id-icon socrata-icon-id" />;
    }
    return <TypeIcon type={this.columnType()} isDisabled={isDisabled} />;
  }

  isFloatingOutputColumn() {
    // by "floating" we mean not based on any input column(s) and
    // contains no data (not a constant, not an :input_row)
    const hasNoInputColumn = !_.get(this.props.outputColumn, 'transform.transform_input_columns', []).length;
    const isBasicTo = _.get(
      this.props.outputColumn,
      'transform.parsed_expr.function_name', ''
    ).startsWith('to_');
    const nullArgs = _.isNull(_.get(this.props.outputColumn, 'transform.parsed_expr.args[0]'));
    return nullArgs && isBasicTo && hasNoInputColumn;
  }

  wasTransformCreatedThroughSelection() {
    if (this.props.outputColumn.inputColumns.length !== 1) {
      return false;
    }
    // there is a better way to do this but it's super complicated.
    // this is just a heuristic that should work
    // TODO: figure out a better way to make the selection thing
    // work - suggestion:
    // beforehand, generate all the possible conversions
    // if there's a single input column. if the current parsed_expr
    // is in that list, selection is enabled.
    // but the issue is the datetime taking entities - so we
    // need to do this in the container. that's a much bigger
    // change than i'm willing to make right now though so...
    // this is what we get
    const ast = this.props.outputColumn.transform.parsed_expr;
    if (!ast) return true;
    if (ast.type === 'column_ref') return true;
    return traverse(ast, true, (node, acc) => {
      if (node && node.type === 'funcall') {
        // lol
        return acc && (
          node.function_name.startsWith('forgive') ||
          node.function_name.startsWith('to_') ||
          node.function_name.startsWith('reproject_to') ||
          node.function_name.startsWith('op$*') ||
          node.function_name.startsWith('op$/')
        );
      }
      return acc;
    });
  }

  // if a user makes a change to the output schema, and we're still saving it
  // then they rapidly make another change, we end up overwriting their last change.
  // this prevents that by waiting for us to successfully make each change
  allColumnKebabOptionsDisabled() {
    const { activeApiCallInvolvingThis, autosaving } = this.props;
    return activeApiCallInvolvingThis || autosaving;
  }

  render() {
    const {
      view,
      outputSchema,
      outputColumn,
      params,
      isDropping,
      width,
      isViewer,
      canTransform,
      modes
    } = this.props;

    const allOptsDisabled = this.allColumnKebabOptionsDisabled();
    let isSelectorDisabled = allOptsDisabled || !canTransform || modes.update; // ability to select source column from dropdown
    let convertibleTo = [];

    // Simple case of only transforming via the selection dropdown
    if (this.wasTransformCreatedThroughSelection()) {
      const inputColumn = outputColumn.inputColumns[0];

      const inputColumnTypeInfo = soqlProperties()[inputColumn.soql_type];
      convertibleTo = Object.keys(inputColumnTypeInfo.conversions);

      // special case for types we don't want people to use anymore
      if (!_.includes(convertibleTo, this.columnType())) {
        convertibleTo.push(this.columnType());
      }

      isSelectorDisabled = isSelectorDisabled || convertibleTo.length === 0;
    } else if (this.isFloatingOutputColumn()) {
      // allow conversion to any type, since the col contains no data anyway
      convertibleTo = Object.keys(soqlProperties().text.conversions);
    } else {
      // More complex case: someone has a transform which
      // was made through the geocoding dialog or transform editor
      // This means the simple to_text/to_number/etc transforms must be disabled,
      // so we disable the selector
      isSelectorDisabled = true;
      // and then say we can convert to ourself so the selector at least has a title
      convertibleTo.push(this.columnType());
    }

    if (isViewer) {
      isSelectorDisabled = true;
    }

    const types = convertibleTo
      .filter(isTypeSupportedInBackend(view))
      .map(type => ({
        humanName: t(`column_header.type_display_names.${type.toLowerCase()}`),
        systemName: type
      }));

    const orderedTypes = _.sortBy(types, 'humanName');

    const dropdownProps = {
      onSelection: e => this[e.value](outputColumn),
      picklistSizingStrategy: picklistSizingStrategy.EXPAND_TO_WIDEST_ITEM,
      options: this.optionsFor(outputColumn),
      placeholder: () => {
        return (
          <button className="dropdown-btn btn btn-xs btn-simple socrata-icon-kebab">
            <span className="accessibility-text">Dropdown Menu</span>
          </button>
        );
      },
      disabled: allOptsDisabled
    };

    const header = (!outputColumn.transform || isViewer || allOptsDisabled) ? ( //show the quick link to the column metadata edit page
      <span
        className="col-name"
        id={`column-field-name-${outputColumn.id}`}
        title={outputColumn.display_name}>
        {outputColumn.display_name}
      </span>
    ) : (
      <Link to={Links.columnMetadataForm(params, outputSchema.id, outputColumn.field_name, 'name')}>
        <span
          className="col-name"
          data-cheetah-hook="col-name"
          id={`column-display-name-${outputColumn.id}`}
          title={outputColumn.display_name}>
          {outputColumn.display_name}
        </span><DSMUIIcon name="edit" className="icon" />
      </Link>
    );

    const classNames = ['column-header'];

    if (isSelectorDisabled) {
      classNames.push('columnHeaderDisabled');
    }

    if (isDropping) {
      classNames.push('dropping');
    }

    return (
      <th key={outputColumn.id} className={classNames.join(' ')} style={{ width }}>
        <div className="header-attributes">
          {header}

          <div className="header-icons">
            {this.isHidden() && <HiddenColumnIcon columnId={outputColumn.id} />}
            {(this.isSortingAscending() || this.isSortingDescending()) && <SortColumnIcon
              columnId={outputColumn.id}
              ascending={this.isSortingAscending()} />}

            <div className="col-dropdown">
              {!isViewer && <Dropdown {...dropdownProps} />}
            </div>
          </div>
        </div>
        <div className="header-controls">
          {this.icon(isSelectorDisabled)}
          <select
            name={`col-type ${this.columnType()}`}
            disabled={isSelectorDisabled}
            value={this.columnType()}
            aria-label={`col-type-${outputColumn.field_name}`}
            onChange={event => this.updateColumnType(event.target.value)}>
            {orderedTypes.map(type => (
              <option key={type.systemName} value={type.systemName}>
                {type.humanName}
              </option>
            ))}
          </select>
        </div>

        {!isViewer && <div
          draggable="true"
          className="drag-handle"
          onDragStart={this.onDragWidthHandleStart}
          onDragEnd={this.onDragWidthHandleEnd}
          onDrag={(event) => this.onDragWidthHandle(event.pageX)}/>}
      </th>
    );
  }
}


ColumnHeader.propTypes = {
  view: PropTypes.object.isRequired,
  isDropping: PropTypes.bool,
  dispatch: PropTypes.func,
  setDropping: PropTypes.func,
  resetDropping: PropTypes.func,
  outputSchema: PropTypes.object.isRequired,
  outputColumn: PropTypes.object.isRequired,
  activeApiCallInvolvingThis: PropTypes.bool.isRequired,
  canTransform: PropTypes.bool.isRequired,
  columnCount: PropTypes.number.isRequired,
  params: PropTypes.object.isRequired,
  modes: Modes.shape,
  width: PropTypes.number.isRequired,
  setWidth: PropTypes.func.isRequired,
  updateColumnWidth: PropTypes.func.isRequired,
  isViewer: PropTypes.bool,
  autosaving: PropTypes.bool
};

export default ColumnHeader;
