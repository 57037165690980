import PropTypes from 'prop-types';
import React from 'react';
import ErrorPill from 'datasetManagementUI/components/ErrorPill/ErrorPill';
import { singularOrPlural } from 'datasetManagementUI/lib/util';
import I18n from 'common/i18n';

function ErrorPointer({ errorInfo, direction, scrollToColIdx }) {
  const classNames = ['error-pointer'];
  if (direction === 'left') {
    classNames.push('error-pointer-left');
  } else {
    classNames.push('error-pointer-right');
  }

  const message = singularOrPlural(
    errorInfo.errorSum,
    I18n.t('dataset_management_ui.show_output_schema.additional_errors.singular'),
    I18n.t('dataset_management_ui.show_output_schema.additional_errors.plural')
  );
  return (
    <span
      onClick={() => {
        scrollToColIdx(errorInfo.firstColWithErrors);
      }}
      className={classNames.join(' ')}>
      {direction === 'left' && <span className="socrata-icon-arrow-left" />}
      <span className='message-body'>
        <ErrorPill number={errorInfo.errorSum} />
        {message}
      </span>
      {direction === 'right' && <span className="socrata-icon-arrow-right" />}
    </span>
  );
}

ErrorPointer.propTypes = {
  errorInfo: PropTypes.shape({
    firstColWithErrors: PropTypes.number.isRequired,
    errorSum: PropTypes.number.isRequired
  }),
  direction: PropTypes.oneOf(['left', 'right']),
  scrollToColIdx: PropTypes.func.isRequired
};

export default ErrorPointer;
