import { fetchTranslation } from 'common/locale';
const t = (k) => fetchTranslation(k, 'dataset_management_ui.show_uploads');

export function getFilename(source, view) {
  if (isUploadSource(source)) return source.source_type.filename;
  if (isURLSource(source)) return source.source_type.filename || source.source_type.url;
  if (isConnectionAgentSource(source)) return (source.source_type.human_path || source.source_type.path).join('/');
  if (isViewSource(source) && view) return view.name;
  return 'Unknown';
}

export function isUploadSource(source) {
  return source.source_type.type === 'upload';
}

export function isURLSource(source) {
  return source.source_type.type === 'url';
}

export function isConnectionAgentSource(source) {
  return source.source_type.type === 'connection_agent';
}

export function isViewSource(source) {
  return source.source_type.type === 'view';
}

export function descriptionOf(source) {
  if (isViewSource(source)) return t('view_source');
  if (isConnectionAgentSource(source)) return t('connection_agent');
  return source.content_type;
}
