import { CompilationFailedDetails } from 'common/types/compiler';
import { PhxChannel, socket } from 'common/types/dsmapi';
import { InputSchema } from 'common/types/dsmapiSchemas';
import { Expr, Scope, SoQLType } from 'common/types/soql';
import { Dispatch } from 'datasetManagementUI/lib/types';
import { DsmuiCompiler, PreviewResults } from '../reducers/compiler';

type TypecheckedExpr = Expr & { result_type: SoQLType };

export enum CompilerActionType {
  COMPILER_SCOPE = 'COMPILER_SCOPE',
  COMPILER_ADD_SUCCESS = 'COMPILER_ADD_SUCCESS',
  COMPILER_ADD_ERROR = 'COMPILER_ADD_ERROR',
  COMPILER_REMOVE = 'COMPILER_REMOVE',
  COMPILATION_SUCCESS = 'COMPILATION_SUCCESS',
  COMPILATION_FAILURE = 'COMPILATION_FAILURE',
  COMPILATION_STARTED = 'COMPILATION_STARTED',
  PREVIEW_STARTED = 'PREVIEW_STARTED',
  PREVIEW_AVAILABLE = 'PREVIEW_AVAILABLE',
  PREVIEW_EMPTY = 'PREVIEW_EMPTY'
}

interface CompilerAdded {
  type: CompilerActionType.COMPILER_ADD_SUCCESS;
  expression: string | null;
  inputSchema: InputSchema;
  channel: PhxChannel;
}
interface CompilerAddError {
  type: CompilerActionType.COMPILER_ADD_ERROR;
  inputSchema: InputSchema;
}
interface CompilerScope {
  type: CompilerActionType.COMPILER_SCOPE;
  scope: Scope;
}
interface CompilationStarted {
  type: CompilerActionType.COMPILATION_STARTED;
  compiler: DsmuiCompiler;
  expression: string;
}
interface CompilationSuccess {
  type: CompilerActionType.COMPILATION_SUCCESS;
  compiler: DsmuiCompiler;
  expression: string;
  parsed: TypecheckedExpr;
}

interface CompilationFailure {
  type: CompilerActionType.COMPILATION_FAILURE;
  reason: string;
  line: number;
  column: number;
  soqlException: CompilationFailedDetails;
}
interface CompilerRemoved {
  type: CompilerActionType.COMPILER_REMOVE;
}

interface PreviewStarted {
  type: CompilerActionType.PREVIEW_STARTED;
}
interface PreviewAvailable {
  type: CompilerActionType.PREVIEW_AVAILABLE;
  expr: string;
  results: PreviewResults;
  soqlType: SoQLType;
}
interface PreviewEmpty {
  type: CompilerActionType.PREVIEW_EMPTY;
}

export type CompilerAction =
  CompilerAdded |
  CompilerRemoved |
  CompilerAddError |
  CompilerScope |
  CompilationStarted |
  CompilationSuccess |
  CompilationFailure |
  PreviewStarted |
  PreviewAvailable |
  PreviewEmpty;


const compilerAddSuccess = (inputSchema: InputSchema, channel: PhxChannel): CompilerAdded => ({
  type: CompilerActionType.COMPILER_ADD_SUCCESS,
  expression: null,
  inputSchema,
  channel
});

const compilerAddError = (inputSchema: InputSchema): CompilerAddError => ({
  type: CompilerActionType.COMPILER_ADD_ERROR,
  inputSchema
});

const compilerScope = (scope: Scope): CompilerScope => ({
  type: CompilerActionType.COMPILER_SCOPE,
  scope
});

const compilationStarted = (compiler: DsmuiCompiler, expression: string): CompilationStarted => ({
  type: CompilerActionType.COMPILATION_STARTED,
  compiler,
  expression
});

const compilationSuccess = (compiler: DsmuiCompiler, expression: string, parsed: TypecheckedExpr): CompilationSuccess => ({
  type: CompilerActionType.COMPILATION_SUCCESS,
  compiler,
  expression,
  parsed
});


const showPreview = (expr: string, results: PreviewResults, soqlType: SoQLType): PreviewAvailable => ({
  type: CompilerActionType.PREVIEW_AVAILABLE,
  expr,
  results,
  soqlType
});

const startPreview = (): PreviewStarted => ({
  type: CompilerActionType.PREVIEW_STARTED
});
const clearPreview = (): PreviewEmpty => ({
  type: CompilerActionType.PREVIEW_EMPTY
});


const compilationFailure = (
  reason: string,
  line: number,
  column: number,
  soqlException: CompilationFailedDetails
): CompilationFailure => ({
  type: CompilerActionType.COMPILATION_FAILURE,
  reason,
  line,
  column,
  soqlException
});

export const addCompiler = (inputSchema: InputSchema) => (dispatch: Dispatch) => {

  const channel = socket().channel(`compiler:${inputSchema.id}`);

  channel.on('scope', ({ scope }: { scope: Scope }) => dispatch(compilerScope(scope)));

  channel
    .join()
    .receive('ok', () => dispatch(compilerAddSuccess(inputSchema, channel)))
    .receive('error', () => dispatch(compilerAddError(inputSchema)));
};

export const removeCompiler = () => ({ type: CompilerActionType.COMPILER_REMOVE });

export const compileExpression = (compiler: DsmuiCompiler, expression: string, generatePreview: boolean) => (dispatch: Dispatch) => {
  dispatch(compilationStarted(compiler, expression));

  compiler.channel
    .push('compile', { expression })
    .receive('ok', (parsed) => {
      dispatch(compilationSuccess(compiler, expression, parsed));
      if (generatePreview) {
        dispatch(startPreview());
        compiler.channel.push('preview', { expression }).receive('ok', ({ results, soql_type: soqlType }: { results: PreviewResults, soql_type: SoQLType }) => {
          dispatch(showPreview(expression, results, soqlType));
        }).receive('error', () => {
          dispatch(clearPreview());
        });
      }
    })
    .receive('error', ({ reason, line, column, soql_exception: soqlException }) => {
      dispatch(compilationFailure(reason, line, column, soqlException));
      dispatch(clearPreview());
    });
};
