import { connect } from 'react-redux';
import { getEnvironment } from 'common/environment';
import { ScanStatus } from 'common/components/ScanResult';
import UploadNotification from 'datasetManagementUI/components/UploadNotification/UploadNotification';
import * as Selectors from 'datasetManagementUI/selectors';
import _ from 'lodash';

const mapStateToProps = ({ entities }, { notification, revision }) => {
  let hasUploadErrors = false;
  const view = entities.views[revision.fourfour];
  const source = entities.sources[notification.subject];

  const currentOutputSchema = Selectors.currentOutputSchema(entities, revision.revision_seq);
  let isUploaded = !!source.finished_at;

  if (currentOutputSchema) {
    const currentInputSchema = entities.input_schemas[currentOutputSchema.input_schema_id];
    if (currentInputSchema.source_id === source.id) {
      const outputColumns = Selectors.columnsForOutputSchema(entities, currentOutputSchema.id);
      hasUploadErrors = _.some(outputColumns, (outputColumn) => outputColumn.transform.error_count > 0);
      isUploaded = _.every(outputColumns, (outputColumn) => {
        return outputColumn.transform.finished_at &&
        _.isNumber(outputColumn.transform.error_count);
      });
    } else {
      // the current output schema belongs to a different source (an older one)
      // so we don't know yet if the currently uploading output schema is finished
      isUploaded = false;
    }
  }

  const scanState = _.get(source, 'scanResult.state', ScanStatus.Unscanned);
  // We still scan files when developing against staging.
  // We do not scan in development, so we should simply pretend that it's scanned and move on.
  const isScanned = getEnvironment() === 'development' || scanState !== ScanStatus.Unscanned;
  const securityScanFlagged = isScanned && scanState === ScanStatus.Bad;

  return {
    view,
    source,
    hasUploadErrors,
    revision,
    isUploaded,
    isScanned,
    securityScanFlagged
  };
};


export default connect(mapStateToProps)(UploadNotification);
