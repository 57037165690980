import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PublishingPlan from 'datasetManagementUI/components/PublishingPlan/PublishingPlan';
import { hideModal } from 'datasetManagementUI/reduxStuff/actions/modal';
import * as ApplyRevision from 'datasetManagementUI/reduxStuff/actions/applyRevision';
import * as RevisionActions from 'datasetManagementUI/reduxStuff/actions/revisions';
import { Dispatch, Entities, Params } from 'datasetManagementUI/lib/types';
import { Revision } from 'common/types/revision';

export function mapStateToProps({ entities }: { entities: Entities }, { params }: { params: Params }) {
  const revision = _.values(entities.revisions)[0];
  const view = _.values(entities.views)[0];

  return {
    params,
    revision,
    view,
    getPlan: () => ApplyRevision.getPlan(params)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onCancelClick: () => dispatch(hideModal()),
    onUpdateRevision: (newRev: Revision, params: Params) => dispatch(RevisionActions.updateRevision(newRev, params))
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublishingPlan));
