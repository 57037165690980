import { connect } from 'react-redux';
import MapFlyout from '../components/MapFlyout/MapFlyout';
import * as Selectors from '../selectors';
import * as LoadDataActions from 'datasetManagementUI/reduxStuff/actions/loadData';


function mapStateToProps(state, props) {

  const outputColumns = Selectors.columnsForOutputSchema(
    state.entities,
    props.outputSchema.id
  );

  const rows = Selectors.getRowData(
    state.entities,
    props.outputSchema.input_schema_id,
    props.displayState,
    outputColumns
  );

  return {
    ...props,
    left: props.left,
    transform: props.transform,
    rows,
    outputColumns
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadVisibleData: (columns) => (
    dispatch(LoadDataActions.loadVisibleData(ownProps.displayState, columns))
    .catch(e => {
      if (e && e.type === 'not_called') {
        // just means we determined we didn't need to
        // load any data, so don't do anything
      } else {
        throw e;
      }
    })
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapFlyout);
