import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'common/i18n';

const SaveButtons = ({ saveHrefForm, isDirty }) => (
  <div>
    <label
      htmlFor="submit-href-form"
      className={`btn btn-primary ${isDirty ? '' : 'dsmp-disabled'}`}
      onClick={isDirty ? () => saveHrefForm(false) : e => e.preventDefault()}
      disabled={!isDirty}>
      {I18n.t('dataset_management_ui.common.save')}
    </label>
    <label
      htmlFor="submit-href-form"
      className={`btn btn-primary ${isDirty ? '' : 'dsmp-disabled'}`}
      onClick={isDirty ? () => saveHrefForm(true) : e => e.preventDefault()}>
      {I18n.t('dataset_management_ui.show_sources.save_and_exit')}
    </label>
  </div>
);

SaveButtons.propTypes = {
  saveHrefForm: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired
};

export default SaveButtons;
