import _ from 'lodash';

/* eslint react/jsx-indent: 0 */
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import classNames from 'classnames';
import I18n from 'common/i18n';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.show_output_schema.ready_to_import' });

const makeErrorString = (start, end, errorRowCount) =>
  <span>
    {t(`help_modal.${start}`)}
    <span className="error-count">{` ${errorRowCount} `}</span>
    {t(`help_modal.${end}`)}
  </span>;

const WhyWontTheyImport = () =>
  <div id="why_wont_they_import" className="help">
    <p>
      {t('help_modal.we_love_your_data')}&nbsp;
      <span className="types-of-errors">{t('help_modal.two_types_of_errors')}</span>
    </p>

    <div className="kinds">
      <div className="type-errors">
        <h6>
          {t('help_modal.type_errors')}
        </h6>
        <img
          alt={t('help_modal.type_errors')}
          className="help_vis1a"
          src="/images/datasetManagementUI/help-visual-1a.png" />
        <p className="small">
          {t('help_modal.type_error_blurb')}
        </p>
      </div>
      <div className="row-errors">
        <h6>
          {t('help_modal.row_errors')}
        </h6>
        <img
          alt={t('help_modal.row_errors')}
          className="help_vis1b"
          src="/images/datasetManagementUI/help-visual-1b.png" />
        <p className="small">
          {t('help_modal.row_error_blurb')}
        </p>
      </div>
    </div>
  </div>;

const WhatCanIDoAboutIt = ({ errorRowCount }) =>
  <div id="what_can_i_do_about_it" className="help">
    <p>
      {t('help_modal.error_file_blurb')}
    </p>
    <h6>
      {makeErrorString('you_can_download_start', 'you_can_download_end', errorRowCount)}
    </h6>
    <img
      alt={t('help_modal.error_file_blurb')}
      className="help_vis2"
      src="/images/datasetManagementUI/help-visual-2.png" />
    <p className="small">
      {t('help_modal.error_file_sub_blurb')}
    </p>
  </div>;

WhatCanIDoAboutIt.propTypes = {
  errorRowCount: PropTypes.number.isRequired
};

const HowToGetRowsBackInDataset = () =>
  <div id="how_to_get_rows_back_in_dataset" className="help">
    <p>
      {t('help_modal.get_rows_into_dataset_blurb')}
    </p>
    <h6>
      {t('help_modal.clean_data_blurb')}
    </h6>
    <img
      alt={t('help_modal.get_rows_into_dataset_blurb')}
      className="help-vis3"
      src="/images/datasetManagementUI/help-visual-3.png" />
    <p className="small">
      {t('help_modal.clean_data_sub_blurb')}
    </p>
  </div>;

const pages = [WhatCanIDoAboutIt, WhyWontTheyImport, HowToGetRowsBackInDataset];

const getHeaderTitle = (idx, errorRowCount) => {
  switch (idx) {
    case 0:
      return makeErrorString('why_wont_start', 'why_wont_end', errorRowCount);
    case 1:
      return t('help_modal.error_file');
    case 2:
      return makeErrorString('clean_data_start', 'clean_data_end', errorRowCount);
    default:
      return '';
  }
};

const getContent = (idx, errorRowCount) => {
  switch (idx) {
    case 0:
      return <WhyWontTheyImport />;
    case 1:
      return <WhatCanIDoAboutIt errorRowCount={errorRowCount} />;
    case 2:
      return <HowToGetRowsBackInDataset />;
    default:
      return null;
  }
};

const Dots = ({ currentPageIdx, numOfTotalPages, setPage }) => {
  let dots = [];

  for (let idx = 0; idx < numOfTotalPages; idx += 1) {
    const dot = (
      <div
        key={idx}
        onClick={() => setPage(idx)}
        className={classNames('dot', { 'dot_selected': idx === currentPageIdx })}>
        &#x25CF;
      </div>
    );

    dots.push(dot);
  }

  return (
    <span>
      {dots}
    </span>
  );
};

Dots.propTypes = {
  currentPageIdx: PropTypes.number.isRequired,
  numOfTotalPages: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired
};

class ErrorsHelp extends Component {
  constructor() {
    super();

    this.state = {
      modalPage: 0
    };

    _.bindAll(this, ['nextPage', 'prevPage', 'setPage']);
  }

  setPage(pageNumber) {
    this.setState({
      modalPage: pageNumber
    });
  }

  nextPage() {
    this.setPage(this.state.modalPage + 1);
  }

  prevPage() {
    this.setPage(this.state.modalPage - 1);
  }

  render() {
    const { onDismiss, errorRowCount } = this.props;
    const { modalPage } = this.state;

    const headerProps = {
      title: getHeaderTitle(modalPage, errorRowCount),
      className: 'errors-help-header',
      onDismiss
    };

    const content = getContent(modalPage, errorRowCount);

    const buttonText = modalPage === pages.length - 1 ? t('help_modal.done') : t('help_modal.next');

    const buttonHandler = modalPage === pages.length - 1 ? onDismiss : this.nextPage;

    const previousButton =
      modalPage > 0
        ? <button onClick={this.prevPage} className="btn btn-default">
            {t('help_modal.previous')}
          </button>
        : null;

    return (
      <div id="dsmp-errors-help">
        <ModalHeader {...headerProps} />
        <ModalContent className="errors-help-content">
          {content}
        </ModalContent>
        <ModalFooter className="errors-help-footer">
          <Dots currentPageIdx={modalPage} numOfTotalPages={pages.length} setPage={this.setPage} />
          <div className="button_container">
            {previousButton}
            <button onClick={buttonHandler} className="btn btn-primary">
              {buttonText}
            </button>
          </div>
        </ModalFooter>
      </div>
    );
  }
}

ErrorsHelp.propTypes = {
  errorRowCount: PropTypes.number.isRequired,
  onDismiss: PropTypes.func
};

export default ErrorsHelp;
