import Button, { VARIANTS } from 'common/components/Button';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { FeatureFlags } from 'common/feature_flags';
import { commaify } from 'common/formatNumber';
import I18n from 'common/i18n';
import { InputSchema, OutputSchema } from 'common/types/dsmapiSchemas';
import { Source } from 'common/types/source';
import { ColumnLike } from 'datasetManagementUI/lib/columnLike';
import * as dsmapiLinks from 'datasetManagementUI/links/dsmapiLinks';
import PropTypes from 'prop-types';
import React from 'react';
import DSMUIIcon from '../DSMUIIcon';

const scope = 'dataset_management_ui.show_output_schema.ready_to_import';
const t = (k: string, p: Record<string, string | number> = {}) => I18n.t(k, { ...p, scope });
const ErrorButton = ({ disabled }: { disabled: boolean }) => (
  <Button className="footer-btn" disabled={disabled}>
    {I18n.t('dataset_management_ui.export_errors')} <SocrataIcon name={IconName.Download} />
  </Button>
);

ErrorButton.propTypes = {
  disabled: PropTypes.bool
};

interface Props {
  outputSchema: OutputSchema;
  errorRows: number;
  source: Source;
  importableRows: number;
  inputSchema: InputSchema;
  extraColumns: ColumnLike<never>[];
  openModal: (kind: string) => void;
  openExtraColumnsModal: () => void;
  openAutomationModal: () => void;
}

const ReadyToImport = (props: Props) => {
  const {
    source,
    inputSchema,
    importableRows,
    errorRows,
    outputSchema,
    extraColumns,
    openModal,
    openExtraColumnsModal,
    openAutomationModal
  } = props;

  if (!outputSchema) {
    return null;
  }

  let errorExportButton;

  if (outputSchema.error_count && outputSchema.error_count > 0) {
    const errorTableLink = dsmapiLinks.errorExport(source.id, inputSchema.id, outputSchema.id);

    errorExportButton = (
      <a href={errorTableLink}>
        <ErrorButton disabled={false} />
      </a>
    );
  } else {
    errorExportButton = <ErrorButton disabled />;
  }

  let automateButton;

  if (source.source_type.type === 'upload') {
    automateButton = (
      <button className="btn btn-default footer-btn" onClick={openAutomationModal}>
        {t('automate_this')}
      </button>
    );
  }

  const enabledExportButton = FeatureFlags.valueOrDefault('enable_valid_rows_export_button', false);

  return (
    <div id="ready-to-import">
      <p>
        {t('ready_to_import')} <span className="importable-rows">{commaify(importableRows)}</span> {t('rows')}
      </p>
      <p>
        {t('will_not_be_imported')}{' '}
        <span data-cheetah-hook="error-rows" className="error-rows">
          {commaify(errorRows)}
        </span>
      </p>
      {/* TODO: add flyout to help icon*/}
      <span
        className="help-modal-icon socrata-icon-question"
        id="row-error-help"
        onClick={() => openModal('ErrorsHelp')}
      />
      {automateButton}
      {errorExportButton}
      {enabledExportButton && (
        <a href={dsmapiLinks.rowsExport(source.id, inputSchema.id, outputSchema.id)}>
          <Button className="footer-btn" variant={VARIANTS.ALTERNATE_2}>
            {t('export_rows')}
          </Button>
        </a>
      )}
      {extraColumns && extraColumns.length > 0 && (
        <p>
          <DSMUIIcon className="warning-icon" name="warning" />
          <span className="extra-columns-warning">
            {t('extra_columns.ignored_columns', { count: extraColumns.length })}
          </span>
          <span
            className="help-modal-icon socrata-icon-question"
            id="extra-columns-help"
            onClick={openExtraColumnsModal}
          />
        </p>
      )}
    </div>
  );
};

export default ReadyToImport;
