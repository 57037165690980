import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';
import { Revision, isSoQLBasedRevision } from 'common/types/revision';
import { Source } from 'common/types/source';
import { View } from 'common/types/view';
import { isEditMode } from 'datasetManagementUI/lib/modes';
import { AppState, Params } from 'datasetManagementUI/lib/types';
import { InputSchema, OutputSchema } from 'common/types/dsmapiSchemas';
import * as Selectors from 'datasetManagementUI/selectors';
import _ from 'lodash';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  AddDataButton,
  EditColumnMetadataButton,
  EditDatasetMetadataButton,
  EditDerivedColumnMetadataButton,
  EditQueryButton,
  ReviewDataButton,
  reviewDataPath
} from '../DataActionsButton/DataActionsButton';
import * as ModalActions from 'datasetManagementUI/reduxStuff/actions/modal';


const t = (k: string) => I18n.t(k, { scope: 'common.data_actions' });

interface Props {
  isDisabled: boolean;
  icon: IconName;
  heading: string;
  subheading: string;
}

const DataActionsCard: FunctionComponent<Props> = ({ isDisabled, icon, heading, subheading, children }) => (
  <div className="data-actions-card">
    {isDisabled && <div className="data-actions-disabled-overlay">&nbsp;</div>}
    <div className="border-bar">
      <SocrataIcon name={icon} className="card-icon" />
    </div>
    <h3>{heading}</h3>
    {isDisabled ? null : <h4>{subheading}</h4>}
    <div className="data-actions-btn-container">{children}</div>
  </div>
);

interface CardProps {
  view: View;
  revision: Revision | undefined;
  inputSchema: InputSchema | null;
  outputSchema: OutputSchema | null;
  sources: Omit<Source, 'schemas'>[];
  blob: Omit<Source, 'schemas'> | null;
  blobId: number | null;
  params: Params;
  isBlobby: boolean;
  href: boolean;
  editing: boolean;
}

interface OwnProps {
  params: Params;
}
export function mapStateToProps(state: AppState, ownProps: OwnProps): CardProps {
  const { params } = ownProps;
  const { entities } = state;
  const revisionSeq = _.toNumber(params.revisionSeq);
  const sources = _.values(entities.sources) || [];
  const outputSchema = Selectors.currentOutputSchema(entities, revisionSeq);
  const inputSchema =
    (outputSchema && _.find(entities.input_schemas, { id: outputSchema.input_schema_id })) || null;
  const blob = Selectors.currentBlobSource(entities, revisionSeq);
  const blobId = (blob && blob.id) || null;
  const view = entities.views[params.fourfour];
  const isBlobby = blobId != null || view.viewType === 'blobby';
  const revision = Selectors.currentRevision(entities, revisionSeq);
  const href = !!revision?.href?.length || view.viewType === 'href';
  const editing = isEditMode(view);

  return {
    view,
    revision,
    sources,
    inputSchema,
    outputSchema,
    editing,
    blob,
    blobId,
    params,
    isBlobby,
    href
  };
}

interface DispatchProps {
  openAssetMetadataForm: (params: Params) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    openAssetMetadataForm: (params: Params) => dispatch(ModalActions.pushModal('ManageAssetMetadata', params))
  };
}

const EditColumnMetadataCard = ({ revision, outputSchema, params }: CardProps) => {
  if (!revision) return null;

  const isDisabled = isSoQLBasedRevision(revision) ? false : _.isNil(revision.output_schema_id);
  return (
    <DataActionsCard
      isDisabled={isDisabled}
      icon={IconName.Edit}
      heading={t('edit_col_metadata')}
      subheading={isDisabled ? '' : t('edit_col_metadata_subheading_two')}
    >
      {isSoQLBasedRevision(revision) ? (
        <EditDerivedColumnMetadataButton params={params} />
      ) : (
        <EditColumnMetadataButton isDisabled={isDisabled} outputSchema={outputSchema} params={params} />
      )}
    </DataActionsCard>
  );
};

const EditMetadataCard = ({ revision, params, openAssetMetadataForm }: CardProps & DispatchProps) => {
  if (!revision) return null;

  const isUSAID = revision.is_parent;
  return (
    <DataActionsCard
      isDisabled={false}
      icon={IconName.Edit}
      heading={isUSAID ? t('edit_metadata_usaid') : t('edit_metadata')}
      subheading={t('edit_metadata_subheading')}
    >
      <EditDatasetMetadataButton isDisabled={false} revision={revision} params={params} openAssetMetadataForm={() => openAssetMetadataForm(params)} />
    </DataActionsCard>
  );
};

const ReviewDataCard = (props: CardProps) => {
  const { inputSchema, outputSchema, blobId, params, isBlobby } = props;
  const isDisabled = !reviewDataPath(inputSchema, outputSchema, blobId, params, isBlobby);
  const review = <ReviewDataButton {...props} isDisabled={isDisabled} />;

  return Selectors.getArchivalSourceType(props.sources).match({
    some: (sourceType) => {
      let subheading = t('review_data_subheading_two');
      if (sourceType.status === 'queued') {
        subheading = t('review_data_archival_source_materializing');
      } else if (sourceType.status === 'materialized' && isDisabled) {
        subheading = t('review_data_archival_source_loading');
      }

      return (
        <DataActionsCard
          isDisabled={false}
          icon={isDisabled ? IconName.Pending : IconName.Data}
          heading={t('review_data')}
          subheading={subheading}
        >
          {isDisabled ? <span className="spinner-default" /> : review}
        </DataActionsCard>
      );
    },
    none: () => (
      <DataActionsCard
        isDisabled={isDisabled}
        icon={IconName.Data}
        heading={t('review_data')}
        subheading={t('review_data_subheading_two')}
      >
        {review}
      </DataActionsCard>
    )
  });
};

const AddDataCard = (props: CardProps) => {
  const { revision } = props;
  if (!revision) return null;
  const isDisabled = revision.is_parent || Selectors.getArchivalSourceType(props.sources).isDefined;
  return (
    <DataActionsCard
      isDisabled={isDisabled}
      icon={IconName.Add}
      heading={t('add_data')}
      subheading={isDisabled ? '' : t('add_data_subheading')}
    >
      <AddDataButton {...props} revision={revision} isDisabled={isDisabled} />
    </DataActionsCard>
  );
};

const EditSoQLQueryCard = ({ view, params }: CardProps) => {
  return (
    <DataActionsCard
      isDisabled={false}
      icon={IconName.Data}
      heading={t('edit_query')}
      subheading={t('edit_query_subheading')}
    >
      <EditQueryButton view={view} isDisabled={false} params={params} />
    </DataActionsCard>
  );
};

export default {
  EditColumnMetadataCard: withRouter(connect(mapStateToProps)(EditColumnMetadataCard)),
  ReviewDataCard: withRouter(connect(mapStateToProps)(ReviewDataCard)),
  AddDataCard: withRouter(connect(mapStateToProps)(AddDataCard)),
  EditMetadataCard: withRouter(connect(mapStateToProps, mapDispatchToProps)(EditMetadataCard)),
  EditSoQLQueryCard: withRouter(connect(mapStateToProps)(EditSoQLQueryCard))
};
