import React from 'react';
import PropTypes from 'prop-types';
import SourceTree from './SourceTree';
import { fetchTranslation } from 'common/locale';
const t = (k) => fetchTranslation(k, 'dataset_management_ui.connection_agent');


function Namespace({ isOnline, namespace, createSource, startSource, listSources }) {
  const path = [];
  const humanPath = [];

  let explorer;
  if (isOnline) {
    explorer = (
      <div className="namespace-explorer">
        <SourceTree
          path={path}
          humanPath={humanPath}
          name={namespace.name}
          listSources={listSources}
          createSource={createSource}
          startSource={startSource}
          topLevel={true}
          />
      </div>
    );
  } else {
    explorer = (
      <div className="namespace-explorer">
        <p className="offline-error">{t('agent_must_be_started')}</p>
      </div>
    );
  }

  return (
    <div className="namespace">
      {explorer}
    </div>
  );
}

Namespace.propTypes = {
  namespace: PropTypes.object.isRequired,
  isOnline: PropTypes.bool.isRequired,
  listSources: PropTypes.func.isRequired,
  createSource: PropTypes.func.isRequired,
  startSource: PropTypes.func.isRequired
};


export default Namespace;
