import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router';
import SchemaActions from 'datasetManagementUI/components/SchemaActions/SchemaActions';

const mapStateToProps = ({ entities }, { params }) => {
  const schemas = _.values(entities.output_schemas)
    .filter(os => os.input_schema_id === Number(params.inputSchemaId))
    .map(os => ({
      ...os,
      isCurrent: os.id === Number(params.outputSchemaId)
    }));

  return {
    oss: _.orderBy(schemas, 'finished_at', 'desc'),
    sources: entities.sources,
    iss: entities.input_schemas
  };
};

export default withRouter(connect(mapStateToProps)(SchemaActions));
