/* eslint react/sort-comp: 0 */
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { fetchTranslation } from 'common/locale';
import * as Rights from 'common/rights';
import { Agent, Namespace } from 'common/types/gateway';
import SourceMessage from 'datasetManagementUI/components/SourceMessage/SourceMessage';
import { CombinedProps } from 'datasetManagementUI/containers/AgentListContainer';
import * as _ from 'lodash';
import * as React from 'react';
import AgentRows from './AgentRows';
import Connections from './Connections';
const t = (k: string) => fetchTranslation(k, 'dataset_management_ui.connection_agent');

interface ListSearchProps {
  onChange: (val: string) => void;
  value: string;
}

function ListSearch({ onChange, value }: ListSearchProps) {
  return (
    <div className="list-search">
      <SocrataIcon className="search-icon" name={IconName.Search} />
      <input
        type="text"
        onChange={e => onChange(e.target.value)}
        value={value}
        name={t('search_your_plugins_or_agents')}
        aria-label={t('search_your_plugins_or_agents')}
        placeholder={t('search_your_plugins_or_agents')}
        className="text-input" />
    </div>
  );
}

function ErrorMessage() {
  return (
    <div className="alert error failed-plugins">
      <SocrataIcon name={IconName.Warning} />
      <span>{t('failed_plugins')}</span>
    </div>
  );
}

function LoadingSpinner({ what }: { what: string }) {
  return (
    <div className="loading-plugins">
      <p>{what}</p>
      <span className="spinner-default" />
    </div>
  );
}

interface StateProps {
  filterText: string;
}

class AgentList extends React.Component<CombinedProps, StateProps> {
  state = {
    filterText: ''
  };

  UNSAFE_componentWillMount() {
    if (this.props.plugins.length === 0 && this.props.isLoading) {
      this.props.getPlugins();
    }
  }

  setFilter = (v: string) => {
    this.setState({ filterText: v });
  };

  filterAgents = (agent: Agent) => {
    const ft = this.state.filterText.toLowerCase();
    if (ft === '' || _.includes(agent.name.toLowerCase(), ft)) {
      return true;
    }
    return agent.namespaces.some(this.filterPlugins);
  };

  filterPlugins = (namespace: Namespace) => {
    const ft = this.state.filterText.toLowerCase();
    return _.includes(namespace.name.toLowerCase(), ft) || _.includes(namespace.type.toLowerCase(), ft);
  };

  withAndWithoutPlugins = (agents: Agent[]) => {
    const withoutP: Agent[] = [];
    const withP: Agent[] = [];
    agents.forEach((agent) => {
      if (agent.namespaces.length !== 0) {
        withP.push(agent);
      } else {
        withoutP.push(agent);
      }
    });
    return { withP: withP, withoutP: withoutP };
  };

  renderLists = () => {
    const { agents, params, showPluginHelp, showAvailablePlugins, plugins, pluginError, isLoading } = this.props;

    let withPluginContent, withoutPluginContent = null;
    let withP: Agent[] = [];
    let withoutP: Agent[] = [];

    if (pluginError) {
      withPluginContent = [];
    } else if (isLoading) {
      withPluginContent = <LoadingSpinner what={t('loading_connections')} />;
    } else {
      const filteredAgents = _.filter(agents, this.filterAgents);
      const result = this.withAndWithoutPlugins(filteredAgents);
      withP = result.withP;
      withoutP = result.withoutP;
      withoutPluginContent = <AgentRows
        agents={withoutP}
        plugins={plugins}
        showAvailablePlugins={showAvailablePlugins}
        showPluginHelp={showPluginHelp}
        params={params} />;
      withPluginContent = <AgentRows
        agents={withP}
        plugins={plugins}
        showAvailablePlugins={showAvailablePlugins}
        showPluginHelp={showPluginHelp}
        params={params} />;
    }

    return (
      [<div className="plugins-by-agents" key="plugins-by-agents">
        <div className="list-controls">
          <ListSearch
            onChange={this.setFilter}
            value={this.state.filterText} />
        </div>
        { /* If there are agents without plugins */ }
        { withoutP.length !== 0 ? (<h3 className="agents-without-plugins-header">{t('agents_without_plugins')}</h3>) : null }
        { withoutP.length !== 0 ? withoutPluginContent : null }

        { /* If there are agents with plugins as well as agents without plugins, delineate them */ }
        { withoutP.length !== 0 && withP.length !== 0 ? (<hr></hr>) : null }

        { /* If there are agents with plugins */ }
        { withP.length != 0 ? withPluginContent : null }

        { /* If there are no agents */ }
        { withoutP.length === 0 && withP.length === 0 ?
          (<div><p>{t('no_agents')}</p></div>) :
          null
        }
      </div>]
    );
  };

  renderContent = (canAddAgents: boolean) => {
    const { agents } = this.props;
    if (Object.keys(agents).length > 0 || canAddAgents) {
      return this.renderLists();
    } else {
      return (
        [<div className="alert info" key="no-agents-alert">
          {t('no_gateway_setup')}
        </div>]
      );
    }
  };

  render() {
    const { hrefExists, showPluginHelp, showAvailablePlugins, showProvisionAgent, plugins } = this.props;
    if (hrefExists) {
      return <SourceMessage hrefExists={hrefExists} />;
    }

    const userRights = _.get(window, 'socrata.currentUser.rights');
    const canAddAgents = userRights.includes(Rights.MANAGE_GATEWAYS) ||
      userRights.includes(Rights.CREATE_GATEWAY_AGENTS);

    return (
      <Connections title={t('choose_namespace')} showAvailablePlugins={showAvailablePlugins} showPluginHelp={showPluginHelp} showProvisionAgent={showProvisionAgent} plugins={plugins} canProvisionAgent={canAddAgents}>
        {this.renderContent(canAddAgents)}
      </Connections>
    );
  }
}

export default AgentList;
