import Button, { VARIANTS } from 'common/components/Button';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';
import RecentActions from 'datasetManagementUI/containers/RecentActionsContainer';
import React from 'react';

function Sidebar({ close, title, children }: React.PropsWithChildren<{ close: () => void, title: string }>) {
  return (
    <div className="home-pane-sidebar recent-actions-container">
      <div className="header-cont">
        <h2>{title}</h2>
        <Button
          variant={VARIANTS.DEFAULT}
          transparent={true}
          className="dismiss"
          aria-label="Dismiss sidebar"
          onClick={close}>
          <SocrataIcon name={IconName.Close2} />
        </Button>
      </div>
      {children}
    </div>
  );
}

interface SidebarProps {
  close: () => void;
}

const RecentActionsSidebar: React.FunctionComponent<SidebarProps> = ({ close }) => (
  <Sidebar close={close} title={I18n.t('dataset_management_ui.home_pane.home_pane_sidebar.recent_actions')}>
    <RecentActions />
  </Sidebar>
);

export default RecentActionsSidebar;

