import PropTypes from 'prop-types';
import React from 'react';
import DSMUIIcon from '../DSMUIIcon';
import { STATUS_CALL_IN_PROGRESS, STATUS_CALL_SUCCEEDED } from 'datasetManagementUI/lib/apiCallStatus';
import I18n from 'common/i18n';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.home_pane' });

function NotifyButton({ apiCall, taskSet, addEmailInterest, className }) {
  if (taskSet) {
    let classNames;
    if (apiCall) {
      if (apiCall.status === STATUS_CALL_IN_PROGRESS) {
        classNames = ['btn', 'btn-primary', 'btn-busy', className].filter(c => c).join(' ');
        return (
          <button id="dsmui-notify-button" className={classNames}>
            <span className="spinner-default spinner-btn-default dsmui-notify-button-spinner" />
          </button>
        );
      } else if (apiCall.status === STATUS_CALL_SUCCEEDED) {
        classNames = ['btn', 'btn-success', className].filter(c => c).join(' ');
        return (
          <button id="dsmui-notify-button" className={classNames}>
            <DSMUIIcon name="checkmark3" />
            {t('email_me_success')}
          </button>
        );
      } else {
        classNames = ['btn', 'btn-error', className].filter(c => c).join(' ');
        return (
          <button id="dsmui-notify-button" className="">
            {t('email_me_error')}
          </button>
        );
      }
    } else {
      classNames = ['btn', 'btn-default', className].filter(c => c).join(' ');
      return (
        <button
          id="dsmui-notify-button"
          className={classNames} onClick={() => {
            addEmailInterest(taskSet.job_uuid);
          }}>
          <DSMUIIcon name="email" /> {t('email_me')}
        </button>
      );
    }
  } else {
    return null;
  }
}

NotifyButton.propTypes = {
  taskSet: PropTypes.shape({
    job_uuid: PropTypes.string
  }),
  apiCall: PropTypes.shape({
    status: PropTypes.string.isRequired
  }),
  addEmailInterest: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default NotifyButton;
