import PropTypes from 'prop-types';
import React from 'react';
import SchemaMismatch from 'datasetManagementUI/containers/SchemaMismatchContainer';

const SchemaMismatchPane = ({ params }) => (
  <div>
    <SchemaMismatch params={params} />
  </div>
);

SchemaMismatchPane.propTypes = {
  params: PropTypes.object.isRequired
};

export default SchemaMismatchPane;
