import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropdown from 'common/components/Dropdown';
import { ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-sh';
import 'ace-builds/src-noconflict/theme-monokai';

import CopyToClipboard from 'react-copy-to-clipboard';
import I18n from 'common/i18n';

const t = (k, scope = 'dataset_management_ui.automate_this') => I18n.t(k, { scope });

const defaultEditorProps = {
  width: '100%',
  theme: 'monokai',
  fontSize: 15,
  minLines: 2,
  maxLines: 30,
  showGutter: false,
  showPrintMargin: false,
  focus: true,
  readOnly: true
};


function uploadFunctionName(source) {
  const contentTypeFunctionNameMapping = [
    ['text/csv', 'csv'],
    ['application/vnd.ms-excel', 'xls'],
    ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'xlsx'],
    ['text/tab-separated-values', 'tsv'],
    ['application/zip', 'shapefile'],
    ['application/x-zip', 'shapefile'],
    ['application/vnd.google-earth.kml+xml', 'kml'],
    ['application/vnd.google-earth.kmz', 'kmz'],
    ['application/vnd.geo+json', 'geojson']
  ];

  for (const [mime, funcName] of contentTypeFunctionNameMapping) {
    if (source.content_type.indexOf(mime) > -1) {
      return funcName;
    }
  }
  return 'csv'; // idk - maybe they'll figure it out at this point
}


function UploadSourcePythonCode({ fourfour, source, importConfigName }) {
  const content = `from socrata.authorization import Authorization
from socrata import Socrata
import os
import sys

auth = Authorization(
  '${window.location.host}',
  os.environ['MY_SOCRATA_USERNAME'],
  os.environ['MY_SOCRATA_PASSWORD']
)

socrata = Socrata(auth)
view = socrata.views.lookup('${fourfour}')

with open('${source.source_type.filename}', 'rb') as my_file:
  (revision, job) = socrata.using_config('${importConfigName}', view).${uploadFunctionName(source)}(my_file)
  # These next 2 lines are optional - once the job is started from the previous line, the
  # script can exit; these next lines just block until the job completes
  job = job.wait_for_finish(progress = lambda job: print('Job progress:', job.attributes['status']))
  sys.exit(0 if job.attributes['status'] == 'successful' else 1)
`;

  return (<div className="code-window">
    <CopyToClipboard text={content}>
      <button className="btn btn-primary btn-xs copy-btn">
        {t('copy_code')}
      </button>
    </CopyToClipboard>
    <AceEditor {...defaultEditorProps} name="automate-this-python-editor" mode="python" readOnly={true} value={content} />
  </div>);
}

UploadSourcePythonCode.propTypes = {
  outputSchemaId: PropTypes.number.isRequired,
  source: PropTypes.object.isRequired,
  fourfour: PropTypes.string.isRequired,
  importConfigName: PropTypes.string.isRequired
};


function ConfigError({ error }) {
  return (
    <div className="alert error config-error">{error}</div>
  );
}
ConfigError.propTypes = {
  error: PropTypes.string.isRequired
};

function ConfigLoading() {
  return (
    <div className="append-or-replace-selection">
      <div className="spinner-default spinner-large" />
    </div>
  );
}

ConfigLoading.propTypes = {
};

function ImportConfigSteps({ fourfour, source, importConfig, outputSchemaId }) {
  return (
    <ol>
      <li>
        {t('install_socrata_py')}
        <div className="code-window">
          <AceEditor {...defaultEditorProps} name="automate-pip-install-editor" mode="sh" readOnly={true} value="pip install socrata-py~=1.1.0" />
        </div>
        {t('local_py_env')}
      </li>
      <li>
        {t('containing_this_code')}
        <UploadSourcePythonCode
          fourfour={fourfour}
          source={source}
          outputSchemaId={outputSchemaId}
          importConfigName={importConfig.name} />
      </li>
      <li>
        {t('use_a_task_scheduler')}
        <div className="code-window">
          <AceEditor {...defaultEditorProps} name="automate-this-run-editor" mode="sh" readOnly={true} value="python3 my-update-script.py" />
        </div>
      </li>
      <li>
        <a target="_blank" href="/admin/activity_feed">{t('activity_log')}</a>
      </li>
    </ol>
  );
}

ImportConfigSteps.propTypes = {
  outputSchemaId: PropTypes.number.isRequired,
  source: PropTypes.object.isRequired,
  fourfour: PropTypes.string.isRequired,
  importConfig: PropTypes.object.isRequired
};

function DataActionChooser({ importConfig, onChooseDataAction }) {
  const dropdownProps = {
    value: importConfig ? importConfig.data_action : null,
    placeholder: t('choose_update_replace'),
    onSelection: (chosen) => onChooseDataAction(chosen.value),
    options: [
      {
        title: t('update'),
        value: 'update'
      },
      {
        title: t('replace'),
        value: 'replace'
      }
    ]
  };
  return (<div className="append-or-replace-selection">
    <Dropdown {...dropdownProps} />
    <p className="small append-update-explanation">
      {t('append_vs_update')}
    </p>
  </div>);
}

DataActionChooser.propTypes = {
  importConfig: PropTypes.object,
  onChooseDataAction: PropTypes.func.isRequired
};

class SetupAutomation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      importConfig: null
    };
    this.onChooseDataAction = this.onChooseDataAction.bind(this);
  }

  onChooseDataAction(action) {
    this.setState({
      loading: true,
      error: false
    });
    this.props.createImportConfig(this.props.source, this.props.outputSchemaId, action)
      .then((importConfig) => {
        this.setState({
          loading: false,
          importConfig
        });
      })
      .catch(({ body }) => {
        this.setState({
          loading: false,
          error: body.message
        });
      });
  }

  render() {
    const { importConfig, loading, error } = this.state;
    const { fourfour, source, outputSchemaId, onDismiss } = this.props;

    const headerProps = {
      title: t('title'),
      onDismiss
    };

    let content;
    if (error) {
      content = (<ConfigError error={error} />);
    } else if (loading) {
      content = (<ConfigLoading />);
    } else if (importConfig) {
      content = (<ImportConfigSteps
        fourfour={fourfour}
        source={source}
        importConfig={importConfig}
        outputSchemaId={outputSchemaId} />);
    } else {
      content = (<DataActionChooser
        importConfig={importConfig}
        onChooseDataAction={this.onChooseDataAction} />
      );
    }

    return (
      <div id="setup-automation">
        <ModalHeader {...headerProps} />
        <ModalContent>
          <p style={{ marginTop: 10 }}>
            {t('description')}
          </p>
          {content}
        </ModalContent>
        <ModalFooter>
          <button
            className="btn btn-primary"
            onClick={onDismiss}>
            {t('done', 'dataset_management_ui.common')}
          </button>
        </ModalFooter>
      </div>
    );
  }
}

SetupAutomation.propTypes = {
  fourfour: PropTypes.string.isRequired,
  outputSchemaId: PropTypes.number.isRequired,
  source: PropTypes.object.isRequired,
  onDismiss: PropTypes.func.isRequired,
  createImportConfig: PropTypes.func.isRequired
};

export default SetupAutomation;
