/*
  This has become a mess. I promise to un-mess it when we remove
  the metadata templates feature flag. A lot of the messiness
  is due to doing validation in two places (frontend JS and via websocket).

*/

// TODO: refactor these imports from components into common
import { PhxChannel } from 'common/types/dsmapi';
import { MetadataTemplate } from 'common/types/metadataTemplate';
import { Revision } from 'common/types/revision';
import { Dispatch } from 'datasetManagementUI/lib/types';
import * as FormActions from 'datasetManagementUI/reduxStuff/actions/forms';
import { FeatureFlags } from 'common/feature_flags';
import { evaluateMetadata } from 'common/dsmapi/ManageMetadataComponent';

export const METADATA_CHANNEL_JOINED = 'METADATA_CHANNEL_JOINED';

export const onMetadataChannelJoined =
  (channel: PhxChannel, revision: Revision, templates: MetadataTemplate[]) => async (dispatch: Dispatch) => {
    if (!FeatureFlags.value('enable_metadata_templates')) return;

    dispatch({
      type: METADATA_CHANNEL_JOINED,
      channel,
      templates
    });

    try {
      const initialEvaluation = await evaluateMetadata(channel, {
        templates: templates.filter((t) => t.is_required).map((t) => t.name),
        inputs: revision.metadata.inputs || [],
        metadata: revision.metadata
      });
      if (initialEvaluation.errors.length > 0) {
        dispatch(FormActions.setHasMetadataErrors(true));
      }
    } catch (e) {
      console.error(e);
    }
  };
