import { DsmapiResource } from 'common/types/dsmapi';
import ImportConfig from 'common/types/importConfig';
import { socrataFetch, checkStatus, getJson } from 'datasetManagementUI/lib/http';
import { Dispatch, Params } from 'datasetManagementUI/lib/types';
import * as dsmapiLinks from 'datasetManagementUI/links/dsmapiLinks';

export const SET_CONFIG = 'SET_CONFIG';

export function getImportConfig(params: Params): (d: Dispatch) => Promise<ImportConfig> {
  return dispatch => {
    return socrataFetch(dsmapiLinks.config(params))
    .then(checkStatus)
    .then(getJson)
    .then((body: DsmapiResource<ImportConfig>) => dispatch({
      type: SET_CONFIG,
      id: params.fourfour,
      config: body.resource
    }));
  };
}
