import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { commaify } from 'common/formatNumber';
import formatString from 'common/js_utils/formatString';
import { Link, withRouter } from 'react-router';
import * as Links from 'datasetManagementUI/links/links';
import * as DisplayState from 'datasetManagementUI/lib/displayState';
import { singularOrPlural } from 'datasetManagementUI/lib/util';
import { attachTo } from 'common/components/legacy';
import SocrataIcon from 'common/components/SocrataIcon';
import I18n from 'common/i18n';

const t = (k, scope = 'dataset_management_ui.show_output_schema.row_errors') => I18n.t(k, { scope });
const FLYOUT_ID = 'malformed-rows-flyout';

function ErrorFlyout({ numRowErrors }) {
  const message = singularOrPlural(
    numRowErrors,
    t('flyout_message_singular'),
    formatString(t('flyout_message_plural'), commaify(numRowErrors))
  );
  return (
    <div id={FLYOUT_ID} className="malformed-rows-flyout flyout flyout-hidden">
      <button className="flyout-close">
        <SocrataIcon name="close-2" />
      </button>
      <section className="flyout-content">
        {message} {t('flyout_message_explanation')}
        <br />
        <span className="click-to-view" tabIndex="0">
          {t('click_to_view', 'dataset_management_ui.show_output_schema')}
        </span>
      </section>
    </div>
  );
}

ErrorFlyout.propTypes = {
  numRowErrors: PropTypes.number.isRequired
};

class RowErrorsLink extends Component {
  componentDidMount() {
    this.attachFlyouts();
  }

  shouldComponentUpdate(nextProps) {
    return !_.isEqual(nextProps, this.props);
  }

  componentDidUpdate() {
    this.attachFlyouts();
  }

  attachFlyouts() {
    attachTo(this.flyoutParentEl);
  }

  render() {
    const { params, displayState, numRowErrors, inRowErrorMode } = this.props;
    const inRowErrorState = displayState.type === DisplayState.ROW_ERRORS;
    const linkPath =
      inRowErrorState
      ? Links.showOutputSchema(params, params.sourceId, params.inputSchemaId, params.outputSchemaId)
      : Links.showRowErrors(params, params.sourceId, params.inputSchemaId, params.outputSchemaId);

    return (
      <tr id="row-errors-count">
        <th
          className={inRowErrorMode ? 'row-errors-count-selected' : ''}>
          <div
            ref={flyoutParentEl => {
              this.flyoutParentEl = flyoutParentEl;
            }}>
            <Link to={linkPath} data-flyout-close-button="true" data-flyout={FLYOUT_ID}>
              <div
                className="malformed-rows-status-text">
                <span className="error error-info">{commaify(numRowErrors)}</span>
                {singularOrPlural(numRowErrors, t('malformed_row'), t('malformed_rows'))}
              </div>
              <ErrorFlyout numRowErrors={numRowErrors} />
            </Link>
          </div>
        </th>
      </tr>
    );
  }
}

RowErrorsLink.propTypes = {
  params: PropTypes.object.isRequired,
  displayState: DisplayState.propType.isRequired,
  numRowErrors: PropTypes.number.isRequired,
  inRowErrorMode: PropTypes.bool.isRequired
};

export default withRouter(RowErrorsLink);
