import {
  InputSchemaId,
  OutputColumnId,
  OutputSchemaId,
  SourceId,
  TransformId
} from 'common/types/dsmapiSchemas';
import { Params } from 'datasetManagementUI/lib/types';
import _ from 'lodash';
import { browserHistory } from 'react-router';

const base = (params: Params) => {
  let prefix = '';

  if (params.locale) {
    prefix = `/${params.locale}`;
  }

  return `${prefix}/${params.category}/${params.name}/${params.fourfour}`;
};

export const revisionBase = (params: Params) => `${base(params)}/revisions/${params.revisionSeq}`;

export const editSoQL = (params: Params) => `${revisionBase(params)}/soql`;

export const home = revisionBase;

export const manageTab = (params: Params) => `${revisionBase(params)}/manageTab`;

export const metadata = (params: Params) => `${revisionBase(params)}/metadata`;

export const datasetMetadataForm = (params: Params) => `${revisionBase(params)}/metadata/dataset`;

// TODO: refine string metadataType here
export type ColumnMetadataType = 'fieldName' | 'name' | 'description';
export const columnMetadataForm = (
  params: Params,
  outputSchemaId: OutputSchemaId,
  columnId?: OutputColumnId,
  metadataType?: ColumnMetadataType
) => {
  if (columnId && metadataType) {
    return `${revisionBase(params)}/metadata/${outputSchemaId}/columns#${columnId},${metadataType}`;
  } else {
    return `${revisionBase(params)}/metadata/${outputSchemaId}/columns${columnId ? `#${columnId}` : ''}`;
  }
};

export const derivedColumnMetadataForm = (params: Params, columnIdOrFieldName?: OutputColumnId | string, metadataType?: ColumnMetadataType) => {
  if (columnIdOrFieldName && metadataType) {
    return `${revisionBase(params)}/metadata/columns#${columnIdOrFieldName},${metadataType}`;
  } else {
    return `${revisionBase(params)}/metadata/columns${columnIdOrFieldName ? `#${columnIdOrFieldName}` : ''}`;
  }
};

export const sources = (params: Params) => `${revisionBase(params)}/sources`;

export const urlSource = (params: Params) => `${sources(params)}/url`;

export const agentsSource = (params: Params) => `${sources(params)}/agent`;

export const agentNamespace = (params: Params, agentUid: string, nsType: string, nsName: string) => {
  return `${agentsSource(params)}/${agentUid}/${nsType}/${nsName}`;
};

export const hrefSource = (params: Params) => `${sources(params)}/href`;

const transformColumnFragment = (params: Params) => `/editor/${params.outputColumnId}`;

export const showOutputSchema = (
  params: Params,
  sourceId: SourceId,
  inputSchemaId: InputSchemaId,
  outputSchemaId: OutputSchemaId,
  pageNo?: number
) => {
  const showBase =
    `${revisionBase(params)}/sources/${sourceId}/schemas/${inputSchemaId}` + `/output/${outputSchemaId}`;
  const page = `${pageNo ? `/page/${pageNo}` : ''}`;

  if (params.transformEditor) {
    return `${showBase}${transformColumnFragment(params)}${page}`;
  } else {
    return `${showBase}${page}`;
  }
};

// Replaces the output schema ID in the current url path.
// Returns `null` if no output schema ID is found in URL or if there is no change
export const changeOutputSchema = (oldOutputSchemaId: OutputSchemaId, newOutputSchemaId: OutputSchemaId) => {
  if (!oldOutputSchemaId || !newOutputSchemaId || oldOutputSchemaId === newOutputSchemaId) {
    return null;
  }
  const { pathname } = browserHistory.getCurrentLocation();
  const schemaIdRegexp = new RegExp(`/output/${oldOutputSchemaId}`);
  if (schemaIdRegexp.test(pathname)) {
    return pathname.replace(schemaIdRegexp, `/output/${newOutputSchemaId}`);
  }

  return null;
};

export const showBlobPreview = (params: Params, blobId?: string | number | null) => {
  if (blobId) {
    return `${revisionBase(params)}/sources/${blobId}/preview`;
  } else {
    return `${revisionBase(params)}/sources/preview`;
  }
};

export const showParseOptions = (params: Params) => {
  const { sourceId, inputSchemaId, outputSchemaId } = params;
  return (
    `${revisionBase(params)}/sources/${sourceId}/schemas/${inputSchemaId}` +
    `/output/${outputSchemaId}/parse_options`
  );
};

export const geocodeShortcut = (params: Params, pageNo?: number) => {
  const { sourceId, inputSchemaId, outputSchemaId, outputColumnId } = params;
  const os = showOutputSchema(
    params,
    _.toNumber(sourceId!),
    _.toNumber(inputSchemaId),
    _.toNumber(outputSchemaId)
  );
  const page = `${pageNo ? `/page/${pageNo}` : ''}`;
  if (_.isUndefined(outputColumnId)) {
    return `${os}/georeference${page}`;
  } else {
    return `${os}/edit_georeference/${outputColumnId}${page}`;
  }
};

export const geocodeShortcutErrors = (params: Params, transformId: TransformId, pageNo?: number) => {
  const page = `${pageNo ? `/page/${pageNo}` : ''}`;
  return `${geocodeShortcut(params)}/column_errors/${transformId}${page}`;
};

export const showAddCol = (params: Params) => {
  const { sourceId, inputSchemaId, outputSchemaId } = params;
  return (
    `${revisionBase(params)}/sources/${sourceId}/schemas/${inputSchemaId}` +
    `/output/${outputSchemaId}/add_col`
  );
};

export const showSchemaMismatch = (params: Params) => {
  const { sourceId, inputSchemaId, outputSchemaId } = params;
  return (
    `${revisionBase(params)}/sources/${sourceId}/schemas/${inputSchemaId}` +
    `/output/${outputSchemaId}/mismatch`
  );
};

export const geocodeColumn = (params: Params) => {
  return (
    `${revisionBase(params)}/sources/${params.sourceId}/schemas/${params.inputSchemaId}` +
    `/output/${params.outputSchemaId}/georeference`
  );
};

export const transformColumn = (
  params: Params,
  sourceId: SourceId,
  inputSchemaId: InputSchemaId,
  outputSchemaId: OutputSchemaId,
  outputColumnId: OutputColumnId
) => {
  return (
    `${revisionBase(params)}/sources/${sourceId}/schemas/${inputSchemaId}` +
    `/output/${outputSchemaId}/editor/${outputColumnId}`
  );
};

export const sessionStatus = '/logout/expire_if_idle';

export const transformColumnErrors = (
  params: Params,
  sourceId: SourceId,
  inputSchemaId: InputSchemaId,
  outputSchemaId: OutputSchemaId,
  outputColumnId: OutputColumnId,
  transformId: TransformId
) => {
  return `${transformColumn(
    params,
    sourceId,
    inputSchemaId,
    outputSchemaId,
    outputColumnId
  )}/column_errors/${transformId}`;
};

export const showColumnErrors = (
  params: Params,
  sourceId: SourceId,
  inputSchemaId: InputSchemaId,
  outputSchemaId: OutputSchemaId,
  errorsTransformId: TransformId,
  pageNo?: number
) => {
  const showBase =
    `${revisionBase(params)}/sources/${sourceId}/schemas/${inputSchemaId}/output/` + `${outputSchemaId}`;
  const columnErrors = `/column_errors/${errorsTransformId}`;
  const page = `${pageNo ? `/page/${pageNo}` : ''}`;

  if (params.transformEditor) {
    return `${showBase}${transformColumnFragment(params)}${columnErrors}${page}`;
  }
  return `${showBase}${columnErrors}${page}`;
};

export const showRowErrors = (
  params: Params,
  sourceId: SourceId,
  inputSchemaId: InputSchemaId,
  outputSchemaId: OutputSchemaId,
  pageNo?: number
) =>
  `${revisionBase(params)}/sources/${sourceId}/schemas/${inputSchemaId}/output/` +
  `${outputSchemaId}/row_errors` +
  `${pageNo ? `/page/${pageNo}` : ''}`;

export const gridview = (params: Params) => `/d/${params.fourfour}/data`;
