import PropTypes from 'prop-types';
import React from 'react';

import { ModalContent, ModalFooter } from 'common/components/Modal';

import DSMUIIcon from '../DSMUIIcon';
import I18n from 'common/i18n';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.home_pane.error_modal' });

class ErrorModal extends React.Component {

  render() {
    const {
      fourfour
    } = this.props;

    let title;
    let body;
    let icon;
    let button;

    title = t('title');
    body = t('body');
    icon = <DSMUIIcon className="dsmui-failure-icon" name="close-circle" />;
    button = (
      <a href={`/d/${fourfour}`} className="dsmui-error-modal-button btn btn-primary">
        {t('to_primer')}
      </a>
    );

    return (
      <div id="dsmui-error-modal">
        <h2>
          {title}
        </h2>
        <ModalContent>
          <p>
            {body}
          </p>
          {icon}
        </ModalContent>
        <ModalFooter className="dsmui-error-modal-footer">
          {button}
        </ModalFooter>
      </div>
    );
  }
}

ErrorModal.propTypes = {
  fourfour: PropTypes.string.isRequired
};

export default ErrorModal;
