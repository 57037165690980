import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { fetchTranslation } from 'common/locale';
import * as Rights from 'common/rights';
import { Agent, Namespace, Plugin } from 'common/types/gateway';
import { Params } from 'datasetManagementUI/lib/types';
import * as Links from 'datasetManagementUI/links/links';
import get from 'lodash/get';
import * as React from 'react';
import { browserHistory } from 'react-router';
import Tile from './Tile';

const t = (k: string) => fetchTranslation(k, 'dataset_management_ui.agent_row');

interface Tile {
  plugin: Plugin | undefined;
  namespace: Namespace;
}

interface TilesProps {
  tiles: Tile[];
  agent: Agent;
  params: Params;
}

function Tiles({ tiles, agent, params }: TilesProps) {
  return (
    <div className="tiles">
      {tiles.length === 0 ? (
        <div>
          <p>{t('no_plugins')}</p>
        </div>
      ) : null}

      {tiles.map(({ namespace, plugin }) => (
        <Tile
          key={`${agent.agent_uid}-${namespace.name}-${namespace.type}`}
          namespace={namespace}
          plugin={plugin}
          expand={() =>
            browserHistory.push(Links.agentNamespace(params, agent.agent_uid, namespace.type, namespace.name))
          }
        />
      ))}
    </div>
  );
}

interface AddPluginButtonProps {
  canAddPlugins: boolean;
  showAvailPlugins: () => void;
}

function AddPluginButton({ canAddPlugins, showAvailPlugins }: AddPluginButtonProps) {
  if (canAddPlugins) {
    return (
      <a className="add-plugin" onClick={showAvailPlugins}>
        <SocrataIcon name={IconName.Add} />
        <span>{t('add_plugin')}</span>
      </a>
    );
  } else {
    return null;
  }
}

interface AgentRowProps {
  agent: Agent;
  plugins: Plugin[];
  params: Params;
  /* eslint @typescript-eslint/ban-types: "warn" */
  showAvailablePlugins: Function;
  /* eslint @typescript-eslint/ban-types: "warn" */
  showPluginHelp: Function;
}

interface AgentRowStateProps {
  expanded: boolean;
}

class AgentRow extends React.Component<AgentRowProps, AgentRowStateProps> {
  state = {
    expanded: false
  };

  pairNamespaceAndPlugin = (namespace: Namespace): Tile => {
    const plugin = this.props.plugins.find((p) => p.type == namespace.type);
    return { namespace: namespace, plugin: plugin };
  };

  toggleExpand = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  sortNamespaces = (namespaces: Namespace[]): Namespace[] => {
    return namespaces.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  };

  userCanAddPlugins = (): boolean => {
    const { agent } = this.props;
    const currentUser = get(window, 'socrata.currentUser');
    const canManageGateways = get(currentUser, 'rights').includes(Rights.MANAGE_GATEWAYS);
    const isAgentOwner = get(currentUser, 'id') === agent.owned_by.user_id;
    return canManageGateways || isAgentOwner;
  };

  render() {
    const { agent, plugins, showAvailablePlugins, showPluginHelp, params } = this.props;
    const tiles = this.sortNamespaces(agent.namespaces).map(this.pairNamespaceAndPlugin);
    const showAvailPlugins = () => showAvailablePlugins(showPluginHelp, plugins, true);

    let klass = '';
    let status = '';
    if (agent.went_online_at) {
      klass = agent.namespaces.length !== 0 ? 'online' : 'empty';
      status = t('online');
    } else {
      klass = agent.namespaces.length !== 0 ? 'offline' : 'empty';
      status = t('offline');
    }

    const arrow = this.state.expanded ? IconName.ArrowUp : IconName.ArrowDown;

    return (
      <div className={`agent-row agent-row-${klass}`}>
        <div className="header">
          <div className="col-left">
            <a className="toggle-column" onClick={() => this.toggleExpand()}>
              <SocrataIcon name={arrow} />
              <div className="name-column">
                {t('agent')}: <strong>{agent.name}</strong>
              </div>
            </a>
          </div>

          <div className="col-right">
            <AddPluginButton canAddPlugins={this.userCanAddPlugins()} showAvailPlugins={showAvailPlugins} />
            <div className="plugin-count-column">{String(agent.namespaces.length) + ' ' + t('plugins')}</div>
            <div className="status-column">
              {t('status')}: <span className={`agent-status-${klass}`}>{status}</span>
            </div>
          </div>
        </div>
        {this.state.expanded ? <Tiles agent={agent} tiles={tiles} params={params} /> : null}
      </div>
    );
  }
}

export default AgentRow;
