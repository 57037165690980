import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as Links from 'datasetManagementUI/links/links';
import { TimelineTimestamp } from 'common/components/AssetTimeline/TimelineItem';
import { Link } from 'react-router';
import SocrataIcon from 'common/components/SocrataIcon';
import I18n from 'common/i18n';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.schema_actions' });

const undoDisabled = (oss) => {
  const current = _.find(oss, os => os.isCurrent);
  const first = _.minBy(oss, os => os.id);
  return current && first && current.id === first.id;
};

const redoDisabled = (oss) => {
  const current = _.find(oss, os => os.isCurrent);
  const latest = _.maxBy(oss, os => os.id);
  return current && latest && current.id === latest.id;
};

const previousOs = (oss) => {
  const sorted = _.sortBy(oss, os => os.id);
  const index = _.findIndex(sorted, os => os.isCurrent);
  return sorted[Math.max(0, index - 1)];
};

const nextOs = (oss) => {
  const sorted = _.sortBy(oss, os => os.id);
  const index = _.findIndex(sorted, os => os.isCurrent);
  return sorted[Math.min(oss.length - 1, index + 1)];
};

const SchemaActions = ({ oss, iss, sources, params }) => {
  function getFallbackDisplayName(sourceId) {
    return _.get(sources, `${sourceId}.created_by.display_name`, '');
  }

  const to = (os) => Links.showOutputSchema(
    params,
    iss[os.input_schema_id].source_id,
    os.input_schema_id,
    os.id
  );

  const items = oss.map((os, idx) => (
    <li key={idx} className={os.isCurrent ? 'schema current-schema' : 'schema'}>
      {_.get(os, 'created_by.display_name', getFallbackDisplayName(iss[os.input_schema_id].source_id))}{' '}
      {t('schema_changed')}
      <br />
      <TimelineTimestamp date={os.created_at} />
      {os.isCurrent || ' - '}
      {os.isCurrent || (
        <Link
          className="restore"
          to={to(os)}>
          {t('restore')}
        </Link>
      )}
    </li>
  ));
  return (
    <ul id="dsmui-schema-actions" className="small">
      <li className="undo-redo">
        <Link
          className={undoDisabled(oss) ? 'disabled' : ''}
          aria-label="Undo"
          onClick={(e) => undoDisabled(oss) && e.preventDefault()}
          to={to(previousOs(oss))}>
          <SocrataIcon name="undo" />
          <span className="undo-redo-text">{t('undo')}</span>
        </Link>
        <Link
          className={redoDisabled(oss) ? 'disabled' : ''}
          aria-label="Redo"
          onClick={(e) => redoDisabled(oss) && e.preventDefault()}
          to={to(nextOs(oss))}>
          <SocrataIcon name="redo" />
          <span className="undo-redo-text">{t('redo')}</span>
        </Link>
      </li>
      {items}
    </ul>
  );
};

SchemaActions.propTypes = {
  oss: PropTypes.array.isRequired,
  iss: PropTypes.object.isRequired,
  sources: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired
};

export default SchemaActions;
