import { fetchTranslation } from 'common/locale';
import { DsmapiResource } from 'common/types/dsmapi';
import { STATUS_CALL_FAILED } from 'datasetManagementUI/lib/apiCallStatus';
import { showFlashMessage, hideFlashMessage } from 'datasetManagementUI/reduxStuff/actions/flashMessage';
import * as DisplayState from 'datasetManagementUI/lib/displayState';
import { checkStatus, getJson, socrataFetch } from 'datasetManagementUI/lib/http';
import { ApiCall, Dispatch, Entities, GetState } from 'datasetManagementUI/lib/types';
import { InputSchemaId, OutputColumn, SourceId, TransformId, WithTransform, ErrorIndices } from 'common/types/dsmapiSchemas';
import * as dsmapiLinks from 'datasetManagementUI/links/dsmapiLinks';
import {
  apiCallStarted,
  apiCallSucceeded,
  apiCallFailed,
  LOAD_ROWS,
  LOAD_DATA
} from 'datasetManagementUI/reduxStuff/actions/apiCalls';
import * as Selectors from 'datasetManagementUI/selectors';
import _ from 'lodash';
import uuid from 'uuid';
import { failedToCompile, sleep } from 'datasetManagementUI/lib/util';

const t = (k: string) => fetchTranslation(k, 'dataset_management_ui.data_preview.load_error');


export const PAGE_SIZE = 50;
export const LOAD_ROW_ERRORS_SUCCESS = 'LOAD_ROW_ERRORS_SUCCESS';
export const LOAD_COLUMN_ERRORS_SUCCESS = 'LOAD_COLUMN_ERRORS_SUCCESS';
export const LOAD_NORMAL_PREVIEW_SUCCESS = 'LOAD_NORMAL_PREVIEW_SUCCESS';
export const CELL_UPDATED = 'CELL_UPDATED';

export interface LoadNormalPreviewSuccess {
  type: 'LOAD_NORMAL_PREVIEW_SUCCESS';
  colData: ColumnizedCells;
  rowErrors: RowErrors;
}
function loadNormalPreviewSuccess(colData: ColumnizedCells, rowErrors: RowErrors): LoadNormalPreviewSuccess {
  return {
    type: LOAD_NORMAL_PREVIEW_SUCCESS,
    colData,
    rowErrors
  };
}

export interface CellUpdated {
  type: 'CELL_UPDATED';
  transformId: TransformId;
  offset: number;
  value: any; // TODO: refine
}
export function cellUpdated(transformId: TransformId, offset: number, value: any): CellUpdated {
  return {
    type: CELL_UPDATED,
    transformId,
    offset,
    value
  };
}

type TransformErrorIndices = {
  [tid: number]: ErrorIndices;
};

export interface LoadColumnErrorsSuccess {
  type: 'LOAD_COLUMN_ERRORS_SUCCESS';
  colData: ColumnizedCells;
  transformErrorIndices: TransformErrorIndices;
}
function loadColumnErrorsSuccess(colData: ColumnizedCells, transformErrorIndices: TransformErrorIndices): LoadColumnErrorsSuccess {
  return {
    type: LOAD_COLUMN_ERRORS_SUCCESS,
    colData,
    transformErrorIndices
  };
}

export interface LoadRowErrorsSuccess {
  type: 'LOAD_ROW_ERRORS_SUCCESS';
  rowErrors: RowErrors;
}
function loadRowErrorsSuccess(rowErrors: RowErrors): LoadRowErrorsSuccess {
  return {
    type: LOAD_ROW_ERRORS_SUCCESS,
    rowErrors
  };
}


export type LoadDataEvent =
  LoadRowErrorsSuccess |
  LoadColumnErrorsSuccess |
  CellUpdated |
  LoadNormalPreviewSuccess;


function getPreviousApiCalls(
  columns: (OutputColumn & WithTransform)[],
  apiCalls: ApiCall[],
  displayState: DisplayState.DisplayState): ApiCall[] {
  return _.filter(apiCalls, call => {
    return _.isEqual(call.callParams && call.callParams.displayState, displayState) &&
      _.isEqual(
        call.callParams && (call.callParams.columnIds || []),
        (columns || []).map(c => c.id)
      );
  });
}

// In cases where an api call has failed, we do want to retry, but we should
// wait a bit before doing so. Add more time if there are more past failures
// since that implies a problem with DSMAPI rather than a network blip.
function getTimeoutLength(
  apiCalls: ApiCall[],
  displayState: DisplayState.DisplayState,
  columns: (OutputColumn & WithTransform)[]): number {
  const failedApiCalls = _.filter(apiCalls, call => call.status === STATUS_CALL_FAILED);
  const previousFailedCalls = getPreviousApiCalls(columns, failedApiCalls, displayState);
  return 100 * previousFailedCalls.length;
}

// TODO: unshit this file

// only exported for tests...
export function needToLoadAnything(
  entities: Entities,
  apiCalls: ApiCall[],
  displayState: DisplayState.DisplayState,
  columns: (OutputColumn & WithTransform)[]) {
  // if anything failed, dsmapi will give a 400, so bail out in that case
  if (_.some(columns, oc => oc.transform.failed_at)) {
    return false;
  }
  // don't want to load if there's any matching api call --
  // succeeded or in progress. We DO want to load if there
  // is an existing api call that has failed. If we made a call
  // for rows to display, and that call fails due to a network
  // blip, say, we should try again. Otherwise dsmui just shows
  // a grayed-out table to the user.
  const unfailedApiCalls = _.filter(apiCalls, call => call.status !== STATUS_CALL_FAILED);
  const hasPreviousApiCall = getPreviousApiCalls(columns, unfailedApiCalls, displayState).length > 0;

  switch (displayState.type) {
    case DisplayState.NORMAL: {
      const { inputSchema } = Selectors.pathForOutputSchema(entities, displayState.outputSchemaId);
      const minRowsProcessed = Selectors.rowsTransformed(columns);
      const firstRowNeeded = (displayState.pageNo - 1) * PAGE_SIZE;
      const lastRowNeeded = firstRowNeeded + PAGE_SIZE;



      // min(null, 50) is 0, so let's assume our dataset has an infinite number of rows until we're told otherwise.
      const effectiveTotalRows = inputSchema.total_rows == null ? Infinity : inputSchema.total_rows;
      const haveWholePage = minRowsProcessed >= Math.min(effectiveTotalRows, lastRowNeeded);
      const doneLoadingThisPage =
        minRowsProcessed === inputSchema.total_rows && minRowsProcessed >= firstRowNeeded;
      return (haveWholePage || doneLoadingThisPage) && !hasPreviousApiCall;
    }
    case DisplayState.ROW_ERRORS:
    case DisplayState.COLUMN_ERRORS:
      return !hasPreviousApiCall;

    default:
      throw new TypeError(`Unknown display state: ${displayState}`);
  }
}

// this is questionable
interface NotCalled {
  type: 'not_called';
}
export function notCalled(): NotCalled {
  return { type: 'not_called' };
}

export function loadVisibleData(
  displayState: DisplayState.DisplayState,
  columns: (OutputColumn & WithTransform)[]
) {
  return (dispatch: Dispatch, getState: GetState) => {
    const { entities, ui } = getState();
    if (needToLoadAnything(entities, ui.apiCalls, displayState, columns)) {
      const timeout = getTimeoutLength(ui.apiCalls, displayState, columns);
      return dispatch(
        loadData({
          operation: LOAD_ROWS,
          callParams: { displayState, columnIds: columns.map(c => c.id) }
        }, columns, timeout)
      );
    } else {
      // Not really an error, just a way for the caller to
      // tell if loadData was called or not
      return Promise.reject(notCalled());
    }
  };
}

function loadData(apiCall: ApiCall, columns: Columns, timeout = 0) {
  return (dispatch: Dispatch) => {
    switch (apiCall.callParams.displayState.type) {
      case DisplayState.NORMAL:
        return dispatch(loadNormalPreview(apiCall, columns, timeout));
      case DisplayState.ROW_ERRORS:
        return dispatch(loadRowErrors(apiCall, columns, timeout));
      case DisplayState.COLUMN_ERRORS:
        return dispatch(loadColumnErrors(apiCall, columns, timeout));
      default:
        throw new TypeError(`Unknown display state type: ${apiCall}`);
    }
  };
}

function urlForPreview(entities: Entities, displayState: DisplayState.DisplayState, columns: (OutputColumn & WithTransform)[]) {
  const { source, inputSchema } = Selectors.pathForOutputSchema(entities, displayState.outputSchemaId);
  const { outputSchemaId } = displayState;
  const offset = (displayState.pageNo - 1) * PAGE_SIZE;
  const limit = PAGE_SIZE;

  switch (displayState.type) {
    case DisplayState.NORMAL: {
      if (columns.length === 1) {
        return dsmapiLinks.columnRows(source.id, columns[0].transform.id, limit, offset);
      } else {
        return dsmapiLinks.rows(source.id, inputSchema.id, outputSchemaId, limit, offset);
      }
    }
    case DisplayState.ROW_ERRORS: {
      return dsmapiLinks.rowErrors(source.id, inputSchema.id, limit, offset);
    }
    case DisplayState.COLUMN_ERRORS: {
      const column = _.find(
        Selectors.columnsForOutputSchema(entities, outputSchemaId),
        // TODO: sometimes transform.id is a string?????? WTF??
        (oc) => _.toNumber(oc.transform.id) === displayState.transformId
      );
      const columnId = column ? column.id : null;
      return dsmapiLinks.columnErrors(source.id, inputSchema.id, outputSchemaId, columnId, limit, offset);
    }
    default:
      throw new TypeError(`Unknown DisplayState: ${displayState}`);
  }
}


export interface DsmapiOk {
  ok: any; // TODO: refine
}
export interface DsmapiError {
  error: any; // TODO: refine
}


const isErrorCell = (c: DsmapiCell): c is DsmapiError => 'error' in c;
const isOkCell = (c: DsmapiCell): c is DsmapiOk => 'ok' in c;
const isRowError = (r: any): r is DsmapiRowError => 'error' in r && ( // ARghghgh
  _.get(r, 'error.type') === 'too_long' ||
  _.get(r, 'error.type') === 'too_short'
);
const isOkRow = (r: DsmapiRow): r is DsmapiOkRow => 'row' in r;

type DsmapiCell = DsmapiOk | DsmapiError;
export interface DsmapiRowError {
  offset: number;
  error: any;
  input_schema_id: InputSchemaId;
}
interface DsmapiOkRow {
  offset: number;
  row: DsmapiCell[];
}
type Columns =  (OutputColumn & WithTransform)[];
interface RowErrors {}
interface OutputSchemaResponse {
  output_columns: Columns;
}

type DsmapiRow = DsmapiRowError | DsmapiOkRow;

type ColumnizedCells = {[transformId: number]: {[offset: number]: DsmapiCell}};

const intoRowErrors = (inputSchemaId: InputSchemaId, rows: DsmapiRow[]) => {
  const rowErrors = rows.filter(isRowError).map(row => ({
    ...row.error,
    id: `${inputSchemaId}-${row.offset}`,
    input_schema_id: inputSchemaId,
    offset: row.offset
  }));

  return _.keyBy(rowErrors, 'id');
};

const insertRows = (
  colData: ColumnizedCells,
  rowErrors: RowErrors,
  dispatch: Dispatch,
  callId: string
) => {
  dispatch(loadNormalPreviewSuccess(colData, rowErrors));
  dispatch(apiCallSucceeded(callId));
};

const insertSingleColumn = (
  dispatch: Dispatch,
  displayState: DisplayState.DisplayState,
  columns: Columns,
  entities: Entities,
  callId: string
) => (resp: DsmapiResource<Array<DsmapiCell | DsmapiRowError>>) => {
  const [transformId] = columns.map(col => col.transform.id);
  const cells = resp.resource;

  const colData = { [transformId]: cells.map((row, id) => ({ id, ...row })) };

  const inputSchemaId = entities.output_schemas[displayState.outputSchemaId].input_schema_id;
  const rowErrors = intoRowErrors(inputSchemaId, cells.filter(isRowError));

  return insertRows(colData, rowErrors, dispatch, callId);
};

const insertMultiColumns = (
  dispatch: Dispatch,
  displayState: DisplayState.DisplayState,
  columns: Columns,
  entities: Entities,
  callId: string
) => (resp: Array<OutputSchemaResponse | DsmapiRow>) => {
  const columnizedCells = columnizeResponse(resp);
  const withoutHeader = resp.slice(1);

  const inputSchemaId = entities.output_schemas[displayState.outputSchemaId].input_schema_id;
  const rowErrors = intoRowErrors(inputSchemaId, withoutHeader.filter(isRowError));
  return insertRows(columnizedCells, rowErrors, dispatch, callId);
};

const insertColumnResults = (columns: OutputColumn[]) => {
  if (columns.length === 1) return insertSingleColumn;
  return insertMultiColumns;
};


function loadNormalPreview(apiCall: ApiCall, columns: (OutputColumn & WithTransform)[], timeout = 0) {
  return async (dispatch: Dispatch, getState: GetState) => {

    const { entities } = getState();
    const { displayState } = apiCall.callParams;

    const url = urlForPreview(entities, displayState, columns);
    const callId = uuid();

    dispatch(apiCallStarted(callId, apiCall));
    dispatch(hideFlashMessage('load_normal_preview_error'));

    await sleep(timeout);
    return socrataFetch(url)
      .then(checkStatus)
      .then(getJson)
      .then(insertColumnResults(columns)(dispatch, displayState, columns, entities, callId))
      .catch((error: any) => {
        // if some column exists which failed but failed for some reason other than a compilation
        // error, show a "idk what happened and there's nothing you can do about it" error message
        if (_.some(columns, oc =>
          oc.transform && oc.transform.failed_at && !failedToCompile(oc.transform))) {
          dispatch(showFlashMessage({ kind: 'error', id: 'load_normal_preview_error', message: t('load_error') }));
        }
        dispatch(apiCallFailed(callId, error));
        throw error;
      });
  };
}

export function loadUnloadedSource(sourceId: SourceId) {
  const apiCall = { operation: LOAD_DATA };
  return (dispatch: Dispatch) => {
    const url = dsmapiLinks.loadData(sourceId);
    const callId = uuid();

    dispatch(apiCallStarted(callId, apiCall));
    dispatch(hideFlashMessage('load_unloaded_source_error'));

    return socrataFetch(url, { method: 'PUT' })
      .then(checkStatus)
      .then(getJson)
      .catch((error: any) => {
        dispatch(showFlashMessage({ kind: 'error', id: 'load_unloaded_source_error', message: t('load_error') }));
        dispatch(apiCallFailed(callId, error));
        throw error;
      });
  };
}

function columnizeResponse(resp: Array<OutputSchemaResponse | DsmapiRow>): ColumnizedCells {
  const outputSchemaResp = resp[0] as OutputSchemaResponse;
  const withoutSchemaRow = resp.slice(1) as DsmapiRow[];
  const idxToTid = outputSchemaResp.output_columns.map(oc => oc.transform.id);

  const columnizedCells: ColumnizedCells = outputSchemaResp.output_columns.reduce((acc, oc) => {
    return { ...acc, [oc.transform.id]: {} };
  }, {});

  withoutSchemaRow.filter(isOkRow).forEach(({ row, offset }) => {
    // as opposed to row errors, which we deal with separately
    row.forEach((cell, colIdx) => {
      columnizedCells[idxToTid[colIdx]][offset] = cell;
    });
  });

  return columnizedCells;
}

export function loadColumnErrors(apiCall: ApiCall, columns: Columns, timeout = 0) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { entities } = getState();
    const { displayState } = apiCall.callParams;
    const url = urlForPreview(entities, displayState, columns);
    const callId = uuid();


    dispatch(apiCallStarted(callId, apiCall));

    await sleep(timeout);
    return socrataFetch(url)
      .then(checkStatus)
      .then(getJson)
      .then((resp: Array<OutputSchemaResponse | DsmapiRow>) => {
        const columnizedCells = columnizeResponse(resp);

        const transformErrorIndices = _.reduce(columnizedCells, (acc, value, tid) => {
          return {
            ...acc,
            [tid]: {
              [displayState.pageNo]: _.flatMap(value, (v, offset) => {
                return isErrorCell(v) ? [offset] : [];
              })
            }
          };
        }, {});

        dispatch(loadColumnErrorsSuccess(columnizedCells, transformErrorIndices));
        dispatch(apiCallSucceeded(callId));
      })
      .catch((error: any) => {
        dispatch(apiCallFailed(callId, error));
        throw error;
      });
  };
}

interface TransformMap {
  [tid: number]: any;
}

export function loadRowErrors(apiCall: ApiCall, columns: (OutputColumn & WithTransform)[], timeout = 0) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const entities = getState().entities;
    const { displayState } = apiCall.callParams;
    const inputSchemaId = entities.output_schemas[displayState.outputSchemaId].input_schema_id;
    const url = urlForPreview(entities, displayState, columns);
    const callId = uuid();

    dispatch(apiCallStarted(callId, apiCall));

    await sleep(timeout);
    return socrataFetch(url)
      .then(checkStatus)
      .then(getJson)
      .then((rows: DsmapiRowError[]) => {
        const rowErrorsWithId = rows.map(row => ({
          ...row.error,
          id: `${inputSchemaId}-${row.offset}`,
          input_schema_id: inputSchemaId,
          offset: row.offset
        }));

        const rowErrorsKeyedById = _.keyBy(rowErrorsWithId, 'id');

        dispatch(loadRowErrorsSuccess(rowErrorsKeyedById));

        dispatch(apiCallSucceeded(callId));
      })
      .catch((error: any) => {
        dispatch(apiCallFailed(callId, error));
      });
  };
}
