import { ModalFooter } from 'common/components/Modal';
import I18n from 'common/i18n';
import SubmitButton from 'datasetManagementUI/components/SubmitButton/SubmitButton';
import { Form } from 'datasetManagementUI/lib/types';
import React from 'react';

const t = (k: string, scope = 'dataset_management_ui') => I18n.t(k, { scope });

interface Props {
  form: Form<unknown>; // we don't access the state here, so unknown is fine
  handleModalDismiss: () => void;
  handleClick: () => void;
  buttonName: string;
  disabled?: boolean;
  loading?: boolean;
}
export default function ManageMetadataFooter({
  loading,
  form,
  handleClick,
  handleModalDismiss,
  buttonName,
  disabled
}: Props) {
  return (
    <ModalFooter>
      <button
        disabled={!form.isDirty && form.submitted}
        className="btn btn-default"
        onClick={handleModalDismiss}
      >
        {t('common.cancel')}
      </button>
        <SubmitButton
          busy={loading}
          handleClick={handleClick}
          isDisabled={!form.isDirty || disabled || false}
          buttonName={buttonName}
        />
    </ModalFooter>
  );
}
