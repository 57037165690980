import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { shapeField } from 'common/components/MetadataTable';
import MetadataTable from 'datasetManagementUI/components/MetadataTable/MetadataTable';
import * as Selectors from 'datasetManagementUI/selectors';
import { associateChildToParent } from 'datasetManagementUI/reduxStuff/actions/associateCollections';
import { UPDATE_REVISION } from 'datasetManagementUI/reduxStuff/actions/apiCalls';
import { FeatureFlags } from 'common/feature_flags';
import { isEditMode } from 'datasetManagementUI/lib/modes';

export const getRevision = (rSeq, revisions) =>
  _.chain(revisions)
    .find({ revision_seq: rSeq })
    .value();

export const getView = (fourfour, views) =>
  _.chain(views)
    .get(fourfour)
    .value();


// MetadataTable component doesn't distinguish between private and non-private
// custom metadata. So we have to do some data-massaging here before passing
// custom metadata info to it.
export const shapeCustomMetadata = (viewlikeObj = {}, customFieldsets = []) => {
  const privateCustomMetadata = _.get(viewlikeObj, 'privateMetadata.custom_fields', {});
  const nonPrivateCustomMetadata = _.get(viewlikeObj, 'metadata.custom_fields', {});

  const combinedCustomMetadata = _.merge(_.cloneDeep(nonPrivateCustomMetadata), privateCustomMetadata);
  const currentAvailableFields = customFieldsets.map(fieldset => fieldset.name);
  const customFieldsetFieldOrdering =
    _.zipObject(currentAvailableFields, _.map(customFieldsets, (fieldset) => fieldset.fields));
  return _.mapValues(
    // Necessary in case user deletes a field but we still have data for it.
    _.pickBy(combinedCustomMetadata, (v, k) => currentAvailableFields.includes(k)),
    (fieldset, fieldsetName) => _.map(customFieldsetFieldOrdering[fieldsetName], (f) => shapeField(f, fieldset))
  );
};

export const mapStateToProps = ({ entities, ui }, { params }) => {
  const rSeq = _.toNumber(params.revisionSeq);

  const r = getRevision(rSeq, entities.revisions);
  const v = getView(params.fourfour, entities.views);

  const emptyProps = {
    view: {},
    customMetadataFieldsets: {}
  };

  if (!r || !v) {
    return emptyProps;
  } else {
    let customMetadataFieldsets = {};

    const viewlikeObj = Selectors.shapeRevisionIntoView(r, v);

    if (viewlikeObj.metadata && viewlikeObj.customMetadataFieldsets) {
      customMetadataFieldsets = shapeCustomMetadata(viewlikeObj, viewlikeObj.customMetadataFieldsets);
    }

    const isUSAID = FeatureFlags.value('usaid_features_enabled');
    // is_parent could be false or undefined
    const enableAssociatedAssets = isUSAID && !isEditMode(v) && !r.is_parent;
    const useDataAssetStrings = isUSAID && r.is_parent === true;

    return {
      associatedAssetsApiCalls: _.filter(_.values(ui.apiCalls), call => call.operation === UPDATE_REVISION),
      enableAssociatedAssets,
      disableContactDatasetOwner: true, // looks up a CurrentDomain feature whatever that is
      coreView: viewlikeObj,
      customMetadataFieldsets,
      revision: r,
      useDataAssetStrings
    };
  }
};

function mergeProps(stateProps, { dispatch }, ownProps) {
  return {
    ...ownProps,
    ...stateProps,
    onSaveAssociationCallback: parentUid =>
      dispatch(associateChildToParent(parentUid, stateProps.revision, ownProps.params))
  };
}

export default withRouter(connect(mapStateToProps, null, mergeProps)(MetadataTable));
