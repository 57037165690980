import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ModalContent, ModalFooter } from 'common/components/Modal';
import GuidanceSummaryV2PropType from 'common/propTypes/GuidanceSummaryV2PropType';
import DSMUIIcon from '../DSMUIIcon';
import ApiCallButton from 'datasetManagementUI/containers/ApiCallButtonContainer';
import { APPLY_REVISION } from 'datasetManagementUI/reduxStuff/actions/apiCalls';
import { withGuidanceV2 } from 'common/core/approvals/index_new';
import I18n from 'common/i18n';

const t = (k, scope = 'dataset_management_ui.home_pane.publish_confirmation') => I18n.t(k, { scope });

export const PERMISSIONS = {
  PUBLIC: 'public',
  PRIVATE: 'private',
  INTERNAL: 'site'
};

class PublishConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPermission: props.permission,
      showApprovalMessage: false
    };
  }

  // Right now we don't update the revision in the store when you toggle the
  // permission here. When/if we separate "change permission" and "publish" into
  // two steps, we need to at least update the redux store on permission toggle.
  // Ideally "change permission" would call to dsmapi, then we would take the
  // response and update the store.
  setCurrentPermission = (permission) => {
    this.setState({ currentPermission: permission });
  };

  render() {
    const { approvalsGuidance, doCancel, doUpdateAndApply } = this.props;
    const { currentPermission, showApprovalMessage } = this.state;

    const willEnter = withGuidanceV2(approvalsGuidance).willEnterApprovalQueue();
    if (willEnter !== showApprovalMessage) {
      this.setState({ showApprovalMessage: willEnter });
    }

    return (
      <div className="publish-confirmation-modal-inner">
        <h2>{t('title')}</h2>
        <ModalContent>
          <span
            onClick={() => this.setCurrentPermission(PERMISSIONS.PUBLIC)}
            className={
              currentPermission === PERMISSIONS.PUBLIC
                ? 'privacy-selector privacy-selector-active'
                : 'privacy-selector'
            }
          >
            <DSMUIIcon name="checkmark3" className="publish-confirmation-checkbox" />
            <h3>{t('public')}</h3>
            <DSMUIIcon className="publish-confirmation-icon" name="public-open" />
            {t('public_msg')}
          </span>
          <span
            onClick={() => this.setCurrentPermission(PERMISSIONS.PRIVATE)}
            className={
              currentPermission === PERMISSIONS.PRIVATE
                ? 'privacy-selector privacy-selector-active'
                : 'privacy-selector'
            }
          >
            <DSMUIIcon name="checkmark3" className="publish-confirmation-checkbox" />
            <h3>{t('private')}</h3>
            <DSMUIIcon className="publish-confirmation-icon" name="private" />
            {t('private_msg')}
          </span>
          {this.state.showApprovalMessage && <div className="approval-message">{t('approval_note')}</div>}
        </ModalContent>
        <ModalFooter className="publish-confirmation-modal-footer">
          <button onClick={doCancel} className="btn btn-default publish-confirmation-button">
            {t('common.cancel', 'dataset_management_ui')}
          </button>
          <ApiCallButton
            additionalClassName="btn btn-primary publish-confirmation-button"
            operation={APPLY_REVISION}
            onClick={() => doUpdateAndApply(currentPermission)}
          >
            {t('button')}
          </ApiCallButton>
        </ModalFooter>
      </div>
    );
  }
}

PublishConfirmation.propTypes = {
  approvalsGuidance: GuidanceSummaryV2PropType.isRequired,
  doCancel: PropTypes.func.isRequired,
  doUpdateAndApply: PropTypes.func.isRequired,
  permission: PropTypes.string.isRequired,
  view: PropTypes.object
};

export default PublishConfirmation;
