import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DSMUIIcon from '../DSMUIIcon';
import { attachTo } from 'common/components/legacy';
import I18n from 'common/i18n';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.show_output_schema.column_header.hidden_column_flyout' });

function flytoutId(columnId) {
  return `hidden-col-flyout-${columnId}`;
}

function hiddenColumnFlyout(columnId) {
  return (
    <div id={flytoutId(columnId)} className="col-attribute-flyout flyout flyout-hidden">
      <section className="flyout-content">
        <DSMUIIcon name="eye-blocked" className="col-icon" />
        {t('title')}
        <br />
        <span className="flyout-body">
          {t('body')}
        </span>
      </section>
    </div>
  );
}

class HiddenColumnIcon extends Component {

  componentDidMount() {
    this.attachFlyouts();
  }

  componentDidUpdate() {
    this.attachFlyouts();
  }

  attachFlyouts() {
    attachTo(this.flyoutParentEl);
  }

  render() {
    const { columnId } = this.props;

    return (
      <div ref={flyoutParentEl => { this.flyoutParentEl = flyoutParentEl; }}>
        <span>
          <div className="header-icon hidden-icon-container" data-flyout={flytoutId(columnId)}>
            <DSMUIIcon name="eye-blocked" className="hiddenColumnIcon" />
          </div>
          {hiddenColumnFlyout(columnId)}
        </span>
      </div>
    );
  }
}

HiddenColumnIcon.propTypes = {
  columnId: PropTypes.number.isRequired
};


export default HiddenColumnIcon;
