import PluginIcon from 'common/components/PluginIcon';
import { fetchTranslation } from 'common/locale';
import { Namespace, Plugin } from 'common/types/gateway';
import React from 'react';
const t = (k: string) => fetchTranslation(k, 'dataset_management_ui.connection_agent');

interface TileProps {
  namespace: Namespace;
  plugin: Plugin | undefined;
  expand: () => void;
}

function Tile({ namespace, plugin, expand }: TileProps) {
  return (
    <div className="tile" onClick={expand}>
      <div className="agent-head">
        <PluginIcon plugin={plugin} />
      </div>
      <div className="attributes">
        <div className="attribute">
          <label>{t('name')}</label>
          <span>{namespace.name}</span>
        </div>
        <div className="attribute">
          <label>{t('type')}</label>
          <span>{namespace.type}</span>
        </div>
      </div>
    </div>
  );
}

export default Tile;
