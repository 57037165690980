import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import I18n from 'common/i18n';
import { ColumnLike } from 'datasetManagementUI/lib/columnLike';
import { AppState } from 'datasetManagementUI/lib/types';
import * as _ from 'lodash';
import React, { Component, ComponentType } from 'react';
import {
  ConnectDragSource,
  ConnectDragPreview,
  ConnectDropTarget,
  DragSource,
  DragSourceConnector,
  DropTarget,
  DropTargetConnector
} from 'react-dnd';
import { connect } from 'react-redux';
import LabelAndErrors from 'common/components/LabelAndErrors';
import TextInput from '../TextInput/TextInput';
import VisibilityToggle from '../VisibilityToggle/VisibilityToggle';

const t = (k: string) => I18n.t(k, { scope: 'dataset_management_ui.metadata_manage.column_tab' });

interface ExternalProps {
  column: ColumnLike<unknown>;
  idx: number;
  handleColumnChange: (c: ColumnLike<unknown>) => void;
  beingDragged: boolean;
  justDropped: boolean;
  hideable: boolean;
  displaySource: boolean;
  dragColumn: (dragged: ColumnLike<unknown>, hoverPosition: number) => void;
  dropColumn: () => void;
  editFieldNameDisabled: boolean;
}

interface DndProps {
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
  connectDragPreview: ConnectDragPreview;
}

interface StateProps {
  errors: { [n: string]: string[] };
}

type Props = ExternalProps & DndProps & StateProps;

class ColumnRow extends Component<Props> {
  render() {
    // "connect" props are passed from the DragSource and DropTarget wrappers
    const {
      column,
      errors,
      idx,
      hideable,
      displaySource,
      handleColumnChange,
      beingDragged,
      justDropped,
      connectDragSource,
      connectDropTarget,
      connectDragPreview,
      editFieldNameDisabled
    } = this.props;

    const displayNameErrors: string[] = errors.display_name || [];
    const fieldNameErrors: string[] = errors.field_name || [];
    const sourceName = column.sourceName || '';

    const [focusFieldName, focusMetadataType] = document.location.hash.slice(1).split(',', 2);

    return connectDropTarget(
      connectDragPreview(
        <div
          className={`dsmp-row ${beingDragged ? 'dragging' : ''} ${justDropped ? 'dropped' : ''}`}
          key={idx}
        >
          {connectDragSource(
            <div>
              <SocrataIcon className="drag-handle" name={IconName.GrabHandle} />
            </div>
          )}
          {displaySource && <div>{sourceName}</div>}
          {hideable && (
            <VisibilityToggle
              name={column.field_name}
              setHidden={(hidden) => {
                const flags = hidden
                  ? [...column.flags, 'hidden']
                  : (column.flags || []).filter((f) => f !== 'hidden');

                handleColumnChange({ ...column, flags });
              }}
              isHidden={_.includes(column.flags, 'hidden')}
            />
          )}

          <LabelAndErrors
            errors={displayNameErrors}
            label={t('display_name')}
            name={t('display_name')}
            useLabels={true}
          >
            <TextInput
              id={['column', idx, 'name'].join('-')}
              field={{
                name: t('display_name'),
                label: t('display_name'),
                placeholder: t('display_name'),
                value: column.display_name || ''
              }}
              focusOnMount={focusMetadataType == 'name' && column.field_name === focusFieldName}
              handleChange={(e) => {
                handleColumnChange({
                  ...column,
                  display_name: e.currentTarget.value
                });
              }}
              inErrorState={!!displayNameErrors.length}
            />
          </LabelAndErrors>

          <LabelAndErrors
            className="column-description"
            errors={[]}
            label={t('description')}
            name={t('description')}
            useLabels={true}
          >
            <TextInput
              id={['column', idx, 'description'].join('-')}
              field={{
                name: t('description'),
                label: t('description'),
                placeholder: t('description'),
                value: column.description
              }}
              focusOnMount={focusMetadataType == 'description' && column.field_name === focusFieldName}
              handleChange={(e) => {
                handleColumnChange({
                  ...column,
                  description: e.currentTarget.value
                });
              }}
              inErrorState={false}
            />
          </LabelAndErrors>

          <LabelAndErrors
            errors={fieldNameErrors}
            label={t('field_name')}
            name={t('field_name')}
            useLabels={true}
          >
            <TextInput
              id={['column', idx, 'field_name'].join('-')}
              field={{
                disabled: editFieldNameDisabled,
                name: t('field_name'),
                label: t('field_name'),
                placeholder: t('field_name'),
                value: column.field_name
              }}
              focusOnMount={focusMetadataType == 'fieldName' && column.field_name === focusFieldName}
              handleChange={(e) => {
                handleColumnChange({
                  ...column,
                  field_name: e.currentTarget.value
                });
              }}
              inErrorState={!!fieldNameErrors.length}
            />
          </LabelAndErrors>
        </div>
      )
    );
  }
}

const dragSourceActions = {
  beginDrag(props: any) {
    // this return value is accessed by the monitor (getItem()) in the dropTarget
    return { column: props.column, idx: props.idx };
  }
};

// drops props into ColumnRow component
const sourceCollect = (connector: DragSourceConnector) => {
  return {
    connectDragSource: connector.dragSource(),
    connectDragPreview: connector.dragPreview()
  };
};

const dropTargetActions = {
  hover(props: any, monitor: any, component: any) {
    if (!component) {
      return null;
    }

    const dragIndex = monitor.getItem().idx;
    const hoverIndex = props.idx;
    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.dragColumn(monitor.getItem().column, props.column.position);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().idx = hoverIndex;
  },
  drop(props: any) {
    props.dropColumn();
  }
};

// drops props into ColumnRow component
function targetCollect(connector: DropTargetConnector) {
  return { connectDropTarget: connector.dropTarget() };
}

// eslint-disable-next-line new-cap
const DraggableColumnRow = DropTarget(
  'column_row',
  dropTargetActions,
  targetCollect
)(
  DragSource('column_row', dragSourceActions, sourceCollect)(ColumnRow) // eslint-disable-line new-cap
);

const mapStateToProps = ({ ui }: AppState, extProps: ExternalProps) => ({
  ...extProps,
  errors: _.get(ui, ['forms', 'columnForm', 'errors', extProps.column.field_name], [])
});

// cast here is because the DND types are all screwed up afaict
export default connect(mapStateToProps)(DraggableColumnRow) as unknown as ComponentType<ExternalProps>;
