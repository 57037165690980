import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import * as SourceActions from 'datasetManagementUI/reduxStuff/actions/createSource';

import { fetchTranslation } from 'common/locale';
const t = (k) => fetchTranslation(k, 'dataset_management_ui.notifications.cancel_source');

export class CancelSource extends React.Component {
  constructor() {
    super();
    this.unConfirm = this.unConfirm.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  state = {
    confirming: false
  };

  unConfirm() {
    this.setState({
      confirming: false
    });
  }

  confirm() {
    this.setState({
      confirming: true
    });
  }


  render() {
    const { source, cancelSource } = this.props;
    const { confirming } = this.state;

    return (
      <div className="cancel-dsmp-source">
        {confirming ?
        (
          <div>
            <p>{t('r_u_sure')}</p>
            <div>
              <a className="dont-cancel" onClick={this.unConfirm}>{t('dont_cancel')}</a>
              <a className="really-cancel" onClick={() => cancelSource(source)}>{t('really_cancel')}</a>
            </div>
          </div>
        ) :
        (
          <a onClick={this.confirm}>
            {t('cancel_source')}
          </a>
        )
        }


      </div>
    );
  }
}


CancelSource.propTypes = {
  cancelSource: PropTypes.func.isRequired,
  source: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => {
  return {
    cancelSource: (source) => dispatch(SourceActions.cancelSource(source))
  };
};

export default connect(null, mapDispatchToProps)(CancelSource);
