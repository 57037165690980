import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { interpolate, easeInOutQuad } from 'datasetManagementUI/lib/interpolate';
import { commaify } from 'common/formatNumber';
import * as Selectors from 'datasetManagementUI/selectors';
import Table from 'datasetManagementUI/containers/TableContainer';
import PagerBar from 'datasetManagementUI/containers/PagerBarContainer';
import ErrorPointer from 'datasetManagementUI/components/ErrorPointer/ErrorPointer';
import FlashMessage from 'datasetManagementUI/containers/FlashMessageContainer';
import { getFilename } from 'datasetManagementUI/lib/sources';
import I18n from 'common/i18n';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.data_preview'});

const COL_WIDTH_PX = 250; // matches style on td in Table.scss
const ERROR_SCROLL_DURATION_MS = 400;
const HORIZ_SCROLL_TOLERANCE = 10;
const COLUMN_PARTIAL_RENDER_THRESHOLD = 50;

class TablePane extends Component {
  constructor() {
    super();
    _.bindAll(this, ['setSize', 'scrollToColIdx']);
    // need to keep throttled version in an instance variable since it contains state used for
    // throttling. Putting _.throttle in JSX doesn't work because throttling state is overwritten
    // on every rerender
    this.throttledSetSize = _.throttle(this.setSize, ERROR_SCROLL_DURATION_MS / 2);
    this.cacheScrollLeft = this.cacheScrollLeft.bind(this);
    this.state = {
      scrollLeft: 0,
      scrollLeftAtFlyoutOpen: 0,
      viewportWidth: 0
    };
  }

  componentDidMount() {
    this.setSize();
    window.addEventListener('resize', this.throttledSetSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledSetSize);
  }

  setSize() {
    if (!this.tableWrap) return;

    const horizontalScrollDistance = Math.abs(this.state.scrollLeft - this.state.scrollLeftAtFlyoutOpen);

    if (horizontalScrollDistance > HORIZ_SCROLL_TOLERANCE) {
      [...document.querySelectorAll('[data-flyout]')].forEach(anchor => {
        const flyout = document.querySelector(`#${anchor.getAttribute('data-flyout')}`);
        if (flyout) {
          flyout.classList.add('flyout-hidden');
        }
      });
    }

    this.setState({
      scrollLeft: this.tableWrap.scrollLeft,
      viewportWidth: this.tableWrap.offsetWidth
    });
  }

  errorsNotInView() {
    // find the minimum and maximum column indexes visible to the user
    const minColIdx = this.offsetToColIdx(this.state.scrollLeft);
    const maxColIdx = this.offsetToColIdx(this.state.scrollLeft + this.state.viewportWidth);
    // get the column objects for columns out of viewport to left and right
    const colsToLeft = this.props.columns.slice(0, minColIdx);
    const colsToRight = this.props.columns.slice(maxColIdx + 1);
    // sum up errors for those columns
    return {
      toLeft: this.errorSumAndFirstColWithErrors(colsToLeft),
      toRight: this.errorSumAndFirstColWithErrors(colsToRight)
    };
  }

  columnsInView() {
    const columnCount = this.props.columns.length;
    // this partial rendering logic only kicks in at a threshold because
    // rendering a new block of columns on scroll can make the scrolling a little
    // bit choppy - for small datasets this optimization isn't required so we can avoid
    // that choppiness by just rendering all the columns all the time, but for wide datasets
    // the table becomes completely unusable - so the mildly choppy horizontal scroll
    // is an OK tradeoff for having the browser crash completely.
    if (columnCount < COLUMN_PARTIAL_RENDER_THRESHOLD) {
      return { minColIdx: 0, maxColIdx: columnCount };
    }
    const columnPadding = 2; // render this many extra columns on either side
    const minColIdx = Math.max(0, this.offsetToColIdx(this.state.scrollLeft) - columnPadding);
    const maxColIdx = Math.min(
      this.offsetToColIdx(this.state.scrollLeft + this.state.viewportWidth) + columnPadding,
      columnCount
    );
    // minColIdx and maxColIdx are maybe not the best names - they are actually
    // slice indexes, so they can be plugged directly into .slice() to get the
    // slice of columns currently in the viewport - ie: columns[maxColIdx - 1] is the last
    // column - but since slice is not inclusive columns.slice(minColIdx, maxColIdx) is
    // all the stuff that is needed
    return { minColIdx, maxColIdx };
  }

  errorSumAndFirstColWithErrors(columns) {
    let firstColWithErrors = null;
    let errorSum = 0;
    columns.forEach(col => {
      const numErrors = col.transform.error_count || 0;
      errorSum += numErrors;
      if (firstColWithErrors === null && numErrors > 0) {
        firstColWithErrors = col.position;
      }
    });
    return {
      errorSum,
      firstColWithErrors
    };
  }

  scrollToColIdx(idx) {
    if (!this.tableWrap) return;
    const offset = this.colIdxToOffset(idx);
    interpolate(this.tableWrap.scrollLeft, offset, ERROR_SCROLL_DURATION_MS, easeInOutQuad, pos => {
      this.tableWrap.scrollLeft = pos;
    });
  }

  offsetToColIdx(offset) {
    let idx = 0;
    let at = 0;
    const maxIdx = this.props.columns.length - 1;

    while (at < offset && idx <= maxIdx) {
      at += this.widthOf(idx);
      idx += 1;
    }

    return idx;
  }

  colIdxToOffset(colIdx) {
    const colOffset = _.sum(_.range(0, colIdx).map(idx => this.widthOf(idx)));
    const centered = colOffset - this.state.viewportWidth / 2 + this.widthOf(colIdx) / 2;
    return Math.max(0, centered);
  }

  widthOf(colIdx) {
    const column = this.props.columns[colIdx];
    if (column && column.format && _.isNumber(column.format.width)) {
      return column.format.width;
    }
    return COL_WIDTH_PX;
  }

  cacheScrollLeft(e) {
    // This runs on mouseover. The idea is to crawl up the
    // the DOM looking for a flyout anchor. If we find one,
    // we store the current horizontal scroll position. We
    // then diff this against the new position calculated
    // on "scroll". This lets us detect any horizontal
    // scrolling since the floyut opened, and if the scrolled
    // amount exceeds a certain tolerance, we close the flyout
    const findFlyout = (elem) => {
      if (!elem || elem.nodeName.toUpperCase() === 'BODY') {
        return;
      }

      if (elem.getAttribute('data-flyout')) {
        this.setState({
          scrollLeftAtFlyoutOpen: this.tableWrap.scrollLeft
        });
        return;
      }

      findFlyout(elem.parentNode);
    };

    findFlyout(e.target);
  }

  render() {
    const {
      inputSchema,
      outputSchema,
      columns,
      displayState,
      numLoadsInProgress,
      params,
      source,
      view
    } = this.props;

    const rowsTransformed = inputSchema.total_rows || Selectors.rowsTransformed(columns);
    const errorsNotInView = this.errorsNotInView();
    const uploadedFile = getFilename(source, view);

    return (
      <div className="content-wrap">
        <div className="flash-container">
          <FlashMessage />
        </div>
        <div
          className="pointer-wrap"
          onScroll={this.throttledSetSize}
          onMouseOver={this.cacheScrollLeft}
          ref={tableWrap => {
            this.tableWrap = tableWrap;
          }}>
          <div className="data-preview">
            <div className="title-wrapper">
              <h2 className="preview-header">{t('title')}</h2>
              {numLoadsInProgress > 0 && <span className="spinner-default" />}
              {uploadedFile && <span className="filename">({uploadedFile})</span>}
            </div>
            <div className="dataset-attribute">
              <div className="dataset-attribute">
                <p>{t('rows')}</p>
                <p className="attribute" data-cheetah-hook="total-rows-transformed">
                  {commaify(rowsTransformed)}
                </p>
              </div>
              <div className="dataset-attribute">
                <p>{t('columns')}</p>
                <p className="attribute">{columns.length}</p>
              </div>
            </div>
          </div>

          <Table
            params={params}
            columns={columns}
            columnsInView={this.columnsInView()}
            inputSchema={inputSchema}
            outputSchema={outputSchema}
            displayState={displayState} />

          {errorsNotInView.toLeft.errorSum > 0 && (
            <ErrorPointer
              errorInfo={errorsNotInView.toLeft}
              direction="left"
              scrollToColIdx={this.scrollToColIdx} />
          )}
          {errorsNotInView.toRight.errorSum > 0 && (
            <ErrorPointer
              errorInfo={errorsNotInView.toRight}
              direction="right"
              scrollToColIdx={this.scrollToColIdx} />
          )}
        </div>
        <PagerBar params={params} displayState={displayState} />
      </div>
    );
  }
}

TablePane.propTypes = {
  columns: PropTypes.array,
  inputSchema: PropTypes.object,
  outputSchema: PropTypes.object,
  displayState: PropTypes.object,
  params: PropTypes.object,
  numLoadsInProgress: PropTypes.number,
  flashVisible: PropTypes.bool,
  source: PropTypes.object,
  view: PropTypes.object
};

export default TablePane;
