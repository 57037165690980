import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Notification from 'datasetManagementUI/containers/NotificationContainer';
import {
  getFilename,
  isURLSource,
  isConnectionAgentSource
} from 'datasetManagementUI/lib/sources';
import moment from 'moment';
import CancelSource from 'datasetManagementUI/components/CancelSource/CancelSource';
import formatString from 'common/js_utils/formatString';
import { AgentError } from 'datasetManagementUI/lib/agentTypes';
import I18n from 'common/i18n';
import { ScanStatus } from 'common/components/ScanResult';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.notifications' });

const steps = [
  'upload',
  'security_scan'
];

function getAdvice(source) {
  if (isURLSource(source)) return t('connection_error_body_advice_url');
  return t('connection_error_body_advice_upload');
}

function getDescription(source) {
  if (isURLSource(source)) {
    return t('connection_error_body_description_url');
  }
  if (isConnectionAgentSource(source)) {
    return t('connection_error_body_description_url');
  }
  return t('connection_error_body_description_upload');
}


function getFailureTitle(source) {
  if (isURLSource(source)) return t('url_failed');
  return t('upload_failed');
}

function getProgressTitle(source, currentStep) {
  if (isURLSource(source)) return t('fetching');
  if (isConnectionAgentSource(source)) return t('fetching');
  if (currentStep === 'upload') {
    return t('uploading');
  } else {
    return t('security_scan_header');
  }
}

function isInfinite(source) {
  return isURLSource(source) || isConnectionAgentSource(source);
}

function getPercentage(source) {
  if (isInfinite(source)) return 100;
  return source.percentCompleted;
}

function getExplanation(currentStep, source) {
  const wrap = (child) => <span className="message-explanation">{child}</span>;
  if (currentStep === 'upload' && !isSourceStreamable(source)) {
    return wrap(t('upload_in_progress'));
  } else if (currentStep === 'security_scan') {
    return wrap(<span dangerouslySetInnerHTML={{ __html: t('scan_in_progress') }} />);
  }
}

const streamableContentTypes = [
  'text/csv',
  'text/tab-separated-values',
  'application/vnd.google-earth.kml+xml',
  'application/vnd.geo+json',
  'application/json',
  'application/json+cjson',
  'application/gzip+cjson'
];

function isSourceStreamable(source) {
  // don't show a scary message until we know for sure
  if (!source.content_type) return true;
  return streamableContentTypes.indexOf(source.content_type) !== -1;
}

function failureDetailsMessage(source) {
  if (!_.isEmpty(source.failure_details) &&
      source.failure_details.message &&
      source.failure_details.message !== 'internal error'
    ) {
    if (isConnectionAgentSource(source)) {
      return (new AgentError(source.failure_details.response)).getOrElse(source.failure_details.message);
    }
    return source.failure_details.message;
  } else if (_.get(source, 'scanResult.state', ScanStatus.Unscanned) === ScanStatus.Bad) {
    return t('file_issue_message');
  }
}

const errorMessage = (source, view) => {
  if (failureDetailsMessage(source)) {
    return (
      <div className="message-container">
        {failureDetailsMessage(source)}
      </div>
    );
  }

  const badConnectionBodyDescription = {
    __html: formatString(getDescription(source), `<span class="filename">${getFilename(source, view)}</span>`)
  };

  const badConnection = (
    <div className="message-container">
      <h6>
        {t('connection_error_title')}
      </h6>
      <p dangerouslySetInnerHTML={badConnectionBodyDescription} />
      <p>
        {getAdvice(source)}
      </p>
    </div>
  );

  return badConnection;
};

// when a source fails because it is an unparsable_file, the UI recreates it with
// parse_source: false, behind the scenes. Don't show the error in this case
function isHiddenError(source) {
  return !!source.failure_details && source.failure_details.key === 'unparsable_file';
}

function isTooOld(ts) {
  return moment().diff(ts, 'minutes') > 1;
}

// This component is called by the NotificationList component. Its main purpose
// is to translate source-specific logic into props that the generic Notification
// component can understand.
const UploadNotification = ({ source, hasUploadErrors, isUploaded, securityScanFlagged, isScanned, view }) => {
  let message;
  let children;
  let notificationStatus;

  // If the upload is complete, then the security scan will begin automatically.
  const currentStep = (() => {
    if (isSourceStreamable(source)) {
      return steps[0];
    }

    if (isUploaded && !hasUploadErrors) {
      return steps[1];
    } else {
      return steps[0];
    }
  })();

  if (source.failed_at) {
    message = (
      <span className="message">
        {getFailureTitle(source)}
      </span>
    );
    children = errorMessage(source, view);
    notificationStatus = 'error';
  } else if (hasUploadErrors || securityScanFlagged) {
    message = (
      <span className="message">
        {getProgressTitle(source, currentStep)}
        <br></br>
        <span className="sub-message">
          {getFilename(source, view)}
        </span>
      </span>
    );
    if (securityScanFlagged) {
      notificationStatus = 'insecure';
    } else if (hasUploadErrors) {
      notificationStatus = 'warning';
    }
    children = (<CancelSource source={source} />);
  } else {
    message = (
      <span className="message">
        {getProgressTitle(source, currentStep)}
        <br></br>
        <span className="sub-message">
          {getFilename(source, view)}
        </span>
        {getExplanation(currentStep, source)}
      </span>
    );

    if (currentStep === steps[0] && isUploaded) {
      notificationStatus = 'success';
    } else if (currentStep === steps[1] && isScanned) {
      notificationStatus = 'secure';
    } else {
      notificationStatus = 'inProgress';
      children = (<CancelSource source={source} />);
    }
  }

  if (notificationStatus === 'error' && isHiddenError(source)) return null;
  if (notificationStatus === 'error' && isTooOld(source.failed_at)) return null;
  if (isTooOld(source.finished_at)) return null;

  return (
    <Notification
      status={notificationStatus}
      id={source.id}
      progressBar
      percentCompleted={getPercentage(source)}
      isInfinite={isInfinite(source)}
      message={message}>
      {children}
    </Notification>
  );
};

UploadNotification.propTypes = {
  view: PropTypes.object.isRequired,
  source: PropTypes.shape({
    source_type: PropTypes.shape({})
  }).isRequired,
  showDetails: PropTypes.bool,
  hasUploadErrors: PropTypes.bool.isRequired,
  isUploaded: PropTypes.bool.isRequired,
  securityScanFlagged: PropTypes.bool.isRequired,
  isScanned: PropTypes.bool.isRequired
};

export default UploadNotification;
