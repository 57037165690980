import { connect } from 'react-redux';
import ErrorFlyout from 'datasetManagementUI/components/ErrorFlyout/ErrorFlyout';
import {
  updateColumnTransform,
  redirectToOutputSchema
} from 'datasetManagementUI/reduxStuff/actions/showOutputSchema';

export const mapStateToProps = (state, props) => {
  return props;
};

function mapDispatchToProps(dispatch, ownProps) {
  const forgiveErrors = () => {
    const newExpr = `forgive(${ownProps.outputColumn.transform.transform_expr})`;
    return dispatch(updateColumnTransform(ownProps.outputSchema, ownProps.outputColumn, newExpr)).then(
      (response) => {
        const outputSchema = response.resource;
        dispatch(redirectToOutputSchema(ownProps.params, outputSchema.id));
        return response;
      }
    );
  };

  return {
    ...ownProps,
    forgiveErrors
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorFlyout);
