import React from 'react';
import {
  toNumberExpr,
  inputColumnSelection,
  getInputColumnFromMapping,
  columnMatchingAst,
  fieldPropTypes
} from './Fields';
import { stripToNumberAst } from 'datasetManagementUI/lib/ast';

const LatLngFields = ({ inputColumns, setMapping, mappings }) => {
  return (
    <div>
      {inputColumnSelection('latitude', inputColumns, setMapping, mappings)}
      {inputColumnSelection('longitude', inputColumns, setMapping, mappings)}
    </div>
  );
};

LatLngFields.propTypes = fieldPropTypes;

const composeFromLatLng = (mappings, { useLocation }) => {
  const lat = toNumberExpr(getInputColumnFromMapping(mappings, 'latitude'));
  const lng = toNumberExpr(getInputColumnFromMapping(mappings, 'longitude'));

  const point = `make_point(${lat}, ${lng})`;
  if (useLocation) {
    return `make_location(${point})`;
  }
  return point;
};

// given a make_point AST, and the output columns, return a mapping
const decomposeFromLatLng = (makePointFunc, inputColumns) => {
  // Each one of these args is not a necessarily column ref! It may be a `to_number` function call
  // which encloses a column ref - but either colRef could be null
  const [latArg, lngArg] = makePointFunc.args;

  // The ORs here are because we might have wrapped the AST in a to_number call
  const latitude =
    columnMatchingAst(inputColumns, latArg) || columnMatchingAst(inputColumns, stripToNumberAst(latArg));
  const longitude =
    columnMatchingAst(inputColumns, lngArg) || columnMatchingAst(inputColumns, stripToNumberAst(lngArg));

  return [['latitude', latitude], ['longitude', longitude]];
};

const relevantMappingsForLatLng = () => ['latitude', 'longitude'];

export { LatLngFields, composeFromLatLng, decomposeFromLatLng, relevantMappingsForLatLng };
