import { requireApprovalRequestWithdrawalBeforeAction } from 'common/components/AssetActionBar/components/publication_action';
import Modal, { ModalContent, ModalHeader } from 'common/components/Modal';
import Spinner from 'common/components/Spinner';
import { assetIdFor, fetchApprovalsGuidanceV2 } from 'common/core/approvals/index_new';
import { lastInChain, replaceLastInChain } from 'common/explore_grid/lib/selectors';
import { updateExpressionName, updateReference } from 'common/explore_grid/lib/soql-helpers';
import I18n from 'common/i18n';
import getRevisionSeq from 'common/js_utils/getRevisionSeq';
import { compileAST, compileText } from 'common/soql/compiler-api';
import { GuidanceSummaryV2 } from 'common/types/approvals';
import {
  QueryCompilationFailed,
  QueryCompilationSucceeded,
  isCompilationFailed,
  isCompilationSucceeded
} from 'common/types/compiler';
import { PhxChannel, socket } from 'common/types/dsmapi';
import { isSoQLBasedRevision, Revision } from 'common/types/revision';
import {
  BinaryTree,
  ColumnRef,
  isColumnRef,
  UnAnalyzedAst,
  UnAnalyzedSelectedExpression
} from 'common/types/soql';
import { View } from 'common/types/view';
import { isDefaultView } from 'common/views/view_types';
import { validateColumns } from 'datasetManagementUI/lib/columnValidators';
import { ColumnLike, ColumnKey } from 'datasetManagementUI/lib/columnLike';
import { Form } from 'datasetManagementUI/lib/types';
import _ from 'lodash';
import React, { Component } from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { none, option, Option, some } from 'ts-option';
import ColumnForm from '../ColumnForm/ColumnForm';
import { showFederatedHrefMessage } from './helpers';
import ManageMetadataFooter from './ManageMetadataFooter';
import { StatusState } from '../StatusIndicator/StatusIndicator';
import { getParameters } from 'datasetManagementUI/lib/util';

const t = (k: string, scope = 'dataset_management_ui') => I18n.t(k, { scope });

export const rewriteAst = (ast: UnAnalyzedAst, newColumns: ColumnLike<ColumnKey>[]): UnAnalyzedAst => {
  let newAst = newColumns.reduce((acc, column) => {
    const oldSelectedExpr = column.key;
    if (!oldSelectedExpr) return acc;

    const newRef: ColumnRef = {
      qualifier: null,
      type: 'column_ref',
      value: column.field_name
    };

    // regardless of explicit alias or not, we
    // need to update the expression name
    //  SELECT the_column WHERE the_column > 1
    // and they're changing "the_column" to "bar"
    // we don't need to update any references,
    // we just have to update the expression name to produce:
    //  SELECT the_column as bar WHERE the_column > 1

    // this check is to let us skip redundant aliases
    // we shouldn't need to worry about cases like
    // SELECT a, a, a
    // SELECT 'a', `a`
    // because the form already requires unique fieldnames to be submittable

    // We also need to update the name if you start with a field name foo, change to bar
    // then change back to foo. In this case, oldSelectedExpr.expr.value !== column.field_name
    // evaluates to false, yet there is still a stale value in the name key.
    if (
      !isColumnRef(oldSelectedExpr.expr) ||
      oldSelectedExpr.expr.value !== column.field_name ||
      (oldSelectedExpr.name && column.field_name !== oldSelectedExpr.name.name)
    ) {
      acc = updateExpressionName(acc, oldSelectedExpr, column.field_name);
    }

    if (
      oldSelectedExpr.name &&
      !(isColumnRef(oldSelectedExpr.expr) && oldSelectedExpr.expr.value === oldSelectedExpr.name.name)
    ) {
      // if the column name we're changing has an explicit alias, ie:
      //  SELECT the_column as foo WHERE foo > 1

      // and they're changing "foo" to "bar"
      // we need to update any reference to foo,
      // so we'd need to produce:
      //  SELECT the_column as bar WHERE bar > 1
      //
      // except if we have a query like:
      //  SELECT foo as foo WHERE foo > 1
      // and we're changing the name of 'foo' to 'bar', we don't want to update
      // any references, because we don't want to re-write it to
      //  SELECT bar as bar WHERE bar > 1, we want
      //  SELECT foo as bar WHERE foo > 1
      const oldRef: ColumnRef = {
        qualifier: null,
        type: 'column_ref',
        value: oldSelectedExpr.name.name
      };
      acc = updateReference(acc, oldRef, newRef);
    }
    return acc;
  }, ast);

  // We've assigned explicit alias where needed at this point, via updateExpressionName
  // so
  //  SELECT a, b
  //  merged with [{field_name: 'column_b'}, {field_name: 'column_a'}]
  // will become
  //  SELECT a AS column_a, b AS column_b
  // but we haven't set the order yet.
  // so if the user wants column_b, column_a we need to rebuild the selected exprs in the selection
  // to do this.
  // we'll match the right column based on explicit alias, if present, and the columnRef value if not
  // (since a column ref value matching fieldname is the only case where we skip giving an explicit alias)
  newAst = {
    ...newAst,
    selection: {
      ...newAst.selection,
      exprs: newColumns.map((nc) => {
        const selectedExpr = newAst.selection.exprs.find((se) => {
          if (!se.name?.name && isColumnRef(se.expr)) {
            return se.expr.value == nc.field_name;
          } else {
            return se.name?.name === nc.field_name;
          }
        });
        if (!selectedExpr) {
          console.error(nc, newAst.selection);
          throw new Error('This should not happen!');
        }
        return selectedExpr;
      })
    }
  };
  return newAst;
};

export interface Props {
  columns: ColumnLike<ColumnKey>[];
  form: Form<ColumnLike<ColumnKey>[]>;
  saveColumnMetadata: (
    c: ColumnLike<ColumnKey>[],
    compilation: QueryCompilationSucceeded | QueryCompilationFailed | undefined
  ) => Promise<void>;
  setFormErrors: (errors: any) => void;
  setFormState: (newState: ColumnLike<unknown>[] | undefined) => void;
  showFlash: (...whatever: any[]) => void;
  hideFlash: (id: string) => void;
  markFormDirty: () => void;
  markFormClean: () => void;
  markFormSubmitted: () => void;
  markFormUnsubmitted: () => void;
  hideAllFlashMessages: () => void;
  handleModalDismiss: () => void;
  approvals: any[];
  view: View;
  revision: Revision;
  columnFormStatus: StatusState;
}

interface State {
  columnsFromQuery: Option<UnAnalyzedSelectedExpression[]>;
  query: Option<BinaryTree<UnAnalyzedAst>>;
  guidance: GuidanceSummaryV2 | undefined;
  channel: Option<PhxChannel>;
  error: Option<string>;
  loading: boolean;
}

class ManageColumnMetadata extends Component<Props, State> {
  state: State = {
    columnsFromQuery: none,
    query: none,
    error: none,
    guidance: undefined,
    channel: none,
    loading: true
  };

  componentDidMount = () => {
    fetchApprovalsGuidanceV2(assetIdFor(this.props.view.id, getRevisionSeq(), false)).then((guidance) => {
      this.setState({ guidance });
    });
    showFederatedHrefMessage(this.props.view, this.props.showFlash);

    if (isSoQLBasedRevision(this.props.revision)) {
      const ff = this.props.view.modifyingViewUid;
      const chan = socket(ff).channel(`query_compiler:${ff}`);
      chan
        .join()
        .receive('ok', async () => {
          this.setState({ channel: some(chan) });
          await this.loadQuery(chan);
          this.doneLoading();
        })
        .receive('error', () => {
          this.showUselessError();
        });
    } else {
      this.doneLoading();
    }
  };

  componentWillUnmount = () => {
    this.state.channel.forEach((c) => c.leave());
  };

  isJoin = () =>
    isSoQLBasedRevision(this.props.revision) &&
    this.state.query
      .map(lastInChain)
      .map((u) => u.joins.length > 0)
      .getOrElseValue(false);

  loadQuery = async (chan: PhxChannel) => {
    const { revision } = this.props;
    const query = revision.metadata.queryString || this.props.view.queryString;
    const clientContextInfo = {
      variables: getParameters(revision.metadata.clientContext, revision.fourfour)
    };
    const pageSize = undefined;
    const currentPage = undefined;
    const compilation = await compileText(chan, query!, pageSize, currentPage, clientContextInfo);

    if (isCompilationSucceeded<QueryCompilationSucceeded>(compilation)) {
      const ast = lastInChain(compilation.unanalyzed);

      if (ast.selection.all_user_except.length > 0 || ast.selection.all_system_except != null) {
        this.setState({ error: some('Query has a star expression, column editing is unavailable.') });
      } else {
        const columnsWithSelectedExpr = _.zip(ast.selection.exprs, this.props.columns).map<
          ColumnLike<UnAnalyzedSelectedExpression>
        >(([selectedExpr, columnLike]) => {
          if (selectedExpr && columnLike) {
            return {
              ...columnLike,
              key: selectedExpr,
              sourceName: this.getSourceNameFromViews(selectedExpr, compilation)
            };
          } else {
            throw new Error('This should not happen');
          }
        });
        this.props.setFormState(columnsWithSelectedExpr);
      }
      this.setState({ query: some(compilation.unanalyzed) });
    } else if (isCompilationFailed(compilation)) {
      this.setState({
        error: some(compilation.soql_exception.english)
      });
    }
  };

  getColumns = (): Option<ColumnLike<ColumnKey>[]> => {
    if (this.state.loading) {
      return none;
    }

    const formState: ColumnLike<ColumnKey>[] = this.props.form.state || this.props.columns;
    return option(formState);
  };

  getSourceNameFromViews(
    selection: UnAnalyzedSelectedExpression,
    compilationResult: QueryCompilationSucceeded
  ): string {
    const expr = selection.expr;
    if (isColumnRef(expr)) {
      const viewKey = expr.qualifier ? compilationResult.tableAliases.realTables[expr.qualifier] || expr.qualifier : '_';
      const view: View = compilationResult.views[viewKey];
      if (view) {
        return view.name;
      } else if (compilationResult.tableAliases.virtualTables.includes(viewKey)) {
        return viewKey.slice(1);
      }
    }
    return '';
  }

  // batched change function so we don't trigger tons of re-renders in interactive
  // things like dragging columns around
  handleColumnChanges = (oldColumns: ColumnLike<ColumnKey>[]) => (newColumns: ColumnLike<unknown>[]) => {
    this.props.markFormDirty();
    this.props.setFormErrors(validateColumns(newColumns));
    this.props.setFormState(newColumns);
  };

  isDisabled = () => {
    if (this.props.columnFormStatus === 'ERRORED') {
      return true;
    }
    return this.state.loading || isSoQLBasedRevision(this.props.revision)
      ? this.state.channel.isEmpty || this.state.query.isEmpty
      : false;
  };

  generateQuery = (
    newColumns: ColumnLike<ColumnKey>[]
  ): Promise<QueryCompilationSucceeded | QueryCompilationFailed> => {
    if (!this.state.query.isDefined || !this.state.channel.isDefined) {
      this.showUselessError();
    }

    // the combinators + async/await turns into a mess.
    const query = this.state.query.get;
    const channel = this.state.channel.get;
    const ast = lastInChain(query);
    const newAst = rewriteAst(ast, newColumns);
    const parameters = {
      variables: getParameters(this.props.revision.metadata.clientContext, this.props.revision.fourfour)
    };
    return compileAST(channel, replaceLastInChain(query, newAst), 100, 0, true, parameters);
  };

  needsQueryRecompilation = (newColumns: ColumnLike<ColumnKey>[]) => {
    if (isSoQLBasedRevision(this.props.revision)) {
      // this is just a little optimization.
      // field_name and position are the only thing that come from the SoQL query
      // so we can skip recompilation if the user is just changing display names
      // or descriptions.
      const newProjection = newColumns.map((c) => c.field_name);
      const existingProjection = (this.props.revision.metadata.columns || []).map((c) => c.fieldName);
      const newNameProjection = newColumns.map((c) => c.display_name);
      const existingNameProjection = (this.props.revision.metadata.columns || []).map((c) => c.name);
      return (!_.isEqual(newProjection, existingProjection) || !_.isEqual(newNameProjection, existingNameProjection));
    }
    return false;
  };

  setLoading = () => {
    this.props.hideFlash('metadata_save_success');
    this.props.hideFlash('metadata_save_error');
    this.setState({ loading: true });
  };

  doneLoading = () => {
    this.setState({ loading: false });
  };

  showUselessError = () => {
    this.doneLoading();
    this.props.showFlash(
      'error',
      'metadata_save_error',
      t('validation_error_general', 'screens.edit_metadata')
    );
  };

  handleColumnFormSubmit = (columns: ColumnLike<ColumnKey>[]) => () => {
    this.setLoading();

    const normalAction = async () => {
      this.props.markFormClean();

      let queryCompilation = undefined;
      if (this.needsQueryRecompilation(columns)) {
        queryCompilation = await this.generateQuery(columns);
      }

      return this.props
        .saveColumnMetadata(columns, queryCompilation)
        .then(() => {
          this.props.showFlash(
            'success',
            'metadata_save_success',
            t('save_success', 'screens.edit_metadata'),
            3500
          );
          this.props.markFormSubmitted();
          this.props.setFormErrors({});
          this.doneLoading();
        })
        .then(() => {
          this.props.handleModalDismiss();
        })
        .catch((err) => {
          this.showUselessError();
          this.props.markFormDirty();
          this.props.setFormErrors(err.errors);
          this.doneLoading();
        });
    };

    return (
      this.state.guidance && requireApprovalRequestWithdrawalBeforeAction(this.state.guidance, normalAction)
    );
  };

  render() {
    const columns = this.getColumns();
    return (
      <div id="manage-metadata">
        <Modal fullScreen onDismiss={this.props.handleModalDismiss} className={'no-sidebar-modal'}>
          <ModalHeader
            title={t('metadata_manage.column_tab.title')}
            onDismiss={this.props.handleModalDismiss}
          />
          <ModalContent>
            {columns
              .map((cols) => (
                <ColumnForm
                  hideable={isDefaultView(this.props.view)}
                  columns={cols}
                  handleColumnChanges={this.handleColumnChanges(cols)}
                  handleColumnFormSubmit={this.handleColumnFormSubmit(cols)}
                  displaySource={this.isJoin()}
                />
              ))
              .getOrElseValue(<Spinner />)}
          </ModalContent>
          {columns
            .map((cols) => (
              <ManageMetadataFooter
                buttonName="submit-column-form"
                form={this.props.form}
                loading={this.state.loading}
                disabled={this.isDisabled()}
                handleClick={this.handleColumnFormSubmit(cols)}
                handleModalDismiss={this.props.handleModalDismiss}
              />
            ))
            .getOrElseValue(<></>)}
        </Modal>
      </div>
    );
  }
}

export { ManageColumnMetadata };

export default DragDropContext(HTML5Backend)(ManageColumnMetadata); // eslint-disable-line new-cap
