import {
  SCHEMA_MISMATCH_UPDATE_MAPPING,
  SCHEMA_MISMATCH_CLEAR_MAPPING
} from '../../reduxStuff/actions/schemaMismatch';

const initialState = {
  mappings: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SCHEMA_MISMATCH_UPDATE_MAPPING: {
      return {
        ...state,
        mappings: {
          ...state.mappings,
          [action.fromColumnFieldName]: action.toColumnFieldName
        }
      };
    }
    case SCHEMA_MISMATCH_CLEAR_MAPPING: {
      return initialState;
    }
    default:
      return state;
  }
};
