import { connect } from 'react-redux';
import _ from 'lodash';
import * as LoadDataActions from 'datasetManagementUI/reduxStuff/actions/loadData';
import TableBodyPlaceholder from 'datasetManagementUI/components/TableBodyPlaceholder/TableBodyPlaceholder';
import * as ModeGrantActions from 'datasetManagementUI/reduxStuff/actions/modeGrant';

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isViewer: ModeGrantActions.isViewer(_.get(state.ui, 'modeGrant.grant', ''))
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadUnloadedData: () => dispatch(LoadDataActions.loadUnloadedSource(ownProps.params.sourceId))
});

export default connect(mapStateToProps, mapDispatchToProps)(TableBodyPlaceholder);
