import _ from 'lodash';
import PropTypes from 'prop-types';

// checks whether in editMode as opposed to ingressMode
export function isEditMode(view = {}) {
  return view.displayType !== 'draft';
}

export function modes(entities, params) {
  const view = entities.views[params.fourfour];
  if (!view) return { edit: false, update: false };

  const edit = isEditMode(view);

  const revisionSeq = _.toNumber(params.revisionSeq);
  const revision = _.find(entities.revisions, { fourfour: params.fourfour, revision_seq: revisionSeq });
  const update = edit && revision && revision.action && revision.action.type === 'update';
  return {
    edit,
    update
  };
}

const shape = PropTypes.shape({
  edit: PropTypes.bool.isRequired,
  update: PropTypes.bool.isRequired
});

export default {
  modes,
  shape
};
