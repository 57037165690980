import PropTypes from 'prop-types';
import React from 'react';
import DSMUIIcon from '../DSMUIIcon';
import I18n from 'common/i18n';

const t = (k, scope = 'dataset_management_ui.add_col') => I18n.t(k, { scope });

const SchemaPreviewTable = ({ outputColumns }) => {
  const rows = outputColumns.map((oc, idx) => {

    return (
      <tr className={oc.newCol ? 'new-col' : ''} key={idx}>
        <td className="name">{oc.display_name}</td>
        <td className="visibility">
          {oc.flags && oc.flags.includes('hidden') ?
            <span>
              <DSMUIIcon className="visbility-icon" name="eye-blocked" />
              <span className="hidden-label">{t('hidden')}</span>
            </span>
           : <DSMUIIcon className="visbility-icon" name="eye" />
          }
        </td>
        <td>
          {oc.transform.output_soql_type ? t(oc.transform.output_soql_type.toLowerCase(), 'dataset_management_ui.show_output_schema.column_header.type_display_names') : ''}
        </td>
      </tr>
    );
  });

  return (
    <figure className="fig schema-preview">
      <figcaption>{t('schema_preview')}</figcaption>
      <table className="schema-preview-table">
        <thead>
          <tr>
            <th className="th">{t('display_name')}</th>
            <th className="th">{t('visibility')}</th>
            <th className="th">{t('type')}</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </figure>
  );
};

SchemaPreviewTable.propTypes = {
  outputColumns: PropTypes.array.isRequired
};

export default SchemaPreviewTable;
