import I18n from 'common/i18n';
import { ManageMetadataModalProps } from 'datasetManagementUI/containers/ManageMetadataModalContainer';
import React from 'react';
import ConfirmationModal from 'common/components/ConfirmationDialog/ConfirmationModal';
import './ManageMetadataModal.scss';

const t = (k: string) => I18n.t(k, { scope: 'screens.edit_metadata' });

function ManageMetadataModal({
  cancelClose,
  yesReallyClose,
  pathToNewOutputSchema
}: ManageMetadataModalProps) {
  return (
    <ConfirmationModal
      onAgree={() => yesReallyClose(pathToNewOutputSchema)}
      agreeButtonText={t('unsaved_changes_close_button')}
      onCancel={cancelClose}
      cancelButtonText={t('continue_editing')}
      headerText={t('unsaved_changes_warning')}
    >
      {t('unsaved_changes_message')}
    </ConfirmationModal>
  );
}

export default ManageMetadataModal;
