import _ from 'lodash';
import { combineReducers } from 'redux';
import dotProp from 'dot-prop-immutable';
import { ADD_TASK_SET, EDIT_TASK_SET } from 'datasetManagementUI/reduxStuff/actions/taskSets';
import { EDIT_VIEW } from 'datasetManagementUI/reduxStuff/actions/views';
import { EDIT_OUTPUT_COLUMN, ADD_OUTPUT_COLUMNS } from 'datasetManagementUI/reduxStuff/actions/outputColumns';
import { EDIT_OUTPUT_SCHEMA } from 'datasetManagementUI/reduxStuff/actions/outputSchemas';
import { EDIT_TRANSFORM } from 'datasetManagementUI/reduxStuff/actions/transforms';
import { EDIT_INPUT_SCHEMA } from 'datasetManagementUI/reduxStuff/actions/inputSchemas';
import { EDIT_INPUT_COLUMN } from 'datasetManagementUI/reduxStuff/actions/inputColumns';
import { UPDATE_PROGRESS, CHUNK_COMPLETED } from 'datasetManagementUI/reduxStuff/actions/uploadFile';
import { SOURCE_UPDATE } from 'datasetManagementUI/reduxStuff/actions/createSource';
import { EDIT_REVISION, SET_REVISION_VALUE } from 'datasetManagementUI/reduxStuff/actions/revisions';
import { AGENT_CREATED, AGENT_UPDATED, AGENTS_INDEXED } from 'datasetManagementUI/reduxStuff/actions/agents';
import { SET_SCHEDULE } from 'datasetManagementUI/reduxStuff/actions/schedules';
import { SET_CONFIG } from 'datasetManagementUI/reduxStuff/actions/configs';
import { PLUGINS_INDEXED } from 'datasetManagementUI/reduxStuff/actions/plugins';
import { SCAN_RESULT } from 'datasetManagementUI/reduxStuff/actions/scanResult';
import { parseDate } from 'datasetManagementUI/lib/parseDate';

import withBatching from './batching';

const schedules = (state = {}, action) => {
  switch (action.type) {
    case SET_SCHEDULE: {
      return {
        ...state,
        [action.id]: action.schedule
      };
    }

    default:
      return state;
  }
};

const configs = (state = {}, action) => {
  switch (action.type) {
    case SET_CONFIG: {
      return {
        ...state,
        [action.id]: action.config
      };
    }

    default:
      return state;
  }
};


const views = (state = {}, action) => {
  switch (action.type) {
    case EDIT_VIEW: {
      return dotProp.set(state, action.id, record => ({
        ...record,
        ...action.payload
      }));
    }

    default:
      return state;
  }
};

const revisions = (state = {}, action) => {
  switch (action.type) {
    case EDIT_REVISION: {
      return dotProp.set(state, action.id, record => {
        // preserve record's top level metadata keys, even if missing from payload
        const metadataMerge = { metadata: { ...record.metadata, ...action.payload.metadata } };
        return {
          ...record,
          ...action.payload,
          ...metadataMerge
        };
      });
    }

    case SET_REVISION_VALUE: {
      return dotProp.set(state, action.path, action.value);
    }

    default:
      return state;
  }
};

const sources = (state = {}, action) => {
  switch (action.type) {
    case CHUNK_COMPLETED:
      return dotProp.set(state, action.sourceId, record => {
        return {
          ...record,
          chunksCompleted: (record.chunksCompleted || 0) + 1
        };
      });

    case UPDATE_PROGRESS:
      return dotProp.set(state, action.sourceId, record => ({
        ...record,
        percentCompleted: action.percentCompleted
      }));

    case SOURCE_UPDATE:
      return dotProp.set(state, action.sourceId, record => ({
        ...record,
        ...action.changes
      }));

    case SCAN_RESULT:
      return dotProp.set(state, action.sourceId, record => ({
        ...record,
        scanResult: action.result
      }));

    default:
      return state;
  }
};

const inputSchemas = (state = {}, action) => {
  switch (action.type) {
    case EDIT_INPUT_SCHEMA:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          ...action.payload
        }
      };
    default:
      return state;
  }
};

const inputColumns = (state = {}, action) => {
  switch (action.type) {
    case EDIT_INPUT_COLUMN:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          ...action.payload
        }
      };
    default:
      return state;
  }
};

const outputSchemas = (state = {}, action) => {
  switch (action.type) {
    case EDIT_OUTPUT_SCHEMA:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          ...action.payload
        }
      };
    default:
      return state;
  }
};

const outputColumns = (state = {}, action) => {
  switch (action.type) {
    case EDIT_OUTPUT_COLUMN:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          ...action.payload
        }
      };
    case ADD_OUTPUT_COLUMNS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const outputSchemaColumns = (state = {}) => state;

const transforms = (state = {}, action) => {
  switch (action.type) {
    case EDIT_TRANSFORM:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          ...action.payload
        }
      };
    default:
      return state;
  }
};


const withTimestamps = (entity) => {
  const putDate = (k) => {
    const val = entity[k];
    entity[k] = val && _.isString(val) ? parseDate(val) : val;
    return entity;
  };
  if (entity) {
    putDate('created_at');
    putDate('finished_at');
    putDate('failed_at');
    putDate('updated_at');
  }
  return entity;
};

const taskSets = (state = {}, action) => {
  switch (action.type) {
    case ADD_TASK_SET:
      return {
        ...state,
        [action.id]: withTimestamps(action.taskSet)
      };
    case EDIT_TASK_SET:
      return {
        ...state,
        [action.id]: withTimestamps({
          ...state[action.id],
          ...action.change
        })
      };
    default:
      return state;
  }
};


const emailInterests = (state = {}) => state;

const rowErrors = (state = {}) => state;

const colData = (state = {}) => state;

const agents = (state = {}, action) => {
  switch (action.type) {
    case AGENTS_INDEXED:
      return action.agents.reduce((acc, agent) => ({
        ...acc,
        [agent.agent_uid]: agent
      }), {});
    case AGENT_CREATED:
      return {
        ...state,
        [action.agent.agent_uid]: action.agent
      };
    case AGENT_UPDATED:
      return {
        ...state,
        [action.agent.agent_uid]: action.agent
      };
    default:
      return state;
  }
};

const plugins = (state = {}, action) => {
  switch (action.type) {
    case PLUGINS_INDEXED:
      return action.plugins.reduce((acc, plugin) => ({
        ...acc,
        [plugin.type]: plugin
      }), {});
    default:
      return state;
  }
};

export default combineReducers({
  views,
  revisions,
  sources,
  input_schemas: inputSchemas,
  input_columns: inputColumns,
  output_schemas: outputSchemas,
  output_columns: outputColumns,
  output_schema_columns: outputSchemaColumns,
  transforms: withBatching(transforms),
  task_sets: taskSets,
  email_interests: emailInterests,
  row_errors: rowErrors,
  col_data: colData,
  agents,
  schedules,
  configs,
  plugins
});
