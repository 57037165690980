import { VQEColumn } from 'common/explore_grid/redux/store';
import { SoQLType } from 'common/types/soql';
import { ColumnFormat, ViewColumn } from 'common/types/viewColumn';
import { UnAnalyzedSelectedExpression } from 'common/types/soql';

export function viewColumnsToColumnLike(viewColumns: ViewColumn[] | VQEColumn[]): ColumnLike<never>[] {
  return viewColumns.map((vc) => ({
    flags: vc.flags || [],
    description: vc.description,
    display_name: vc.name || null,
    field_name: vc.fieldName,
    format: vc.format,
    output_soql_type: vc.dataTypeName
  }));
}

export function columnLikeToViewColumn(columnLikes: ColumnLike<any>[]): Partial<ViewColumn>[] {
  return columnLikes.map((cl, i) => ({
    flags:[],
    fieldName: cl.field_name,
    name: cl.display_name || cl.field_name,
    description: cl.description,
    format: cl.format,
    dataTypeName: cl.output_soql_type,
    position: i + 1
  }));
}

export interface ColumnLike<T> {
  flags: string[];
  format: ColumnFormat;
  field_name: string;
  display_name: string | null;
  description: string;
  initial_output_column_id?: number;
  id?: number;
  key?: T;
  output_soql_type: SoQLType;
  sourceName?: string;
}

export type Columns = ColumnLike<unknown>[];
export type ColumnKey = UnAnalyzedSelectedExpression | undefined;
