import cx from 'classnames';
import airbrake from 'common/airbrake';
import ceteraUtils from 'common/cetera/utils';
import DataChange from 'common/components/AssetChanges/DataChange';
import MetadataChange from 'common/components/AssetChanges/MetadataChange';
import SchemaChange from 'common/components/AssetChanges/SchemaChange';
import { Tense } from 'common/components/AssetChanges/util';
import Checkbox from 'common/components/Checkbox';
import HelperTooltip from 'common/components/HelperTooltip';
import { ModalContent, ModalFooter, ModalHeader } from 'common/components/Modal';
import { FeatureFlags } from 'common/feature_flags';
import I18n from 'common/i18n';
import { DsmapiResource } from 'common/types/dsmapi';
import { isSetSchemaPlanStep, MetadataDiff, Plan, PlanStep, Revision, SetSchemaPlanStep } from 'common/types/revision';
import { View } from 'common/types/view';
import { Params } from 'datasetManagementUI/lib/types';
import moment from 'moment';
import React, { Component } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { option, Option, some, none } from 'ts-option';
import { isEnrolled } from 'common/core/archival';
import ClientContextVariableChange from 'common/components/AssetChanges/ClientContextVariableChange';

const t = (k: string, options = {}) =>
  I18n.t(k, { ...options, scope: 'dataset_management_ui.revision_plan' });

// noidea what this thing is doing and i just need inter-module types to work
const withUseQueryHookHOC = (WrappedComponent: any) => {
  const UseQueryHOC = (props: { view: View }) => {
    const view = props.view;
    let numDerivedView = 0;

    const { data: derivedViewCount } = useQuery(
      `derivedViews-${view.id}`,
      async () => await fetchNumberOfDerivedViews(view),
      {
        // keep the value cached for 30 seconds
        staleTime: 30 * 1000
      }
    );

    if (derivedViewCount && derivedViewCount > 0) numDerivedView = derivedViewCount;

    return <WrappedComponent derivedViewCount={numDerivedView} {...props} />;
  };

  const queryClient = new QueryClient();

  return (props: any) => (
    <QueryClientProvider client={queryClient}>
      <UseQueryHOC {...props} />
    </QueryClientProvider>
  );
};

const fetchNumberOfDerivedViews = async (view: View): Promise<number> => {
  const childAssetsResponse = await ceteraUtils.query({ derivedFrom: view.id });

  if (childAssetsResponse?.resultSetSize === undefined) {
    airbrake.notify({
      error: `SetSchemaStep could not find get assets derived from ${view.id}`,
      context: { component: 'PublishingPlan' }
    });
    return Promise.reject(undefined);
  }

  return Promise.resolve(childAssetsResponse.resultSetSize);
};

// the reason these have hasChanges functions is so we don't add them at all
// to the list of steps if they can't display anything meaningful. if all elements
// say they can't display anything, then we just show a message saying changes can't
// be displayed. that's why this isn't a "real" component
export interface StepProps {
  step: PlanStep;
  key: any;
  derivedViewWarning: JSX.Element | null;
}
function getStep({ step, key, derivedViewWarning }: StepProps): React.ReactElement | null {
  // eslint-disable-line
  switch (step.type) {
    case 'apply_metadata': {
      return <MetadataChange key={key} step={step} tense={Tense.Present} />;
    }
    case 'set_schema': {
      return (
        <SchemaChange
          key={key}
          step={step}
          tense={Tense.Present}
          derivedViewWarning={derivedViewWarning || undefined}
        />
      );
    }
    case 'upsert_task':
      return <DataChange key={key} step={step} />;
    default:
      return null;
  }
}

function getStepsFromPlan(plan: Plan, derivedViewWarning: JSX.Element | null): (React.ReactElement | null)[] {
  const steps = plan.map((step, i) => getStep({ step, key: i, derivedViewWarning })).filter((step) => step !== null);

  // parameters also uses the metadata plan and we want it to use its own component so we add it manually
  // if metadata changes are present.
  const metadataStep = plan.find(v=> v.type === 'apply_metadata');
  if (metadataStep) {
    steps.push(<ClientContextVariableChange key={steps.length} step={metadataStep as MetadataDiff} tense={Tense.Present} />);
  }

  return steps;
}

interface NotesProps {
  view: View;
  revision: Revision;
  note: Option<string>;
  setNote: (s: Option<string>) => void;
}
interface NotesState {
  show: boolean;
}
class Notes extends Component<NotesProps, NotesState> {
  state: NotesState = {show: false};
  async componentDidMount() {
    this.setState({show: FeatureFlags.valueOrDefault('enable_asset_archival', false) && await isEnrolled(this.props.view)});
  }
  render() {
    const { setNote, note, revision } = this.props;
    if (!this.state.show) return null;
    return (
      <div className="revision-notes">
        <legend>
          <label htmlFor="revision-notes">{t('note_title')}</label>
          <HelperTooltip id="revision-notes-tt" content={t('note_explanation')} />
        </legend>

        <textarea
          name="revision-notes"
          onChange={(e) => setNote(some(e.currentTarget.value))}
          placeholder={t('note_placeholder')}
          value={note.getOrElseValue(revision.notes || '')}
        ></textarea>
      </div>
    );
  }
}

interface Props {
  getPlan: () => Promise<DsmapiResource<Plan>>;
  onCancelClick: () => void;
  onContinue: () => void;
  onConfirmButton: unknown; // Will override onContinue
  revision: Revision;
  view: View;
  derivedViewCount: number;
  params: Params;
  onUpdateRevision: (newRev: Revision, params: Params) => void;
}

interface State {
  plan: Plan | null;
  error: string | null;
  acceptedResponsibility: boolean;
  alert: boolean;
  requireAcceptance: boolean;
  note: Option<string>;
}

class PublishingPlan extends Component<Props, State> {
  state: State = {
    plan: null,
    error: null,
    acceptedResponsibility: false,
    alert: false,
    requireAcceptance: false,
    note: none
  };

  componentDidMount() {
    this.props
      .getPlan()
      .then(({ resource }) => {
        const requireAcceptance = option(resource.find(isSetSchemaPlanStep))
          .map(
            (step: SetSchemaPlanStep) =>
              step.operations.length > 0
          )
          .getOrElseValue(false);

        this.setState({
          error: null,
          plan: resource,
          requireAcceptance: requireAcceptance
        });
      })
      .catch(({ body }) => {
        const message = body ? body.message : t('internal_error');
        this.setState({
          error: message,
          plan: null
        });
      });
  }

  onToggleDerivedWarningAcceptance = () => {
    this.setState({ acceptedResponsibility: !this.state.acceptedResponsibility });
    if (this.state.alert) this.setState({ alert: false });
  };

  derivedViewWarning = () => {
    const checkboxAttributes = {
      checked: this.state.acceptedResponsibility,
      id: 'derived-warning-acceptance-check-box',
      onChange: this.onToggleDerivedWarningAcceptance
    };
    const derivedViewCount = this.props.derivedViewCount;
    if (derivedViewCount === 0) return null;

    return (
      <div className="alert warning">
        <p>
          <span className="socrata-icon-warning"></span>
          {t('warning_description', { derivedViewCount: derivedViewCount })}
        </p>
        <p>
          <a className="unstyled-link nonbold-link" href={`/d/${this.props.view.id}`} target="_blank">
            {t('info_link')}
          </a>
        </p>
        <form className={cx('derived-warning-acceptance-check-box', { required: this.state.alert })}>
          <Checkbox {...checkboxAttributes}>
            <span>{t('accept')}</span>
            {this.state.alert && (
              <span className="required-comment">
                <br />
                {t('please_confirm')}
              </span>
            )}
          </Checkbox>
        </form>
        <p>{t('support')}</p>
      </div>
    );
  };

  verifyAcceptance = () => {
    const onNext = () => {
      this.state.note.forEach((notes) => {
        this.props.onUpdateRevision(
          {
            ...this.props.revision,
            notes
          },
          this.props.params
        );
      });
      this.props.onContinue();
    };

    if (
      this.props.derivedViewCount > 0 &&
      this.state.requireAcceptance === true &&
      !this.state.acceptedResponsibility
    ) {
      this.setState({ alert: true });
    } else {
      onNext();
    }
  };

  render() {
    const { revision, onCancelClick, onConfirmButton } = this.props;
    const { error, plan } = this.state;
    const derivedViewWarning = this.derivedViewWarning();
    let revisionPlan: JSX.Element;

    if (error) {
      revisionPlan = <div className="alert error">{error}</div>;
    } else if (!plan) {
      revisionPlan = (
        <div className="getting-plan">
          <p>{t('gathering_changes')}</p>
          <div className="spinner-default spinner-large" />
        </div>
      );
    } else {
      const steps = getStepsFromPlan(plan, derivedViewWarning);
      revisionPlan = (
        <div className="revision-general">
          <div className="revision-updated">
            <table>
              <tbody>
                <tr>
                  <td className="attribute-name">{t('draft_created')}</td>
                  <td>{moment(revision.created_at).fromNow()}</td>
                </tr>
                <tr>
                  <td className="attribute-name">{t('draft_edited')}</td>
                  <td>{moment(revision.updated_at).fromNow()}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <Notes view={this.props.view} note={this.state.note} revision={revision} setNote={(s) => this.setState({ note: s })} />

          <div className="steps">{steps.length ? steps : <p>{t('no_changes')}</p>}</div>
        </div>
      );
    }
    const queryClient = new QueryClient();

    const confirmButton = onConfirmButton || (
      <button
        key="continue"
        onClick={() => plan && this.verifyAcceptance()}
        className={`btn btn-primary continue-button ${!plan && 'btn-disabled'}`}
      >
        {t('continue')}
      </button>
    );

    return (
      <div className="publishing-plan">
        <ModalHeader onDismiss={onCancelClick} title={t('review_changes')}>
          <span>{t('see_what_changed')}</span>
        </ModalHeader>
        <ModalContent>
          <QueryClientProvider client={queryClient}>{revisionPlan}</QueryClientProvider>
        </ModalContent>
        <ModalFooter>
          <button key="cancel" onClick={onCancelClick} className="btn btn-default cancel-button">
            {t('cancel')}
          </button>
          {confirmButton}
        </ModalFooter>
      </div>
    );
  }
}

export default withUseQueryHookHOC(PublishingPlan);
