import I18n from 'common/i18n';
import { isColumnRef } from 'common/types/soql';
import Fieldset from 'datasetManagementUI/components/Fieldset/Fieldset';
import WithFlash from 'datasetManagementUI/containers/WithFlashContainer';
import { ColumnLike, Columns, ColumnKey } from 'datasetManagementUI/lib/columnLike';
import React, { Component } from 'react';
import DraggableColumnRow from './DraggableColumnRow';

const t = (k: string) => I18n.t(k, { scope: 'dataset_management_ui.metadata_manage.column_tab' });

interface Props {
  columns: ColumnLike<ColumnKey>[];
  hideable: boolean;
  handleColumnChanges: (newColumns: Columns) => void;
  handleColumnFormSubmit: (e: React.FormEvent<HTMLInputElement | HTMLFormElement>) => void;
  displaySource: boolean;
}
interface State {
  draggingColumnFieldName: string | null;
  justDroppedColumnFieldName: string | null;
}

class ColumnForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      draggingColumnFieldName: null,
      justDroppedColumnFieldName: null
    };
  }

  onDragColumn(column: ColumnLike<unknown>, newPosition: number) {
    this.setState({ draggingColumnFieldName: column.field_name });

    const others = this.props.columns.filter((c) => c.field_name !== column.field_name);
    const newPositionBaseZero = Math.max(0, newPosition - 1);

    const left = others.slice(0, newPositionBaseZero);
    const right = others.slice(newPositionBaseZero);
    const changes = [...left, { ...column }, ...right].map((c, i) => ({
      ...c,
      position: i + 1
    }));
    this.props.handleColumnChanges(changes);
  }

  onDropColumn() {
    this.setState({
      draggingColumnFieldName: null,
      justDroppedColumnFieldName: this.state.draggingColumnFieldName
    });
    setTimeout(() => this.setState({ justDroppedColumnFieldName: null }), 1000);
  }

  render() {
    const { handleColumnChanges, handleColumnFormSubmit, displaySource } = this.props;
    return (
      <WithFlash>
        <div id="column-form">
          <form className="dsmp-form" onSubmit={handleColumnFormSubmit}>
            <Fieldset>
              <div className="dsmp-row row-header" key="dsmp-row-header">
                <label className="block-label">{t('position')}</label>
                {this.props.hideable && <label className="block-label visibility-toggle">{t('visibility')}</label>}
                {displaySource && <label className="block-label">{'Source'}</label>}
                <div className="block-label-wrapper">
                  <label className="block-label label-required">
                    {t('display_name')}
                  </label>
                  <span className="block-label-subtitle">{t('name_subtitle')}</span>
                </div>
                <div className="column-description block-label-wrapper">
                  <label className="block-label column-description">{t('description')}</label>
                  <span className="block-label-subtitle">{t('description_subtitle')}</span>
                </div>
                <div className="block-label-wrapper">
                  <label className="block-label label-required">{t('field_name')}</label>
                  <span className="block-label-subtitle">{t('field_name_subtitle')}</span>
                </div>
              </div>
              <div>
                {this.props.columns.map((column, idx) => (
                  <DraggableColumnRow
                    column={column}
                    key={idx}
                    idx={idx}
                    hideable={this.props.hideable}
                    editFieldNameDisabled={
                      column.key?.expr && isColumnRef(column.key.expr) && column.key.expr.value.startsWith(':@') || false
                    }
                    displaySource={displaySource}
                    beingDragged={column.field_name === this.state.draggingColumnFieldName}
                    justDropped={column.field_name === this.state.justDroppedColumnFieldName}
                    handleColumnChange={(changed: ColumnLike<unknown>) => {
                      const newColumn = {
                        ...changed,
                        initial_output_column_id: column.initial_output_column_id || column.id
                      };

                      const newColumns = [
                        ...this.props.columns.slice(0, idx),
                        newColumn,
                        ...this.props.columns.slice(idx + 1)
                      ];
                      handleColumnChanges(newColumns);
                    }}
                    dragColumn={(draggedColumn: ColumnLike<unknown>, hoverPosition: number) => {
                      this.onDragColumn(draggedColumn, hoverPosition);
                    }}
                    dropColumn={() => this.onDropColumn()}
                  />
                ))}
              </div>
            </Fieldset>
            <input type="submit" id="submit-column-form" className="dsmp-hidden" value="submit" />
          </form>
        </div>
      </WithFlash>
    );
  }
}

export default ColumnForm;
