import { socrataFetch, checkStatus, getJson, getError } from 'datasetManagementUI/lib/http';
import * as dsmapiLinks from 'datasetManagementUI/links/dsmapiLinks';
import { downloadAgent } from 'common/downloadAgent';
import uuid from 'uuid';
import {
  apiCallStarted,
  apiCallSucceeded,
  apiCallFailed
} from 'datasetManagementUI/reduxStuff/actions/apiCalls';

export const AGENTS_INDEXED = 'AGENTS_INDEXED';
export const AGENT_CREATED = 'AGENT_CREATED';
export const AGENT_UPDATED = 'AGENT_UPDATED';
export const AGENTS_CHANNEL_JOINED = 'AGENTS_CHANNEL_JOINED';
export const PLUGINS_INDEXED = 'PLUGINS_INDEXED';

export const onAgentsChannelJoined = (channel) => ({
  type: AGENTS_CHANNEL_JOINED,
  channel
});

export const onAgentsIndexed = (agents) => ({
  type: AGENTS_INDEXED,
  agents
});

export const onAgentCreated = (agent) => ({
  type: AGENT_CREATED,
  agent
});

export const onAgentUpdated = (agent) => ({
  type: AGENT_UPDATED,
  agent
});

export const onPluginsIndexed = (plugins) => ({
  type: PLUGINS_INDEXED,
  plugins
});

export function getPlugins() {
  return socrataFetch('/api/publishing/v1/connection_agent/plugin')
    .then(checkStatus)
    .then(getJson)
    .catch(getError);
}

const push = (event, payload, getState) => {
  const chan = getState().ui.agents.channel;

  return new Promise((resolve, reject) => {
    if (chan) {
      chan.push(event, payload)
        .receive('ok', (resp) => {
          resolve(resp);
        })
        .receive('error', (resp) => {
          reject(resp);
        });
        // We are *not* putting a timeout on this
        // because some agents can take forever to
        // respond (ie: massive query that takes)
        // a long time to return. We have the tick
        // mechanism in place that will mark the
        // source as failed if something goes
        // wrong, and we're subscribed to the
        // source channel to get those updates.
      } else {
      reject('Not connected to channel');
    }
  });
};

export const listSources = (agentUid, namespace, path, page, filter) => (dispatch, getState) => (
  push(
    'list',
    {
      'agent_uid': agentUid,
      namespace: {
        name: namespace.name,
        type: namespace.type
      },
      path,
      page,
      filter

    },
    getState
  )
);

export const startSource = (sourceId) => (dispatch, getState) => (
  push(
    'give',
    { 'source_id': sourceId },
    getState
  )
);

export const currentVersion = () => {
  return socrataFetch(dsmapiLinks.currentAgentVersion(), {
    method: 'GET'
  })
  .then(checkStatus)
  .then(getJson)
  .catch(getError);
};

export const PROVISION_AGENT = 'PROVISION_AGENT';
export const provisionAgent = ({ name }) => (dispatch) => {
  const callId = uuid();
  const callParams = { name };
  const call = {
    operation: PROVISION_AGENT,
    callParams
  };

  dispatch(apiCallStarted(callId, call));

  return downloadAgent({ name })
    .then(() => {
      dispatch(apiCallSucceeded(callId));
    })
    .catch((error) => {
      dispatch(apiCallFailed(callId, error));
    });
};
