import _ from 'lodash';
import { connect } from 'react-redux';
import * as Selectors from 'datasetManagementUI/selectors';
import DraftTableRow from
  'datasetManagementUI/components/DatasetContentsTableRows/DraftTableRow';
import * as dsmapiLinks from 'datasetManagementUI/links/dsmapiLinks';

export function mapStateToProps({ entities }, { revisionSeq }) {
  const numericRevisionSeq = _.toNumber(revisionSeq);
  const revision = Selectors.currentRevision(entities, numericRevisionSeq);
  const source = Selectors.currentSource(entities, numericRevisionSeq);
  const currentSchema = Selectors.currentOutputSchema(entities, numericRevisionSeq);
  const inputSchema = currentSchema ?
    Selectors.inputSchemaFromOutputSchema(entities, currentSchema.id) :
    null;

  const sourceColumns = Selectors.columnsForOutputSchema(entities, currentSchema.id);
  const columnCount = sourceColumns.length;

  const fatalError = !!source.failed_at || _.some(sourceColumns.map(c => c.transform.failed_at));
  const totalRowCount =
    Selectors.totalRows(entities, numericRevisionSeq) ||
    Selectors.rowsTransformed(sourceColumns);

  let importRowCount;
  if (fatalError) {
    importRowCount = 0;
  } else {
    importRowCount = source.finished_at
      ? Selectors.rowsToBeImported(entities, currentSchema.id)
      : -1;
  }

  const errorRowCount = totalRowCount - importRowCount;

  const exportErrorsLink = source
    ? dsmapiLinks.errorExport(source.id, inputSchema.id, currentSchema.id)
    : undefined;

  const hasRowId = (currentSchema)
    ? sourceColumns.filter(column => (column.is_primary_key === true)).length > 0
    : false;

  let rowAction;
  if (revision.action.type === 'update') {
    rowAction = hasRowId ? 'update' : 'append';
  }

  const rowLabel = _.get(revision, 'metadata.metadata.rowLabel', '');

  const sourceType = _.get(source, 'source_type.type');
  const displayType = sourceType === 'view' ? 'info' : 'new';

  return {
    importRowCount,
    displayType,
    errorRowCount,
    columnCount,
    rowLabel,
    rowAction,
    exportErrorsLink
  };
}

export default connect(mapStateToProps)(DraftTableRow);
