import { fetchTranslation } from 'common/locale';
import TextInput from 'datasetManagementUI/components/TextInput/TextInput';
import _ from 'lodash';
import React, { KeyboardEvent } from 'react';

const t = (k: string) => fetchTranslation(k, 'dataset_management_ui.parse_options');
type InputEvent = React.ChangeEvent<HTMLInputElement>;

interface Props<T> {
  placeholder?: string;
  name: string;
  setOption: (v: string) => void;
  value: T;
  handleKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  error?: {
    message: string;
    value: T;
  };
}

export default function ParseOption<T>({ placeholder, name, setOption, value, error, handleKeyDown }: Props<T>) {
  let errorMessage = null;
  if (error) {
    errorMessage = <div className="option-error">{error.message}</div>;
  }

  return (
    <div>
      <label htmlFor={name}>{t(name)}</label>
      {errorMessage}
      <TextInput
        field={{
          placeholder: placeholder,
          name: name,
          label: name,
          value: _.toString(error ? error.value : value)
        }}
        handleChange={(e: InputEvent) => setOption(e.target.value)}
        handleKeyDown={handleKeyDown}
        inErrorState={!!error} />
    </div>
  );
}
