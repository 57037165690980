import { socket } from 'common/types/dsmapi';
import { fetchTranslation } from 'common/locale';
const t = (k) => fetchTranslation(k, 'dataset_management_ui.common');
import { showFlashMessage, hideFlashMessage } from './flashMessage';

export const SOCKET_CREATED = 'SOCKET_CREATED';
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const SOCKET_ERROR = 'SOCKET_ERROR';

export const socketCreated = (sock) => ({
  type: SOCKET_CREATED,
  socket: sock
});

export const socketConnected = () => ({
  type: SOCKET_CONNECTED
});

export const socketError = () => ({
  type: SOCKET_ERROR
});

const fourfour = () => window.initialState.view.id;
const token = () => window.serverConfig.websocketToken;
const createWebsocket = () => socket(fourfour(), token(), false);
const createLongPoll = () => socket(fourfour(), token(), true);

const hideSocketFlash = (dispatch) => {
  dispatch(hideFlashMessage('socket_disconnected'));
  dispatch(hideFlashMessage('socket_reconnected'));
};

const showDisconnectionError = () => (dispatch, getState) => {
  const state = getState().socket;
  hideSocketFlash(dispatch);
  // we only want to show the disconnection if either:
  // the connection has been established at some point, and there was an error
  // or - if we fail to start the websocket AND longpolling connections, ie: there
  // is more than one failed attempt to set up the socket
  if (state.connectionEstablishedOnce || state.connectionErrorCount > 1) {
    dispatch(
      showFlashMessage({
        kind: 'warning',
        id: 'socket_disconnected',
        message: t('disconnected')
      })
    );
  }
};

const showReconnectionSuccess = () => (dispatch, getState) => {
  if (!getState().socket.connectionEstablishedOnce) return;

  hideSocketFlash(dispatch);
  dispatch(
    showFlashMessage({
      kind: 'success',
      id: 'socket_reconnected',
      message: t('reconnected'),
      hideAfterMS: 3000
    })
  );
};

// socketCreator is a function which returns a socket, by default it returns
// a websocket
export const setupSocket =
  (socketCreator = createWebsocket) =>
  (dispatch) => {
    const bindSocketActions = (sock) => {
      sock.onOpen(() => {
        dispatch(showReconnectionSuccess());
        dispatch(socketConnected());
      });
      sock.onError(() => {
        dispatch(socketError());
        dispatch(showDisconnectionError());
        dispatch(maybeReInit);
      });
    };

    window.socket = socketCreator();
    bindSocketActions(window.socket);
    dispatch(socketCreated(window.socket));
    window.socket.connect();
  };

function maybeReInit(dispatch, getState) {
  const state = getState();
  if (!state.socket.connectionEstablishedOnce) {
    console.warn(
      'The browser says it supports websockets, but failed to open one, falling back to long polling...'
    );
    // if the connection has never been successfully established
    // we need to fall back to long polling. Phoenix will automatically
    // fall back in the case where the browser doesn't support websockets,
    // but often times customers have proxies which don't support
    // websockets, so we need to manually fall back to long polling if we
    // try to establish the connection and fail
    if (window.socket) window.socket.disconnect();
    window.socket = null;
    setupSocket(createLongPoll)(dispatch, getState);
  }
}
