import { get as _get, flowRight as _flowRight, some as _some, find as _find } from 'lodash';
import { View } from 'common/types/view';
import { ScanStatus, ScanResult } from 'common/components/ScanResult';
import { checkStatus, getJson, socrataFetch } from 'datasetManagementUI/lib/http';
import { OutputSchema, Transform } from 'common/types/dsmapiSchemas';
import { ClientContext, Revision } from 'common/types/revision';
import { hasOwnerLikeRights } from 'common/views/has_rights';

const MAX_PREVIEWABLE_BLOB_SIZE = 26214400;

export const singularOrPlural = (quantity: number, singularString: string, pluralString: string): string => {
  return quantity === 1 ? singularString : pluralString;
};

export function failedToCompile(transform: Transform): boolean {
  if (transform.failed_at && transform.failure_details) {
    return transform.failure_details.type === 'transform_compilation_failure';
  } else {
    return false;
  }
}

export const getComponentName = (component: { displayName?: string; name: string }): string =>
  component.displayName || component.name;

export function getUniqueName(arr: string[], name: string, count = 1): string {
  const newName = count > 1 ? `${name} ${count}` : name;
  if (!arr.includes(newName)) {
    return newName;
  } else {
    return getUniqueName(arr, name, count + 1);
  }
}

export function mergeRecords(existing = {}, updates = {}) {
  const updateKeys = Object.keys(updates);

  return updateKeys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: { ...existing[key], ...updates[key] }
    }),
    existing
  );
}

export const removeWhitespace = (str: string): string => str.replace(/\s/g, '_');

interface FieldNameFn {
  (existingNames: string[], newName: string): string;
}

export const getUniqueFieldName: FieldNameFn = _flowRight(removeWhitespace, getUniqueName);

export const titleCase = (word: string): string =>
  word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();

export function getBasename(url: string) {
  const name: string | undefined = url.split(/[\\/]/).pop();

  if (!name) {
    return '';
  }

  return name.split('?').shift();
}

export function getExtension(filename = '') {
  // check that we have an arg and that it is a string
  if (!filename || typeof filename !== 'string') {
    return '';
  }

  // find the index of the last '.' in the string
  const pos = filename.lastIndexOf('.');

  // if there was no '.' (lastIndexOf returned -1) or it was at the start of the
  // filename string (e.g. .htaccess)
  if (pos < 1) {
    return '';
  }

  return filename.slice(pos + 1);
}

export function getScanResult(scanResult?: ScanResult): ScanResult {
  if (scanResult) {
    return scanResult;
  }

  return {
    state: ScanStatus.Unscanned,
    scannedAt: null
  };
}

type BlobType = 'no_preview' | 'google_viewer' | 'image' | 'no_preview';

export function getBlobType(contentType?: string): BlobType {
  if (!contentType) {
    // sometimes it's null
    return 'no_preview';
  }
  // should match definitions in frontend/app/models/displays/blob.rb
  const googleViewables = [
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'image/tiff',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  const imageViewables = ['image/jpeg', 'image/gif', 'image/png'];

  if (
    _some(googleViewables, (ct) => {
      return contentType.match(ct);
    })
  ) {
    return 'google_viewer';
  } else if (
    _some(imageViewables, (ct) => {
      return contentType.match(ct);
    })
  ) {
    return 'image';
  } else {
    return 'no_preview';
  }
}

export function isBlobOverGoogleViewerSizeLimit(fileSize: number): boolean {
  return fileSize > MAX_PREVIEWABLE_BLOB_SIZE;
}

// If you're calling this function, you're on DSMP.
// that means you have publishing abilities UNLESS you're
// a legacy contributor, in which case you can edit revisions
// but not apply them.
export function hasPublishingAbilities(view: View) {
  return hasOwnerLikeRights(view);
}

interface Config {
  type: string;
}

export function getConfigurations(configType: string) {
  return socrataFetch(`/api/configurations.json?type=${configType}&defaultOnly=true&merge=false`)
    .then(checkStatus)
    .then(getJson)
    .then((configs: Config[]) => {
      return _find(configs, (c) => c.type.toLowerCase() === configType);
    });
}

// obe means: the view is obe and will be obe for all eternity
export const isDraft = (view: View) => view.displayType === 'draft';
export const isEternalObe = (view: View) => !view.newBackend && !isDraft(view);
export const isEternalNbe = (view: View) => view.newBackend && !isDraft(view);

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getParameters = (clientContext: ClientContext | undefined, viewId: string) => {
  const initialParams = window.initialState!.clientContext!;
  if (clientContext && clientContext.clientContextVariables) {
    const revisionParams = clientContext.clientContextVariables.map((ccvc) => {
      return { ...ccvc, inherited: false, viewId: viewId };
    });
    return [...revisionParams, ...initialParams.filter((p) => p.inherited)];
  } else {
    return initialParams;
  }
};

// isNumber :: a -> Boolean
// verifies its input is a number, exluding NaN; _.isNumber(NaN) returns true, which
// we don't want here
export function isNumber(x: any) {
  return typeof x === 'number' && !isNaN(x);
}

// getOutputSchemaId :: Number -> Revision -> Number
export function getOutputSchemaId(
  idFromParams: number,
  revision: Revision | undefined,
  fallbackOS: OutputSchema | null
) {
  if (isNumber(idFromParams)) {
    return idFromParams;
  } else if (revision && revision.output_schema_id) {
    return revision.output_schema_id;
  } else {
    return fallbackOS ? fallbackOS.id : null;
  }
}
