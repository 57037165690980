import dotProp from 'dot-prop-immutable';
import { FormActionType, FormAction } from '../types';

export const addColInitialErrorState = [
  'displayName',
  'transform',
  'sourceColumnId',
  'fieldName',
  'description'
].reduce((acc, k) => {
  acc[k] = [];
  return acc;
}, {});

const initialState = {
  hasMetadataErrors: false,
  columnForm: {
    isDirty: false,
    showErrors: false,
    errors: [],
    submitted: false,
    state: undefined
  },
  parseOptionsForm: {
    isDirty: false,
    showErrors: false,
    state: {}
  },
  hrefForm: {
    isDirty: false,
    shouldExit: false,
    errors: []
  },
  addColForm: {
    isDirty: false,
    clearInternalState: false,
    errors: addColInitialErrorState,
    state: {},
    submitted: false
  },
  geocodeShortcutForm: {
    state: {},
    errors: {
      displayNameErrors: [],
      fieldNameErrors: []
    },
    showErrors: false,
    isDirty: false,
    submitted: false
  }
};

const forms = (state = initialState, action: FormAction) => {
  switch (action.type) {
    case FormActionType.markFormDirty:
      return dotProp.set(state, `${action.formName}.isDirty`, true);

    case FormActionType.markFormClean:
      return dotProp.set(state, `${action.formName}.isDirty`, false);

    case FormActionType.showFormErrors:
      return dotProp.set(state, `${action.formName}.showErrors`, true);

    case FormActionType.hideFormErrors:
      return dotProp.set(state, `${action.formName}.showErrors`, false);

    case FormActionType.setFormErrors:
      return dotProp.set(state, `${action.formName}.errors`, action.errors);

    case FormActionType.setFormState:
      return dotProp.set(state, `${action.formName}.state`, action.state);

    case FormActionType.appendFormError:
      return dotProp.set(state, `${action.formName}.errors`, (existingErrors: string) => [
        ...existingErrors,
        action.error
      ]);

    case FormActionType.clearInternalState:
      return dotProp.set(state, `${action.formName}.clearInternalState`, action.val);

    case FormActionType.setShouldExit:
      return dotProp.set(state, `${action.formName}.shouldExit`, action.shouldExit);

    case FormActionType.markFormSubmitted:
      return dotProp.set(state, `${action.formName}.submitted`, true);

    case FormActionType.markFormUnsubmitted:
      return dotProp.set(state, `${action.formName}.submitted`, false);

    case FormActionType.setHasMetadataErrors:
      return dotProp.set(state, 'hasMetadataErrors', action.hasMetadataErrors);

    default:
      return state;
  }
};

export default forms;
