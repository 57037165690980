import * as _ from 'lodash';
import React from 'react';
import { fetchTranslation } from 'common/locale';
import ParseOption from './ParseOption';

const t = (k: string) => fetchTranslation(k, 'dataset_management_ui.parse_options');

interface RadioProps {
  name: string;
  value: string;
  onChange: (isChecked: boolean) => void;
  checked: boolean;
}
function Radio({ name, value, onChange, checked }: RadioProps) {
  return (
    <div className="delimiter-chooser">
      <input
        checked={checked}
        id={`choose-${name}`}
        type="radio"
        name="delimiter"
        value={value}
        onChange={(e) => onChange(e.target.checked)} />
      <label htmlFor={`choose-${name}`}>{t(name)}</label>
    </div>
  );
}



interface Props {
  value: string;
  setOption: (v: string) => void;
  error?: {
    message: string;
    value: string;
  };
}
interface State {
  showOther: boolean;
}


export default class DelimiterChooser extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showOther: !_.includes([',', ';', '\t'], this.props.value)
    };
  }

  render() {
    let other = null;
    if (this.state.showOther) {
      other = (
        <ParseOption<string>
          name={'column_separator'}
          error={this.props.error}
          placeholder={t('column_separator')}
          value={this.props.value}
          setOption={this.props.setOption} />
      );
    }

    const setIt = (value: string) => (checked: boolean) => {
      if (checked) {
        this.props.setOption(value);
        this.setState({ showOther: false });
      }
    };

    const setOther = (checked: boolean) => {
      this.setState({ showOther: checked });
    };

    const isChecked = (value: string) => (
      !this.state.showOther && this.props.value === value
    );

    return (
      <div>
        <div>
          <label htmlFor={'column_separator'}>{t('column_separator')}</label>
        </div>
        <Radio checked={isChecked(',')} onChange={setIt(',')} name="comma" value="," />
        <Radio checked={isChecked('\t')} onChange={setIt('\t')} name="tab" value="\t" />
        <Radio checked={isChecked(';')} onChange={setIt(';')} name="semicolon" value=";" />
        <Radio checked={this.state.showOther} onChange={setOther} name="other_separator" value="other" />
        {other}
      </div>
    );

  }
}
