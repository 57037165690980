import { ScanStatus } from 'common/components/ScanResult';
import getRevisionSeq from 'common/js_utils/getRevisionSeq';
import { socrataFetch, checkStatus, getJson } from 'datasetManagementUI/lib/http';
import * as dsmapiLinks from 'datasetManagementUI/links/dsmapiLinks';
import { getEnvironment } from 'common/environment';

export const SCAN_RESULT = 'SCAN_RESULT';

export function getScanResult(sourceId, revisionSeq = getRevisionSeq()) {
  return dispatch => {
    return socrataFetch(dsmapiLinks.scanResult(revisionSeq, sourceId))
    .then(checkStatus)
    .then(getJson)
    .then(result => {
      dispatch({
        type: SCAN_RESULT,
        sourceId,
        result
      });

      // If the environment is 'development', then we don't care about the scan result because there won't be one.
      if (result.state === ScanStatus.Unscanned
        && getEnvironment() !== 'development') {
        setTimeout(() => {
          dispatch(getScanResult(sourceId, revisionSeq));
        }, 1000);
      }
    });
  };
}
