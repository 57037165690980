import uuid from 'uuid';
import * as dsmapiLinks from 'datasetManagementUI/links/dsmapiLinks';
import { socrataFetch, checkStatus, getJson, getError } from 'datasetManagementUI/lib/http';
import { showFlashMessage, hideAllFlashMessages } from 'datasetManagementUI/reduxStuff/actions/flashMessage';
import {
  apiCallStarted,
  apiCallSucceeded,
  apiCallFailed
} from 'datasetManagementUI/reduxStuff/actions/apiCalls';
import { cellUpdated } from 'datasetManagementUI/reduxStuff/actions/loadData';
import { editTransform } from 'datasetManagementUI/reduxStuff/actions/transforms';

function updateCell(sourceId, transformId, offset, newValue) {
  return (dispatch) => {
    const params = { value: newValue };
    const callId = uuid();
    const url = dsmapiLinks.updateCell(sourceId, transformId, offset);
    dispatch(apiCallStarted(callId, params));
    return socrataFetch(url, {
      method: 'PUT',
      body: JSON.stringify(params)
    })
    .then(checkStatus)
    .then(getJson)
    .then(({ cell, transform }) => {
      dispatch(cellUpdated(transformId, offset, cell));
      dispatch(editTransform(transformId, transform));
      dispatch(apiCallSucceeded(callId));
      dispatch(hideAllFlashMessages());
    })
    .catch(getError)
    .catch((err) => {
      dispatch(apiCallFailed(callId, err));
      dispatch(showFlashMessage({ kind: 'error', id: 'update_cell_error', message: err.message }));
      throw err;
    });
  };
}

export {
  updateCell
};
