import PropTypes from 'prop-types';
import React from 'react';
import { Link, withRouter } from 'react-router';
import * as Links from 'datasetManagementUI/links/links';
import I18n from 'common/i18n';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.no_match'});

export function NoMatch({ params }) {
  return (
    <div id="dsmp-no-match">
      <h1>{t('title')}</h1>
      <p>{t('subtitle')}</p>
      <p>
        <Link to={Links.home(params)}>{t('suggestion')}</Link>
      </p>
    </div>
  );
}

NoMatch.propTypes = {
  params: PropTypes.object.isRequired
};

export default withRouter(NoMatch);
