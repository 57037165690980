import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router';
import LayerChooser from 'datasetManagementUI/components/LayerChooser/LayerChooser';
import * as Links from 'datasetManagementUI/links/links';
import { currentOutputSchema, latestOutputSchemaForInputSchema } from 'datasetManagementUI/selectors';

const mapStateToProps = ({ entities }, { params }) => {
  const sourceId = _.toNumber(params.sourceId);
  const inputSchemaId = _.toNumber(params.inputSchemaId);
  const revSeq = _.toNumber(params.revisionSeq);

  const currentInputSchema = entities.input_schemas[inputSchemaId];
  const currentOs = currentOutputSchema(entities, revSeq);

  const inputSchemasAndPaths = _.values(entities.input_schemas)
    .filter(is => is.source_id === sourceId)
    .map((is) => {
      let os;
      if (currentOs && currentOs.input_schema_id === is.id) {
        os = currentOs;
      } else {
        os = latestOutputSchemaForInputSchema(entities, is.id);
      }

      return {
        inputSchema: is,
        link: Links.showOutputSchema(params, sourceId, is.id, os.id)
      };
    });

  return {
    inputSchemasAndPaths,
    currentInputSchema
  };
};

export default withRouter(connect(mapStateToProps)(LayerChooser));
