import { AutosaveAction } from '../types';
import { AutosavingTypes } from '../actions/autosaving';

const initialState = {};

// used primarily to render spinners & error notifications
export default function apiCalls(state = initialState, action: AutosaveAction) {
  switch (action.type) {
    case AutosavingTypes.START_AUTOSAVE: {
      return {
        ...state,
        inProgress: true
      };
    }
    case AutosavingTypes.FINISH_AUTOSAVE: {
      return {
        ...state,
        inProgress: false
      };
    }
    default:
      return state;
  }
}
