import { FormActionType, FormAction } from '../types';
import { Errors } from 'datasetManagementUI/containers/AddColFormContainer';

export const markFormDirty = (formName: string): FormAction => ({
  type: FormActionType.markFormDirty,
  formName
});

export const markFormClean = (formName: string): FormAction => ({
  type: FormActionType.markFormClean,
  formName
});

export const showFormErrors = (formName: string): FormAction => ({
  type: FormActionType.showFormErrors,
  formName
});

export const hideFormErrors = (formName: string): FormAction => ({
  type: FormActionType.hideFormErrors,
  formName
});

export const setFormErrors = (formName: string, errors: Errors): FormAction => ({
  type: FormActionType.setFormErrors,
  formName,
  errors
});

export const setHasMetadataErrors = (hasMetadataErrors: boolean): FormAction => ({
  type: FormActionType.setHasMetadataErrors,
  hasMetadataErrors
});

export const appendFormError = (formName: string, error: string): FormAction => ({
  type: FormActionType.appendFormError,
  formName,
  error
});

export const setFormState = (formName: string, state: any): FormAction => ({
  type: FormActionType.setFormState,
  formName,
  state
});

export const clearInternalState = (formName: string, val: boolean): FormAction => ({
  type: FormActionType.clearInternalState,
  formName,
  val
});

export const setShouldExit = (formName: string, shouldExit: boolean): FormAction => ({
  type: FormActionType.setShouldExit,
  formName,
  shouldExit
});

export const markFormSubmitted = (formName: string): FormAction => ({
  type: FormActionType.markFormSubmitted,
  formName
});

export const markFormUnsubmitted = (formName: string): FormAction => ({
  type: FormActionType.markFormUnsubmitted,
  formName
});
