import PropTypes from 'prop-types';
import React from 'react';
import DSMUIIcon from '../DSMUIIcon';
import { COL_STATUS } from 'datasetManagementUI/components/TransformStatus/TransformStatus';

const StatusText = ({ message, status }) => {
  let icon;

  switch (status) {
    case COL_STATUS.DONE:
      icon = <DSMUIIcon name="checkmark3" className="status-icon success" />;
      break;
    case COL_STATUS.IN_PROGRESS:
      icon = <span className="spinner-default status-icon status-text-spinner" />;
      break;
    default:
      icon = null;
  }

  return (
    <div className="status-text">
      {icon}
      {message}
    </div>
  );
};

StatusText.propTypes = {
  message: PropTypes.string,
  status: PropTypes.string.isRequired
};

export default StatusText;
