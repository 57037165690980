import { UndoRedoHistory } from 'common/explore_grid/redux/store';

export const UPDATE_UNDO_REDO_HISTORY = 'UPDATE_UNDO_REDO_HISTORY';
export interface UpdateUndoRedoHistory {
  type: 'UPDATE_UNDO_REDO_HISTORY';
  vqeUndoRedoHistory: UndoRedoHistory;
}
export const updateUndoRedoHistory = (vqeUndoRedoHistory: UndoRedoHistory) => ({
  type: UPDATE_UNDO_REDO_HISTORY,
  vqeUndoRedoHistory
});
