import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import formatString from 'common/js_utils/formatString';
import I18n from 'common/i18n';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.show_output_schema.row_errors'});
export default function RowError({ row }) {
  const { rowError, rowIdx } = row;
  const escaper = (key, value) => {
    return typeof value === 'string' ? _.escape(value) : value;
  };
  const malformedRowContents = rowError.contents.map(v => JSON.stringify(v, escaper)).join(',');

  let malformedRowMessage;
  switch (rowError.type) {
    case 'too_short':
    case 'too_long':
      malformedRowMessage = {
        __html: formatString(t('error_message'),
          `<span>${rowError.wanted}</span>`,
          `<span>${rowError.got}</span>`
        )
      };
      break;
    default:
      throw new TypeError(`Unknown error type: ${rowError.type}`);
  }

  return (
    <tr key={rowIdx} className="malformed-row">
      <td className="dsmp-row-error-location">
        {formatString(t('row'),rowError.offset + 1)}
      </td>
      <td className="dsmp-row-error-message" dangerouslySetInnerHTML={malformedRowMessage} />
      <td className="dsmp-row-error-contents">
        {t('row_content')}: <span>{malformedRowContents}</span>
      </td>
    </tr>
  );
}

RowError.propTypes = {
  row: PropTypes.object.isRequired
};
