import _ from 'lodash';
import * as ModeGrantActions from 'datasetManagementUI/reduxStuff/actions/modeGrant';
import { connect } from 'react-redux';
import * as LoadDataActions from 'datasetManagementUI/reduxStuff/actions/loadData';
import * as WriteDataActions from 'datasetManagementUI/reduxStuff/actions/writeData';

import TableBody from 'datasetManagementUI/components/TableBody/TableBody';

const mapStateToProps = ({ ui }, ownProps) => {
  const isViewer = ModeGrantActions.isViewer(_.get(ui, 'modeGrant.grant', ''));

  return {
    apiCalls: ui.apiCalls,
    isViewer,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadVisibleData: () => (
    dispatch(LoadDataActions.loadVisibleData(ownProps.displayState, ownProps.columns))
    .catch(e => {
      if (e && e.type === 'not_called') {
        // just means we determined we didn't need to
        // load any data, so don't do anything
      } else {
        throw e;
      }
    })
  ),
  updateCell: (transformId, offset, newValue) => (
    dispatch(WriteDataActions.updateCell(ownProps.sourceId, transformId, offset, newValue))
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(TableBody);
