import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import * as Selectors from 'datasetManagementUI/selectors';
import * as Links from 'datasetManagementUI/links/links';
import { isSoQLBasedRevision } from 'common/types/revision';
import { updateRevision } from 'datasetManagementUI/reduxStuff/actions/revisions';
import {
  createNewOutputSchema,
  cloneOutputSchema
} from 'datasetManagementUI/reduxStuff/actions/showOutputSchema';
import { getOutputSchemaId } from 'datasetManagementUI/lib/util';
import { updateUndoRedoHistory } from 'datasetManagementUI/reduxStuff/actions/vqeUndoRedoHistory';
import { FormValidationError } from 'datasetManagementUI/containers/HrefFormContainer';
import * as FormActions from 'datasetManagementUI/reduxStuff/actions/forms';
import * as FlashActions from 'datasetManagementUI/reduxStuff/actions/flashMessage';
import * as MetadataActions from 'datasetManagementUI/reduxStuff/actions/manageMetadata';
import { viewColumnsToColumnLike, columnLikeToViewColumn } from 'datasetManagementUI/lib/columnLike';
import I18n from 'common/i18n';
import { isCompilationSucceeded, isCompilationFailed } from 'common/types/compiler';
import { StatusState } from 'datasetManagementUI/components/StatusIndicator/StatusIndicator';
import { some } from 'ts-option';
import ManageColumnMetadataForm from 'datasetManagementUI/components/ManageMetadata/ManageColumnMetadata';
import { validateColumns } from 'datasetManagementUI/lib/columnValidators';

const t = (k, scope = 'dataset_management_ui.metadata_manage.dataset_tab') => I18n.t(k, { scope });

// ==========
// CONSTANTS
// ==========
export const COL_FORM_NAME = 'columnForm';

export const getRevision = (revisions = {}, revisionSeq) => {
  if (_.isNumber(revisionSeq)) {
    return _.values(revisions).find((revision) => revision.revision_seq === revisionSeq);
  }
  return;
};

// hasColumnErrors :: { [String] : ColumnError } -> Boolean
export function hasColumnErrors(ui) {
  // this should be the result of validateColumns, but from the redux state
  const colFormErrors = _.get(ui, 'forms.columnForm.errors', {});
  if (isCompilationFailed(colFormErrors)) {
    return true;
  }
  return columnValidationFailed(colFormErrors);
}

function columnValidationFailed(validation) {
  const errors = _.flatMap(_.values(validation), (col) => col.display_name.concat(col.field_name));
  return errors.length > 0;
}

// isNumber :: a -> Boolean
// verifies its input is a number, exluding NaN; _.isNumber(NaN) returns true, which
// we don't want here
function isNumber(x) {
  return typeof x === 'number' && !isNaN(x);
}

// getOutputSchemaCols :: Entities Number -> (Array OutputColumn) | undefined
export function getOutputSchemaCols(entities, outputSchemaId) {
  let cols;

  if (isNumber(outputSchemaId)) {
    cols = Selectors.columnsForOutputSchema(entities, outputSchemaId);
  }

  return cols;
}

function isBadArg(thing) {
  return thing == undefined || thing === '';
}

export function getModalRedirectLink(params, sId, osId, isId) {
  if (isBadArg(sId) || isBadArg(osId) || isBadArg(isId)) {
    return Links.home(params);
  } else {
    return Links.showOutputSchema(params, sId, isId, osId);
  }
}

const columnFormStatus = (ui) => {
  const form = ui.forms.columnForm;
  if (hasColumnErrors(ui)) {
    return StatusState.ERRORED;
  }
  if (form.isDirty) {
    return StatusState.UNSAVED;
  }
  if (form.submitted) {
    return StatusState.SAVED;
  }
  return StatusState.INITIALIZED;
};

const mapStateToProps = ({ entities, ui }, { params }) => {
  const view = entities.views[params.fourfour];
  const revisionSeq = Number(params.revisionSeq);
  const revision = getRevision(entities.revisions, revisionSeq) || {};
  const approvals = entities.views[params.fourfour].approvals;

  const form = ui.forms[COL_FORM_NAME];
  const outputSchemaId = getOutputSchemaId(
    Number(params.outputSchemaId),
    revision,
    Selectors.currentOutputSchema(entities, Number(params.revisionSeq))
  );

  const columns =
    (isSoQLBasedRevision(revision)
      ? viewColumnsToColumnLike(revision.metadata.columns)
      : getOutputSchemaCols(entities, outputSchemaId)) || [];
  const { source, inputSchema, outputSchema } = Selectors.treeForOutputSchema(entities, outputSchemaId);
  const sourceId = source && source.id;
  const inputSchemaId = inputSchema && inputSchema.id;
  const redirectLink = getModalRedirectLink(params, sourceId, outputSchemaId, inputSchemaId);
  const { vqeUndoRedoHistory } = ui;

  return {
    approvals,
    view,
    form,
    columns,
    columnsExist: columns.length > 0,
    redirectLink,
    inputSchemaId,
    outputSchemaId,
    outputSchema,
    revision,
    columnFormStatus: columnFormStatus(ui),
    vqeUndoRedoHistory
  };
};

// handleServerErrors :: { display_name : Array {value : String, position: Int}, field_name: Array {value : String, position: Int} }
//  -> { [ColId] : { display_name : Array String , field_name : Array String } }
export function handleServerErrors(errorDetails = {}, columns = []) {
  const displayNameErrors = errorDetails.display_name || [];
  const fieldNameErrors = errorDetails.field_name || [];

  return columns.reduce((acc, col) => {
    const hasDisplayNameError = displayNameErrors.find((error) => error.value === col.display_name);
    const hasFieldNameError = fieldNameErrors.find((error) => error.value === col.field_name);

    const errors = {};

    if (hasDisplayNameError) {
      errors.display_name = [t('validation_error_dupe', 'screens.edit_metadata')];
    }

    if (hasFieldNameError) {
      errors.field_name = [t('validation_error_dupe', 'screens.edit_metadata')];
    }

    if (_.isEmpty(errors)) {
      return acc;
    } else {
      return {
        ...acc,
        [col.id]: errors
      };
    }
  }, {});
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  showFlash: (type, id, msg, hideAfter = undefined, helpMsg = undefined, helpLink = undefined) =>
    dispatch(
      FlashActions.showFlashMessage({
        kind: type,
        id: id,
        message: msg,
        hideAfterMS: hideAfter,
        helpMessage: helpMsg,
        helpUrl: helpLink
      })
    ),
  hideFlash: (id) => dispatch(FlashActions.hideFlashMessage(id)),
  hideAllFlashMessages: () => dispatch(FlashActions.hideAllFlashMessages()),
  handleModalDismiss: (path, formStatus) =>
    dispatch(
      MetadataActions.dismissMetadataPane(path, ownProps.params, formStatus, false, ownProps.toggleFormOpen)
    ),
  setFormErrors: (errors) => dispatch(FormActions.setFormErrors(COL_FORM_NAME, errors)),
  setFormState: (state) => dispatch(FormActions.setFormState(COL_FORM_NAME, state)),
  markFormDirty: () => dispatch(FormActions.markFormDirty(COL_FORM_NAME)),
  markFormClean: () => dispatch(FormActions.markFormClean(COL_FORM_NAME)),
  markFormSubmitted: () => dispatch(FormActions.markFormSubmitted(COL_FORM_NAME)),
  markFormUnsubmitted: () => dispatch(FormActions.markFormUnsubmitted(COL_FORM_NAME)),
  saveColumnMetadata: (revision, inputSchemaId, outputSchema, vqeUndoRedoHistory) => {
    if (isSoQLBasedRevision(revision)) {
      return (columns, queryCompilation) => {
        let metadata = {
          ...revision.metadata,
          columns: columnLikeToViewColumn(columns)
        };

        // Sometimes, for soql based revisions, we don't recompile the query, since it's not
        // required. If that's the case, queryCompilation will be undefined and we don't
        // need to update the query string at all. This happens when we update something like
        // a column description
        if (queryCompilation) {
          // Generate our own fake justApplied if EC has not been initialized or this is the first apply on the EC.
          vqeUndoRedoHistory.justApplied
            .orElseValue(
              some({
                queryText: revision.metadata.queryString,
                clientContext: {
                  variables: revision.metadata.clientContext.clientContextVariables.map((ccvCreate) => ({
                    ...ccvCreate,
                    inherited: false, // The only parameters on the revision should not have been inherited. Probably.
                    viewId: revision.fourfour
                  }))
                },
                columnMetadata: revision.metadata.columns
              })
            )
            .forEach((justApplied) => {
              dispatch(
                updateUndoRedoHistory({
                  ...vqeUndoRedoHistory,
                  undo: [...vqeUndoRedoHistory.undo, justApplied],
                  justApplied: some({
                    ...justApplied,
                    queryText: queryCompilation.text.get,
                    columnMetadata: columnLikeToViewColumn(columns)
                  })
                })
              );
            });
          if (isCompilationSucceeded(queryCompilation)) {
            metadata = { ...metadata, queryString: queryCompilation.text.get };
          } else if (isCompilationFailed(queryCompilation)) {
            return Promise.reject(new FormValidationError(COL_FORM_NAME, queryCompilation));
          }
        }

        return dispatch(updateRevision({ ...revision, metadata }, ownProps.params));
      };
    } else {
      return (columns) => {
        const result = validateColumns(columns);
        if (columnValidationFailed(result)) {
          return Promise.reject(new FormValidationError(COL_FORM_NAME, result));
        }

        const call = {
          operation: 'SAVE_COLUMN_METADATA',
          callParams: {}
        };

        const osBody = cloneOutputSchema(outputSchema);
        return dispatch(createNewOutputSchema(inputSchemaId, columns, call, true, osBody))
          .then((resp) => {
            browserHistory.push(Links.columnMetadataForm(ownProps.params, resp.resource.id));
          })
          .catch((err) => {
            let res;
            if (err.body && err.body.params && err.body.params.details) {
              res = handleServerErrors(err.body.params.details, columns);
            }

            if (!res || _.isEmpty(res)) {
              // not really using this, we just don't want the errors to be an empty {}
              // since we interpret that as no errors
              res = { generic: err.body.message };
            }

            throw new FormValidationError(COL_FORM_NAME, res);
          });
      };
    }
  }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    saveColumnMetadata: dispatchProps.saveColumnMetadata(
      stateProps.revision,
      stateProps.inputSchemaId,
      stateProps.outputSchema,
      stateProps.vqeUndoRedoHistory
    ),
    handleModalDismiss: () => dispatchProps.handleModalDismiss(stateProps.redirectLink, stateProps.form)
  };
};

export const ManageColumnMetadata = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ManageColumnMetadataForm);
