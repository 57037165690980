import { connect } from 'react-redux';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import InfoPane from 'common/components/InfoPane';
import * as Links from 'datasetManagementUI/links/links';
import * as ModeGrantActions from 'datasetManagementUI/reduxStuff/actions/modeGrant';
import * as Selectors from 'datasetManagementUI/selectors';

function mapStateToProps({ entities, ui }, { params }) {
  const revision = Selectors.currentRevision(entities, _.toNumber(params.revisionSeq)) || {};

  const outputSchema = _.get(entities, `output_schemas.${revision.output_schema_id}`, {});

  const inputSchema = _.get(entities, `input_schemas.${outputSchema.input_schema_id}`, {});

  const isViewer = ModeGrantActions.isViewer(_.get(ui, 'modeGrant.grant'));

  return {
    isViewer,
    isDraft: true,
    handleViewButtonClick: () =>
      browserHistory.push(
        Links.showOutputSchema(params, inputSchema.source_id, inputSchema.id, outputSchema.id)
      ),
    name: _.get(revision, 'metadata.name', ''),
    description: _.get(revision, 'metadata.description', ''),
    category: _.get(revision, 'metadata.category', '')
  };
}

export default connect(mapStateToProps)(InfoPane);
