import _ from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AgentNamespace from 'datasetManagementUI/components/AgentList/AgentNamespace';
import { hideModal, showModal } from 'datasetManagementUI/reduxStuff/actions/modal';
import * as SourceActions from 'datasetManagementUI/reduxStuff/actions/createSource';
import * as AgentActions from 'datasetManagementUI/reduxStuff/actions/agents';
import * as PluginActions from 'datasetManagementUI/reduxStuff/actions/plugins';
import * as Selectors from 'datasetManagementUI/selectors';
import { AppState, Params } from 'datasetManagementUI/lib/types';
import { Agent, Namespace } from 'common/types/gateway';

import { Plugin } from 'common/types/gateway';

interface NamespaceWithPlugin extends Namespace {
  plugin: Plugin;
}

interface StateProps {
  agent: Agent;
  plugins: Plugin[];
  namespace?: NamespaceWithPlugin;
}

interface MergeProps {
  getPlugins: () => void;
  showPluginHelp: (plugin: Plugin) => void;
  /* eslint @typescript-eslint/ban-types: "warn" */
  showAvailablePlugins: (showPluginHelp: Function, plugins: Plugin[], canSetupPlugins: boolean) => any;
  /* eslint @typescript-eslint/ban-types: "warn" */
  showProvisionAgent: (showAvailablePlugins: Function) => void;
  listSources: (pt: string, pg: number, ft: string) => Promise<any>;
  createSource: (path: string, humanPath: string) => (sourceParams: object) => Promise<any>;
  startSource: (source: { id: number }) => Promise<any>;
}

interface OwnProps {
  params: Params;
}

export type CombinedProps = StateProps & MergeProps & OwnProps;

const mapStateToProps = ({ entities }: AppState, { params }: { params: Params }): StateProps => {
  const rev = Selectors.currentRevision(entities, _.toNumber(params.revisionSeq));

  let agent;

  if (params.agent) {
    agent = entities.agents[params.agent];
  }

  let namespace;

  if (agent) {
    namespace = _.find(
      agent.namespaces,
      (ns: Namespace) => ns.name === params.nsname && ns.type === params.nstype
    );
  }

  return {
    agent,
    namespace: namespace ? { ...namespace, plugin: entities.plugins[namespace.type]} : undefined,
    plugins: Object.values(entities.plugins)
  };
};

const mergeProps = (stateProps: StateProps, { dispatch }: any, { params }: OwnProps): CombinedProps => {
  const { agent, namespace } = stateProps;

  return {
    ...stateProps,
    params,
    getPlugins: () => dispatch(PluginActions.getPlugins()),
    showPluginHelp: (plugin: Plugin) => dispatch(showModal('ProvisionPluginModal', {
      plugin,
      hideModal: () => dispatch(hideModal())
    })),
    showAvailablePlugins: (showPluginHelp: Function, plugins: Plugin[], canSetupPlugins: boolean) => dispatch(showModal('AvailablePlugins', {
      showPluginHelp,
      plugins,
      canSetupPlugins,
      hideModal: () => dispatch(hideModal())
    })),
    showProvisionAgent: (showAvailablePlugins: Function) => dispatch(showModal('ProvisionAgentModal', {
      showAvailablePlugins,
      hideModal: () => dispatch(hideModal())
    })),
    listSources: (path: string, page: number, filter: string) => {
      return dispatch(AgentActions.listSources(agent.agent_uid, namespace, path, page, filter));
    },
    createSource: (path: string, humanPath: string) => {
      // sourceParams are the optional plugin defined parameters that may influence the query that gets data
      return (sourceParams: object) => {
        return dispatch(SourceActions.createAgentSource(
          params,
          agent.agent_uid,
          namespace,
          path,
          humanPath,
          sourceParams
        )).catch((error: { message: string }) => {
          throw error;
        });
      };
    },
    startSource: (source: { id: number }) => {
      return dispatch(
        AgentActions.startSource(source.id)
      ).catch((error: { message: string }) => {
        throw error;
      });
    }
  };
};

export default connect(mapStateToProps, null, mergeProps)(AgentNamespace);
