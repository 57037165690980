/* eslint react/jsx-indent: 0 */
import I18n from 'common/i18n';
import { Revision } from 'common/types/revision';
import { Source } from 'common/types/source';
import { descriptionOf, getFilename } from 'datasetManagementUI/lib/sources';
import { Entities, Params } from 'datasetManagementUI/lib/types';
import * as Links from 'datasetManagementUI/links/links';
import * as Selectors from 'datasetManagementUI/selectors';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { IndexLink, Link } from 'react-router';

const t = (k: string, params: Record<string, any> = {}) => I18n.t(k, { ...params, scope: 'dataset_management_ui.show_uploads' });


export const SingleSourceItem = ({ entities, source, params, revision }: SingleSourceProps) => {
  const noBytesUploaded = !(source.finished_at || source.failed_at);
  if (_.isEmpty(source) || noBytesUploaded) {
    return null;
  }

  const outputSchema = Selectors.latestOutputSchemaForSource(entities, source.id);
  const isCurrent = outputSchema && revision.output_schema_id === outputSchema.id;

  const linkTarget = outputSchema
    ? Links.showOutputSchema(params, source.id, outputSchema.input_schema_id, outputSchema.id)
    : Links.showBlobPreview(params, source.id);

  return (
    <li>
      <Link to={linkTarget} className={isCurrent ? 'bold' : ''}>
        {getFilename(source, entities.views[params.fourfour])}
      </Link>
      <p>{descriptionOf(source)}</p>
      {outputSchema && (outputSchema.total_rows !== null) ? <p>{t('row_counts', outputSchema) }</p> : null}
      <div className="timestamp">{moment.utc(source.created_at).fromNow()}</div>
    </li>
  );
};

interface SingleSourceProps {
  revision: Revision;
  source: Omit<Source, 'schemas'>;
  entities: Entities;
  params: Params;
}

export const SourceList = ({ entities, params, sources, revision }: SourceListProps) => {
  return (
    <div className="source-list">
      <h2>{t('sources')}</h2>
      <ul>
        {sources.map((source, idx) => {
          return (
            <SingleSourceItem
              revision={revision}
              entities={entities}
              params={params}
              source={source}
              key={idx}
            />
          );
        })}
      </ul>
    </div>
  );
};

interface SourceListProps {
  revision: Revision;
  sources: Omit<Source, 'schemas'>[];
  entities: Entities;
  params: Params;
}

const SourceSidebar = ({ entities, revision, sources, params, hideHrefLink, hideImportLinks }: Props) => {
  return (
    <section className="dsmp-sidebar source-sidebar">
      {hideImportLinks || (
        <IndexLink to={Links.sources(params)} className="dsmp-tab sidebar-tab" activeClassName="selected">
          {t('upload_link')}
        </IndexLink>
      )}
      {hideImportLinks || (
        <Link to={Links.urlSource(params)} className="dsmp-tab sidebar-tab" activeClassName="selected">
          {t('url_link')}
        </Link>
      )}
      {hideImportLinks || (
        <Link to={Links.agentsSource(params)} className="dsmp-tab sidebar-tab" activeClassName="selected">
          {t('connections')}
        </Link>
      )}

      {hideHrefLink || (
        <Link to={Links.hrefSource(params)} className="dsmp-tab sidebar-tab" activeClassName="selected">
          {t('external_source')}
        </Link>
      )}
      {!!sources.length && (
        <SourceList revision={revision} entities={entities} sources={sources} params={params} />
      )}
    </section>
  );
};

interface Props {
  revision: Revision;
  sources: Omit<Source, 'schemas'>[];
  entities: Entities;
  params: Params;
  hideHrefLink: boolean;
  hideImportLinks: boolean;
}

export default SourceSidebar;
