import React from 'react';
import TableBody from 'datasetManagementUI/containers/TableBodyContainer';
import * as DisplayState from 'datasetManagementUI/lib/displayState';
import I18n from 'common/i18n';
import { Entities, Params } from 'datasetManagementUI/lib/types';
import { ColumnFormat } from 'common/types/viewColumn';
import { InputSchema, OutputColumn, OutputSchema, WithTransform } from 'common/types/dsmapiSchemas';

interface Props {
  outputColumn: OutputColumn & WithTransform;
  format: ColumnFormat;
  inputSchema: InputSchema;
  outputSchema: OutputSchema;
  entities: Entities;
  params: Params;
}

const FormatPreview = ({
  outputColumn,
  format,
  inputSchema,
  outputSchema,
  entities,
  params
}: Props) => {
  // This just constructs a default display state on page 1
  const displayState = DisplayState.normal(1, outputSchema.id);
  return (
    <div className="format-preview-wrap">
      <h6>{I18n.t('dataset_management_ui.format_column.formatting_preview')}</h6>
      <div className="format-preview">
        <div>
          <table>
            <TableBody
              uid={params.fourfour}
              entities={entities}
              columns={[{ ...outputColumn, format }]}
              displayState={displayState}
              inputSchemaId={inputSchema.id} />
          </table>
        </div>
      </div>
    </div>
  );
};

export default FormatPreview;
