import PropTypes from 'prop-types';
import React from 'react';
import I18n from 'common/i18n';
import { ForgeTextField } from '@tylertech/forge-react';
import PrivateFieldIcon from 'common/FormatColumn/PrivateFieldIcon/PrivateFieldIcon';

const t = (k, scope = 'dataset_management_ui.metadata_manage.dataset_tab') => I18n.t(k, { scope });

const TextArea = ({ isRequired, handleChange, field, inErrorState, isPrivate, isRestrictedForUser }) => {
  const baseClasses = ['text-area', 'dsmp-text-area', 'forge-dsmp-text-area'];
  const errorClasses = ['text-input-error', 'dsmp-text-area-error'];

  if (inErrorState) {
    baseClasses.concat(errorClasses);
  }

  return (
    <ForgeTextField invalid={inErrorState} floatLabelType="always" required={isRequired} className="metadata-modal-width">
      { isPrivate && <PrivateFieldIcon/>}
      <textarea
        id={field.name}
        name={field.name}
        placeholder={field.placeholder}
        className={baseClasses.join(' ')}
        aria-label={field.label}
        aria-required={field.isRequired}
        value={field.value}
        onChange={handleChange}
        disabled={field.disabled}
      />
      <label htmlFor={field.name} slot="label">{ field.label || field.name }</label>
      {isRestrictedForUser && <span slot="helper-text">{t('subtitles.restricted_field')}</span>}
    </ForgeTextField>
  );

};

TextArea.propTypes = {
  isRequired: PropTypes.bool,
  field: PropTypes.object.isRequired,
  inErrorState: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  isPrivate: PropTypes.bool
};

export default TextArea;
