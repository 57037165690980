import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import * as Selectors from 'datasetManagementUI/selectors';
import { clearMappings } from 'datasetManagementUI/reduxStuff/actions/schemaMismatch';
import * as Links from 'datasetManagementUI/links/links';
import {
  getInputColumnReferences,
  hasUndefinedReferences,
  remapColumnAsts
} from 'datasetManagementUI/lib/schemaMismatch';
import { newOutputSchema } from 'datasetManagementUI/reduxStuff/actions/showOutputSchema';
import { fetchTranslation } from 'common/locale';
const common = (k) => fetchTranslation(k, 'dataset_management_ui.common');


export const SaveSchemaMismatchButton = ({
  createNewOutputSchema,
  cancelMapping,
  outputColumns,
  mappings,
  isComplete
}) => {
  return (
    <div>
      <label
        htmlFor="save-schema-mismatch-form"
        onClick={cancelMapping}
        className="btn btn-default"
        disabled={!isComplete}>
        {common('cancel')}
      </label>
      <label
        htmlFor="save-schema-mismatch-form"
        onClick={() => isComplete && createNewOutputSchema(outputColumns, mappings)}
        className={`btn btn-primary ${isComplete ? '' : 'dsmp-disabled'}`}
        disabled={!isComplete}>
        {common('done')}
      </label>
    </div>
  );
};

SaveSchemaMismatchButton.propTypes = {
  mappings: PropTypes.object.isRequired,
  outputColumns: PropTypes.array.isRequired,
  createNewOutputSchema: PropTypes.func.isRequired,
  cancelMapping: PropTypes.func.isRequired,
  isComplete: PropTypes.bool.isRequired
};


export const mapStateToProps = ({ entities, ui }, { params }) => {
  const mappings = ui.schemaMismatch.mappings;
  const outputSchema = entities.output_schemas[_.toNumber(params.outputSchemaId)];
  const inputColumns = Selectors.columnsForInputSchema(entities, outputSchema.input_schema_id);
  const outputColumns = Selectors.columnsForOutputSchema(entities, outputSchema.id);
  const references = getInputColumnReferences(inputColumns, outputColumns);
  const isComplete = !hasUndefinedReferences(references, mappings);

  return {
    mappings,
    outputColumns,
    isComplete,
    outputSchema
  };
};


export function mergeProps(stateProps, { dispatch }, ownProps) {
  const { params } = ownProps;

  const sourceId = _.toNumber(params.sourceId);
  const inputSchemaId = _.toNumber(params.inputSchemaId);

  const createNewOutputSchema = (outputColumns, mappings) => {
    const desiredColumns = remapColumnAsts(outputColumns, mappings);

    dispatch(newOutputSchema(inputSchemaId, desiredColumns, true, stateProps.outputSchema)).then(
      (response) => {

        const outputSchema = response.resource;
        browserHistory.push(Links.showOutputSchema(
          { ...params, outputSchemaId: outputSchema.id },
          sourceId,
          inputSchemaId,
          outputSchema.id
        ));

        dispatch(clearMappings());

        return response;
      }
    );
  };

  return {
    ...ownProps,
    ...stateProps,
    createNewOutputSchema,

    cancelMapping: () => {
      dispatch(clearMappings());
    }
  };
}

export default connect(mapStateToProps, null, mergeProps)(SaveSchemaMismatchButton);

