import { Agent, Plugin } from 'common/types/gateway';
import { Params } from 'datasetManagementUI/lib/types';
import * as React from 'react';
import AgentRow from './AgentRow';

interface AgentRowsProps {
  agents: Agent[];
  plugins: Plugin[];
  params: Params;
  /* eslint @typescript-eslint/ban-types: "warn" */
  showAvailablePlugins: Function;
  /* eslint @typescript-eslint/ban-types: "warn" */
  showPluginHelp: Function;
}

function AgentRows({ agents, plugins, params, showAvailablePlugins, showPluginHelp }: AgentRowsProps) {
  const sortedAgents = agents.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  return (
    <div className="agent-rows">
      {Object.keys(sortedAgents).map((key, index) => {
        const agent = sortedAgents[key];
        return (
          <AgentRow
            key={String(agent.agent_uid)}
            agent={agent}
            plugins={plugins}
            showAvailablePlugins={showAvailablePlugins}
            showPluginHelp={showPluginHelp}
            params={params}
          />
        );
      })}
    </div>
  );
}

export default AgentRows;
