import { connect } from 'react-redux';
import _ from 'lodash';
import uuid from 'uuid';
import isURLHelper from 'validator/lib/isURL';
import * as Selectors from 'datasetManagementUI/selectors';
import HrefForm from 'datasetManagementUI/components/HrefForm/HrefForm';
import { updateRevision } from 'datasetManagementUI/reduxStuff/actions/revisions';
import * as FormActions from 'datasetManagementUI/reduxStuff/actions/forms';
import * as FlashActions from 'datasetManagementUI/reduxStuff/actions/flashMessage';

// CONSTANTS
const FORM_NAME = 'hrefForm';

// DATA SHAPING STUFF
function namespaceURLs(href) {
  // Check if the href saved on the server was saved with no urls--ie, a url key
  // with the value of {}. If so, create an empty url obj and insert it
  if (_.isEmpty(href.urls)) {
    return {
      ...href,
      urls: {
        [uuid()]: {
          url: '',
          filetype: ''
        }
      }
    };
    // If not, re-shape the existing urls into a nicer, namespaced format
  } else {
    return {
      ...href,
      urls: Object.keys(href.urls).reduce(
        (acc, key) => ({
          ...acc,
          [uuid()]: {
            url: href.urls[key],
            filetype: key
          }
        }),
        {}
      )
    };
  }
}

function addHrefIds(href, id) {
  return {
    ...href,
    id
  };
}

const removeEmptyValues = hrefURLObj => _.omitBy(hrefURLObj, val => !val.url);

const makeExtKeys = hrefURLObj =>
  Object.keys(hrefURLObj).reduce((acc, uuident) => {
    const entry = hrefURLObj[uuident];

    return {
      ...acc,
      [entry.filetype]: entry.url
    };
  }, {});

const hrefIsEmpty = href => {
  const hasUrls = !_.isEmpty(href.urls);

  return !(
    hasUrls ||
    href.title ||
    href.description ||
    href.data_dictionary_type ||
    href.data_dictionary
  );
};

const shapeHrefState = rawState =>
  rawState
    .map(href => ({
      ...href,
      urls: makeExtKeys(removeEmptyValues(href.urls))
    }))
    .filter(href => !hrefIsEmpty(href));

// FORM DATA VALIDATORS

// filter rather than find since hrefs can technically
// have the same url namespaced under two different
// file extensions
function findUrlsWithId(url, idurls) {
  return _.filter(idurls, (val) => val.url === url);
}

// hrefs on the client side use uuids to manage urls but
// these don't persist to the server (they are namespaced by
// file extension there), so this takes the server urls with
// no ids and maps the to the client urls
function addIdsToUrls(serverHref, clientHrefs) {
  // find a matching client href
  const client = _.find(clientHrefs, href => href.id === serverHref.id);

  if (client) {
    const clientUrls = Object.keys(client.urls).reduce((acc, k) => {
      return [...acc, { uuid: k, ...client.urls[k]}];
    }, []);

    // for each server url, find matching client urls
    return _.flatMap(serverHref.urls, (url => findUrlsWithId(url, clientUrls)));
  } else {
    return [];
  }
}

export function createBadUrls(serverHrefs = [], clientHrefs = []) {
  return _.flatMap(serverHrefs, (sHref) => addIdsToUrls(sHref, clientHrefs));
}


const findDupes = hrefURLObj => {
  const filetypes = _.values(hrefURLObj)
    .map(val => val.filetype)
    .filter(ft => ft);

  const dupes = filetypes.filter(filetype => {
    const firstIdx = _.findIndex(filetypes, ft => ft === filetype);
    const withCurrentOmitted = filetypes.filter((ft, idx) => firstIdx !== idx);
    return withCurrentOmitted.includes(filetype);
  });

  return [...new Set(dupes)];
};

function isInvalidUrl(url) {
  return !isURLHelper(url, { require_protocol: true });
}

function findInvalidURLs(urls = {}) {
  return Object.keys(urls).map(uid => {
    return {
      uuid: uid,
      url: _.get(urls, `${uid}.url`)
    };
  }).filter(obj => obj.url) // empty urls are valid in core for reasons
    .filter(obj => isInvalidUrl(obj.url));
}

const findEmpties = (href, hrefURLObj) =>
  _.map(hrefURLObj, (val, uuident) => {
    if (val.url && !val.filetype) {
      return { hrefId: href.id, id: uuident };
    } else {
      return null;
    }
  });

export const validate = hrefs => {
  const dupes = _.chain(hrefs)
    .map(href => ({ hrefId: href.id, dupes: findDupes(href.urls) }))
    .filter(err => err.dupes.length)
    .map(err => new DuplicateExtension(err.dupes, err.hrefId))
    .value();

  const badUrls = _.chain(hrefs)
    .flatMap(href => findInvalidURLs(href.urls))
    .thru(urls => (urls.length ? [new BadUrl(urls)] : []))
    .value();

  const badDataDict = _.chain(hrefs)
    .filter(href => href.data_dictionary) // valid for data_dictionary to be empty so filter those out
    .filter(href => isInvalidUrl(href.data_dictionary))
    .map(href => new BadDataDict(href.data_dictionary))
    .value();

  const empties = _.chain(hrefs)
    .flatMap(href => findEmpties(href, href.urls))
    .filter(err => err)
    .map(err => new MissingValue(err.id, err.hrefId))
    .value();

  return [...dupes, ...badUrls, ...empties, ...badDataDict];
};

// FORM ERROR TYPES
// DuplicateExtension :: [ String ] Int ->
//  { name : String, extensions : [ String ], hrefId : Int }
// example: DuplicateExtension(['csv', 'txt'], 7)
export function DuplicateExtension(extensions, hrefId) {
  this.name = 'DuplicateExtension';
  this.extensions = extensions;
  this.hrefId = hrefId;
}

// BadUrl :: [ String ] -> { name : String, urls : [ String ] }
// example: BadUrl(['ww.not-valid-url.com', 'www.another-bad-one'])
export function BadUrl(urls) {
  this.name = 'BadUrl';
  this.urls = urls;
}

export function BadDataDict(url) {
  this.name = 'BadDataDict';
  this.url = url;
}

// MissingValue :: String -> Int -> { name : String, urlId : String, hrefId : Int }
// A dataset can have many hrefs
// An href can have many urls
// hrefId refers to the href in which the requried value is missing
// urlId refers to the url inside the href in which the required value is missing
// example: MissingValue('478c91d4-783f-49a7-8d38-b6900116bda8', 8)
export function MissingValue(urlId, hrefId) {
  this.name = 'MissingValue';
  this.urlId = urlId;
  this.hrefId = hrefId;
}

// HrefError = MissingValue | BadUrl | DuplicateExtension
// FormValidationError :: String -> [ HrefError ] ->
//   { name : String, message : String, errors : [ HrefError ] }
export function FormValidationError(formName, errors) {
  this.name = 'FormValidationError';
  this.formName = formName;
  this.message = `Validation of ${formName} failed`;
  this.errors = errors;
}

FormValidationError.prototype = new Error();

// CONTAINER COMPONENT
const mapStateToProps = ({ entities, ui }, { params }) => {
  const revision = Selectors.currentRevision(
    entities,
    _.toNumber(params.revisionSeq)
  );

  let hrefs = [];

  if (revision && revision.href && Array.isArray(revision.href)) {
    hrefs = revision.href;
  }

  return {
    hrefs: hrefs
      .map(namespaceURLs)
      .map((href, idx) => addHrefIds(href, idx + 1)),
    shouldExit: ui.forms.hrefForm.shouldExit,
    isDirty: ui.forms.hrefForm.isDirty,
    schemaExists: !!revision.output_schema_id,
    blobExists: !!revision.blob_id
  };
};

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  return {
    ...stateProps,
    ...ownProps,
    disableSave: stateHrefs => {
      // If the user has saved href stuff on the server, we want to let them
      // save an empty array to hrefs since that is in effect removing the href
      // dataset altogether. If we did not allow for this, the user could never
      // remove an href dataset once added.

      // However, we don't want to let the user save an empty href dataset if nothing
      // exists on the server already. So in this case, we disable the save buttons
      // by...

      // Checking if the redux store has any hrefs on the revision. The redux
      // store hrefs array is update only on successful save, so safe to interpret
      // an empty array as meaning "no saved hrefs on server"
      if (stateProps.hrefs.length > 0) {
        return;
      }

      const hrefsToAdd = shapeHrefState(stateHrefs);

      // If no saved hrefs on server, and no data in the dataset form locally,
      // then mark form clean. This disables the save buttons.
      if (hrefsToAdd.length === 0) {
        dispatch(FormActions.markFormClean(FORM_NAME));
      }
    },
    markFormDirty: () => dispatch(FormActions.markFormDirty(FORM_NAME)),
    markFormClean: () => dispatch(FormActions.markFormClean(FORM_NAME)),
    setFormErrors: errors =>
      dispatch(FormActions.setFormErrors(FORM_NAME, errors)),
    showFlash: (type, id, msg) =>
      dispatch(FlashActions.showFlashMessage({ kind: type, id: id, message: msg })),
    clearAllFlash: () => dispatch(FlashActions.hideAllFlashMessages()),
    validateAndSaveHrefs: hrefs => {
      const errors = validate(hrefs);

      if (errors.length) {
        return Promise.reject(new FormValidationError(FORM_NAME, errors));
      }

      const serverHrefs = { href: shapeHrefState(hrefs) };

      return dispatch(updateRevision(serverHrefs, ownProps.params))
        .catch(err =>
          err.response.json().then(({ params }) => {
            if (!params) {
              return;
            }

            // Dsmapi validation returns a list of objects.
            // If the object is empty, the href is valid,
            // so let's filter those out here
            const invalidHrefs = _.chain(params.href)
              .filter(href => !_.isEmpty(href))
              .map((href, idx) => ({id: idx + 1, ...href}))
              .value();

            const badUrls = createBadUrls(invalidHrefs, hrefs);

            throw new FormValidationError(FORM_NAME, [new BadUrl(badUrls)]);
          })
        );
    }
  };
};

export default connect(mapStateToProps, null, mergeProps)(HrefForm);
