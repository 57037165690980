import Button, { VARIANTS } from 'common/components/Button';
import I18n from 'common/i18n';
import React from 'react';

interface Props {
  handleClick: () => void;
  isDisabled: boolean;
  buttonName: string;
  busy?: boolean;
}

function SubmitButton({ isDisabled, handleClick, buttonName, busy }: Props) {
  return (
    <Button
      disabled={isDisabled}
      name={buttonName}
      variant={VARIANTS.PRIMARY}
      busy={busy}
      onClick={(e) => {
        e.preventDefault();
        if (!isDisabled) {
          handleClick();
        }
      }}
    >
      {I18n.t('dataset_management_ui.common.save')}
    </Button>
  );
}

export default SubmitButton;
