import _ from 'lodash';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import * as Links from 'datasetManagementUI/links/links';
import Modes from 'datasetManagementUI/lib/modes';
import SchemaMismatch from 'datasetManagementUI/components/SchemaMismatch/SchemaMismatch';
import { guidance } from 'datasetManagementUI/reduxStuff/actions/revisions';
import * as ModalActions from 'datasetManagementUI/reduxStuff/actions/modal';
import * as Selectors from 'datasetManagementUI/selectors';
import { updateMapping, clearMappings } from 'datasetManagementUI/reduxStuff/actions/schemaMismatch';
import {
  getInputColumnReferences,
  hasUndefinedReferences
} from 'datasetManagementUI/lib/schemaMismatch';

export const mapStateToProps = ({ entities, ui }, { params }) => {
  const modes = Modes.modes(entities, params);
  const outputSchema = entities.output_schemas[_.toNumber(params.outputSchemaId)];
  const inputColumns = Selectors.columnsForInputSchema(entities, outputSchema.input_schema_id);
  const outputColumns = Selectors.columnsForOutputSchema(entities, outputSchema.id);
  const references = getInputColumnReferences(inputColumns, outputColumns);
  const mappings = ui.schemaMismatch.mappings;
  const isComplete = !hasUndefinedReferences(references, mappings);

  return {
    params,
    inputColumns,
    outputColumns,
    references,
    mappings,
    isComplete,
    modes
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  const { params } = ownProps;

  const hideModal = () => dispatch(ModalActions.hideModal());

  const getGuidance = () => guidance(params.fourfour);

  const gotoTransformEditor = (outputColumn) => {
    browserHistory.push(Links.transformColumn(
      params,
      params.sourceId,
      params.inputSchemaId,
      params.outputSchemaId,
      outputColumn.id
    ));
  };


  const gotoSources = () => {
    browserHistory.push(Links.sources(params));
  };

  const gotoParseOptions = () => {
    browserHistory.push(Links.showParseOptions(params));
  };

  const gotoGeocodeColumnEditor = (outputColumn) => {
    browserHistory.push(Links.geocodeShortcut({
      ...params,
      outputColumnId: outputColumn.id
    }));
  };

  const showConfirmation = (props) => {
    dispatch(ModalActions.showModal('ConfirmationModal', {
      ...props,
      onConfirm: () => {
        hideModal();
        props.onConfirm();
      },
      onDismiss: hideModal
    }));
  };


  return {
    ...ownProps,
    gotoTransformEditor,
    gotoSources,
    getGuidance,
    gotoParseOptions,
    gotoGeocodeColumnEditor,
    showConfirmation,
    hideModal,

    updateMapping: (fromColumnFieldName, toColumnFieldName) => {
      dispatch(updateMapping(fromColumnFieldName, toColumnFieldName));
    },

    clearMappings: () => {
      dispatch(clearMappings());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SchemaMismatch);
