import { SHOW_MODAL, HIDE_MODAL, PUSH_MODAL, POP_MODAL } from 'datasetManagementUI/reduxStuff/actions/modal';

export const initialState = {
  modals: []
};

const modal = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        modals: [{
          contentComponentName: action.contentComponentName,
          payload: action.payload
        }]
      };

    case HIDE_MODAL:
      return {
        modals: []
      };
    case PUSH_MODAL:
      return {
        modals: [{
            contentComponentName: action.contentComponentName,
            payload: action.payload
          },
          ...state.modals
        ]
      };
    case POP_MODAL:
      return {
        modals: [
          ...state.modals.slice(1, state.modals.length)
        ]
      };
    default:
      return state;
  }
};

export default modal;
