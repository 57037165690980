import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ErrorModal from 'datasetManagementUI/components/ErrorModal/ErrorModal';

export function mapStateToProps(ignored, { params }) {
  const { fourfour } = params;
  return {
    fourfour
  };
}

export default withRouter(connect(mapStateToProps)(ErrorModal));
