/* eslint max-len: 0 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as React from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/theme-monokai';

import { fetchTranslation } from 'common/locale';
const t = (k) => fetchTranslation(k, 'dataset_management_ui.column_config');

function ColumnConfig({ outputColumn, config }) {

  const defaultEditorProps = {
    width: '100%',
    theme: 'monokai',
    minLines: 2,
    maxLines: 30,
    showGutter: false,
    showPrintMargin: false,
    focus: true,
    readOnly: true
  };

  const columnConfig = _.find(config.columns, (c) => c.field_name === outputColumn.field_name);
  if (!columnConfig) return null;
  return (
    <div className="column-config">
      <h5>{t('title')}</h5>
      <p>{t('previous')}</p>
      <AceEditor {...defaultEditorProps} mode="sql" readOnly={true} value={columnConfig.transform_expr} />
      <p className="explanation">{t('explanation')}</p>
      <div className="learning">
        <a
          target="_blank"
          href="https://support.socrata.com/hc/en-us/articles/360000242188-Transforming-and-Validating-data-in-Socrata-before-publishing">
          {t('learn_more')}
        </a>
      </div>
    </div>
  );
}
ColumnConfig.propTypes = {
  outputColumn: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired
};

export default ColumnConfig;
