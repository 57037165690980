import { isEditMode } from 'datasetManagementUI/lib/modes';
import * as HasRights from 'common/views/has_rights';

const MODE = {
  EDIT: 'edit',
  INITIAL_INGRESS: 'initial'
};

const GRANT = {
  VIEWER: 'viewer',
  OWNER: 'owner'
};

export function isViewer(grant) {
  return grant === GRANT.VIEWER;
}

export function setModeGrant(view = {}) {
  const modeGrant = { mode: '', grant: '' };
  const edit = isEditMode(view);
  const owner = HasRights.hasOwnerLikeRights(view);
  const viewer = HasRights.hasReadOnlyRights(view);

  if (edit) {
    modeGrant.mode = MODE.EDIT;
  } else {
    modeGrant.mode = MODE.INITIAL_INGRESS;
  }

  if (viewer) {
    modeGrant.grant = GRANT.VIEWER;
  }

  if (owner) {
    modeGrant.grant = GRANT.OWNER;
  }

  return modeGrant;
}
