import { replace as _replace } from 'lodash';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { ShowBlobPreview } from 'datasetManagementUI/pages/ShowBlobPreview/ShowBlobPreview';
import * as Links from 'datasetManagementUI/links/links';
import { getBlobType, isBlobOverGoogleViewerSizeLimit } from 'datasetManagementUI/lib/util';
import { assetIsPublic } from 'common/views/helpers';

export function mapStateToProps({ entities }, ownProps) {
  const params = ownProps.params;
  const fourfour = params.fourfour;
  const view = entities.views[fourfour];
  const contentType = view.blobMimeType.replace(/;.*/, '');
  const blobType = getBlobType(contentType);
  const blobFilename = view.blobFilename;
  const downloadLink = `/download/${view.id}/${encodeURIComponent(_replace(contentType))}`;
  const scanResultUrl = `/api/views/${view.id}/files/${view.blobId}.json?method=judgement`;
  // Google Viewer cannot preview private blobs.
  const blobNotPublic = !assetIsPublic(view);
  // Google Viewer cannot show files over 25MB, and often even fails when trying to show its "too large" error message.
  // We'll just just skip trying to preview in this case to ensure a cleaner error message.
  const blobFileSize = view.blobFileSize;
  const blobOverGoogleViewerSizeLimit = isBlobOverGoogleViewerSizeLimit(blobFileSize);

  const isPreviewable = (() => {
    if (blobType == 'no_preview') {
      return false;
    } else if (blobType == 'google_viewer') {
      // See the special cases related to Google Viewer above.
      if (blobNotPublic || blobOverGoogleViewerSizeLimit) {
        return false;
      } else {
        return true;
      }
    } else {
      // It's the image type, always previewable.
      return true;
    }
  })();

  const blobPreviewProps = {
    isPreviewable,
    previewUrl: downloadLink,
    previewType: blobType,
    blobFilename,
    blobNotPublic,
    blobTooLarge: blobOverGoogleViewerSizeLimit
  };

  const blobDownloadProps = {
    showDownloadSection: true,
    blobFilename,
    scanResultUrl,
    downloadLink
  };

  const blobFileInfoProps = {
    contentType,
    fileSize: view.blobFileSize,
    sourcesLink: Links.sources(params)
  };

  return {
    blobPreviewProps,
    blobFileInfoProps,
    blobDownloadProps,
    saveBlobButtonProps: {
      autosaved: true,
      revision: null,
      blobId: null,
      params
    },
    isSourceFailedButWillBeBlobbified: false,
    isSourceInProgress: false
  };
}

export const mapDispatchToProps = (dispatch, ownProps) => ({
  goHome: () => browserHistory.push(Links.revisionBase(ownProps.params))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowBlobPreview);
