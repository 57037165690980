import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { ModalHeader, ModalContent } from 'common/components/Modal';

import I18n from 'common/i18n';
const scope = 'dataset_management_ui.show_output_schema.ready_to_import.extra_columns';

function FixItLink({ canAddColumn, goToAddColumn, onDismiss }) {
  if (canAddColumn) {
    return (
      <Link
        className="go-elsewhere explanation"
        onClick={() => {
          onDismiss();
          goToAddColumn();
        }}>
        {I18n.t('add_missing', { scope })}
      </Link>
    );
  } else {
    return (
      <div className="explanation">
        <p> {I18n.t('new_draft_fix.start_new', { scope })} </p>
        <ol>
          <li> {I18n.t('new_draft_fix.add_to_config', { scope })} </li>
          <li> {I18n.t('new_draft_fix.update', { scope })} </li>
          <li> {I18n.t('new_draft_fix.retry', { scope })} </li>
        </ol>
      </div>
    );
  }
}

FixItLink.propTypes = {
  goToAddColumn: PropTypes.func.isRequired,
  canAddColumn: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired
};


function ExtraColumnsModal({ onDismiss, canAddColumn, goToAddColumn, extraColumns }) {
  const notImported = I18n.t('not_imported', { scope, count: extraColumns.length });

  const headerProps = {
    title: I18n.t('extra_column', { scope, count: extraColumns.length }),
    className: 'extra-columns-header',
    onDismiss: onDismiss
  };

  return (
    <div>
      <ModalHeader {...headerProps} />
      <ModalContent>
        <div className="extra-columns-help">
          <p>{notImported}</p>
          <ul className="extra-columns-list">
            {extraColumns.map((col, i) => (<li key={i}>{col.field_name}</li>))}
          </ul>
          <h6> {I18n.t('fix_it', { scope })} </h6>
          {<FixItLink canAddColumn={canAddColumn} goToAddColumn={goToAddColumn} onDismiss={onDismiss} />}
          <h6> {I18n.t('do_nothing', { scope })} </h6>
          <p className="explanation">The column(s) will not be imported.</p>
          <h6> {I18n.t('why_did_this_happen', { scope })} </h6>
          <p className="explanation">
            {I18n.t('happened_because', { scope })}
          </p>
        </div>
      </ModalContent>
    </div>
  );
}


ExtraColumnsModal.propTypes = {
  extraColumns: PropTypes.array.isRequired,
  canAddColumn: PropTypes.bool.isRequired,
  goToAddColumn: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired
};

export default ExtraColumnsModal;
