import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { COLUMN_OPERATIONS } from 'datasetManagementUI/reduxStuff/actions/apiCalls';
import { STATUS_CALL_IN_PROGRESS } from 'datasetManagementUI/lib/apiCallStatus';
import ColumnHeader from 'datasetManagementUI/components/ColumnHeader/ColumnHeader';
import * as Selectors from 'datasetManagementUI/selectors';
import * as ShowActions from 'datasetManagementUI/reduxStuff/actions/showOutputSchema';
import * as ModeGrantActions from 'datasetManagementUI/reduxStuff/actions/modeGrant';

function activeApiCallInvolvingThis(apiCalls, column) {
  const apiCallsByColumnId = _.chain(apiCalls)
    .filter(call => _.includes(COLUMN_OPERATIONS, call.operation) && call.status === STATUS_CALL_IN_PROGRESS)
    .keyBy('params.outputColumnId')
    .value();

  return _.has(apiCallsByColumnId, column.id);
}

const mapStateToProps = ({ ui, entities }, props) => {
  const { outputSchema, outputColumn } = props;
  const outputColumns = Selectors.columnsForOutputSchema(entities, outputSchema.id);

  const isViewer = ModeGrantActions.isViewer(_.get(ui, 'modeGrant.grant', ''));

  return {
    ...props,
    outputColumns,
    isViewer,
    activeApiCallInvolvingThis: activeApiCallInvolvingThis(ui.apiCalls, outputColumn),
    autosaving: ui.autosaving.inProgress
  };
};

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const { outputColumns } = stateProps;
  const { outputColumn, outputSchema, params } = ownProps;
  return {
    ...stateProps,
    ...ownProps,
    dispatch, // yea this is gross but this is how it was written and no one has fixed it yet
    updateColumnWidth: () => {
      const desiredColumns = outputColumns.map(oc => {
        if (oc.id === outputColumn.id) {
          const cloned = ShowActions.cloneOutputColumn(oc);

          return {
            ...cloned,
            format: { ...oc.format, width: ownProps.width }
          };
        } else {
          return ShowActions.cloneOutputColumn(oc);
        }
      });
      dispatch(ShowActions.newOutputSchema(
        outputSchema.input_schema_id,
        desiredColumns,
        false,
        outputSchema
      )).then(resp => {
        dispatch(ShowActions.redirectToOutputSchema(params, resp.resource.id));
      });
    }
  };
};

export default withRouter(connect(mapStateToProps, null, mergeProps)(ColumnHeader));
