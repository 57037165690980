import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import React from 'react';

export enum StatusState {
  SAVED = 'SAVED',
  UNSAVED = 'UNSAVED',
  ERRORED = 'ERRORED',
  INITIALIZED = 'INITIALIZED',
  DELETED = 'DELETED'
}

// these are for JS folks at this point
export const SAVED = StatusState.SAVED;
export const UNSAVED = StatusState.UNSAVED;
export const ERRORED = StatusState.ERRORED;
export const INITIALIZED = StatusState.INITIALIZED;
export const DELETED = StatusState.DELETED;

interface Props {
  formStatus: StatusState;
}
const StatusIndicator = ({ formStatus }: Props) => {
  let colorClass;
  let iconName;

  switch (formStatus) {
    case StatusState.INITIALIZED:
      colorClass = 'dsmp-status-initialized';
      break;
    case StatusState.SAVED:
      colorClass = 'dsmp-status-saved';
      iconName = IconName.CheckmarkAlt;
      break;
    case StatusState.UNSAVED:
      colorClass = 'dsmp-status-unsaved';
      iconName = IconName.Pending;
      break;
    case StatusState.ERRORED:
      colorClass = 'dsmp-status-errored';
      iconName = IconName.WarningAlt;
      break;
    case StatusState.DELETED:
      colorClass = 'dsmp-status-errored';
      iconName = IconName.CheckmarkAlt;
      break;
    default:
      colorClass = 'dsmp-status-initialized';
  }

  return (
    <span className="status-indicator">
      {iconName ? <SocrataIcon name={iconName} className={colorClass} /> : <span className={colorClass} />}
    </span>
  );
};

export default StatusIndicator;
