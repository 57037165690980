import ManageMetadataModal from 'datasetManagementUI/components/ManageMetadataModal/ManageMetadataModal';
import { getOutputSchemaId } from 'datasetManagementUI/lib/util';

import { AppState, Dispatch, Params } from 'datasetManagementUI/lib/types';
import { showOutputSchema } from 'datasetManagementUI/links/links';
import { dismissMetadataPane } from 'datasetManagementUI/reduxStuff/actions/manageMetadata';
import { popModal } from 'datasetManagementUI/reduxStuff/actions/modal';
import * as Selectors from 'datasetManagementUI/selectors';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getRevision } from './ManageColumnMetadataContainer.js';

interface OwnProps {
  params: Params;
  specialModalFunctionBad: () => void;
}
const mapStateToProps = (state: AppState, props: OwnProps): StateProps => {
  const entities = state.entities;
  const params = props.params;
  const revisionSeq = Number(params.revisionSeq);
  const revision = getRevision(entities.revisions, revisionSeq);
  const outputSchemaId = getOutputSchemaId(
    Number(params.outputSchemaId),
    revision,
    Selectors.currentOutputSchema(entities, revisionSeq)
  );
  const { source, inputSchema, outputSchema } = Selectors.treeForOutputSchema(entities, outputSchemaId!);

  const pathToNewOutputSchema =
    source && inputSchema && outputSchema
      ? showOutputSchema(params, source.id, inputSchema.id, outputSchema.id)
      : null;

  return {
    pathToNewOutputSchema
  };
};

const mapDispatchToProps = (dispatch: Dispatch, { params }: OwnProps): DispatchProps => ({
  cancelClose: () => dispatch(popModal()),
  yesReallyClose: (path: string | null) => {
    dispatch(popModal());
    dispatch(dismissMetadataPane(path, params, null, true));
  }
});

interface StateProps {
  pathToNewOutputSchema: string | null;
}
interface DispatchProps {
  cancelClose: () => void;
  yesReallyClose: (path: string | null) => void;
}
export type ManageMetadataModalProps = StateProps & DispatchProps;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageMetadataModal));
