import React from 'react';
import PropTypes from 'prop-types';
import * as Links from 'datasetManagementUI/links/links';
import I18n from 'common/i18n';

function TableBodyPlaceholder(props) {
  const { loadUnloadedData, isViewer, params } = props;
  const message = I18n.t('dataset_management_ui.data_preview.table_placeholder_viewer_message', {
    url: Links.gridview(params)
  });

  return (
    <tbody id="table-body-placeholder">
      <div className="dsmp-message-section">
        {isViewer
        ? <div className="dsmp-viewer-mode-message">
          <span dangerouslySetInnerHTML={{ __html: message }} />
        </div>
        : <div className="dsmp-admin-mode-message">
          <div className="dsmp-tablebody-message">
            <span>{I18n.t('dataset_management_ui.data_preview.table_placeholder_message')}</span>
          </div>

          <div className="dsmp-tablebody-message">
            <button
              onClick={() => loadUnloadedData()}
              className="btn btn-primary">{I18n.t('dataset_management_ui.data_preview.button_text')}
            </button>
          </div>

          <div className="dsmp-tablebody-message">
            <span className="dsmp-error">{I18n.t('dataset_management_ui.data_preview.warning')} </span>
            <span>{I18n.t('dataset_management_ui.data_preview.table_placeholder_warning')}</span>
          </div>

          <div className="dsmp-tablebody-message">
            <span className="dsmp-tablebody-message">
              {I18n.t('dataset_management_ui.data_preview.table_placeholder_alternative')}
            </span>
          </div>
        </div>}
      </div>
    </tbody>
  );
}

TableBodyPlaceholder.propTypes = {
  params: PropTypes.object.isRequired,
  displayState: PropTypes.object.isRequired,
  loadUnloadedData: PropTypes.func.isRequired,
  isViewer: PropTypes.bool
};

export default TableBodyPlaceholder;
