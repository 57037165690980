import I18n from 'common/i18n';
import { DsmapiResource } from 'common/types/dsmapi';
import { NestedOutputSchema, OutputColumn, OutputSchema, Transform, WithTransform } from 'common/types/dsmapiSchemas';
import { Expr, SoQLType } from 'common/types/soql';
import { ColumnFormat } from 'common/types/viewColumn';
import {
  validateDisplayName,
  validateFieldName,
  validateValue
} from 'datasetManagementUI/containers/AddColFormContainer';
import { checkStatus, getError, getJson, socrataFetch } from 'datasetManagementUI/lib/http';
import { makeNormalizedCreateOutputSchemaResponse } from 'datasetManagementUI/lib/jsonDecoders';
import { soqlProperties } from 'datasetManagementUI/lib/soqlTypes';
import { Dispatch, Entities, GetState, Params } from 'datasetManagementUI/lib/types';
import * as dsmapiLinks from 'datasetManagementUI/links/dsmapiLinks';
import * as Links from 'datasetManagementUI/links/links';
import {
  ADD_COLUMN,
  apiCallFailed,
  apiCallStarted,
  apiCallSucceeded,
  DROP_COLUMN,
  HIDE_COLUMN,
  NEW_OUTPUT_SCHEMA,
  SET_ROW_IDENTIFIER,
  UNHIDE_COLUMN,
  UPDATE_COLUMN_TYPE,
  VALIDATE_ROW_IDENTIFIER
} from 'datasetManagementUI/reduxStuff/actions/apiCalls';
import { finishAutosave, startAutosave } from 'datasetManagementUI/reduxStuff/actions/autosaving';
import * as FlashActions from 'datasetManagementUI/reduxStuff/actions/flashMessage';
import * as FormActions from 'datasetManagementUI/reduxStuff/actions/forms';
import { showModal } from 'datasetManagementUI/reduxStuff/actions/modal';
import * as ShowActions from 'datasetManagementUI/reduxStuff/actions/showOutputSchema';
import {
  subscribeToOutputSchema,
  subscribeToTransformsInOutputSchema
} from 'datasetManagementUI/reduxStuff/actions/subscriptions';
import * as Selectors from 'datasetManagementUI/selectors';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import uuid from 'uuid';

export const CREATE_NEW_OUTPUT_SCHEMA_SUCCESS = 'CREATE_NEW_OUTPUT_SCHEMA_SUCCESS';

// refine this
type Call = any;

export type DesiredColumns = {
  field_name: string;
  display_name: string;
  position: number;
  description: string;
  is_primary_key: boolean;
  format: ColumnFormat;
  flags: string[];
  transform: Partial<Transform>;
}[];
export function createNewOutputSchema(
  inputSchemaId: number,
  desiredColumns: DesiredColumns,
  call: Call,
  force = false,
  outputSchemaBody: Partial<OutputSchema> = {}
) {
  return (dispatch: Dispatch, getState: GetState) => {
    const callId = uuid();

    dispatch(startAutosave());
    dispatch(apiCallStarted(callId, call));

    const { entities } = getState();

    const inputSchema = entities.input_schemas[inputSchemaId];

    const source = Selectors.sourceFromInputSchema(entities, inputSchemaId);

    const url = dsmapiLinks.newOutputSchema(source.id, inputSchemaId, force);

    return socrataFetch(url, {
      method: 'POST',
      body: JSON.stringify({ ...outputSchemaBody, output_columns: desiredColumns })
    })
      .then(checkStatus)
      .then(getJson)
      .catch(getError)
      .then((resp: DsmapiResource<NestedOutputSchema>) => {
        const { resource: os } = resp;
        dispatch(apiCallSucceeded(callId));

        const payload = makeNormalizedCreateOutputSchemaResponse(os, inputSchema.total_rows);
        dispatch(createNewOutputSchemaSuccess(payload));
        dispatch(subscribeToOutputSchema(os));
        dispatch(subscribeToTransformsInOutputSchema(os));
        dispatch(finishAutosave());
        return resp;
      })
      .catch((error: any) => {
        dispatch(apiCallFailed(callId, error));
        dispatch(finishAutosave());
        throw error;
      });
  };
}

export function createNewOutputSchemaSuccess(payload: Record<string, unknown>) {
  return {
    type: CREATE_NEW_OUTPUT_SCHEMA_SUCCESS,
    ...payload
  };
}

export const redirectToOutputSchema =
  (params: Params, outputSchemaId: number) => (dispatch: Dispatch, getState: GetState) => {
    const { entities } = getState();
    const { source, inputSchema } = Selectors.treeForOutputSchema(entities, outputSchemaId);
    if (source && inputSchema) {
      const to = Links.showOutputSchema(params, source.id, inputSchema.id, outputSchemaId);
      return browserHistory.push(to);
    }
  };

export const newOutputSchema =
  (
    inputSchemaId: number,
    desiredColumns: DesiredColumns,
    force: boolean,
    outputSchema: Partial<OutputSchema> = {}
  ) =>
  (dispatch: Dispatch) => {
    const call = {
      operation: NEW_OUTPUT_SCHEMA,
      callParams: {}
    };
    const osBody = outputSchema ? cloneOutputSchema(outputSchema) : {};
    return dispatch(createNewOutputSchema(inputSchemaId, desiredColumns, call, force, osBody));
  };

export const updateColumnTransform =
  (outputSchema: OutputSchema, oldColumn: OutputColumn, newExpr: string) =>
  (dispatch: Dispatch, getState: GetState) => {
    const { entities } = getState();

    const call = {
      operation: UPDATE_COLUMN_TYPE,
      callParams: {
        outputSchemaId: outputSchema.id,
        outputColumnId: oldColumn.id
      }
    };

    const genTransform = (outputColumn: OutputColumn & WithTransform) => {
      if (outputColumn.id === oldColumn.id) {
        return newExpr;
      } else {
        return outputColumn.transform.transform_expr;
      }
    };

    const newOutputColumns = getOutputColumnsForTransforming(entities, outputSchema, oldColumn).map((c) =>
      buildNewOutputColumn(c, genTransform)
    );

    return dispatch(
      createNewOutputSchema(
        outputSchema.input_schema_id,
        newOutputColumns,
        call,
        false,
        cloneOutputSchema(outputSchema)
      )
    );
  };

export const updateColumnType =
  (outputSchema: OutputSchema, oldColumn: OutputColumn, newType: SoQLType) =>
  (dispatch: Dispatch, getState: GetState) => {
    const { entities } = getState();

    const call = {
      operation: UPDATE_COLUMN_TYPE,
      callParams: {
        outputSchemaId: outputSchema.id,
        outputColumnId: oldColumn.id
      }
    };

    const newOutputColumns = outputColumnsWithChangedType(entities, outputSchema, oldColumn, newType);

    return dispatch(
      createNewOutputSchema(
        outputSchema.input_schema_id,
        newOutputColumns,
        call,
        false,
        cloneOutputSchema(outputSchema)
      )
    );
  };

export const dropColumn =
  (outputSchema: OutputSchema, column: OutputColumn) => (dispatch: Dispatch, getState: GetState) => {
    const { entities } = getState();

    const call = {
      operation: DROP_COLUMN,
      callParams: {
        outputSchemaId: outputSchema.id,
        outputColumnId: column.id
      }
    };

    const current = Selectors.columnsForOutputSchema(entities, outputSchema.id);

    const newOutputColumns = current
      .filter((oc) => oc.id !== column.id)
      .map((oc) => buildNewOutputColumn(oc, sameTransform(entities)));

    return dispatch(
      createNewOutputSchema(
        outputSchema.input_schema_id,
        newOutputColumns,
        call,
        true,
        cloneOutputSchema(outputSchema)
      )
    );
  };

export const hideColumn =
  (outputSchema: OutputSchema, outputColumnToHide: OutputColumn) =>
  (dispatch: Dispatch, getState: GetState) => {
    const { entities } = getState();

    const call = {
      operation: HIDE_COLUMN,
      callParams: { outputSchema, outputColumnToHide }
    };

    const newOutputColumns = Selectors.columnsForOutputSchema(entities, outputSchema.id).map(
      (outputColumn) => {
        if (outputColumn.id === outputColumnToHide.id) {
          return {
            ...ShowActions.cloneOutputColumn(outputColumn),
            flags: _.union(outputColumn.flags, ['hidden'])
          };
        } else {
          return ShowActions.cloneOutputColumn(outputColumn);
        }
      }
    );

    return dispatch(
      createNewOutputSchema(
        outputSchema.input_schema_id,
        newOutputColumns,
        call,
        false,
        cloneOutputSchema(outputSchema)
      )
    );
  };

export const showColumn =
  (outputSchema: OutputSchema, outputColumnToShow: OutputColumn) =>
  (dispatch: Dispatch, getState: GetState) => {
    const { entities } = getState();

    const call = {
      operation: UNHIDE_COLUMN,
      callParams: { outputSchema, outputColumnToShow }
    };

    const newOutputColumns = Selectors.columnsForOutputSchema(entities, outputSchema.id).map(
      (outputColumn) => {
        if (outputColumn.id === outputColumnToShow.id) {
          return {
            ...ShowActions.cloneOutputColumn(outputColumn),
            flags: _.pull(outputColumn.flags, 'hidden')
          };
        } else {
          return ShowActions.cloneOutputColumn(outputColumn);
        }
      }
    );
    return dispatch(
      createNewOutputSchema(
        outputSchema.input_schema_id,
        newOutputColumns,
        call,
        false,
        cloneOutputSchema(outputSchema)
      )
    );
  };

export const setRowIdentifier =
  (outputSchema: OutputSchema, outputColumnToSet: OutputColumn) =>
  (dispatch: Dispatch, getState: GetState) => {
    const { entities } = getState();

    const call = {
      operation: SET_ROW_IDENTIFIER,
      callParams: { outputSchema, outputColumnToSet }
    };

    const newOutputColumns = Selectors.columnsForOutputSchema(entities, outputSchema.id)
      .map((outputColumn) => ({
        ...outputColumn,
        is_primary_key: outputColumn.id === outputColumnToSet.id
      }))
      .map((oc) => buildNewOutputColumn(oc, sameTransform(entities)));

    return dispatch(
      createNewOutputSchema(
        outputSchema.input_schema_id,
        newOutputColumns,
        call,
        false,
        cloneOutputSchema(outputSchema)
      )
    );
  };

export const unsetRowIdentifier =
  (outputSchema: OutputSchema) => (dispatch: Dispatch, getState: GetState) => {
    const { entities } = getState();

    const call = {
      operation: SET_ROW_IDENTIFIER,
      callParams: { outputSchema }
    };

    const newOutputColumns = Selectors.columnsForOutputSchema(entities, outputSchema.id)
      .map((outputColumn) => ({
        ...outputColumn,
        is_primary_key: false
      }))
      .map((oc) => buildNewOutputColumn(oc, sameTransform(entities)));

    return dispatch(
      createNewOutputSchema(
        outputSchema.input_schema_id,
        newOutputColumns,
        call,
        false,
        cloneOutputSchema(outputSchema)
      )
    );
  };

export function cloneOutputSchema(outputSchema: Partial<OutputSchema>): Partial<OutputSchema> {
  return {
    sort_bys: outputSchema.sort_bys
  };
}

export const moveColumnToPosition =
  (outputSchema: OutputSchema, column: OutputColumn & WithTransform, positionBaseOne: number) =>
  (dispatch: Dispatch, getState: GetState) => {
    const { entities } = getState();

    const call = {
      operation: NEW_OUTPUT_SCHEMA,
      callParams: { outputSchema }
    };

    const allColumns = Selectors.columnsForOutputSchema(entities, outputSchema.id);
    const columns = allColumns.filter((oc) => oc.id !== column.id);

    const positionBaseZero = Math.min(Math.max(0, positionBaseOne - 1), allColumns.length - 1);

    columns.splice(positionBaseZero, 0, column);

    const newOutputColumns = columns
      .map((oc) => buildNewOutputColumn(oc, sameTransform(entities)))
      .map((oc, i) => ({ ...oc, position: i + 1 }));

    return dispatch(
      createNewOutputSchema(
        outputSchema.input_schema_id,
        newOutputColumns,
        call,
        false,
        cloneOutputSchema(outputSchema)
      )
    );
  };

export const sortColumn =
  (outputSchema: OutputSchema, fieldName: string, ascending: boolean) =>
  (dispatch: Dispatch, getState: GetState) => {
    const { entities } = getState();

    const call = {
      operation: NEW_OUTPUT_SCHEMA,
      callParams: { outputSchema }
    };

    const columns = Selectors.columnsForOutputSchema(entities, outputSchema.id).map(cloneOutputColumn);
    const existingSortBys = outputSchema.sort_bys || [];
    const body = {
      sort_bys: [
        ...existingSortBys.filter((sb) => sb.field_name !== fieldName), // dont' sort by same col twice
        { field_name: fieldName, ascending }
      ]
    };
    return dispatch(createNewOutputSchema(outputSchema.input_schema_id, columns, call, false, body));
  };

export const unSortColumn =
  (outputSchema: OutputSchema, fieldName: string) => (dispatch: Dispatch, getState: GetState) => {
    const { entities } = getState();

    const call = {
      operation: NEW_OUTPUT_SCHEMA,
      callParams: { outputSchema }
    };

    const columns = Selectors.columnsForOutputSchema(entities, outputSchema.id).map(cloneOutputColumn);
    const body = {
      sort_bys: outputSchema.sort_bys.filter((oc) => oc.field_name !== fieldName)
    };
    return dispatch(createNewOutputSchema(outputSchema.input_schema_id, columns, call, false, body));
  };

export function buildNewOutputColumn(
  outputColumn: OutputColumn & WithTransform,
  genTransform: (oc: OutputColumn & WithTransform) => string
) {
  const transform: Partial<Transform> = {
    transform_expr: genTransform(outputColumn),
    output_soql_type: undefined as SoQLType | undefined,
    parsed_expr: undefined as Expr | undefined
  };

  return {
    field_name: outputColumn.field_name,
    position: outputColumn.position,
    display_name: outputColumn.display_name,
    description: outputColumn.description,
    transform,
    is_primary_key: outputColumn.is_primary_key,
    format: outputColumn.format,
    flags: outputColumn.flags
  };
}

export function cloneOutputColumn(outputColumn: OutputColumn & WithTransform) {
  const cloned = buildNewOutputColumn(outputColumn, (oc) => oc.transform.transform_expr);
  // in the event of the expression not compiling (when we're forcing a save)
  // we need the output type
  cloned.transform.output_soql_type = outputColumn.transform.output_soql_type;
  return cloned;
}

function sameTransform(entities: Entities) {
  return (column: OutputColumn) => entities.transforms[column.transform_id].transform_expr;
}

function getOutputColumnsForTransforming(
  entities: Entities,
  oldOutputSchema: OutputSchema,
  oldColumn: OutputColumn
) {
  return Selectors.columnsForOutputSchema(entities, oldOutputSchema.id).map((oc) => {
    // This was a lovely bug!
    // When changing the type of a primary key column, we need to make it a non-pk, because
    // type conversion could lead to duplicate or null values; in fact, this is true of any
    // transformation
    return oc.id === oldColumn.id ? { ...oc, is_primary_key: false } : oc;
  });
}

export function outputColumnsWithChangedType(
  entities: Entities,
  oldOutputSchema: OutputSchema,
  oldColumn: OutputColumn,
  newType: SoQLType
) {
  const oldOutputColumns = getOutputColumnsForTransforming(entities, oldOutputSchema, oldColumn);

  // Input columns are presently always text.  This will eventually
  // change, and then we'll need the input column here instead of
  // just hardcoding a comparison to text.
  const genTransform = (outputColumn: OutputColumn) => {
    const transform = entities.transforms[outputColumn.transform_id];
    const transformExpr = transform.transform_expr;

    if (outputColumn.id !== oldColumn.id) {
      // user is not updating this column
      return transformExpr;
    }

    const inputColumns = transform.transform_input_columns.map(
      (inputColumnRef) => entities.input_columns[inputColumnRef.input_column_id]
    );

    let conversionExpr;

    if (inputColumns.length === 0) {
      // col was added by user and not based off any input column(s)
      conversionExpr = soqlProperties().text.conversions[newType]();
    } else if (inputColumns.length !== 1) {
      console.error('expected transform', transform.id, 'to have 1 input column; has', inputColumns.length);
    } else {
      const inputColumn = inputColumns[0];

      const expr = soqlProperties()[inputColumn.soql_type].conversions[newType](
        inputColumn,
        entities.transforms
      );

      const fieldName = inputColumn.field_name;

      conversionExpr = inputColumn.soql_type === newType ? `\`${fieldName}\`` : expr;
    }

    return conversionExpr;
  };

  return oldOutputColumns.map((c) => buildNewOutputColumn(c, genTransform));
}

interface RowIdentifiedJudgement {
  valid: boolean;
}
export const validateThenSetRowIdentifier =
  (outputSchema: OutputSchema, outputColumn: OutputColumn) => (dispatch: Dispatch, getState: GetState) => {
    const { source } = Selectors.pathForOutputSchema(getState().entities, outputSchema.id);
    const transformId = outputColumn.transform_id;
    const call = {
      operation: VALIDATE_ROW_IDENTIFIER,
      callParams: {
        outputSchemaId: outputSchema.id,
        outputColumnId: outputColumn.id
      }
    };
    const callId = uuid();

    dispatch(apiCallStarted(callId, call));
    const url = dsmapiLinks.validateRowIdentifier(source.id, transformId);
    return socrataFetch(url)
      .then(checkStatus)
      .then(getJson)
      .then((result: RowIdentifiedJudgement) => {
        dispatch(apiCallSucceeded(callId));
        if (!result.valid) {
          dispatch(showModal('RowIdentifierError', result));
        } else {
          return dispatch(setRowIdentifier(outputSchema, outputColumn));
        }
      })
      .catch((error: unknown) => {
        dispatch(apiCallFailed(callId, error));
      });
  };

interface ColumnFormColumn {
  fieldName: string;
  displayName: string;
  sourceColumnId: number;
  transform: number;
  position: number;
  description: string;
  transformExpr: string;
}
function validateColForm(data: ColumnFormColumn, fieldNames: string[], displayNames: string[]) {
  return {
    fieldName: validateFieldName(data.fieldName, fieldNames),
    displayName: validateDisplayName(data.displayName, displayNames),
    sourceColumnId: validateValue(data.sourceColumnId),
    transform: validateValue(data.transform),
    description: []
  };
}

function getNames(entities: Entities, osid: number) {
  const columns = Selectors.columnsForOutputSchema(entities, osid);

  return {
    fieldNames: columns.map((col) => col.field_name),
    displayNames: columns.map((col) => col.display_name)
  };
}

function getPosition(entities: Entities, osid: number) {
  const columns = Selectors.columnsForOutputSchema(entities, osid);

  if (columns && Array.isArray(columns) && columns.length > 0) {
    return Math.max(...columns.map((col) => col.position)) + 1;
  } else {
    return 1;
  }
}

export function snakeCase(colData: ColumnFormColumn) {
  return {
    field_name: colData.fieldName,
    display_name: colData.displayName,
    position: colData.position,
    description: colData.description,
    is_primary_key: false,
    format: null,
    flags: [],
    transform: { transform_expr: colData.transformExpr }
  };
}

export function addCol(colData: ColumnFormColumn, params: Params) {
  return (dispatch: Dispatch, getState: GetState) => {
    const { entities } = getState();

    const osid = _.toNumber(params.outputSchemaId);

    const { fieldNames, displayNames } = getNames(entities, osid);

    const validationErrors = validateColForm(colData, fieldNames, displayNames);

    dispatch(FormActions.setFormErrors('addColForm', validationErrors));

    const totalErrors = _.reduce(validationErrors, (acc, errs) => acc.concat(errs), [] as string[]);

    if (totalErrors.length) {
      dispatch(
        FlashActions.showFlashMessage({
          kind: 'error',
          id: 'add_col_error',
          message: I18n.t('dataset_management_ui.add_col.error_flash_message')
        })
      );
      return Promise.reject();
    }

    const call = {
      operation: ADD_COLUMN,
      callParams: {
        outputSchemaId: osid
      }
    };

    const os = entities.output_schemas[osid];

    const newCol = {
      field_name: colData.fieldName,
      display_name: colData.displayName,
      position: colData.position || getPosition(entities, osid),
      description: colData.description,
      is_primary_key: false,
      format: {},
      flags: [],
      transform: { transform_expr: colData.transformExpr }
    };

    const currentCols = Selectors.columnsForOutputSchema(entities, osid);

    const newCols = [...currentCols, newCol];

    return dispatch(createNewOutputSchema(os.input_schema_id, newCols, call));
  };
}
