import React from 'react';
import ReactDOM from 'react-dom';
import { browserHistory } from 'react-router';
import * as Selectors from './selectors';
import Airbrake from 'common/airbrake';
import { addLocation } from 'datasetManagementUI/reduxStuff/actions/history';
import { setupSocket } from 'datasetManagementUI/reduxStuff/actions/socket';
import I18n from 'common/i18n';
import createStore from 'datasetManagementUI/reduxStuff/store';
import App from 'datasetManagementUI/components/App/App';

const store = createStore();
store.dispatch(setupSocket());

// 126728 is Publishing airbrake project id
Airbrake.init(window.serverConfig.airbrakeProjectId, window.serverConfig.airbrakeKey);

browserHistory.listen(location => {
  store.dispatch(addLocation(location));
});

ReactDOM.render(
  <App store={store} history={browserHistory} />,
  document.getElementById('app')
);

window.addEventListener('beforeunload', evt => {
  const sourcesInProgress = Selectors.sourcesInProgress(store.getState().ui.apiCalls);
  if (sourcesInProgress.length !== 0) {
    const msg = I18n.t('dataset_management_ui.notifications.source_warning');
    evt.returnValue = msg;
    return msg;
  }
});
