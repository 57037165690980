import { Errors } from 'datasetManagementUI/containers/AddColFormContainer';
import { AutosavingTypes } from 'datasetManagementUI/reduxStuff/actions/autosaving';

export enum FormActionType {
  markFormDirty = 'MARK_FORM_DIRTY',
  markFormClean = 'MARK_FORM_CLEAN',
  showFormErrors = 'SHOW_FORM_ERRORS',
  hideFormErrors = 'HIDE_FORM_ERRORS',
  setFormErrors = 'SET_FORM_ERRORS',
  setHasMetadataErrors = 'SET_HAS_METADATA_ERRORS',
  setFormState = 'SET_FORM_STATE',
  appendFormError = 'APPEND_FORM_ERROR',
  setShouldExit = 'SET_SHOULD_EXIT',
  clearInternalState = 'CLEAR_INTERNAL_STATE',
  markFormSubmitted = 'MARK_FORM_SUBMITTED',
  markFormUnsubmitted = 'MARK_FORM_UNSUBMITTED'
}

interface MarkFormDirtyAction {
  type: FormActionType.markFormDirty;
  formName: string;
}

interface MarkFormCleanAction {
  type: FormActionType.markFormClean;
  formName: string;
}

interface ShowFormErrorsAction {
  type: FormActionType.showFormErrors;
  formName: string;
}

interface HideFormErrorsAction {
  type: FormActionType.hideFormErrors;
  formName: string;
}

interface SetFormErrorsAction {
  type: FormActionType.setFormErrors;
  formName: string;
  errors: Errors;
}

interface SetHasMetadataErrors {
  type: FormActionType.setHasMetadataErrors;
  hasMetadataErrors: boolean;
}

interface AppendFormErrorAction {
  type: FormActionType.appendFormError;
  formName: string;
  error: string;
}

interface SetFormStateAction {
  type: FormActionType.setFormState;
  formName: string;
  state: any;
}

interface ClearInternalStateAction {
  type: FormActionType.clearInternalState;
  formName: string;
  val: boolean;
}

interface SetShouldExitAction {
  type: FormActionType.setShouldExit;
  formName: string;
  shouldExit: boolean;
}

interface MarkFormSubmittedAction {
  type: FormActionType.markFormSubmitted;
  formName: string;
}

interface MarkFormUnsubmittedAction {
  type: FormActionType.markFormUnsubmitted;
  formName: string;
}

export type FormAction =
  | MarkFormDirtyAction
  | MarkFormCleanAction
  | ShowFormErrorsAction
  | HideFormErrorsAction
  | SetFormErrorsAction
  | SetHasMetadataErrors
  | SetFormStateAction
  | ClearInternalStateAction
  | AppendFormErrorAction
  | SetShouldExitAction
  | MarkFormSubmittedAction
  | MarkFormUnsubmittedAction;

export interface AutosaveAction {
  type: AutosavingTypes;
}
