import _ from 'lodash';
import { isDraft, isEternalObe, isEternalNbe } from 'datasetManagementUI/lib/util';
import { FeatureFlags } from 'common/feature_flags';

const basicTo = name => ic => {
  if (!ic) {
    return `to_${name}(null)`;
  }
  return `to_${name}(\`${ic.field_name}\`)`;
};

const toDateLike = (dateType, transformFunc) => (ic, transforms) => {
  if (!ic) {
    return `${transformFunc}(null)`;
  }
  // to_floating_timestamp takes a format argument. If the user switches
  // something that was guessed as
  // to_floating_timestamp(`datetime_column`, '{YYYY}-{0M}-{0D}')
  // to a text, and then back again, we need to restore the guessed
  // format as well. This means the date dropdown is not as simple as hardcoding
  // a string, since it needs to look at the previous transforms for this input data,
  // find one that is a transform to date, and use that expression

  const originalTransforms = _.orderBy(
    _.filter(
      transforms,
      (t) => {
        const isDatetimeTransform = t && (t.output_soql_type === dateType);
        const isSameTransform = (
          t &&
          t.transform_input_columns &&
          t.transform_input_columns.length === 1 &&
          t.transform_input_columns[0].input_column_id === ic.id
        );

        return isDatetimeTransform && isSameTransform;
      }
    ),
    'id'
  );
  if (originalTransforms.length) {
    return originalTransforms[0].transform_expr;
  } else {
    return `${transformFunc}(\`${ic.field_name}\`)`;
  }
};

const soqlPropertiesList = (forceJsonEnable) => {
  // when this feature flag is removed, soqlPropertiesList can become a static map,
  // and we won't have to close over everything. please please please make this change
  // rather than leaving all these as functions.
  const jsonEnabled = forceJsonEnable || FeatureFlags.value('enable_soql_json_datatype');

  let textConversions = {
    text: basicTo('text'),
    number: basicTo('number'),
    checkbox: basicTo('boolean'),
    calendar_date: toDateLike('calendar_date', 'to_floating_timestamp'),
    url: basicTo('url'),
    point: basicTo('point'),
    line: basicTo('line'),
    polygon: basicTo('polygon'),
    multipoint: basicTo('multipoint'),
    multiline: basicTo('multiline'),
    multipolygon: basicTo('multipolygon'),
  };

  if (jsonEnabled) {
    textConversions = {...textConversions, json: basicTo('json') };
  }

  const types = [
    {
      canonicalName: 'text', // The standard SODA2 API name
      cssName: 'text', // The name used in CSS classes
      icon: 'text',
      nullify: 'null::text',
      orderable: true,
      backends: ['nbe', 'obe'],
      conversions: textConversions
    },
    {
      canonicalName: 'number',
      cssName: 'number',
      icon: 'number',
      nullify: 'null::number',
      orderable: true,
      backends: ['nbe', 'obe'],
      conversions: {
        text: basicTo('text'),
        checkbox: basicTo('boolean'),
        number: basicTo('number')
      }
    },
    {
      canonicalName: 'url',
      cssName: 'url',
      icon: 'link',
      nullify: 'null::url',
      orderable: true,
      backends: ['nbe', 'obe'],
      conversions: {
        text: basicTo('text'),
        url: basicTo('url')
      }
    },
    {
      canonicalName: 'checkbox',
      cssName: 'boolean',
      icon: 'boolean',
      nullify: 'null::boolean',
      orderable: true,
      backends: ['nbe', 'obe'],
      conversions: {
        text: basicTo('text'),
        checkbox: basicTo('boolean')
      }
    },
    {
      canonicalName: 'calendar_date',
      cssName: 'floatingTimestamp',
      icon: 'date',
      nullify: 'to_floating_timestamp(null)', // not sure why we don't expose a typecast for this...
      orderable: true,
      backends: ['nbe', 'obe'],
      conversions: {
        text: basicTo('text'),
        calendar_date: toDateLike('calendar_date', 'to_floating_timestamp')

      }
    },
    {
      canonicalName: 'date',
      cssName: 'floatingTimestamp',
      icon: 'date',
      nullify: 'to_fixed_timestamp(null)', // not sure why we don't expose a typecast for this...
      orderable: true,
      backends: ['nbe', 'obe'],
      conversions: {
        text: basicTo('text'),
        calendar_date: toDateLike('date', 'to_fixed_timestamp')

      }
    },
    // geo types
    {
      canonicalName: 'location',
      cssName: 'location',
      sodaType: 'location',
      icon: 'map',
      nullify: 'null::location',
      orderable: false,
      backends: ['obe', 'nbe'],
        // yes, location is really obe-only however as part of the effort to migrate datasets to NBE
        // while maintaining feature / perceived parity, the API will begin to return 'location'-like
        // responses for datasets that _used_ to have location columns, but have been migrated to the NBE.
        // For these datasets, we want the user to be able to still interact with the location type in DSMUI.
        // Talk to #rainbows-internal if you need more information.
      conversions: {
        text: basicTo('text'),
        location: basicTo('location')
      }
    },
    {
      canonicalName: 'point',
      cssName: 'point',
      icon: 'map',
      nullify: 'null::point',
      orderable: false,
      backends: ['nbe'],
      conversions: {
        text: basicTo('text'),
        point: basicTo('point')
      }
    },
    {
      canonicalName: 'multipoint',
      cssName: 'multipoint',
      icon: 'map',
      nullify: 'null::multipoint',
      orderable: false,
      backends: ['nbe'],
      conversions: {
        text: basicTo('text'),
        multipoint: basicTo('multipoint')
      }
    },
    {
      canonicalName: 'line',
      cssName: 'line',
      icon: 'map',
      nullify: 'null::line',
      orderable: false,
      backends: ['nbe'],
      conversions: {
        text: basicTo('text'),
        line: basicTo('line')
      }
    },
    {
      canonicalName: 'multiline',
      cssName: 'multiline',
      icon: 'map',
      nullify: 'null::multiline',
      orderable: false,
      backends: ['nbe'],
      conversions: {
        text: basicTo('text'),
        multiline: basicTo('multiline')
      }
    },
    {
      canonicalName: 'polygon',
      cssName: 'polygon',
      icon: 'map',
      nullify: 'null::polygon',
      orderable: false,
      backends: ['nbe'],
      conversions: {
        text: basicTo('text'),
        polygon: basicTo('polygon')
      }
    },
    {
      canonicalName: 'multipolygon',
      cssName: 'multipolygon',
      icon: 'map',
      nullify: 'null::multipolygon',
      orderable: false,
      backends: ['nbe'],
      conversions: {
        text: basicTo('text'),
        multipolygon: basicTo('multipolygon')
      }
    }
  ];

  if (jsonEnabled) {
    types.push(
      {
        canonicalName: 'json',
        cssName: 'text',
        icon: 'text',
        nullify: 'null::json',
        orderable: false,
        backends: ['nbe'],
        conversions: {
          text: basicTo('text')
        }
      }
    );
  }

  return types;
};



const reduceProperties = (propertyList) => {
  return propertyList.reduce(
    (acc, typespec) => ({
      ...acc,
      [typespec.canonicalName]: typespec
    }),
    {}
  );
};



export const soqlProperties = (forceJsonEnable) => reduceProperties(soqlPropertiesList(forceJsonEnable));

const obeSoqlProperties = () => reduceProperties(
  _.filter(soqlPropertiesList(), typ => _.includes(typ.backends, 'obe'))
);

const nbeSoqlProperties = () => reduceProperties(
  _.filter(soqlPropertiesList(), typ => _.includes(typ.backends, 'nbe'))
);

const soqlTypes = () => Object.keys(soqlProperties());
const obeSoqlTypes = () => Object.keys(obeSoqlProperties());
const nbeSoqlTypes = () => Object.keys(nbeSoqlProperties());

export const soqlTypesForBackend = (view) => {
  if (isDraft(view)) return soqlTypes();
  if (isEternalObe(view)) return obeSoqlTypes();
  if (isEternalNbe(view)) return nbeSoqlTypes();
};

export const conversionsByBackend = (view) => (soqlType) =>
  _.pick(soqlProperties(soqlType === 'json')[soqlType].conversions, soqlTypesForBackend(view));

// The canonical name returned by this function should correspond
// to the input column soql type returned by the dsmapi api. The
// api deals in SoqlTypes but when it comes time to json encode it,
// it prefers the OBE name for that type if it exists. So eg when
// it wants to encode SoQLBoolean, it choses checkbox over boolean.
// We follow the same pattern here to stay in sync with the backend.
// Some useful files for reference:
// https://github.com/socrata-platform/soql-reference/blob/5f7ff780221930c2bfabadec92161fcdf6e317d5/soql-stdlib/src/main/scala/com/socrata/soql/functions/SoQLTypeClasses.scala
// https://github.com/socrata/dsmapi/blob/7ee07883451a74d9459f9b95ccaabdfd10f0fb3c/lib/dsmapi/soql.ex
export function toFunctionToCanonicalName(toFunction) {
  switch (toFunction) {
    case 'to_number':
      return 'number';
    case 'to_floating_timestamp':
      return 'calendar_date';
    case 'to_fixed_timestamp':
      return 'date';
    case 'to_text':
      return 'text';
    case 'to_boolean':
      return 'checkbox';
    case 'to_url':
      return 'url';
    case 'to_point':
      return 'point';
    case 'to_line':
      return 'line';
    case 'to_polygon':
      return 'polygon';
    case 'to_multipoint':
      return 'multipoint';
    case 'to_multiline':
      return 'multiline';
    case 'to_multipolygon':
      return 'multipolygon';
    default:
      return '';
  }
}

export function isOrderable(canonicalName) {
  return soqlProperties(canonicalName === 'json')[canonicalName].orderable;
}
