import _ from 'lodash';
import React from 'react';
import I18n from 'common/i18n';
import Fieldset from 'datasetManagementUI/components/Fieldset/Fieldset';
import TextInput from 'datasetManagementUI/components/TextInput/TextInput';
import { makeFieldName } from 'datasetManagementUI/components/AddColForm/AddColForm';

const t = (k: string) => I18n.t(k, { scope: 'dataset_management_ui.show_output_schema.geocode_shortcut' });

interface NameErrors {
  displayNameErrors: string[];
  fieldNameErrors: string[];
}

interface ColumnNameInputProps {
  displayName: string;
  errors: NameErrors;
  fieldName: string;
  fieldNameEdited: boolean;
  changeColumnNames: (columNames: { displayName: string; fieldName: string; fieldNameEdited: boolean }) => void;
}

const getErrorList = (errors: string[]): JSX.Element => (<ul className="dsmp-error-list">
  {errors.map(error => (
    <li key={btoa(error)} className="dsmp-error-message">
      {error}
    </li>
  ))}
</ul>);

const ColumnNameInputs = ({ displayName, errors, fieldName, fieldNameEdited, changeColumnNames }: ColumnNameInputProps) => {
  const handleDisplayNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDisplayName = e.target.value;
    const newFieldName = fieldNameEdited ? fieldName : makeFieldName(newDisplayName);
    changeColumnNames({
      displayName: newDisplayName,
      fieldName: newFieldName,
      fieldNameEdited
    });
  };

  const handleFieldNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFieldName = e.target.value;
    changeColumnNames({
      displayName,
      fieldName: newFieldName,
      fieldNameEdited: newFieldName !== ''
    });
  };

  return (<Fieldset>
    <h6>{t('column_name_header')}</h6>
    <label htmlFor="displayName">{t('display_name_label')}</label>
    <TextInput
      field={{
        name: 'displayName',
        value: displayName
      }}
      inErrorState={errors.displayNameErrors.length > 0}
      handleChange={handleDisplayNameChange} />
    {errors.displayNameErrors.length > 0 && getErrorList(errors.displayNameErrors)}
    <label htmlFor="fieldName">{t('field_name_label')}</label>
    <TextInput
      field={{
        name: 'fieldName',
        value: fieldName
      }}
      inErrorState={errors.fieldNameErrors.length > 0}
      handleChange={handleFieldNameChange} />
    {errors.fieldNameErrors.length > 0 && getErrorList(errors.fieldNameErrors)}
  </Fieldset>);
};

export default ColumnNameInputs;
