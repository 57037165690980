import { connect } from 'react-redux';
import _ from 'lodash';
import * as Selectors from 'datasetManagementUI/selectors';
import * as DisplayState from 'datasetManagementUI/lib/displayState';
import * as Links from 'datasetManagementUI/links/links';
import Table from 'datasetManagementUI/components/Table/Table';
import { VALIDATE_ROW_IDENTIFIER } from 'datasetManagementUI/reduxStuff/actions/apiCalls';

// TODO: this is wrong...this only gets a single input column....not all
const getInputColumns = (entities, outputColumns) =>
  outputColumns.map(outputColumn => {
    const inputColumns = outputColumn.transform.transform_input_columns.map(
      ic => entities.input_columns[ic.input_column_id]
    );

    return {
      ...outputColumn,
      inputColumns
    };
  });

function getOutputColumns(entities, osid) {
  return _.chain(Selectors.columnsForOutputSchema(entities, osid))
    .thru(ocs => getInputColumns(entities, ocs))
    .sortBy('position')
    .value();
}

const mapStateToProps = ({ entities, ui }, { path, inputSchema, outputSchema, displayState }) => {
  const outputColumns = getOutputColumns(entities, outputSchema.id);

  const validatingRowIDForColumn = _.compact(
    _.values(ui.apiCalls)
    .filter(call => call.operation === VALIDATE_ROW_IDENTIFIER)
    .map(op => _.find(outputColumns, oc => oc.id === _.get(op, 'callParams.outputColumnId')))
  )[0];

  return {
    entities,
    path,
    inputSchema,
    outputSchema,
    displayState,
    outputColumns,
    validatingRowIDForColumn
  };
};

const mergeProps = (stateProps, ignored, ownProps) => {
  const dispatchProps = {
    getColumnErrorLinkPath: (path, transform, displayState) => {
      return DisplayState.inErrorMode(displayState, transform)
           ? Links.showOutputSchema(
             path,
             path.sourceId,
             path.inputSchemaId,
             path.outputSchemaId)
           : Links.showColumnErrors(
             path,
             path.sourceId,
             path.inputSchemaId,
             path.outputSchemaId,
             transform.id);
    }
  };

  return { ...stateProps, ...dispatchProps, ...ownProps };
};

export default connect(mapStateToProps, null, mergeProps)(Table);
