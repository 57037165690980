import _ from 'lodash';
import uuid from 'uuid';
import {
  apiCallStarted,
  apiCallSucceeded,
  apiCallFailed
} from 'datasetManagementUI/reduxStuff/actions/apiCalls';
import * as dsmapiLinks from 'datasetManagementUI/links/dsmapiLinks';
import { socrataFetch, checkStatus, getJson, getError } from 'datasetManagementUI/lib/http';
import { parseDate } from 'datasetManagementUI/lib/parseDate';
import { browserHistory } from 'react-router';
import * as Selectors from 'datasetManagementUI/selectors';
import Modes from 'datasetManagementUI/lib/modes';
import * as Links from 'datasetManagementUI/links/links';
import { normalizeCreateSourceResponse } from 'datasetManagementUI/lib/jsonDecoders';
import { subscribeToSource } from 'datasetManagementUI/reduxStuff/actions/subscriptions';
import {
  addNotification,
  updateNotification,
  removeNotificationAfterTimeout
} from 'datasetManagementUI/reduxStuff/actions/notifications';
import { showFlashMessage, hideAllFlashMessages } from 'datasetManagementUI/reduxStuff/actions/flashMessage';
import I18n from 'common/i18n';
import { chunkAndUpload, CancelledError } from 'datasetManagementUI/reduxStuff/actions/uploadChunks';
import { getContentType } from 'datasetManagementUI/reduxStuff/actions/uploadFile';

export const CREATE_SOURCE = 'CREATE_SOURCE';
export const UPDATE_SOURCE = 'UPDATE_SOURCE';
export const CANCEL_SOURCE = 'CANCEL_SOURCE';
export const CREATE_SOURCE_SUCCESS = 'CREATE_SOURCE_SUCCESS';
export const CREATE_UPLOAD_SOURCE_SUCCESS = 'CREATE_UPLOAD_SOURCE_SUCCESS';
export const SOURCE_UPDATE = 'SOURCE_UPDATE';

const scope = 'dataset_management_ui.show_uploads';

// CREATE SOURCE THUNKS
// Generic Create Source
export function createSource(params, callParams, optionalCallId = null) {

  return async dispatch => {
    const callId = optionalCallId || uuid();

    const call = {
      operation: CREATE_SOURCE,
      callParams
    };

    dispatch(apiCallStarted(callId, call));

    try {
      const response = await socrataFetch(dsmapiLinks.sourceCreate(params), {
        method: 'POST',
        body: JSON.stringify(callParams)
      })
        .then(checkStatus)
        .then(getJson)
        .catch(getError);

      const { resource } = response;

      dispatch(apiCallSucceeded(callId));

      return resource;
    } catch (err) {
      const unparsableError = _.get(err, 'body.key') === 'unparsable_file';

      if (!unparsableError) {
        // Hold off declaring the api call failed if the server returns a key
        // with above value on the error. The source create action failed and
        // returned an error, but we don't want to show the error in this case.
        // We want to try uploading the file as a blob and see if it works.
        // If it doesn't, then we show an error.
        dispatch(apiCallFailed(callId, err));
      }

      throw err;
    }
  };
}

export function getSource(sourceId) {
  return socrataFetch(dsmapiLinks.sourceShow(sourceId), {
    method: 'GET',
  })
  .then(checkStatus)
  .then(getJson)
  .catch(getError);
}

export function createSourceSuccess(payload) {
  return {
    type: CREATE_SOURCE_SUCCESS,
    ...payload
  };
}

// View Source
export function createViewSource(params) {
  const callParams = {
    source_type: { type: 'view' }
  };
  // TODO: create revision channel and subscribe to it here or above to catch
  // os id updates
  return async dispatch => {
    try {
      dispatch(hideAllFlashMessages());
      const resource = await dispatch(createSource(params, callParams));
      const normalizedResource = normalizeCreateSourceResponse(resource);
      dispatch(createSourceSuccess(normalizedResource));
      return normalizedResource;
    } catch (err) {
      dispatch(showFlashMessage({ kind: 'error', id: 'create_view_source_error', message: I18n.t('flash_error_message', { scope }) }));
    }
  };
}

function maybeAddViewColumnMetadata(callParams, params, entities) {
  // This is kind of a special case (ticket EN-23445)
  // If the user has edited column metadata on a subsequent revision, and
  // then they add a source, in the normal case, the column metadata gets
  // taken from the source, so it wipes out their edits. That behavior is
  // something we don't want, so in this case, if the user has as saved output schema
  // and we're in edit mode, we explicitly pass in the column
  // metadata attributes when we create the source. This way they can
  // open a revision -> update column metadata -> upload a file, and their
  // column metadata gets transferred over.
  // The caveat is that the field name needs to remain the same, but - better than
  // nothing
  const modes = Modes.modes(entities, params);
  const rev = Selectors.currentRevision(entities, _.toNumber(params.revisionSeq));
  if (!rev) return callParams;

  const outputSchema = entities.output_schemas ?
    Selectors.currentOutputSchema(entities, rev.revision_seq) :
    null;

  if (modes.edit && outputSchema) {
    const existingColumns = Selectors.columnsForOutputSchema(entities, outputSchema.id);

    if (existingColumns.length > 0) {
      return {
        ...callParams,
        columns: existingColumns.map(oc => ({
          display_name: oc.display_name,
          field_name: oc.field_name,
          description: oc.description,
          format: oc.format,
          transform_expr: oc.transform.transform_expr
        }))
      };
    }
  }

  return callParams;
}

function initiateUpload(sourceId, contentType) {
  return () => {
    return socrataFetch(dsmapiLinks.initiateUpload(sourceId), {
      method: 'POST',
      body: JSON.stringify({content_type: contentType})
    }).then(checkStatus)
      .then(getJson);
  };
}

export function createUploadSource(file, parseableFileType, params, callId) {
  return async (dispatch, getState) => {
    const parseSetting = Selectors.getShouldParseFile(getState().entities);
    const shouldParseFile = parseSetting === null ? parseableFileType : parseSetting;
    // TODO: remove this from callParams and send only with initiateUpload
    // once dsmapi change has gone out to all envs
    const contentType = getContentType(file.type);
    const callParams = maybeAddViewColumnMetadata({
      source_type: { type: 'upload', filename: file.name },
      content_type: contentType,
      parse_options: { parse_source: shouldParseFile }
    }, params, getState().entities);

    let resource;

    try {
      resource = await dispatch(createSource(params, callParams, callId));
      dispatch(hideAllFlashMessages());
      dispatch(
        createUploadSourceSuccess(resource.id, resource.created_by, resource.created_at, resource.source_type)
      );

      dispatch(subscribeToSource(resource.id, params));

      const {
        preferred_upload_parallelism: pHint,
        preferred_chunk_size: cHint
      } = await dispatch(initiateUpload(resource.id, contentType));

      await dispatch(chunkAndUpload(resource.id, file, pHint, cHint));
    } catch (err) {
      // we can only change the parseOption if the parseSetting is empty (allows for either)
      if (_.get(err, 'response.status') >= 500) {
        dispatch(showFlashMessage({
          kind: 'error',
          id: 'create_upload_source_5xx',
          message: I18n.t('dataset_management_ui.notifications.connection_5xx')
        }));
      } else if (_.get(err, 'response.status') === 401) {
        dispatch(showFlashMessage({
          kind: 'error',
          id: 'create_upload_source_401',
          message: I18n.t('dataset_management_ui.notifications.connection_401')
        }));
      } else if (err instanceof TypeError) {
        // a network error occured on either createSource or uploadFile; we got
        // no response from the server via http or websocket. A TypeError is what
        // fetch returns if it cannot reach the network at all.
        dispatch(showFlashMessage({
          kind: 'error',
          id: 'create_upload_source_type_error',
          message: I18n.t('dataset_management_ui.notifications.connection_error_body')
        }));
      } else if (_.get(err, 'body.key') === 'validation_failed') {
        dispatch(showFlashMessage({
          kind: 'error',
          id: 'create_upload_source_unsupported',
          message: I18n.t('unsupported_file', { scope }),
          helpMessage: I18n.t('flash_help_message', { scope }),
          helpUrl: 'https://support.socrata.com/hc/en-us/articles/202949918'
        }));
      } else if (err instanceof CancelledError) {
        console.warn('The data source was cancelled', resource);
      } else {
        console.error('Unknown error', err, resource);
        dispatch(showFlashMessage({
          kind: 'error',
          id: 'create_upload_source_unknown_error',
          message: I18n.t('unknown_error', { scope })
        }));
      }
    }
  };
}

export function createUploadSourceSuccess(id, createdBy, createdAt, sourceType, percentCompleted = 0) {
  return {
    type: CREATE_UPLOAD_SOURCE_SUCCESS,
    source: {
      id,
      source_type: sourceType,
      created_by: createdBy,
      created_at: parseDate(createdAt),
      percentCompleted
    }
  };
}


// URL Source
export function createURLSource(url, params, optionalCallId = null) {
  return async (dispatch, getState) => {
    const shouldParseFile = Selectors.getShouldParseFile(getState().entities);

    const callParams = maybeAddViewColumnMetadata({
      source_type: {
        type: 'url',
        url
      },
      parse_options: { parse_source: shouldParseFile }
    }, params, getState().entities);

    const callId = optionalCallId || uuid();

    try {
      const resource = await dispatch(createSource(params, callParams, callId));
      dispatch(
        createUploadSourceSuccess(
          resource.id,
          resource.created_by,
          resource.created_at,
          resource.source_type
        )
      );
      dispatch(addNotification('source', resource.id));
      dispatch(subscribeToSource(resource.id, params));
    } catch (err) {
      dispatch(apiCallFailed(callId, err));
      throw err;
    }
  };
}

// TODO: differentiate params and sourceParams in a meaningful way
export function createAgentSource(params, agentUid, namespace, path, humanPath, sourceParams) {
  const callId = uuid();

  const callParams = {
    source_type: {
      type: 'connection_agent',
      agent_uid: agentUid,
      namespace,
      path,
      human_path: humanPath,
      parameters: sourceParams
    },
    parse_options: { parse_source: true }
  };

  return async dispatch => {
    try {
      const resource = await dispatch(createSource(params, callParams, callId));

      dispatch(
        createUploadSourceSuccess(
          resource.id,
          resource.created_by,
          resource.created_at,
          resource.source_type
        )
      );

      dispatch(addNotification('source', resource.id));
      dispatch(subscribeToSource(resource.id, params));

      return resource;
    } catch (err) {
      dispatch(apiCallFailed(callId, err));
      throw err;
    }
  };
}

// UPDATE SOURCE THUNKS
function updateSource(params, source, changes) {
  return async dispatch => {
    const callId = uuid();

    const call = {
      operation: UPDATE_SOURCE,
      callParams: { sourceId: source.id }
    };

    dispatch(apiCallStarted(callId, call));

    try {
      const { resource } = await socrataFetch(dsmapiLinks.sourceUpdate(source.id), {
        method: 'POST',
        body: JSON.stringify(changes)
      })
        .then(checkStatus)
        .then(getJson);

      dispatch(apiCallSucceeded(callId));

      return resource;
    } catch (err) {
      dispatch(apiCallFailed(callId, err));
      throw err;
    }
  };
}

export function updateSourceParseOptions(params, source, parseOptions) {
  return async dispatch => {
    const resource = await dispatch(updateSource(params, source, { parse_options: parseOptions }));
    const normalizedResource = normalizeCreateSourceResponse(resource);
    dispatch(subscribeToSource(resource.id, params));
    dispatch(createSourceSuccess(normalizedResource));
    return normalizedResource;
  };
}

export function dontParseSource(params, source) {
  return async dispatch => {
    const resource = await dispatch(updateSource(params, source, { parse_options: { parse_source: false } }));

    dispatch(subscribeToSource(resource.id, params));

    dispatch(
      createUploadSourceSuccess(
        resource.id,
        resource.created_by,
        resource.created_at,
        resource.source_type,
        100
      )
    );

    dispatch(updateNotification(source.id, { status: 'success' }));
    dispatch(removeNotificationAfterTimeout(source.id));

    browserHistory.push(Links.showBlobPreview(params, resource.id));

    return resource;
  };
}

export function sourceUpdate(sourceId, changes) {
  // oh ffs....we did this to ourselves.
  // TODO: fix this garbage
  if (_.isString(changes.created_at)) {
    changes.created_at = parseDate(changes.created_at);
  }
  if (_.isString(changes.finished_at)) {
    changes.finished_at = parseDate(changes.finished_at);
  }
  return {
    type: SOURCE_UPDATE,
    sourceId,
    changes
  };
}


export function cancelSource(source) {
  const sourceId = source.id;

  return dispatch => {
    const callParams = { sourceId };

    const callId = uuid();
    const subject = sourceId;

    const call = {
      operation: CANCEL_SOURCE,
      callParams
    };

    dispatch(apiCallStarted(callId, call));


    return socrataFetch(dsmapiLinks.cancelSource(sourceId), {
      method: 'POST'
    })
      .then(resp => JSON.parse(resp.responseText))
      .then(resp => {
        dispatch(apiCallSucceeded(callId));
        return resp;
      })
      .catch(error => {
        dispatch(updateNotification(subject, { status: 'error', error: error.body }));
        dispatch(removeNotificationAfterTimeout(subject));
        dispatch(apiCallFailed(callId, error));
        return error;
      });
  };
}
