import ApiCallButton from 'datasetManagementUI/components/ApiCallButton/ApiCallButton';
import { ApiCall, ApiCallOperation, AppState } from 'datasetManagementUI/lib/types';
import _ from 'lodash';
import { connect } from 'react-redux';

const SHOW_RESULT_STATE_FOR_MS = 1000;

export const findMatchingApiCall = (apiCalls: ApiCall[], operation: ApiCallOperation, callParams: any, limit = SHOW_RESULT_STATE_FOR_MS) =>
  _.find(apiCalls, call => {
    const now = new Date();
    const callTime = call.succeededAt || call.failedAt || call.startedAt;
    const timeElapsed = callTime ? now.getTime() - callTime.getTime() : 0;
    // we have a match if
    // 1. the api call operation matches
    // 2. the api callParams match
    // 3. the call finished/started/failed within a certain timeframe (specified by SHOW_RESULT_STATE_FOR_MS)
    // If no match, the ApiCallButton just renders normal blue button rather than one that tracks apiCall status
    return _.isMatch(call, { operation, callParams }) && timeElapsed < limit;
  });

interface OwnProps {
  callParams: any;
  operation: ApiCallOperation;
  limit?: number;
  onClick: () => void;
  children?: any;
  forceDisable?: boolean;
  additionalClassName?: string;
}

export const mapStateToProps = ({ ui }: AppState, ownProps: OwnProps) => {
  const apiCall = findMatchingApiCall(
    ui.apiCalls,
    ownProps.operation,
    ownProps.callParams,
    ownProps.limit
  );

  return {
    status: apiCall ? apiCall.status : null
  };
};

export default connect(mapStateToProps)(ApiCallButton);
