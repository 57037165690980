import PropTypes from 'prop-types';
import React from 'react';
import I18n from 'common/i18n';

export function getGeoFlyoutId(transform) {
  return `transform-geo-flyout-${transform.id}`;
}

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.show_output_schema.column_header' });

function GeoFlyout({ transform }) {

  const flyoutId = getGeoFlyoutId(transform);

  return (
    <div id={flyoutId} className="transform-status-flyout flyout flyout-hidden">
      <section className="flyout-content">
        {t('can_geocode')}
        <br />
        <span className="click-to-view">
          {t('click_for_options')}
        </span>
      </section>
    </div>
  );
}

GeoFlyout.propTypes = {
  transform: PropTypes.object.isRequired
};

export default GeoFlyout;
