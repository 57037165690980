import React from 'react';
import {
  toTextExpr,
  inputColumnSelection,
  getInputColumnFromMapping,
  columnMatchingAst,
  fieldPropTypes
} from './Fields';
import { traverse, stripToTextAst } from 'datasetManagementUI/lib/ast';

const CombinedFields = ({ inputColumns, setMapping, mappings }) => (
  <div>
    {inputColumnSelection('full_address', inputColumns, setMapping, mappings)}
  </div>
);

CombinedFields.propTypes = fieldPropTypes;

const composeFromCombined = (mappings, { useLocation, provider, defaultCountry }) => {
  const geocodeFunc = provider === 'esri' ? 'geocode_arcgis' : 'geocode';
  let fullAddress = toTextExpr(getInputColumnFromMapping(mappings, 'full_address'));
  if (defaultCountry) {
    fullAddress = `${fullAddress} || " " || "${defaultCountry}"`;
  }
  // there is a location -> location impl of geocode
  const base = `${geocodeFunc}(to_location(${fullAddress}))`;

  if (!useLocation) {
    return `location_to_point(${base})`;
  }
  return base;
};

const decomposeFromCombined = (locToPoint, inputColumns) => {
  const ast = traverse(locToPoint, false, (node, acc) => {
    if (node && node.type === 'funcall' && node.function_name === 'to_location') {
      // pluck out the arg to `to_location/1` - it is our output column ast
      return node.args[0];
    }
    return acc;
  });
  return [
    // The ORs here are because we might have wrapped the AST in a to_text call
    [
      'full_address',
      columnMatchingAst(inputColumns, ast) || columnMatchingAst(inputColumns, stripToTextAst(ast))
    ]
  ];
};

const relevantMappingsForCombined = () => ['full_address'];

export {
  CombinedFields,
  composeFromCombined,
  decomposeFromCombined,
  relevantMappingsForCombined
};
