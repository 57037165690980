/* eslint react/jsx-indent: 0 */
import _ from 'lodash';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { hideModal } from 'datasetManagementUI/reduxStuff/actions/modal';
import * as Selectors from 'datasetManagementUI/selectors';
import * as ShowActions from 'datasetManagementUI/reduxStuff/actions/showOutputSchema';
import * as FlashActions from 'datasetManagementUI/reduxStuff/actions/flashMessage';
import * as FormActions from 'datasetManagementUI/reduxStuff/actions/forms';
import * as DisplayState from 'datasetManagementUI/lib/displayState';
import { traverse } from 'datasetManagementUI/lib/ast';
import * as Links from '../links/links';
import I18n from 'common/i18n';
import {
  GeocodeShortcut,
  COMPONENTS,
  COMBINED,
  LATLNG
} from 'datasetManagementUI/components/GeocodeShortcut/GeocodeShortcut';

import {
  composeFromLatLng,
  decomposeFromLatLng,
  relevantMappingsForLatLng
} from 'datasetManagementUI/components/GeocodeShortcut/LatLngFields';

import {
  composeFromCombined,
  decomposeFromCombined,
  relevantMappingsForCombined
} from 'datasetManagementUI/components/GeocodeShortcut/CombinedFields';

import {
  composeFromComponents,
  decomposeFromComponents,
  relevantMappingsForComponents
} from 'datasetManagementUI/components/GeocodeShortcut/ComponentFields';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.show_output_schema.geocode_shortcut' });

function generateDefaultMappings(inputColumns) {
  return [
    'full_address',
    'address',
    'city',
    'state',
    'zip'
  ].map((name) => {
    return [name, undefined];
  });
}

function getOutputSchema(entities, props) {
  const id = _.toNumber(props.params.outputSchemaId);
  return entities.output_schemas[id];
}

// Restore a form state from a target output column (which has a transform, which has an AST)
export function buildDefaultFormState(
  currentFormState,
  view,
  entities,
  inputColumns,
  outputSchema,
  outputColumns,
  outputColumn
) {
  let formState = getMappingsFromOutputColumn(
    inputColumns,
    outputColumn
  );

  formState = {
    ...currentFormState,
    ...formState,
    ...getErrorForgivenessFromOutputColumn(outputColumn),
    columnNames: {
      displayName: _.get(outputColumn, 'display_name', ''),
      fieldName: _.get(outputColumn, 'field_name', ''),
      fieldNameEdited: false
    }
  };
  const desiredColumns = genDesiredColumns(
    view,
    outputColumns,
    outputColumn,
    formState
  );

  return { ...formState, desiredColumns };
}

function getStateFromGeocodeFuncAst(geocodeFunc, inputColumns) {
  if (geocodeFunc.args.length === 1) {
    return {
      mappings: decomposeFromCombined(geocodeFunc, inputColumns),
      composedFrom: COMBINED
    };
  } else if (geocodeFunc.args.length === 4 || geocodeFunc.args.length === 5) {
    // Now we want to set the mappings from the component name to the input column
    // referred to in the AST
    return {
      mappings: decomposeFromComponents(geocodeFunc, inputColumns),
      composedFrom: COMPONENTS
    };
  }
  return {
    configurationError: t('transform_too_complex')
  };
}

function getStateFromMakePointAst(geocodeFunc, inputColumns) {
  return {
    mappings: decomposeFromLatLng(geocodeFunc, inputColumns),
    composedFrom: LATLNG
  };
}

function getMappingsFromOutputColumn(inputColumns, outputColumn) {
  if (outputColumn && outputColumn.transform && outputColumn.transform.parsed_expr) {
    const { parsed_expr: ast } = outputColumn.transform;
    // Now we need to seed the column mapping, so we need to walk the ast
    // until we find the `geocode` function and pluck its arguments out
    const geocodeFunc = traverse(ast, false, (node, acc) => {
      if (node && (node.function_name === 'geocode' || node.function_name === 'geocode_arcgis')) {
        return node;
      }
      return acc;
    });

    // This is a limitation of the Geocode shortcut -
    // each arg has to be a column, nothing else will
    // work
    if (geocodeFunc) {
      return getStateFromGeocodeFuncAst(geocodeFunc, inputColumns);
    }

    // So we haven't found an output column that is derived from geocoding - so
    // let's try to fill the third tab in this dialog with a make_point
    // output column
    const makePointFunc = traverse(ast, false, (node, acc) => {
      if (node && node.function_name === 'make_point') {
        return node;
      }
      return acc;
    });

    if (makePointFunc) {
      return getStateFromMakePointAst(makePointFunc, inputColumns);
    }
  }
  return {
    mappings: generateDefaultMappings(inputColumns),
    composedFrom: COMPONENTS
  };
}

function relevantArgsForComposition(formState) {
  const argsOf = mappingNames =>
      formState.mappings
      .filter(([name]) => _.includes(mappingNames, name))
      .map(([_name, outputColumn]) => outputColumn); // eslint-disable-line

  switch (formState.composedFrom) {
    case COMPONENTS:
      return argsOf(relevantMappingsForComponents());
    case COMBINED:
      return argsOf(relevantMappingsForCombined());
    case LATLNG:
      return argsOf(relevantMappingsForLatLng());
    default:
      return [];
  }
}

function getErrorForgivenessFromOutputColumn(outputColumn) {
  if (outputColumn && outputColumn.transform && outputColumn.transform.parsed_expr) {
    const { parsed_expr: ast } = outputColumn.transform;
    // If the wrapping function is forgive, we're converting geocoding
    // errors into null values
    const shouldConvertToNull = ast.function_name === 'forgive';
    return { shouldConvertToNull };
  }
  return { shouldConvertToNull: false };
}

export function genNewExpression(view, formState, forceLocation) {
  const maybeForgive = expr => {
    if (formState.shouldConvertToNull) {
      return `forgive(${expr})`;
    }
    return expr;
  };

  const geocodingOptions = {
    useLocation: forceLocation,
    provider: formState.provider,
    defaultCountry: formState.defaultCountry
  };

  switch (formState.composedFrom) {
    case COMPONENTS:
      return maybeForgive(composeFromComponents(formState.mappings, geocodingOptions));
    case COMBINED:
      return maybeForgive(composeFromCombined(formState.mappings, geocodingOptions));
    case LATLNG:
      return maybeForgive(composeFromLatLng(formState.mappings, geocodingOptions));
    default:
      throw new Error(`Invalid composition: ${formState.composedFrom}`);
  }
}

// given an array of old output columns (as an array of field_name strings) and a new
// set of output columns, it returns a new copy of output columns based on the order
// of the old output columns.
//
// any columns not found in the old output cols will be placed at the end
export function sortOutputColumns(initialOutputColumns = [], outputColumns = []) {
  const initOutputCols = {};
  _.forEach(initialOutputColumns, (name, i) => initOutputCols[name] = i + 1);

  function compare(a, b) {
    // if not in initial output cols, sort by `position` attr
    if (!initOutputCols[a.field_name] && !initOutputCols[b.field_name]) {
      if (a.position <= b.position) {
        return -1;
      } else {
        return 1;
      }
    } else if (!initOutputCols[a.field_name]) {
      // if not left-side column is not in initial col, move it to the right
      return 1;
    } else if (!initOutputCols[b.field_name]) {
      return -1;
    } else if (initOutputCols[a.field_name] < initOutputCols[b.field_name]) {
      return -1;
    } else if (initOutputCols[a.field_name] > initOutputCols[b.field_name]) {
      return 1;
    } else {
      return 0;
    }
  }

  const sorted = outputColumns.slice().sort(compare);
  const repositioned = _.map(sorted, (col, i) => ({ ...col, position: i + 1 }));
  return repositioned;
}

function genDesiredColumns(view, existingColumns, targetColumn, formState) {
  const anyMappings = _.some(
    formState.mappings.map(([_name, value]) => value !== null) // eslint-disable-line
  );

  let desiredColumns;
  if (targetColumn) {
    // We already have a target column - so the default behavior
    // is to replace the existing one with one of a new expression
    desiredColumns = existingColumns.map(oc => {
      if (oc.id === targetColumn.id) {
        const forceLocation = oc.transform.output_soql_type === 'location';
        // This is the column we want to update - clone it, but with the new expression
        const newOC = {
          ...oc,
          display_name: _.get(formState, 'columnNames.displayName', oc.display_name),
          field_name: _.get(formState, 'columnNames.fieldName', oc.field_name)
        };
        return ShowActions.buildNewOutputColumn(newOC, () => genNewExpression(view, formState, forceLocation));
      } else {
        // Otherwise, we just clone it
        return ShowActions.cloneOutputColumn(oc);
      }
    });
  } else {
    desiredColumns = existingColumns.map(ShowActions.cloneOutputColumn);
    if (anyMappings) {
      const proposedFieldName = _.get(formState, 'columnNames.fieldName', '');
      const proposedDisplayName = _.get(formState, 'columnNames.displayName', '');
      const similar = existingColumns.filter(e => {
        return e.field_name.startsWith(proposedFieldName) || e.display_name.startsWith(proposedDisplayName);
      });
      const disambiguator = similar.length ? `_${similar.length}` : '';

      desiredColumns.push({
        field_name: `${proposedFieldName}${disambiguator}`,
        position: desiredColumns.length + 1, // position is 1 based, not 0, because core
        display_name: proposedDisplayName,
        description: '',
        transform: {
          transform_expr: genNewExpression(view, formState, false)
        },
        is_primary_key: false
      });
    }
  }

  const initialOutputCols = formState.initialOutputColumns;
  const desiredCols = desiredColumns.map((dc, i) => ({ ...dc, position: i + 1 }));
  return sortOutputColumns(initialOutputCols, desiredCols);
}

export const mapStateToProps = ({ entities, ui }, props) => {
  const { params, location, newOutputSchema } = props;

  const view = entities.views[params.fourfour];

  const inputSchemaId = _.toNumber(params.inputSchemaId);
  const outputSchemaId = _.toNumber(params.outputSchemaId);
  const outputColumnId = _.toNumber(params.outputColumnId);

  const inputSchema = entities.input_schemas[inputSchemaId];
  const inputColumns = Selectors.columnsForInputSchema(entities, inputSchemaId);

  const outputSchema = getOutputSchema(entities, props);
  const outputColumns = Selectors.columnsForOutputSchema(entities, outputSchemaId);
  const outputColumn = _.find(outputColumns, (oc) => oc.id === outputColumnId);

  let formState = ui.forms.geocodeShortcutForm.state;

  if (formState.outputSchemaId !== outputSchemaId) {
    formState = buildDefaultFormState(
      formState,
      view,
      entities,
      inputColumns,
      outputSchema,
      outputColumns,
      outputColumn
    );
  }

  const relevantColumns = relevantArgsForComposition(formState);
  const anySelected = relevantColumns.length > 0 && _.some(relevantColumns);

  const enableAddColumn = !!formState.desiredColumns && ui.forms.geocodeShortcutForm.isDirty && anySelected;

  let isPreviewable = false;

  if (outputColumn) {
    const forceLocation = outputColumn.transform.output_soql_type === 'location';
    const namesMatch = outputColumn.display_name === _.get(formState, 'columnNames.displayName')
      && outputColumn.field_name === _.get(formState, 'columnNames.fieldName');
    isPreviewable = outputColumn.transform.transform_expr.replace(/\s/g,'') === genNewExpression(view, formState, forceLocation).replace(/\s/g,'') && namesMatch;
  }

  return {
    newOutputSchema,
    entities,
    params: { ...params, geocodeShortcut: true },
    view,
    displayState: DisplayState.fromUiUrl({ params, location }),
    formState,

    inputSchema,
    outputSchema,
    outputColumns,
    outputColumn,
    inputColumns,
    anySelected,
    isPreviewable,
    enableAddColumn,
    formErrors: ui.forms.geocodeShortcutForm.errors
  };
};

const mergeProps = (stateProps, { dispatch }, ownProps) => {
  const formState = stateProps.formState;
  // The reason we need to sync this to the store is because there are two
  // buttons living in diff components that need to use this in order to make
  // a new schema
  const updateDesiredColumns = (newFormState) => {
    const desiredColumns = genDesiredColumns(
      stateProps.view,
      stateProps.outputColumns,
      stateProps.outputColumn,
      newFormState
    );

    const outputSchemaId = stateProps.outputSchema.id;
    dispatch(FormActions.setFormState(
      'geocodeShortcutForm',
      { ...newFormState, outputSchemaId, desiredColumns }
    ));
  };

  const dispatchProps = {
    onDismiss: () => dispatch(hideModal()),

    redirectGeocodePane: (newOutputSchema, withColumnId = true) => {
      // Now we are given back a new schema from dsmapi, and we need to find
      // which column is the one we just added so we can redirect to the new URL
      // and cause all the components to update with the new state (which it grabs)
      // from the URL params
      // look for the column with the new fieldname
      const oldColumnFieldnames = stateProps.outputColumns.reduce((acc, oc) => {
        acc[oc.field_name] = true;
        return acc;
      }, {});
      const oldColumnIds = stateProps.outputColumns.reduce((acc, oc) => {
        acc[oc.id] = true;
        return acc;
      }, {});
      const isNewColumn = (oc) => !oldColumnFieldnames[oc.field_name] || !oldColumnIds[oc.id];
      const newColumn = _.find(newOutputSchema.output_columns, isNewColumn);

      browserHistory.push(Links.geocodeShortcut(
        { ...stateProps.params,
          outputSchemaId: newOutputSchema.id,
          outputColumnId: withColumnId ? newColumn.id : undefined
        }
      ));
    },

    setMapping: (addressComponent, outputColumn) => {
      const mappings = formState.mappings
        .filter(([component]) => component !== addressComponent)
        .concat([[addressComponent, outputColumn]]);

      const newFormState = { ...formState, mappings };
      dispatch(FormActions.setFormState('geocodeShortcutForm', newFormState));
      dispatch(FormActions.markFormUnsubmitted('geocodeShortcutForm'));
      dispatch(FormActions.markFormDirty('geocodeShortcutForm'));
      updateDesiredColumns(newFormState);
    },

    setComposedFrom: composedFrom => {
      const newFormState = { ...formState, composedFrom };
      dispatch(FormActions.setFormState('geocodeShortcutForm', newFormState));
      updateDesiredColumns(newFormState);
    },

    toggleConvertToNull: () => {
      const shouldConvertToNull = !formState.shouldConvertToNull;
      const newFormState = { ...formState, shouldConvertToNull };
      dispatch(FormActions.setFormState('geocodeShortcutForm', newFormState));
      dispatch(FormActions.markFormUnsubmitted('geocodeShortcutForm'));
      dispatch(FormActions.markFormDirty('geocodeShortcutForm'));
      updateDesiredColumns(newFormState);
    },

    changeColumnNames: (columnNames) => {
      const newFormState = { ...formState, columnNames };
      dispatch(FormActions.setFormState('geocodeShortcutForm', newFormState));
      dispatch(FormActions.markFormUnsubmitted('geocodeShortcutForm'));
      dispatch(FormActions.markFormDirty('geocodeShortcutForm'));
      updateDesiredColumns(newFormState);
    },

    showError: message => dispatch(FlashActions.showFlashMessage({
      kind: 'error', id: 'geocode_error', message, hideAfterMS: 10000
    })),

    showSuccess: message => dispatch(FlashActions.showFlashMessage({
      kind: 'success', id: 'geocode_success', message, hideAfterMS: 10000
    })),

    markFormSubmitted: () => dispatch(FormActions.markFormSubmitted('geocodeShortcutForm')),

    markFormUnsubmitted: () => dispatch(FormActions.markFormUnsubmitted('geocodeShortcutForm')),

    setNewOutputSchemaId: (newOutputSchemaId) => {
      const newFormState = { ...formState, newOutputSchemaId };
      dispatch(FormActions.setFormState('geocodeShortcutForm', newFormState));
    },

    setOldOutputSchemaId: (oldOutputSchemaId) => {
      const newFormState = { ...formState, oldOutputSchemaId };
      dispatch(FormActions.setFormState('geocodeShortcutForm', newFormState));
    },

    clearForm: () => {
      dispatch(FormActions.setFormState('geocodeShortcutForm', {
        ...formState,
        columnNames: {
          displayName: '',
          fieldName: '',
          fieldNameEdited: false
        }
      }));
      dispatch(FormActions.markFormClean('geocodeShortcutForm'));
    },

    setErrors: (errors) => dispatch(FormActions.setFormErrors('geocodeShortcutForm', errors)),

    clearOutputSchemaId: () => dispatch(FormActions.setFormState('geocodeShortcutForm', {
      ...formState,
      outputSchemaId: undefined
    }))
  };

  return { ...dispatchProps, ...ownProps, ...stateProps };
};

export default connect(mapStateToProps, null, mergeProps)(GeocodeShortcut);
