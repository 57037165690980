import PropTypes from 'prop-types';
import React from 'react';
import I18n from 'common/i18n';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.show_output_schema.geocode_shortcut' });

const ErrorHandling = ({ toggleConvertToNull, shouldConvertToNull }) =>
  <div className="radiobutton error-handling">
    <h6>
      {t('error_handling')}
    </h6>
    <div>
      <input
        type="radio"
        id="as-null"
        name="radio"
        onChange={toggleConvertToNull}
        checked={shouldConvertToNull} />
      <label htmlFor="as-null">
        <span className="fake-radiobutton" />
        {t('treat_as_empty')}
        <p className='label-subtitle'>
          {t('what_is_forgive')}
        </p>
      </label>
    </div>
    <div>
      <input
        type="radio"
        id="as-error"
        name="radio"
        onChange={toggleConvertToNull}
        checked={!shouldConvertToNull} />
      <label htmlFor="as-error">
        <span className="fake-radiobutton" />
        {t('skip_row')}
      </label>
      <p className='label-subtitle'>
        {t('what_is_an_error')}
      </p>
    </div>
  </div>;

ErrorHandling.propTypes = {
  toggleConvertToNull: PropTypes.func.isRequired,
  shouldConvertToNull: PropTypes.bool.isRequired
};

export default ErrorHandling;
