import I18n from 'common/i18n';
import { PERMISSIONS } from 'datasetManagementUI/components/PublishConfirmation/PublishConfirmation';
import { checkStatus, getError, getJson, socrataFetch } from 'datasetManagementUI/lib/http';
import * as dsmapiLinks from 'datasetManagementUI/links/dsmapiLinks';
import {
  ADD_EMAIL_INTEREST, apiCallFailed, apiCallStarted,
  apiCallSucceeded,

  APPLY_REVISION
} from 'datasetManagementUI/reduxStuff/actions/apiCalls';
import { showModal } from 'datasetManagementUI/reduxStuff/actions/modal';
import { addNotification } from 'datasetManagementUI/reduxStuff/actions/notifications';
import {
  updateRevision
} from 'datasetManagementUI/reduxStuff/actions/revisions';
import { subscribeToTaskSet } from 'datasetManagementUI/reduxStuff/actions/subscriptions';
import { addTaskSet } from 'datasetManagementUI/reduxStuff/actions/taskSets';
import _ from 'lodash';
import uuid from 'uuid';
// match DSMAPI: https://github.com/socrata/dsmapi/blob/e4eb96e24e0734b33d5ab6ffb26351a07b1c61d1/web/models/task_set.ex#L30-L35
export const TASK_SET_INITIALIZING = 'initializing';
export const TASK_SET_CREATING_COLUMNS = 'creating_columns';
export const TASK_SET_UPSERTING = 'upserting';
export const TASK_SET_AWAITING_UPSERT_RESPONSE = 'awaiting_upsert_response';
export const TASK_SET_INVALID = 'invalid'; // deprecated; replaced by awaiting_upsert_response

export const TASK_SET_FINISHING = 'finishing';
export const TASK_SET_SUCCESSFUL = 'successful';
export const TASK_SET_FAILURE = 'failure';
export const TASK_SET_UNRETRYABLE_FAILURE = 'unretryable_failure';
export const TASK_SET_PAUSED = 'paused';
export const TASK_SET_UNPAUSING = 'unpausing';
export const TASK_SET_RUNNING = 'running';

// this is the only thing we look at from the log
export const TASK_SET_STAGE_ROWS_UPSERTED = 'rows_upserted';

export const TASK_SET_STATUSES = [
  TASK_SET_INITIALIZING,
  TASK_SET_CREATING_COLUMNS,
  TASK_SET_UPSERTING,
  TASK_SET_AWAITING_UPSERT_RESPONSE,
  TASK_SET_INVALID,
  TASK_SET_FINISHING,
  TASK_SET_SUCCESSFUL,
  TASK_SET_FAILURE,
  TASK_SET_UNRETRYABLE_FAILURE,
  TASK_SET_PAUSED,
  TASK_SET_UNPAUSING,
];

export function taskSetInProgress(taskSet) {
  return taskSet.status !== TASK_SET_SUCCESSFUL &&
    taskSet.status !== TASK_SET_FAILURE &&
    taskSet.status !== TASK_SET_UNRETRYABLE_FAILURE;
}

export function taskSetApplyable(taskSet) {
  taskSet.status !== TASK_SET_SUCCESSFUL && !taskSetInProgress(taskSet);
}

export function updatePermission(permission, params) {
  return (dispatch, getState) => {
    const { entities } = getState();
    const { id: revisionId } = _.find(entities.revisions, { revision_seq: _.toNumber(params.revisionSeq) });
    const oldPermission = _.get(entities, `revisions.${revisionId}.action.permission`, PERMISSIONS.PUBLIC);

    if (permission === oldPermission) {
      return Promise.resolve();
    }

    const update = {
      action: {
        permission
      }
    };

    return dispatch(updateRevision(update, params));
  };
}

export function applyRevision(params) {
  return dispatch => {
    const callId = uuid();

    dispatch(
      apiCallStarted(callId, {
        operation: APPLY_REVISION,
        callParams: {}
      })
    );

    return socrataFetch(dsmapiLinks.applyRevision(params), {
      method: 'PUT'
    })
      .then(checkStatus)
      .then(getJson)
      .then(resp => {
        dispatch(apiCallSucceeded(callId));
        dispatch(addTaskSet(resp.resource));
        dispatch(showModal('Publishing'));

        const taskSetId = resp.resource.id;

        // maybe return status and then do something based on that?
        dispatch(subscribeToTaskSet(taskSetId));
      })
      .catch(err => {
        dispatch(apiCallFailed(callId, err));
        const errorCode = _.get(err, 'response.status', null);
        const errorType = errorCode === 409 ? 'revision_conflict_error' : 'dataset_revision_error';
        dispatch(addNotification('error', I18n.t(`dataset_management_ui.notifications.${errorType}`)));
      });
  };
}


export function getPlan(params) {
  return socrataFetch(dsmapiLinks.revisionPlan(params), {
    method: 'GET'
  })
  .then(checkStatus)
  .then(getJson)
  .catch(getError);
}


export function addEmailInterest(jobUUID) {
  return dispatch => {
    dispatch(
      apiCallStarted(jobUUID, {
        operation: ADD_EMAIL_INTEREST
      })
    );

    return socrataFetch(`/users/${serverConfig.currentUser.id}/email_interests.json`, {
      method: 'POST',
      body: JSON.stringify({
        eventTag: 'MAIL.IMPORT_ACTIVITY_COMPLETE',
        extraInfo: jobUUID
      })
    })
      .then(checkStatus)
      .then(() => dispatch(apiCallSucceeded(jobUUID)))
      .catch(err => dispatch(apiCallFailed(jobUUID, err)));
  };
}
