import PropTypes from 'prop-types';
import React from 'react';
import ParseOptions from 'datasetManagementUI/containers/ParseOptionsContainer';

const ParseOptionsPane = ({ params }) => (
  <div className="">
    <ParseOptions params={params} />
  </div>
);

ParseOptionsPane.propTypes = {
  params: PropTypes.object.isRequired
};

export default ParseOptionsPane;
