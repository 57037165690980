import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Dropdown from 'common/components/Dropdown';
import TextInput from 'datasetManagementUI/components/TextInput/TextInput';
import I18n from 'common/i18n';

const t = (k) => I18n.t(k, { scope: 'dataset_management_ui.show_output_schema.geocode_shortcut' });
const ENTER_CONSTANT = 'enter-constant';

function getInputColumnFromMapping(mappings, addressComponent) {
  const mapping = _.find(mappings, ([ac]) => ac === addressComponent);
  return mapping && mapping.length ? mapping[1] : null;
}

function inputColumnSelection(
  addressComponent,
  inputColumns,
  setMapping,
  mappings,
  allowConstant
) {
  const inputColumn = getInputColumnFromMapping(mappings, addressComponent);
  const name = t(addressComponent);
  const optionRenderer = (
    { title, className } // eslint-disable-line
  ) => <span className={`picklist-title ${className}`}>{title}</span>;

  const empty = {
    title: t('none'),
    value: null,
    className: 'empty-option',
    render: optionRenderer
  };

  const constant = {
    title: t('constant'),
    value: ENTER_CONSTANT,
    className: 'constant-option',
    render: optionRenderer
  };

  const options = inputColumns
    .map(ic => ({
      title: ic.display_name,
      value: ic.field_name,
      render: optionRenderer
    }))
    .concat([empty]);

  if (allowConstant) {
    options.push(constant);
  }

  const props = {
    onSelection: ({ value }) => {
      if (value === ENTER_CONSTANT) {
        setMapping(addressComponent, '');
      } else if (value) {
        const newInputColumn = _.find(inputColumns, ic => ic.field_name === value);
        setMapping(addressComponent, newInputColumn);
      } else {
        setMapping(addressComponent, null);
      }
    },
    value: _.isString(inputColumn) ? ENTER_CONSTANT : (inputColumn && inputColumn.field_name),
    options
  };

  let constantView;
  if (_.isString(inputColumn)) {
    const onUpdateConstant = e => {
      setMapping(addressComponent, e.target.value);
    };

    constantView = (
      <TextInput
        field={{
          name: `constant-${addressComponent}`,
          placeholder: t('constant'),
          value: inputColumn
        }}
        handleChange={onUpdateConstant}
        inErrorState={false} />
    );
  }

  return (
    <div>
      <label>{name}</label>
      <Dropdown {...props} />
      {constantView}
    </div>
  );
}

const esc = (name) => `\`${name}\``;

// Find the column in output columns which is has the transform_expr
// that matches the parsed tree (ast)
const columnMatchingAst = (inputColumns, ast) => {
  if (ast && ast.type === 'column_ref') {
    return _.find(inputColumns, ic => ic.field_name === ast.value);
  }
  return null;
};

const toTextExpr = ic => {
  if (!ic) return null;
  if (_.isString(ic)) return JSON.stringify(ic); // JSON is a valid SoQL string literal

  if (ic.soql_type === 'text') return esc(ic.field_name);
  return `to_text(${esc(ic.field_name)})`;
};

const toNumberExpr = ic => {
  if (!ic) return null;
  if (ic.soql_type === 'number') return esc(ic.field_name);
  return `to_number(${esc(ic.field_name)})`;
};

const stringLiteral = arg => {
  if (arg && arg.type === 'string_literal') {
    return arg.value;
  }
};

const fieldPropTypes = {
  inputColumns: PropTypes.array.isRequired
};

export {
  getInputColumnFromMapping,
  inputColumnSelection,
  columnMatchingAst,
  toTextExpr,
  toNumberExpr,
  fieldPropTypes,
  stringLiteral
};
