import { socrataFetch, checkStatus, getJson } from 'datasetManagementUI/lib/http';
import * as dsmapiLinks from 'datasetManagementUI/links/dsmapiLinks';

export const SET_SCHEDULE = 'SET_SCHEDULE';

export function getSchedule(params) {
  return dispatch => {
    return socrataFetch(dsmapiLinks.schedule(params))
    .then(checkStatus)
    .then(getJson)
    .then(body => dispatch({
      type: SET_SCHEDULE,
      id: body.resource.fourfour,
      schedule: body.resource
    }));
  };
}
