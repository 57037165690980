import _ from 'lodash';

/*
This is entirely due to this: https://bugzilla.mozilla.org/show_bug.cgi?id=505521
It's 10 years old.
It will never be fixed.
*/

class FirefoxDragHack {
  x: number | undefined;
  y: number | undefined;

  constructor() {
    this.onMove = this.onMove.bind(this);
    this.reset();
    if (this.isFirefox()) {
      this.installHack();
    }
  }

  installHack() {
    document.addEventListener('dragover', this.onMove, true);
  }

  reset() {
    this.x = undefined;
    this.y = undefined;
  }

  cleanup() {
    document.removeEventListener('dragover', this.onMove);
  }

  onStart(e: React.DragEvent) {
    // also firefox disables all drag events unless you call setData
    // with *something* in it
    e.dataTransfer!.setData('text', 'anything');
    this.reset();
  }

  onEnd() {
    this.reset();
  }

  onMove(e: MouseEvent) {
    this.x = e.pageX;
    this.y = e.pageY;
  }

  isFirefox() {
    return _.includes(navigator.userAgent, 'Firefox');
  }

  getX(x: number) {
    if (this.isFirefox() && !_.isUndefined(this.x)) {
      return this.x;
    }
    return x;
  }

  getY(y: number) {
    if (this.isFirefox() && !_.isUndefined(this.y)) {
      return this.y;
    }
    return y;
  }
}

export default FirefoxDragHack;
