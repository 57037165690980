import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory, withRouter } from 'react-router';
import _ from 'lodash';
import * as Links from 'datasetManagementUI/links/links';
import I18n from 'common/i18n';

const LINK_ID = 'dsmui-parse-error-link';

function handler(e, params) {
  e.preventDefault();

  if (_.get(e, 'target.id') === LINK_ID) {
    browserHistory.push(Links.hrefSource(params));
  }
}

const SourceMessage = ({ hrefExists, sourceExists, params }) => {
  let message;

  if (hrefExists) {
    message = I18n.t('dataset_management_ui.show_sources.error_href_exists_html', {
      id: LINK_ID,
      linkText: I18n.t('dataset_management_ui.show_sources.error_href_exists_link_text')
    });
  } else if (sourceExists) {
    message = I18n.t('dataset_management_ui.show_sources.error_schema_exists');
  } else {
    message = I18n.t('dataset_management_ui.show_sources.error_unknown');
  }

  return (
    <section
      id="source-message"
      className="container"
      data-cheetah-hook="no-source-ingress-message">
      <span
        className="message"
        onClick={e => handler(e, params)}
        dangerouslySetInnerHTML={{ __html: message }} />
    </section>
  );
};

SourceMessage.propTypes = {
  hrefExists: PropTypes.bool,
  sourceExists: PropTypes.bool,
  params: PropTypes.object
};

export default withRouter(SourceMessage);
