import React from 'react';
import { connect } from 'react-redux';
import { hideFlashMessage } from 'datasetManagementUI/reduxStuff/actions/flashMessage';
import FlashMessage from 'datasetManagementUI/components/FlashMessage/FlashMessage';

const FlashMessageContainer = ({ flashMessages, onCloseClick }) => {

  return (
    <div>
      {flashMessages.map((msg) => (
        <FlashMessage {...msg} key={msg.id} onCloseClick={() => onCloseClick(msg.id)}></FlashMessage>
      ))}
    </div>
  );
};

const mapStateToProps = ({ ui }) => ({
  flashMessages: ui.flashMessage.flashMessages
});

const mapDispatchToProps = dispatch => ({
  onCloseClick: (id) => dispatch(hideFlashMessage(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(FlashMessageContainer);
