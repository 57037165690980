import React from 'react';
import { fetchTranslation } from 'common/locale';
import { Plugin } from 'common/types/gateway';

const t = (k: string) => fetchTranslation(k, 'dataset_management_ui.connection_agent');

interface ProvisionAgentButtonProps {
  canProvisionAgent: boolean;
  showProvAgent: () => void;
}

function ProvisionAgentButton({ canProvisionAgent, showProvAgent }: ProvisionAgentButtonProps) {
  if (canProvisionAgent) {
    return (
      <div>
        <a onClick={showProvAgent}>{t('new_agent')}</a>
      </div>
    );
  } else {
    return null;
  }
}

interface ConnectionsProps {
  children: any[];
  title: string;
  plugins: Plugin[];
  /* eslint @typescript-eslint/ban-types: "warn" */
  showAvailablePlugins: Function;
  /* eslint @typescript-eslint/ban-types: "warn" */
  showProvisionAgent: Function;
  /* eslint @typescript-eslint/ban-types: "warn" */
  showPluginHelp: Function;
  canProvisionAgent: boolean;
}

class Connections extends React.Component<ConnectionsProps, {}> {
  render() {
    const {
      children,
      title,
      showAvailablePlugins,
      showPluginHelp,
      showProvisionAgent,
      plugins,
      canProvisionAgent
    } = this.props;
    const showProvAgent = () => showProvisionAgent(() => showAvailablePlugins(showPluginHelp, plugins, true));

    return (
      <section className="import-agent-section-container">
        <div className="connections-head">
          <div>
            <h2>{t('connections')}</h2>
            <ProvisionAgentButton canProvisionAgent={canProvisionAgent} showProvAgent={showProvAgent} />
          </div>
          <div>
            <legend>{title}</legend>
          </div>
        </div>

        {children}
      </section>
    );
  }
}

export default Connections;
