import classnames from 'classnames';
import Dropdown from 'common/components/Dropdown';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { fetchTranslation } from 'common/locale';
import { DsmapiResource } from 'common/types/dsmapi';
import { isApplyMetadataPlanStep, isSetSchemaPlanStep, Plan, Revision } from 'common/types/revision';
import { Params } from 'datasetManagementUI/lib/types';
import React, { Component } from 'react';
import { option } from 'ts-option';


const t = (k: string) => fetchTranslation(k, 'dataset_management_ui.update_replace');

function DataActionOption({ title, disabled }: { title: string; disabled: boolean }) {
  const classNames = [];

  if (disabled) {
    classNames.push('action-disabled');
  }

  return <div className={classNames.join(' ')}>{title}</div>;
}

interface Props {
  revision: Revision;
  setDataAction: (r: Revision) => Promise<Revision>;
  gotoSources: () => void;
  useInverseStyle: boolean;
  params: Params;
  getPlan: (p: Params) => Promise<DsmapiResource<Plan>>;
  isDisabled: boolean;
}
interface State {
  isUpdateAllowed: boolean;
  isReplaceAllowed: boolean;
  willHavePk: boolean | null;
}
class UpdateReplaceChooser extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isUpdateAllowed: false,
      isReplaceAllowed: true, // replace is always allowed,
      willHavePk: null
    };
    this.onSelection = this.onSelection.bind(this);
  }

  componentDidMount() {
    const { params, getPlan } = this.props;
    getPlan(params).then((plan) => {
      const schemaChangeOps = option(plan.resource.find(isSetSchemaPlanStep))
        .map((s) => s.operations)
        .getOrElseValue([]);
      // Column metadata changes are recorded as "schema changes", but
      // only field name updates necessitate a working copy.
      // I think we should revisit this, though.  In particular, I think that
      // only _column type changes_ (which are represented as a
      // drop-and-add of the column) are things which absolutely can't
      // be done as updates.  Not doing that now because that's a
      // bigger change than the bug I'm trying to solve (and I think
      // there might be some usability footguns there if we're not
      // careful), so for now I'm implementing the weaker condition
      // of "does not require a working copy".
      const allSafeSchemaChanges = schemaChangeOps.every(
        (sco) =>
          sco.type === 'update_column' && sco.attributes.field_name === sco.attributes.original_field_name
      );

      const metadataChanges = option(plan.resource.find(isApplyMetadataPlanStep));
      const rowIdChange = metadataChanges.map((s) => !!s.has_pk_changes).getOrElseValue(false);
      const willHavePk = metadataChanges.map((s) => !!s.will_have_pk).getOrElseValue(false);

      this.setState({
        isUpdateAllowed: allSafeSchemaChanges && !rowIdChange,
        willHavePk
      });
    });
  }

  onSelection({ value }: { value: 'update' | 'replace' }) {
    if (value === 'update' && !this.state.isUpdateAllowed) return;
    if (value === 'replace' && !this.state.isReplaceAllowed) return;

    const updatedRev = {
      ...this.props.revision,
      action: {
        ...this.props.revision.action,
        type: value
      }
    };
    this.props.setDataAction(updatedRev).then(this.props.gotoSources);
  }

  render() {
    const appendOrUpsert = t(this.state.willHavePk ? 'upsert' : 'append');

    return (
      <Dropdown
        disabled={this.props.isDisabled}
        className="update-replace-chooser"
        onSelection={this.onSelection}
        options={[
          {
            value: 'update',
            title: appendOrUpsert,
            description: appendOrUpsert,
            disabled: !this.state.isUpdateAllowed,
            render: DataActionOption
          },
          {
            value: 'replace',
            title: t('replace'),
            description: t('replace'),
            disabled: !this.state.isReplaceAllowed,
            render: DataActionOption
          }
        ]}
        placeholder={() => {
          return (
            <a
              aria-disabled={this.props.isDisabled}
              className={classnames('btn', 'btn-sm', 'btn-alternate-2', 'btn-inverse', {
                'btn-disabled': this.props.isDisabled,
                'btn-inverse': this.props.useInverseStyle
              })}
            >
              {t('add_data')} <SocrataIcon name={IconName.ArrowDown} />
            </a>
          );
        }}
      />
    );
  }
}

export default UpdateReplaceChooser;
