import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getEnvironment } from 'common/environment';
import { FeatureFlags } from 'common/feature_flags';
import { ScanStatus } from 'common/components/ScanResult';

import PublishButton from 'datasetManagementUI/components/PublishButton/PublishButton';
import { taskSetApplyable } from 'datasetManagementUI/reduxStuff/actions/applyRevision';
import * as Selectors from 'datasetManagementUI/selectors';
import { hideModal, showModal } from 'datasetManagementUI/reduxStuff/actions/modal';
import { hasDatasetErrors } from '../selectors';
import { getScanResult, hasPublishingAbilities } from 'datasetManagementUI/lib/util';
import { isEditMode } from 'datasetManagementUI/lib/modes';
import { isSoQLBasedRevision } from 'common/types/revision';

function isUSAID() {
  return FeatureFlags.value('usaid_features_enabled');
}

function isViewSource(source) {
  return _.get(source, 'source_type.type', '') === 'view';
}

function isLoadedViewSource(source) {
  return isViewSource(source) && _.get(source, 'source_type.loaded', false);
}

function isUnloadedViewSource(source) {
  return isViewSource(source) && !isLoadedViewSource(source);
}

export function isBlobEdit(view = {}, sources = {}) {
  return isEditMode(view) && view.viewType === 'blobby' && _.isEmpty(sources);
}

function isHrefSatisfied(entities, ui, params) {
  const rev = Selectors.currentRevision(entities, _.toNumber(params.revisionSeq));
  return rev.output_schema_id == null && !!rev.href.length && !ui.forms.hrefForm.errors.length;
}

function requiresParent(entities, params) {
  const rev = Selectors.currentRevision(entities, _.toNumber(params.revisionSeq));
  return isUSAID() && !rev.is_parent;
}

function isParent(entities, params) {
  const rev = Selectors.currentRevision(entities, _.toNumber(params.revisionSeq));
  return isUSAID() && rev.is_parent;
}

// do not allow publishing of 'is_parent: false' revisions w/o a collectionParent on USAID
function hasParent(entities, params) {
  if (!isUSAID()) {
    return true;
  }
  const rev = Selectors.currentRevision(entities, _.toNumber(params.revisionSeq));
  return _.get(rev, 'metadata.metadata.collectionParent', isParent(entities, params)) !== false;
}

function isDataSatisfied(entities, params) {
  const revisionSeq = _.toNumber(params.revisionSeq);
  const source = Selectors.currentSource(entities, revisionSeq);
  const view = _.get(entities, `views.${params.fourfour}`);
  const outputSchema = Selectors.currentOutputSchema(entities, revisionSeq);
  const blob = Selectors.currentBlobSource(entities, revisionSeq);
  const revision = Selectors.currentRevision(entities, revisionSeq);

  if (
    isUSAID() ||
    isUnloadedViewSource(source) ||
    isBlobEdit(view, entities.sources) ||
    isSoQLBasedRevision(revision)
  ) {
    return true;
  } else if (outputSchema) {
    return !!outputSchema.finished_at;
  } else if (blob) {
    const scanState = getScanResult(blob.scanResult).state;
    const isDevelopment = getEnvironment() === 'development';
    // if unscanned && development: okay
    // if unscanned && !development: bad
    // if bad: bad
    return (
      !!blob.finished_at &&
      scanState !== ScanStatus.Bad &&
      (isDevelopment || scanState !== ScanStatus.Unscanned)
    );
  } else {
    return false;
  }
}

function requiresHref(entities, ui, params) {
  const view = _.get(entities, `views.${params.fourfour}`);
  if (isParent(entities, params)) {
    return false;
  } else if (isEditMode(view)) {
    return view.viewType === 'href';
  } else {
    return isHrefSatisfied(entities, ui, params);
  }
}

function isMetadataSatisfied(ui) {
  return !hasDatasetErrors(ui);
}

export function readyToPublish(entities, ui, params) {
  let ready = true;

  if (requiresHref(entities, ui, params)) {
    ready = ready && isHrefSatisfied(entities, ui, params);
  } else {
    // if it doesn't require href, it requires data
    ready = ready && isDataSatisfied(entities, params);
  }

  if (requiresParent(entities, params)) {
    ready = ready && hasParent(entities, params);
  }

  return ready && isMetadataSatisfied(ui);
}

function statusIsApplyable(taskSets) {
  return !!_.chain(taskSets)
    .filter((set) => taskSetApplyable(set))
    .size()
    .value();
}

export function mapStateToProps({ entities, ui }, { params }) {
  const view = entities.views[params.fourfour];
  return {
    params,
    metadataSatisfied: !hasDatasetErrors(ui),
    requiresHref: requiresHref(entities, ui, params),
    hrefSatisfied: isHrefSatisfied(entities, ui, params),
    requiresData: !isParent(entities, params) && !requiresHref(entities, ui, params),
    dataSatisfied: isDataSatisfied(entities, params),
    view,
    requiresParent: requiresParent(entities, params),
    hasParent: hasParent(entities, params),
    applyableState: statusIsApplyable(entities.task_sets),
    hasPublishingAbilities: hasPublishingAbilities(view),
    readyToPublish: readyToPublish(entities, ui, params)
  };
}

function mergeProps(stateProps, { dispatch }, ownProps) {
  // ownProps.onClick comes from AAB...or something...sometimes
  let onContinue = () => dispatch(showModal('PublishConfirmation'));
  if (ownProps.onClick) {
    onContinue = () => {
      dispatch(hideModal());
      ownProps.onClick();
    };
  }

  return {
    ...stateProps,
    ...ownProps,
    onClick: () =>
      dispatch(
        showModal('Plan', {
          onContinue
        })
      )
  };
}

export default withRouter(connect(mapStateToProps, null, mergeProps)(PublishButton));
