import _ from 'lodash';
import { SHOW_FLASH_MESSAGE, HIDE_FLASH_MESSAGE, HIDE_ALL_FLASH_MESSAGES } from 'datasetManagementUI/reduxStuff/actions/flashMessage';

export const initialState = {
  flashMessages: []
};

const flashMessage = (state = initialState, { type, id, message, helpMessage, helpUrl, kind, alignLeft }) => {
  switch (type) {
    case SHOW_FLASH_MESSAGE:
      if (!id) {
        throw new Error('showFlashMessage requires a message ID');
      }
      // don't display duplicate messages
      if (state.flashMessages.some(msg => msg.id === id)) {
        return {
          ...state
        };
      }

      return {
        ...state,
        flashMessages:
          [
            ...state.flashMessages,
            {
              id,
              message,
              helpMessage,
              helpUrl,
              kind,
              alignLeft,
              visible: true
            }
          ]
      };
    case HIDE_FLASH_MESSAGE:
      if (!id) {
        throw new Error('hideFlashMessage requires a message ID');
      }
      return {
        ...state,
        flashMessages: _.reject(state.flashMessages, { id })
      };
    case HIDE_ALL_FLASH_MESSAGES:
      return {
        ...state,
        flashMessages: []
      };
    default:
      return state;
  }
};

export default flashMessage;
