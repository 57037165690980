import I18n from 'common/i18n';
import { fetchTranslation } from 'common/locale';
import { Source } from 'common/types/source';

const scope = 'dataset_management_ui.connection_agent';
const t = (k: string) => fetchTranslation(k, scope);

export interface AgentErrorParams {
  fallback?: string;
  translations?: {
    [lang: string]: string;
  };
}

export class AgentError {
  private underlying: AgentErrorParams | undefined;

  constructor(rejection: AgentErrorParams | undefined) {
    this.underlying = rejection;
  }

  private intoString(): string | null | undefined {
    if (this.underlying && this.underlying.translations) {
      return this.underlying.translations[I18n.locale] || this.underlying.fallback;
    }
    return null;
  }

  public get(): string {
    return this.getOrElse(t('unknown_agent_error'));
  }

  public getOrElse(other: string) {
    return this.intoString() || other;
  }
}

export interface DataSourceParameterSpec {} // todo: refine when converting the modal
export interface DataSourceParameters {} // todo: refine when converting the modal

export type CreateSource = (p: DataSourceParameters) => Promise<Source>;
export type StartSource = (s: Source) => Promise<void>;
